import React, { useRef } from 'react'

//Styles
import Styles from './search.module.scss'

//Assets
import { ReactComponent as Magnifier } from 'assets/magnifier-v1.svg'

import { useDetailListingState } from '../../context'
import useEnterKey from "hooks/useEnterKey";

const Search = () => {
  const { search, setSearch, triggerSearch } = useDetailListingState();
  const refInput = useRef(null)

  useEnterKey(() => {
    
    //check is input is focused
    if (document.activeElement === refInput.current) {
      triggerSearch()
    }

  })

  return (
    <div className={Styles['wrapper']}>
      <Magnifier onClick={triggerSearch} />
      <input ref={refInput} onChange={(e) => setSearch(e.target.value || '')} value={search} placeholder='Nama / email / telepon / No. Regitrasi' />
    </div>
  )
}

export default Search