import { useContext } from 'react'
import { GlobalContext } from "contexts";
import { Navigate } from 'react-router-dom';

function PrivateDashboardList({ children }) {
  const { profile } = useContext(GlobalContext);

  if(profile?.company?.id != 228) return <Navigate to="/" />
  return children
}

export default PrivateDashboardList