import React from 'react'

// Components
import ModalWrapper from "./ModalWrapper";
import Divider from "./Divider";
import Button from 'components/v1/Button';

import Checklist from "assets/checklist-v1.png"

const ModalSuccessCancelCommision = ({ onClose, ...props }) => {
  return (
    <ModalWrapper {...props} onClose={onClose} hideClose={true} className="!p-0">
      <div className='pt-6 px-8 pb-4 flex flex-col items-center'>
        <img className='w-[68px] h-[68px] object-contain mb-4' src={Checklist} alt='Checklist' />
        <p className='text-[18px] font-semibold mb-2'>Sisa Komisi Telah Dibatalkan</p>
        <p className='text-sm text-gray-2'>Riwayat transaksi bisa dilihat pada tab Disetujui. Kamu juga bisa melihat kembali jumlah sisa komisi yang dibatalkan.</p>
        
        <Divider className="!my-4" />

        <div className='flex justify-center'>
          <Button
            text={"Tutup"}
            onClick={onClose}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalSuccessCancelCommision