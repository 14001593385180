import { useState, useContext } from 'react'

import Toggle1 from "components/v1/Toggle1"
import { UploadListingContext } from "../../../../contexts"

const LMSToggler = () => {
  const { isToggleLMS, setIsToggleLMS, slug, createdArebi } = useContext(UploadListingContext);

  return (
    <div className='relative pr-[32px] mr-[32px] border-r-[1px] border-r-solid border-r-white-0 flex items-center'>
      <div className='mr-[32px]'>
        <p className={`text-base font-semibold`}>Bagikan ke MLS AREBI?</p>
        <p className={`text-xs`}><span className={`font-semibold`}>{isToggleLMS ? "Ya" : "Tidak"},</span> listing {isToggleLMS ? <span className={`font-semibold text-green-0`}>dibagikan</span> : <span className='font-semibold text-red-0'>tidak dibagikan</span>} ke MLS AREBI</p>
      </div>
      <Toggle1
        selected={isToggleLMS}
        onClick={() => setIsToggleLMS(!isToggleLMS)}
      />
    </div>
  )
}

export default LMSToggler