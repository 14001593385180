import { useMutation } from '@tanstack/react-query'

import {
  addCalonMA,
  deleteCalonMA,
  editCalonMA,
  addTrainingParticipant,
  createTagihan,
  deleteTrainingParticipant
} from "./api"

export const useAddCalonMa = () => {
  return useMutation({
    queryKey: ['add-calon-ma'],
    mutationFn: (params) => addCalonMA(params)
  })
}

export const useEditCalonMa = () => {
  return useMutation({
    queryKey: ['edit-calon-ma'],
    mutationFn: ({ id, params }) => editCalonMA(id, params)
  })
}

export const useDeleteCalonMa = () => {
  return useMutation({
    queryKey: ['delete-calon-ma'],
    mutationFn: (id) => deleteCalonMA(id)
  })
}

export const useDeleteParticipant = () => {
  return useMutation({
    queryKey: ['delete-training-participant'],
    mutationFn: (data) => deleteTrainingParticipant(data)
  })
}

export const useRegisterParticipant = () => {
  return useMutation({
    queryKey: ['register-participant'],
    mutationFn: ({ id, data }) => addTrainingParticipant(id, data)
  })
}

export const useCreateTagihan = () => {
  return useMutation({
    queryKey: ['create-tagihan'],
    mutationFn: (id) => createTagihan(id)
  })
}
