import React, { useState } from 'react'
import lodash from 'lodash'

//Styles
import Styles from './plafon-kredit.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'


//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PlafonKredit() {
  const {
    setCurrentStep,
    formHandler,
    handlePrevState
  } = useFormMethod();
  const { form } = useFormState()

  //State
  const [plafonKredit, setPlafonKredit] = useState(form.plafond.value || 0)

  const onNext = () => {
    handlePrevState()

    formHandler("plafond", plafonKredit)
    setCurrentStep(STEPS.namaDebitur)
  }

  const hideButton = () => {
    if (lodash.toNumber(plafonKredit) === 0) return false
    return true
  }

  return (
    <WrapperAndBox>
      <div className={Styles['komisi-wrapper']}>
        <TextMedium required>
          Plafon Kredit
        </TextMedium>
        <div className={Styles['wrapper-currency']}>
          <InputCurrency
            disabled={false}
            onValueChange={(val) => setPlafonKredit(val)}
            value={plafonKredit || ''}
            placeholder={"0"}
            decimalSeparator="."
            groupSeparator=","
            className="hide-border"
            Icon={() => <span className={Styles['prefix']}>Rp</span>}
          />
        </div>
      </div>

      <BoxFlex>
        <>
          <ButtonSebelumnya />
          {hideButton() && <ButtonSelanjutnya onClick={onNext} />}
        </>
      </BoxFlex>
    </WrapperAndBox>
  )
}


export default PlafonKredit
