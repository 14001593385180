import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

// Components
import ModalWrapper from "./ModalWrapper";
import Divider from "./Divider";
import Button from 'components/v1/Button';

// Assets
import PDF from "assets/pdf-1.png";
import Gallery from "assets/gallery-1.png";
import Trash from "assets/trash-1.png";

// Api
import { cancelCommision } from 'actions';

// Context
import { useGlobalCtx } from 'contexts';

const ModalCancelCommision = ({
  onNextModal,
  onClose,
  files,
  setFiles,
  idTrx,
  pendingCommision,
  ...props
}) => {
  const { setShowLoading } = useGlobalCtx();

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      toast.error(fileRejections[0].errors[0].message, {
        position: toast.POSITION.TOP_CENTER
      })
    }

    const currentFiles = [...files, ...acceptedFiles].filter((_, index) => index < 5)

    setFiles(currentFiles || [])
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp'],
      'application/pdf': ['.pdf']
    }
  })

  const handleDelete = (selectedIdx) => {
    const newFiles = files.filter((_, i) => i !== selectedIdx);
    setFiles(newFiles)
  }

  const handleCancelCommision = async () => {
    try {
      const formData = new FormData()

      files.forEach((file, index) => {
        formData.append(`transaction_report[tgcc_cancellations_attributes[${index}][cancellation_attachment]]`, file)
      });

      setShowLoading(true)
      await cancelCommision(idTrx, formData)
      onNextModal()
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  return (
    <ModalWrapper {...props} onClose={onClose} className="!pt-6 !px-8 !pb-4">
      <div>
        <p className='text-[18px] font-medium mb-2'>Batalkan Sisa Komisi yang Belum Diterima?</p>
        <p className='text-sm text-gray-2 mb-2'>Setelah memilih batalkan, sisa komisi yang belum diterima sebesar <span className='font-semibold text-black-0'>Rp{pendingCommision}</span> akan dihapus dari informasi transaksi bertahap.</p>
        <p className='text-sm text-gray-2 mb-4'><span className='font-semibold text-black-0'>Harap diperhatikan:</span> Pembatalan ini akan mengurangi Trading GCC pada bulan berjalan.</p>

        <div className='dropzone rounded-[8px] px-4 py-6 border border-dashed border-yellow-0 bg-yellow-6'>
          <div {...getRootProps({ className: 'flex flex-col items-center dropzone' })}>
            <div className='flex items-center justify-center mb-4'>
              <img className="w-8 h-8 mr-6 object-fit" src={PDF} alt='pdf' />
              <img className="w-8 h-8 object-fit" src={Gallery} alt='photo' />
            </div>
            <p className='text-sm font-medium text-gold-1 underline cursor-pointer'>Klik untuk unggah bukti pembatalan</p>
            <p className='text-xs text-gray-0'>Berkas .pdf atau .png/.jpg (maksimal 5 gambar)</p>

            <input {...getInputProps()} />
          </div>
          <div className='w-full'>
            {!!files?.length && files.map((f, i) => {
              const isPDF = f.type === "application/pdf";

              return (
                <div key={`files-cancel-commision-${i}`} className='mb-2 first:mt-4 last:mb-0 w-full flex items-center justify-between rounded-[8px] p-[12px] border-[0.5px] border-solid border-white-0 bg-white'>
                  <img className='w-8 h-8 object-contain' src={isPDF ? PDF : Gallery} alt={f.name} />
                  <div className='w-[calc(100%-48px-16px)]'>
                    <p className='text-sm font-medium'>{f.name}</p>
                    <p className='text-xs text-gray-0'>Ukuran file: <span className='text-black-0'>{f.size} kb</span></p>
                  </div>
                  <img onClick={() => handleDelete(i)} className='cursor-pointer w-4 h-4 object-contain' src={Trash} alt='Delete' />
                </div>
              )
            })}
          </div>
        </div>

        <Divider className="!my-4" />

        <div className='flex justify-end'>
          <Button
            text={"Kembali"}
            onClick={onClose}
            type="secondary"
            className="mr-4"
          />
          <Button
            text={"Batalkan Sisa Komisi"}
            onClick={handleCancelCommision}
            type="negative"
            className="!text-semibold"
            disabled={!files?.length}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalCancelCommision