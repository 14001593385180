import {
  createContext,
  useContext,
  useState,
  useMemo
} from "react";

// API
import {
  useGetCalonMa,
  useGetTraining,
  useGetAllTrainingAndBatch
} from 'api/training/query';
import dayjs from 'dayjs';

const ListEducation = [
  {
    label: "SMA",
    value: "SMA"
  },
  {
    label: "D3",
    value: "D3"
  },
  {
    label: "S-1",
    value: "S-1"
  },
  {
    label: "S-2",
    value: "S-2"
  },
  {
    label: "S-3",
    value: "S-3"
  }
]

export const TrainingCtx = createContext();

export const TrainingProvider = ({ children }) => {
  const [paramsListCalonMa, setParamsListCalonMa] = useState({
    q: ""
  })
  const { data: listCalonMa, refetch: refetchCalonMa, isFetching: isFetchingCalonMA } = useGetCalonMa({ params: paramsListCalonMa });

  const { data: listTraining, refetch: refetchListTraining, isFetching: isFetchingListTraining } = useGetTraining({});
  const { data: listAllTrainingAndBatches, refetch: refetchAllTrainingAndBatch, isFetching: isFetchingTrainingAndBatch } = useGetAllTrainingAndBatch({});

  const isFreeBatchOpen = useMemo(() => {
    if (!listAllTrainingAndBatches?.data?.data?.length) return false;

    const newList = listAllTrainingAndBatches?.data?.data?.map(l => {
      if (!!l?.batches?.length) {
        return { ...l, batches: l?.batches.filter(b => b?.batch_status === "active" && dayjs().isBefore(b?.end_date)) }
      }
      return l
    })

    return !!newList?.find(d => !d?.is_paid && !!d?.batches?.length)
  }, [listAllTrainingAndBatches?.data?.data])

  const isPaidBatchOpen = useMemo(() => {
    if (!listAllTrainingAndBatches?.data?.data?.length) return false;

    const newList = listAllTrainingAndBatches?.data?.data?.map(l => {
      if (!!l?.batches?.length) {
        return {
          ...l, batches: l?.batches.filter(b => {
            return b?.batch_status === "active" && dayjs().isBefore(b?.end_date)
          })
        }
      }
      return l
    })

    return !!newList?.find(d => d?.is_paid && !!d?.batches?.length)
  }, [listAllTrainingAndBatches?.data?.data])

  const refetchAll = () => {
    refetchCalonMa()
    refetchListTraining()
    refetchAllTrainingAndBatch()
  }

  return (
    <TrainingCtx.Provider value={{
      listCalonMa,
      listTraining,
      ListEducation,
      listAllTrainingAndBatches,

      // Params
      paramsListCalonMa,
      setParamsListCalonMa,
      refetchCalonMa,

      refetchAll,
      isFreeBatchOpen,
      isPaidBatchOpen,

      isLoading: isFetchingCalonMA || isFetchingListTraining || isFetchingTrainingAndBatch
    }}>
      {children}
    </TrainingCtx.Provider>
  )
}

export const useTrainingCtx = () => {
  const ctx = useContext(TrainingCtx);

  if (!ctx) {
    throw new Error("useTrainingCtx must be used within the TrainingProvider");
  }

  return ctx;
};
