import React, { useState } from 'react'

// Assets
import { ReactComponent as IconMagnifierActive } from "assets/black-magnify.svg"

// Components
import CardBatch from "./components/card-batch"
import CardBatchPassed from "./components/card-batch-passed"

// Ctx
import { useTrainingCtx } from "pages/dashboard/training/useTraining"
import { useDebounce } from 'hooks/useDebounce';
import { useGetTrainingBatch, useGetPassedParticipant } from 'api/training/query';

const CardTraining = ({ id, name, is_paid }) => {
  const {
    setParamsListCalonMa,
    paramsListCalonMa
  } = useTrainingCtx();

  const { data: listPassedBatch } = useGetPassedParticipant({ params: {} })
  const { data: listBatch } = useGetTrainingBatch({ trainingId: id, params: {} })

  const [tab, setTab] = useState("batch") // batch | peserta-lulus

  const [search, setSearch] = useState("")

  const { debounceFn } = useDebounce({
    onChange: (val) => setParamsListCalonMa({ ...paramsListCalonMa, q: val })
  })

  const handleSearch = (e) => {
    const val = e.target.value || ''
    debounceFn(val)
    setSearch(val)
  }

  const handleSetTab = (t) => {
    setTab(t)
    setParamsListCalonMa({
      ...paramsListCalonMa,
    })
  }
 
  return (
    <div className='mr-4 border-r border-solid border-[#D8D8D9] last:mr-0 last:pr-0 last:border-0'>
      <div className={'min-w-[310px] max-w-[310px] rounded bg-white'}>
        <div className='flex items-center px-4 py-2 rounded-t'>
          <span className='text-[13px] font-semibold'>{name}</span>
        </div>
        <div className='h-0.5 border-b-[2px] border-solid border-[#D8D8D9]' />
        {!is_paid && <div>
          <button
            onClick={() => handleSetTab("batch")}
            className={`${tab === "batch" ? "text-white font-semibold bg-black-1" : ""} w-[50%] px-4 py-2 text-[11px] bg-white`}
          >Batch</button>
          <button
            onClick={() => handleSetTab("peserta-lulus")}
            className={`${tab === "peserta-lulus" ? "text-white font-semibold bg-black-1" : ""} w-[50%] px-4 py-2 text-[11px] bg-white`}
          >Peserta Lulus</button>
        </div>}
        <div className='h-0.5 border-t-[1px] border-solid border-[#252526]' />
        <div className='px-4 py-2'>
          <div className={`flex items-center w-full rounded border border-solid p-2 
          ${!listBatch?.data?.data ? "bg-[#FAFAFA] border-[#FAFAFA]" : "border-[#D8D8D9]"}`}>
            <IconMagnifierActive className='w-[14px] h-[14px] mr-1' />
            <input
              placeholder='Cari marketing'
              className='text-[11px] placeholder:text-[#D8D8D9]'
              onChange={handleSearch}
              value={search}
              disabled={!listBatch?.data?.data}
            />
          </div>
        </div>
        <div className='h-0.5 border-b-[0.5px] border-dashed border-[#D8D8D9]' />
        {tab === "batch" ? (
          <div className={is_paid ? 'h-[532px] overflow-y-auto hide-scrollbar' : 'h-[500px] overflow-y-auto hide-scrollbar'}>
            {listBatch?.data?.data?.length ? (listBatch.data.data.map((batch, idx) => {
              return <CardBatch tab={tab} trainingId={id} key={`list-batch-${batch?.id}-${idx}`} {...batch} />
            })) : (
              <div className='w-full h-full flex justify-center items-center'>
                <p className='p-4 text-[13px] text-[#D8D8D9]'>Belum ada batch dibuka</p>
              </div>
            )}
          </div>
        ) : (
          <div className='h-[500px] overflow-y-auto hide-scrollbar'>
            {listPassedBatch?.data?.data?.length ? (listPassedBatch.data.data.map((batch, idx) => {
              return <CardBatchPassed tab={tab} trainingId={id} key={`list-batch-${batch?.id}-${idx}`} {...batch} />
            })) : (
              <div className='w-full h-full flex justify-center items-center'>
                <p className='p-4 text-[13px] text-[#D8D8D9]'>Belum ada batch dibuka</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CardTraining