
import { api } from "../utils";

//Constant
import {
  //URL
  MARKETING_AGENT,
  GET_TRANSACTION_REPORT,
  MY_LISTINGS,
  PROPERTY_KANTOR,
  LEADS,
  PROPERTY_SEARCH,
  COLLEAGUES,
  INVOICE,
  IPAF_EVENT,
  SUBMIT_IPAF_EVENT,
  TRSANCTION_LIST,
  VALIDATE_OTP,
  CREATE_NEW_PASSWORD,
  REQUEST_OTP,
  URL_E_REPORTING_COMPANY,
  PROPERTY_KANTOR_INACTIVE,
  ACTIVED_MY_LISTINGS,
  CREATE_PJP_OFFLINE,
  GET_RUMAH_123,
  TRACK_DOWNLOAD_BANNER,
  LIST_LEADS,
  HOT_LISTING,
  MARKETING_AGREEMENT,
  CANCEL_TGCC,
  LIST_DISTRIBUTION_LEADS,
  LIST_MARKETING_DISTRIBUTION_LEADS,
  SUBMIT_DISTRIBUTION_LEADS,
  CONFIRM_VISIT,
  GET_ALL_CITIES,
  AREAS,
  COUNT_DISTRIBUTION_LEADS
} from "../constant/URL";

import authAction from "./authAction";
import uploadListingAction from "./uploadListingAction";
import eReportingAction from "./eReportingAction";

const getMarketingAgent = async (query = "") => await api().get(`${MARKETING_AGENT}/${query}`)
const getTransactionReport = async (params) => await api({}, true).get(`${GET_TRANSACTION_REPORT}-v2${params}`);
const getTransactionReportSummary = async (query = "") => await api({}, true).get(`${GET_TRANSACTION_REPORT}/summary${query}`);
const getPendingTx = async (id = "") => await api({}, true).get(`${GET_TRANSACTION_REPORT}/not-yet-paid-off-count`);
const getCommisionHistoryTx = async (id = "") => await api({}, true).get(`${GET_TRANSACTION_REPORT}/${id}/payments`);

const getMyListing = async (query = "") => await api().get(`${MY_LISTINGS}${query}`);
const getListingKantor = async (query = "") => await api({}, true).get(`${PROPERTY_KANTOR}${query}`);
const getListingKantorInactive = async (query = "") => await api({}, true).get(`${PROPERTY_KANTOR_INACTIVE}${query}`);
const searchListProperty = async (query = "") => await api({}, true).get(`${PROPERTY_SEARCH}${query}`);
const getDetailListing = async (slug = "") => await api().get(`${MY_LISTINGS}/${slug}`);
const getDetailOfficeListing = async (slug = "") => await api().get(`${PROPERTY_KANTOR}/${slug}`);
const getDetailImages = async (link) => await api().get(link);
const editListing = async (slug = "", payload) => await api().patch(`${MY_LISTINGS}/${slug}`, payload);
const archiveListing = async (slug = "") => await api().patch(`${MY_LISTINGS}/${slug}/archive`);
const unarchiveListing = async (slug = "") => await api().patch(`${MY_LISTINGS}/${slug}/unarchive`);
const getLeadsDashboardList = async (query = "") => await api().get(`${LEADS}${query}`);
const confirmVisitScheduled = async (id, payload) => await api().post(`${CONFIRM_VISIT.replace(":id", id)}`, payload);
const updateLeads = async (id = "", data) => await api().patch(`${LEADS}/${id}`, data);
const deleteListing = async (slug = "") => await api().delete(`${MY_LISTINGS}/${slug}`);
const activedListing = async (slug = "") => await api().patch(`${ACTIVED_MY_LISTINGS}/${slug}/active`);

// Daftar Listing
const exportListing = async (query = "") => await api({}, true, { responseType: 'arraybuffer' }).get(`${PROPERTY_KANTOR}/export.xlsx${query}`);

//Users
const getColleagues = async (query = "") => await api({}, true).get(`${COLLEAGUES}${query}`);
const getColleagueDetail = async (id) => await api().get(`${COLLEAGUES}/${id}`);
const updateColleague = async (id, payload) => await api().patch(`${COLLEAGUES}/${id}`, payload);

//Invoices
const getInvoiceList = async (query = "") => await api().get(`${INVOICE}${query}`);
const getInvoiceDetail = async (id) => await api().get(`${INVOICE}/${id}`);
const getInvoicePayments = async (id) => await api().get(`${INVOICE}/${id}/payments`);

//Event
const getIpafEvents = async () => await api().get(IPAF_EVENT);
const submitRegistration = async (payload) => await api().post(SUBMIT_IPAF_EVENT, payload);

const submitCommision = async (id, payload) => await api().post(`${TRSANCTION_LIST}/${id}/received-commission`, payload);
const deleteTransactionReport = async (id) => await api().delete(`${TRSANCTION_LIST}/${id}`);

const requestOtp = async (payload) => await api().post(`${REQUEST_OTP}`, payload);
const confirmOtp = async (payload) => await api().post(`${VALIDATE_OTP}`, payload);
const createNewPassword = async (payload) => await api().post(`${CREATE_NEW_PASSWORD}`, payload);

const downloadereporting = async (nextParam) => await api().get(URL_E_REPORTING_COMPANY + nextParam);
const createPJPOffline = async (payload) => await api().post(CREATE_PJP_OFFLINE, payload);
const getRumah123 = async (query = "") => await api({}, true).get(`${GET_RUMAH_123}${query}`);
const trackBannerDownloads = async (payload) => await api().post(TRACK_DOWNLOAD_BANNER, payload);
const getLeadsList = async (query = "") => await api({}, true).get(`${LIST_LEADS}${query}`);

// Leads Distribution
const getLeadsDistributionList = async (query = "") => await api({}, true).get(`${LIST_DISTRIBUTION_LEADS}${query}`);
const getLeadsDistributionMarketingList = async (query = "") => await api({}, true).get(`${LIST_MARKETING_DISTRIBUTION_LEADS}${query}`);
const submitDistributionLeads = async (id = "", payload) => await api({}, true).patch(`${SUBMIT_DISTRIBUTION_LEADS.replace(":id", id)}`, payload);
const getDistributionLeadsCount = async (query) => await api({}, true).get(`${COUNT_DISTRIBUTION_LEADS}${query}`);

// Hot listing
const createHotListing = async (payload) => await api().post(HOT_LISTING, payload);
const getHotListings = async (query = "") => await api({}, true).get(`${HOT_LISTING}${query}`);
const deleteHotListings = async (hotListingId) => await api({}, true).delete(`${HOT_LISTING}/${hotListingId}`);
const getMarketingAgreement = async (uuid) => await api({}, true).get(`${MARKETING_AGREEMENT}/${uuid}`);

const cancelCommision = async (id, formData) => await api().patch(`${CANCEL_TGCC.replace(":id", id)}`, formData);
const getAllCities = async () => await api({}, true).get(GET_ALL_CITIES);
const getAllAreas = async (query) => await api({}, true).get(`${AREAS}?_for=areaonly&q=${query}`);

export {
  authAction,
  uploadListingAction,
  eReportingAction,

  //Global API
  getMarketingAgent,
  getTransactionReport,
  getListingKantor,
  getDetailListing,
  getDetailImages,
  getDetailOfficeListing,
  editListing,
  getLeadsDashboardList,
  updateLeads,
  getTransactionReportSummary,
  searchListProperty,
  getColleagues,
  getColleagueDetail,
  updateColleague,
  getInvoiceList,
  getInvoiceDetail,
  getInvoicePayments,
  getIpafEvents,
  submitRegistration,
  exportListing,
  submitCommision,
  archiveListing,
  unarchiveListing,
  confirmOtp,
  createNewPassword,
  requestOtp,
  downloadereporting,
  getListingKantorInactive,
  activedListing,
  deleteListing,
  createPJPOffline,
  getRumah123,
  deleteTransactionReport,
  trackBannerDownloads,
  getPendingTx,
  getCommisionHistoryTx,
  getLeadsList,
  getMarketingAgreement,
  cancelCommision,
  getLeadsDistributionList,
  getLeadsDistributionMarketingList,
  submitDistributionLeads,
  confirmVisitScheduled,
  getAllCities,
  getDistributionLeadsCount,

  // Hot Listing
  createHotListing,
  getHotListings,
  deleteHotListings,
  getAllAreas
}