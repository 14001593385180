import React from 'react'

// Components
import { ReactComponent as InfoIcon } from "assets/info-ereporting.svg";

const InfoSquare = ({ openModal }) => {
  return (
    <div className='relative flex items-center'>
      <button className='cursor-pointer' onClick={openModal}>
        <InfoIcon className='w-[34px] h-[34px] object-contain' />
      </button>
    </div>
  )
}

export default InfoSquare