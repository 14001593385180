import React from 'react'

// Components
import ModalV2 from 'components/organism/modal-v2';

// Assets
import { ReactComponent as Warning } from "assets/warning-orange.svg";
import { ReactComponent as CrossIcon } from "assets/cross.svg";

// Componenst
import Button from "components/v1/Button"

const Divider = ({ className }) => <div className={`w-full h-[1px] bg-white-0 my-4 ${className}`} />

const ModalConfirmationHotListing = ({
  onClose,
  onContinue,
  ...props
}) => {

  return (
    <ModalV2 onClose={onClose} {...props}>
      <div className='bg-white rounded-md w-[488px] px-8 py-6 z-[42]'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <Warning className='w-6 h-6 object-fit mr-1.5' />
            <span className='font-semibold text-base'>Yakin Jadikan Hot Listing?</span>
          </div>
          <CrossIcon onClick={onClose} className='w-4 h-4 object-fit cursor-pointer' />
        </div>
        <Divider />
        <p className='text-sm mb-8'>
          Setelah memilih “Ya”, Hot Listing akan aktif dan berakhir <span className='font-semibold'>7 hari</span> terhitung sejak diaktifkan.
        </p>
        <div className='flex items-center justify-end'>
          <Button
            text="Kembali"
            size="xl"
            type="secondary"
            className="mr-4"
            onClick={onClose}
          />
          <Button
            text="Ya, Jadikan Hot Listing"
            size="xl"
            onClick={onContinue}
          />
        </div>
      </div>
    </ModalV2 >
  )
}

export default ModalConfirmationHotListing
