import dayjs from "dayjs";
import "dayjs/locale/id";

//ex: 21 Agustus 2022
export const formatTanggalV1 = (date = "", format = "DD MMMM YYYY") => {
  if (!date) return "";
  let result = dayjs(date).format(format)

  if (result == "Invalid Date" || !result) {
    const newDate = date.replace(/-/g, '/')
    result = dayjs(newDate).format(format)
  }

  return result
}