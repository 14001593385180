import React from 'react'

//Styles
import "./style.scss"

//Components
import BarisPertama from "./baris-pertama";
import BarisKedua from "./baris-kedua";
import BarisKetiga from "./baris-ketiga";
import Chart from "pages/dashboard/key-metric-office-dashboard/reuseable-component/chart"

import { DataKeseluruhanProvider } from "./ctxDataKeseluruhan";

function DataKeseluruhan() {
  return (
    <DataKeseluruhanProvider>
      <div id='kmod-data-keseluruhan'>
        <BarisPertama />
        {/* <BarisKedua /> */}
        <BarisKetiga />
        {/* <Chart /> */}
      </div>
    </DataKeseluruhanProvider>
  )
}

export default DataKeseluruhan