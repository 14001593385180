import React from 'react';

// Component
import CModal from '../organism/CModal';
import { Divider, Button } from "../atomic";

// Assets
import { ReactComponent as CrossIcon } from "../../assets/cross.svg"

function ModalSuccessV1({ Image, title, Info, confirmText, confirmAction }) {
  return (
    <CModal id='modal-success-v1'>
      <div className='modal-content'>
        <CrossIcon className='close-icon' />

        <div className='modal-body'>
          <Image className="header-image" />
          <p className='title'>{title}</p>
          <Info className="info" />
        </div>

        <Divider />

        <div className='modal-footer'>
          <Button action={confirmAction} text={confirmText} className='confirm-btn !text-white' />
        </div>
      </div>
    </CModal>
  )
}

export default ModalSuccessV1