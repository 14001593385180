import React, {useRef} from 'react'

function CTextInputV2({
  label,
  name,
  placeholder,
  disabled,
  onChange,
  error,
  value,
  className,
  suffixPosition,
  suffixText,
  suffixIcon,
  type,
  mandatoryLabel,
  optionalLabel,
  arrowOff = false  
}) {
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    // Mendapatkan kode tombol yang ditekan
    const keyCode = event.keyCode || event.which;

    // Mengabaikan peristiwa jika tombol panah atas atau bawah ditekan
    if (keyCode === 38 || keyCode === 40) {
      event.preventDefault();
    }
  };

  return (
    <div className={`c-textinput-v2 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      {mandatoryLabel ? <span className='addon-label'>{"("}<span className='mandatory-label'>* tidak boleh kosong</span>{")"}</span> : null}
      {optionalLabel ? <span className='addon-label'>{"("}<span className='optional-label'>boleh kosong</span>{")"}</span> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className='suffix-wrapper'>
          {suffixIcon ? suffixIcon : <span>{suffixText}</span>}
        </div>

        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <input
            ref={inputRef}
            name={name}
            value={value || ""}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            type={type}
            onKeyDown={!arrowOff && handleKeyDown}
            onWheel={!arrowOff && ((e) => e.target.blur())}
          />
        </div>

      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

CTextInputV2.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  type: "text",
  disabled: false,
  mandatoryLabel: false,
  onChange: () => { }
}

export default CTextInputV2