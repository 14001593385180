import React, { useState, useContext, useEffect } from 'react'

//Assets
import { ReactComponent as CrossIcon } from "../../../assets/cross.svg";
import { ReactComponent as MagnifierIcon } from "../../../assets/magnifier.svg";

//Components
import { CModal, CSearchV1, Divider } from "../../../components";
import Button from "components/v1/Button";

import { GlobalContext } from 'contexts';

const ModalAgenBody = ({
  onClose,
  marketingAgent = [],
  onSelect,
  isLoading
}) => {
  const { setShowLoading } = useContext(GlobalContext)
  const [agents, setAgents] = useState([...marketingAgent])
  const [selectedAgent, setSelectedAgent] = useState(null)

  const searchAgent = (e) => {
    const value = e?.target?.value
    if (!marketingAgent?.length || !value || typeof value != "string") return setAgents(marketingAgent)

    const newAgent = marketingAgent.filter(agent => {
      if (agent?.full_name && typeof agent?.full_name == "string") {
        return agent.full_name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      }
    })
    setAgents(newAgent)
  }

  //Classnames
  const selectedAgentClass = (name) => selectedAgent?.full_name == name ? " selected" : ""

  useEffect(() => {
    setShowLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setAgents(marketingAgent)
  }, [marketingAgent])

  return (
    <div className='modal-content'>
      <div className='search-section'>
        <p className='search-header'>Pilih marketing assosiate</p>
        <CSearchV1
          onChange={searchAgent}
          className="search-agent"
          Icon={({ className }) => <MagnifierIcon className={className} />}
          placeholder="Siapa yang kamu cari?"
        />
        <p className='search-example'>Contoh. “robert fox”</p>
        <Divider />
        <p className='search-info'>Marketing associate yang dipilih</p>
        {
          selectedAgent?.full_name ? <p className='search-info-large selected-agent'>{selectedAgent.full_name}</p>
            :
            <p className='search-info-large'>Belum ada marketing associate yang dipilih...</p>
        }
      </div>
      <div className='list-section'>
        <div className='close-btn-wrapper'><CrossIcon onClick={onClose} className='close-icon' /></div>
        <p className='list-header'>Silahkan pilih marketing associate.</p>
        <div className='list-content'>
          {
            agents?.length ? agents.map((agent, key) => {
              return (
                <button
                  className={`agent-name${selectedAgentClass(agent.full_name)}`}
                  key={`agent-name-${key}`}
                  onClick={() => setSelectedAgent(agent)}
                >
                  <span>{agent.full_name}</span>
                </button>
              )
            })
              : null
          }
        </div>
        <Button
          disabled={!selectedAgent}
          text="Pilih"
          className="w-full !justify-center"
          onClick={() => onSelect(selectedAgent)}
        />
      </div>
    </div>
  )
}

function ModalAddAgent({ open, onClose, ...rest }) {
  if (!open) return null
  return (
    <CModal toggle={onClose} id="modal-add-agent">
      <ModalAgenBody {...rest} onClose={onClose} />
    </CModal>
  )
}

export default ModalAddAgent