const createFile = async (url, fileName, meta = {}) => {
  try{
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      ...meta,
      type: 'image/png'
    };
    let file = new File([data], "fileName", metadata);
  
    return Promise.resolve(file)
  } catch(error){
    return Promise.reject("Failed to convert file")
  }
}

export default createFile