import React from 'react'

// Components
import CardParticipantPassed from './card-participant-passed'

// Assets
const CardBatchPassed = ({ ...props }) => {
  return (
    <div className='w-full py-2 px-4'>
      <div className='h-0.5 border-b-[1px] border-solid border-[#D8D8D9]' />
      <CardParticipantPassed  {...props} />
    </div>
  )
}

export default CardBatchPassed