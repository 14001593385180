import { useState } from 'react'

//Components
import SelectAddress from 'pages/dashboard/e-reporting/forms/components/reuseables/select-address/SelectAddress'

//Hoc
import withFetch from './withFetch'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihProvinsiPemilikProperti({ data }) {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()

  const [selectedData, setSelectedData] = useState({
    ...form.provinsi_pemilik_properti.value,
    value: form.provinsi_pemilik_properti.value.id,
    label: form.provinsi_pemilik_properti.value.name
  })

  const onNext = () => {
    handlePrevState()
    formHandler('provinsi_pemilik_properti', selectedData)
    setCurrentStep(STEPS.kotaPemilikProperti)
  }

  return (
    <SelectAddress
      order="d. "
      question={'Di provinsi mana domisili pemilik properti?'}
      placeholder="Pilih provinsi"
      required
      data={data}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      onNext={onNext}
      disabled={isAprroval}
    />
  )
}

export default withFetch(PilihProvinsiPemilikProperti)