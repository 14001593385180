import React from 'react'

const CInput = ({ label, required, name, ...props }) => {
  return (
    <div className='py-2 flex items-center justify-between border-b border-solid border-[#D8D8D9]'>
      <label className='text-[13px] w-[126px] py-2'>{label}{required && <span className='text-red-0'>*</span>}</label>
      <input className='w-[236px] text-[13px] border border-solid border-[#D8D8D9] p-2 rounded placeholder:text-[#D8D8D9]' name={name} id={name} {...props} />
    </div>
  )
}

export default CInput