import { useState } from "react";

//Components
import ModalLoader from "components/organism/ModalLoader";

function withModal(Modal) {
  const HOCModal = (props) => {
    
    if (!props.open) return null
    return <Modal {...props} />
  }
  return HOCModal
}

export default withModal