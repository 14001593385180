import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import lodash from "lodash";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/id";

//Components
import { ButtonSvgIcon, Button, Divider } from "components";
import LTCard from "pages/dashboard/e-reporting/TableTransaksi/TTComponents/LTCard";

//Assets
import { ReactComponent as FilterIcon } from "assets/filter.svg"
import { ReactComponent as ArrowLeftUp } from "assets/arrow-left-up.svg"
import { ReactComponent as Calendar } from "assets/calendar.svg"
import { ReactComponent as Download } from "assets/download-1.svg"

import { useERMethod, useERState } from "pages/dashboard/e-reporting/ERCtx";
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

import ModalFilterLT from "pages/dashboard/e-reporting/TableTransaksi/TTComponents/ModalFilterLT";
import ModalFilterDownload from "pages/dashboard/e-reporting/TableTransaksi/TTComponents/ModalFilterDownload";

// Hook
function LTHeader() {
  const { setPage } = useERMethod()
  const { userLoginAuth } = useERState()
  const {
    periodeTransaksi,
    setOpenModalFilter,
    setOpenModalDownload,
    setDownloadForm,
    setFilterForm,
    filterForm,
    getTransactionReport,
    getSummary,
    repportSummary,
    selectedTransactionStatus,
    setSelectedTransactionStatus,
    pendingTxCounter
  } = useTTCtx()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDate, setOpenDate] = useState(false)

  const openModalDownload = () => {
    setOpenModalDownload(true)
  }

  const onChange = async (dates) => {
    const [start, end] = dates;

    const fDate = start ? dayjs(start).format("YYYY-MM-DD") : null;
    const fEnd = end ? dayjs(end).format("YYYY-MM-DD") : null;

    const newFrom = { ...filterForm }
    dayjs.locale("id");
    dayjs.extend(relativeTime);

    newFrom["transaction_date[from]"] = fDate;
    newFrom["transaction_date[to]"] = fEnd;
    setFilterForm(newFrom)

    setStartDate(start)
    setEndDate(end)

    if (lodash.compact(dates)?.length == 2) {
      setStartDate(null)
      setEndDate(null)
      setOpenDate(false)
      await getTransactionReport(1, { "transaction_date[from]": fDate, "transaction_date[to]": fEnd })
      await getSummary({ "transaction_date[from]": fDate, "transaction_date[to]": fEnd })
    }
  };

  return (
    <div className='lt-header-container'>
      {/* TOP Section */}
      <div className='mb-[40px]'>
        <div className={`lt-ts-wrapper box-between mb-[8px]`}>
          <p className='lt-ts-header'>Laporan Transaksi</p>
          <div className={`top-buttons-wrapper whitespace-nowrap box-center`}>
            <button onClick={openModalDownload}
              className='mr-4 cursor-pointer flex items-center justify-center px-[14px] text-sm py-[10px] shadow-[0px_1px_2px_rgb(16,16,16,0.05)] border border-solid border-[#d9d9d9] rounded'>
              <Download className='mr-2 w-[14px] h-[14px]' /> Unduh Laporan
            </button>
            <button onClick={() => setOpenModalFilter(true)}
              className='mr-4 cursor-pointer flex items-center justify-center px-[14px] text-sm py-[10px] shadow-[0px_1px_2px_rgb(16,16,16,0.05)] border border-solid border-[#d9d9d9] rounded'>
              <FilterIcon className='mr-2 w-[14px] h-[14px]' /> Filter
            </button>

            <Button
              text="Tambah transaksi"
              className="mr-[16px] border-solid border-1 border border-black-rgba-0 shadow-black-rgba-0 tambah-transaksi-btn text-white mr-[32px]"
              action={() => setPage("form")}
            />

            <Divider classname="c-divider mr-[32px]" />

            <ButtonSvgIcon
              icon={<ArrowLeftUp className='icon-l' />}
              className="left-up-arrow"
            />

          </div>
        </div>
        <p className='lt-welcome-text'>Selamat datang kembali, {userLoginAuth?.full_name}!</p>
      </div>

      {/* BOTTOM Section */}
      <div className='lt-btm-section'>
        {/* CARDS */}
        <div className='card-wrapper'>
          <LTCard title="Gross Closed Commision" className="mr-[16px]" price={repportSummary?.gross_clossed_commision.amount ?? 0} unit={repportSummary?.gross_clossed_commision.unit ?? 0} />
          <LTCard title="GCC Jual" className="mr-[16px] white" price={repportSummary?.sale_commision.amount ?? 0} unit={repportSummary?.sale_commision.unit ?? 0} />
          <LTCard title="GCC Sewa" className="white" price={repportSummary?.rent_commision.amount ?? 0} unit={repportSummary?.rent_commision.unit ?? 0} />
        </div>

        <div className='lt-date-wrapper whitespace-nowrap'>
          <div className='lt-datepicker'>
            {(selectedTransactionStatus !== "bertahap" && selectedTransactionStatus !== "menunggu") && <ButtonSvgIcon
              text="Pilih tanggal"
              icon={<Calendar />}
              className="calendar-btn"
              action={() => setOpenDate(true)}
            />}
            {openDate ? <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              inline
            /> : ""}
          </div>
          {(selectedTransactionStatus !== "bertahap" && selectedTransactionStatus !== "menunggu") && <p className='periode'>Periode <span>{periodeTransaksi}</span></p>}

          <div className='lth-filter-header'>
            <button
              onClick={() => setSelectedTransactionStatus("menunggu")}
              className={`${selectedTransactionStatus === "menunggu" ? "active" : ""}`}
            >
              <span>Menunggu</span>
            </button>
            <button
              onClick={() => setSelectedTransactionStatus("selesai")}
              className={`${selectedTransactionStatus === "selesai" ? "active" : ""}`}
            >
              <span>Disetujui</span>
            </button>
            <button
              onClick={() => setSelectedTransactionStatus("bertahap")}
              className={`${selectedTransactionStatus === "bertahap" ? "active" : ""} relative`}
            >
              {!!pendingTxCounter && <span className='absolute z-10 top-[-13px] right-[-10px] w-6 h-6 flex items-center 
                justify-center bg-red-3 rounded-[80px] font-inter !font-semibold !text-xs !text-white'>{pendingTxCounter}</span>}
              <span>Belum Lunas</span>
            </button>
          </div>
        </div>
      </div>

      {/* MODALS */}
      {/* <ModalFilterLT /> */}
      <ModalFilterDownload openModalDownload={openModalDownload} />
    </div>
  )
}

export default LTHeader