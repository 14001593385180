import React, { useContext, useMemo, useRef, useState } from 'react'
import lodash from "lodash";

//CSS
import "./style.scss"

//Utils
import { formatTanggalV1 } from "utils/formatTanggal";

//Assets - svg
import { ReactComponent as CloclWise } from "assets/arrow-clock-wise.svg";
import { ReactComponent as PaperPlane } from "assets/paper-plane-tilt.svg";
import { ReactComponent as ArrowBottom } from "assets/arrow-bottom.svg";
import { ReactComponent as ArrowNext } from "assets/next.svg";

//Hooks
import useOutsideAlerter from "hooks/useOutsideAlerter";

//Context
import { DashboardCtx } from "../../context/LeadDashboard";

function CustomDropdown({ datum }) {
  const { currentTab, onSelectLeadList } = useContext(DashboardCtx);
  const customDropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  useOutsideAlerter(customDropdownRef, () => setOpenDropdown(false))


  const isMenunggu = useMemo(() => currentTab == "Menunggu", [currentTab])

  if (isMenunggu) return (
    <div className='dropdown-wrapper' ref={customDropdownRef}>
      <button onClick={() => setOpenDropdown(true)}>
        <CloclWise className='left-icon' />
        <span>Menunggu</span>
        <ArrowBottom className='right-icon' />
      </button>

      {openDropdown ? <button onClick={() => onSelectLeadList('Dikontak', datum)}>
        <PaperPlane className='left-icon' />
        <span>Dikontak</span>
        <ArrowBottom className='right-icon' />
      </button> : null}
    </div>
  )

  return (
    <div className='dropdown-wrapper' ref={customDropdownRef}>
      <button onClick={() => setOpenDropdown(true)}>
        <PaperPlane className='left-icon' />
        <span>Dikontak</span>
        <ArrowBottom className='right-icon' />
      </button>

      {openDropdown ? <button onClick={() => onSelectLeadList('Menyewa', datum)}>
        <CloclWise className='left-icon' />
        <span>Menunggu</span>
        <ArrowBottom className='right-icon' />
      </button> : null}
    </div>
  )
}

function Table() {

  const { leadsLists, currentTab, onChangePIC } = useContext(DashboardCtx);

  const isMenunggu = useMemo(() => {
    return currentTab == "Menunggu"
  }, [currentTab])

  return (
    <div className='dashboard-leads-table'>
      <div className='table'>
        <div className='row header'>
          <div className='cell tanggal'><span>Tanggal</span></div>
          <div className='cell nama'><span>Nama</span></div>
          <div className='cell phone'><span>Nomor telepon</span></div>
          <div className='cell email'><span>Alamat email</span></div>
          <div className='cell address'><span>Alamat lengkap</span></div>
          <div className='cell status'><span>Status</span></div>
          <div className='cell minat'><span>Minat</span></div>
          <div className='cell status-leads'><span>Status leads</span></div>
          {!isMenunggu ? <div className='cell pic'><span>PIC</span></div> : null}
        </div>

        {leadsLists.map((datum, key) => {
          const displayValue = (key) => datum?.[key] ?? "";
          const pic = lodash.get(datum, 'user.full_name');

          return (
            <div className='row body' key={`dashboard-leads-table-${key}`}>
              <div className='cell tanggal'><span>{formatTanggalV1(displayValue('created_at'))}</span></div>
              <div className='cell nama'><span>{displayValue('name')}</span></div>
              <div className='cell phone'><span>{displayValue('phone')}</span></div>
              <div className='cell email'><span>{displayValue('email')}</span></div>
              <div className='cell address'><span>{displayValue('address')}</span></div>
              <div className='cell status'><span>{displayValue('status')}</span></div>
              <div className='cell minat'><span>{displayValue("intention")}</span></div>
              <div className='cell status-leads'><CustomDropdown datum={datum} /></div>
              {!isMenunggu ? <div className='cell pic'>
                <button onClick={() => onChangePIC('Dikontak', datum)}>
                  <span>{pic}</span>
                  <ArrowNext />
                </button>
              </div> : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Table