import React from 'react'

//Styles
import "./style.scss";

function StepsLabels({ number, text }) {
  return (
    <div className='steps-labels-wrapper'>
      <div className='steps-labels'>
        <span className='step-number'>{number}</span>
        <span className='step-text'>{text}</span>
      </div>
    </div>
  )
}

export default StepsLabels