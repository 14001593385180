import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Styles
import Styles from './ipaf-popup-banner.module.scss'

// Assets
import IpafBanner from 'assets/ipaf-banner.jpg'

const IpafPopupBanner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isShow, setIsShow] = useState(true)

  if (location.pathname === '/ipaf-event') return null
  if (!isShow) return null
  return (
    <div className={Styles['wrapper']} onClick={() => setIsShow(false)} >
      <div className={Styles['banner-wrapper']}>
        <img src={IpafBanner} alt="ipaf-banner" onClick={() => navigate("/ipaf-event")} />
      </div>
    </div>
  )
}

export default IpafPopupBanner