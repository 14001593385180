import { useState } from 'react'

//Components
import SelectAddress from 'pages/dashboard/e-reporting/forms/components/reuseables/select-address/SelectAddress'

//Hoc
import withFetch from './withFetch'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihKotaPembeliProperti({ data }) {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form, isAprroval, isEditing, isRent } = useFormState()

  const [selectedData, setSelectedData] = useState({
    ...form.kota_pembeli_properti.value,
    value: form.kota_pembeli_properti.value.id,
    label: form.kota_pembeli_properti.value.name
  })

  const onNext = () => {
    handlePrevState()
    formHandler('kota_pembeli_properti', selectedData)
    setCurrentStep(STEPS.areaPembeliProperti)
  }

  return (
    <SelectAddress
      order="b. "
      question={`Di kota mana domisili ${isRent ? "penyewa" : "pembeli"}?`}
      placeholder="Tulis kota"
      required
      data={data}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      onNext={onNext}
      disabled={disabledAll}
      showButton={isAprroval || isEditing}
    />
  )
}

export default withFetch(PilihKotaPembeliProperti)