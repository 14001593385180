import React, { useEffect } from 'react'

//Styles
import Styles from './modal.module.scss'

//Hooks
import useClassHandler from 'hooks/useClassHandler'

function Modal({ toggle, children, className }) {
  const classHandler = useClassHandler(Styles);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, []);

  return (
    <div className={classHandler('c-modal ' + className)}>
      <div className={classHandler('backdrop')} onClick={toggle} />
      {children}
    </div>
  )
}

export default Modal