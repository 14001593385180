import React, { useState } from 'react'
import moment from 'dayjs';
import DatePicker from "react-datepicker"
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

//Assets - svg
import { ReactComponent as CalendarIcon } from "assets/calendar-v2.svg"
import { ReactComponent as ArrowDown } from "assets/arrow-down.svg"

function CalendarRange({ min, rangeDay, className, initStart = null, initEnd = null, setDate, ...props }) {
  const [startDate, setStartDate] = useState(initStart);
  const [endDate, setEndDate] = useState(initEnd);

  const onChange = async (dates) => {
    const [start, end] = dates;

    setStartDate(start)
    setEndDate(end)

    if (rangeDay && start && end) {
      const s = moment(start);
      const e = moment(end);

      const rangeSelectedDay = e.diff(s, 'days') + 1

      if (rangeSelectedDay % rangeDay != 0) {
        toast.error(`Tanggal harus kelipatan ${rangeDay}`, { position: toast.POSITION.TOP_CENTER })
        return
      }
    }

    if (start && end) {
      setDate && setDate(start, end)
    }
  };

  return (
    <div className={`range-datepicker-input ${className}`}>
      <CalendarIcon className='calendar-icon' />
      {min && <span>{min}</span>}
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange={true}
        dateFormat="dd/MM/yyyy"
        {...props}
      />
      <ArrowDown className='arrow-down' />
    </div>
  )
}

export default CalendarRange