import { useCallback } from 'react'
import { debounce } from 'lodash';

export const useDebounce = ({ onChange, delay = 1000 }) => {

  const debounceFn = useCallback(debounce((query => {
    onChange(query)
  }), delay), []);

  return { debounceFn }
}
