import { useState } from 'react'

//Components
import SelectAddress from 'pages/dashboard/e-reporting/forms/components/reuseables/select-address/SelectAddress'

//Hoc
import withFetch from './withFetch'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihAreaProperti({ data }) {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form } = useFormState()

  const [selectedData, setSelectedData] = useState({
    ...form.area_properti.value,
    value: form.area_properti.value.id,
    label: form.area_properti.value.name
  })

  const onNext = () => {
    handlePrevState()
    formHandler('area_properti', selectedData)
    setCurrentStep(STEPS.tulisAlamatProperti)
  }
  return (
    <SelectAddress
      order="a. "
      question={'Di area mana properti?'}
      placeholder="Tulis area"
      required
      data={data}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      onNext={onNext}
      showButton={true}
      disabled={disabledAll}
    />
  )
}

export default withFetch(PilihAreaProperti)