import { createContext, useState, useEffect, useContext } from "react";

import { ModalConfirmationV1 } from "components";

//Functions
import AuthAction from "actions/authAction";
import ERAction from "actions/eReportingAction";

const ERStateCtx = createContext(null);
const ERStateMethodCtx = createContext(null);

export const ERProvider = ({ children }) => {
  const [page, setPage] = useState("table-transaksi")

  //User login auth
  const [userLoginAuth, setUserLoginAuth] = useState(null)
  const [fetchingUserLoginAuth, setFethingUserLoginAuth] = useState(true);

  //Banks
  const [banks, setBanks] = useState({ data: [], fetching: false })

  //Companies
  const [companies, setCompanies] = useState({ data: [], fetching: false })

  const [updateListingData, setUpdateListingData] = useState(null)
  const [formType, setFormType] = useState('')

  const [openModal, setOpenModal] = useState(false)

  //Funtions
  const toUpdateListing = (listing) => {
    setUpdateListingData(listing)
    setPage("form")
    setFormType('update')
  }
  const toApproveListing = (listing) => {
    setUpdateListingData(listing)
    setPage("form")
    setFormType('approve')
  }
  const resetForm = () => {
    setUpdateListingData(null)
    setFormType('')
  }
  const backToHomepage = () => {
    setOpenModal(true)
  }

  //Functions - API's

  const getUserProfile = async () => {
    try {
      setFethingUserLoginAuth(true)

      const list = await AuthAction.getUserDetail()
      const newData = list?.data ?? {}

      setUserLoginAuth(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setFethingUserLoginAuth(false)
    }
  }

  const getBanks = async (count = 1) => {
    try {
      setBanks(val => ({ ...val, fetching: true }))

      const list = await ERAction.getKPR(`?page=${count}&_limit=50`)
      const newData = list?.data ?? {}

      setBanks(val => ({ ...val, data: newData }))

      if (newData.length) {
        if (count == 1) {
          setBanks(val => ({ ...val, data: newData }))
        } else {
          setBanks(val => {
            const result = {...val, data: [...val.data, ...newData]}
            return result
          })
        }
        await getBanks(count + 1)
      }
    } catch (error) {
      console.log("ReportingContext.js, fn getUserProfile \n", error)
    } finally {
      setBanks(val => ({ ...val, fetching: false }))
    }
  }

  const getCompanyList = async (count = 1) => {
    try {
      setCompanies(val => ({ ...val, fetching: true }))

      const list = await ERAction.getCompanies(`?page=${count}&_limit=50`)
      const newData = list?.data ?? []
      
      if (newData.length) {
        if (count == 1) {
          newData.unshift({ id: "0", name: "Non Century 21" })
          setCompanies(val => ({ ...val, data: newData }))
        } else {
          setCompanies(val => {
            const result = {...val, data: [...val.data, ...newData]}
            return result
          })
        }
        await getCompanyList(count + 1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setCompanies(val => ({ ...val, fetching: false }))
    }
  }

  useEffect(() => {
    getUserProfile()
    getBanks()
    getCompanyList()
  }, [])

  return (
    <ERStateCtx.Provider value={{
      userLoginAuth,
      fetchingUserLoginAuth,
      updateListingData,
      banks,
      page,
      companies,
      formType
    }}>
      <ERStateMethodCtx.Provider value={{ setPage, toUpdateListing, resetForm, backToHomepage, toApproveListing }}>
        {children}
        {openModal ? <ModalConfirmationV1
          noImage={true}
          title="Kamu yakin ingin kembali ke halaman utama?"
          info="Setelah memilih kembali, seluruh data yang telah kamu isikan akan dihapus. Meskipun jika memilih Agent dan listing yang sama, kamu akan kembali mengisi isian mulai dari awal, yaitu isi data Agent."
          confirmText="Oke, kembali ke dashboard"
          declineText="Batal, saya tidak ingin kembali"
          confirmAction={() => {
            setUpdateListingData(null)
            setOpenModal(false)
            setPage("table-transaksi")
          }}
          declineAction={() => setOpenModal(false)}
          onClose={() => setOpenModal(false)}
        /> : null}
      </ERStateMethodCtx.Provider>
    </ERStateCtx.Provider>
  )
}

//Custom hooks for the values
export const useERState = () => {
  const ctx = useContext(ERStateCtx);

  if (!ctx) {
    throw new Error("useERState must be used within the ERProvider");
  }

  return ctx;
};

//Custom hooks for the functions
export const useERMethod = () => {
  const ctx = useContext(ERStateMethodCtx);

  if (!ctx) {
    throw new Error("useERMethod must be used within the ERProvider");
  }

  return ctx;
};