import { useState } from 'react'

//Styles
import Styles from "./binary-question.module.scss";

//Components - reuseables
import ButtonSelanjutnya from "pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya";
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets - svg
import { ReactComponent as CheckBlack } from "assets/check-black.svg"
function BinaryQuestion({ hideBack, disabledAll, value = '', order, title, question, onNext, orderBg }) {
  const [selectedButton, setSelectedButton] = useState(value);

  //Functions
  const handleContinue = () => onNext(selectedButton);
  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['box']}>
        <div className={Styles['title-and-number']}>
          <span className={Styles['text']}>{title}</span>
        </div>
        <p className={Styles['question']}>{question}</p>
        <div className={Styles['button-wrapper']}>
          <button disabled={!!disabledAll} className={`${Styles['button']} ${selectedButton == 'ya' ? Styles['active'] : ''}`} onClick={() => setSelectedButton('ya')}>
            <span className={Styles['text']}>Ya</span>
            <CheckBlack className={Styles['check-icon']} />
          </button>
          <button disabled={!!disabledAll} className={`${Styles['button']} ${selectedButton == 'tidak' ? Styles['active'] : ''}`} onClick={() => setSelectedButton('tidak')}>
            <span className={Styles['text']}>Tidak</span>
            <CheckBlack className={Styles['check-icon']} />
          </button>
        </div>
        <BoxFlex>
          <>
            {!hideBack && <ButtonSebelumnya />}
            {selectedButton ? <ButtonSelanjutnya className={Styles['btn-selanjutnya']} onClick={handleContinue} /> : null}
          </>
        </BoxFlex>
      </div>
    </div>
  )
}

export default BinaryQuestion