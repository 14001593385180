import React from 'react'

//CSS
import Styles from "./style.scss"

function Header() {
  return (
    <div className={'dashboard-leads-wrapper'}>
      <p>Leads My Ciputra</p>
    </div>
  )
}

export default Header