import React from 'react'
import _ from "lodash"
import { Oval } from "react-loader-spinner";

//Utils
import { nominalBilangan } from "utils/currencyFormatter";

// Assets
import { ReactComponent as SellBadge } from "assets/sell-badge.svg";
import { ReactComponent as RentBadge } from "assets/rent-badge.svg";

// Context
import { useDetailListingCtx } from "../Context"

// Component
import Button from 'components/v1/Button';

const RowWidth = {
  masaAktif: "13.68% mr-6",
  marketing: "13.83% mr-6",
  area: "13.68% mr-6",
  harga: "13.68% mr-6",
  jenisProperti: "27.01%"
}

const HeaderCol = "w-full"
const HeaderColSpan = "text-xs text-gray-4"

const HRow = () => (
  <div className='flex items-center py-4'>
    <div className={`${HeaderCol} ${RowWidth.masaAktif}`}>
      <span className={HeaderColSpan}>Masa Aktif</span>
    </div>
    <div className={`${HeaderCol} ${RowWidth.marketing}`}>
      <span className={HeaderColSpan}>Marketing</span>
    </div>
    <div className={`${HeaderCol} ${RowWidth.area}`}>
      <span className={HeaderColSpan}>Area</span>
    </div>
    <div className={`${HeaderCol} ${RowWidth.harga}`}>
      <span className={HeaderColSpan}>Harga</span>
    </div>
    <div className={`${HeaderCol} ${RowWidth.jenisProperti}`}>
      <span className={HeaderColSpan}>Jenis Property</span>
    </div>
  </div>
)

const Ellipses = "truncate"
const BodyCol = "w-full";
const BodyColSpan = "text-sm";
const remainingDays = {
  general: "rounded-md px-[8px] py-[4px] text-xs border-[1px] border-solid",
  red: "text-red-6 border-red-6 bg-red-7",
  green: "text-green-1 border-green-1 bg-green-3"
}

const BRow = ({
  remaining_days,
  property,
  onClick
}) => {
  const handleArea = (locations) => {
    if (!!locations?.length) {
      return locations[0].area.name
    }
    return ""
  }

  return (
    <div className='flex items-center justify-between py-6 border-b border-solid border-white-0 last:border-[none]' onClick={onClick}>
      <div className={`${BodyCol} ${RowWidth.masaAktif}`}>
        <span className={`${remainingDays.general} ${remaining_days <= 3 ? remainingDays.red : remainingDays.green}`}>{remaining_days} hari lagi</span>
      </div>
      <div className={`${Ellipses} ${BodyCol} ${RowWidth.marketing}`}>
        <span className={`${BodyColSpan}`}>{property?.user?.full_name}</span>
      </div>
      <div className={`${BodyCol} ${RowWidth.area}`}>
        <span className={`${BodyColSpan}`}>{handleArea(property?.summary?.property_locations ?? [])}</span>
      </div>
      <div className={`${BodyCol} ${RowWidth.harga}`}>
        <span className={`${BodyColSpan}`}>Rp {nominalBilangan(parseInt(property?.price ?? 0))}</span>
      </div>
      <div className={`${BodyCol} ${RowWidth.jenisProperti}`}>
        <span className={`${BodyColSpan} flex items-center`}>{_.get(property, "property_type.id") == 1 ? <SellBadge className='mr-[4px] w-4 h-4 object-contain' /> : <RentBadge className='mr-[4px] w-4 h-4 object-contain' />} {property?.property_category?.name}</span>
      </div>
    </div>
  )
}

const ListHotListing = () => {
  const {
    myListing,
    setMyListing,
    setListDetail,
    search,
    searchHandler,
    handleGetDetailListing,
  } = useDetailListingCtx();

  const handleSetListDetail = (list) => {
    let newList = {
      ...list.property,
      hotListingParent: list
    }
    setListDetail(newList)
  }
  return (
    <div className='w-full border-[1px] rounded-md border-white-0 py-4 px-6 bg-white'>
      <p className='mb-[6px] text-xs text-gray-2'>Cari berdasarkan</p>
      <div className='w-[298px] mb-[40px] px-[16px] py-[10px] rounded-md border-[1px] border-white-0'>
        <input
          placeholder='Judul/alamat/deskripsi'
          className='w-full text-sm placeholder:text-gray-0'
          disabled={myListing.loading}
          value={search}
          onChange={searchHandler}
        />
      </div>
      {myListing.loading ? (
        <div className='w-[100%] min-h-[720px] flex justify-center items-center h-screen'>
          <Oval
            height={60}
            width={60}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      ) : (
        <div className='min-h-[720px] mb-[48px]'>
          <HRow />
          {!!myListing?.data?.length && myListing?.data.map((list, key) => {
            return <BRow onClick={() => handleGetDetailListing(list?.property?.slug)} {...list} key={`list-hot-listing-${key}`} />
          })}
        </div>
      )}

      <div className='flex justify-end items-center'>
        <Button
          text="Sebelumnya"
          type="secondary"
          disabled={myListing.loading || (myListing.page == 1)}
          onClick={() => setMyListing((val) => ({ ...val, page: val.page - 1 }))}
        />
        <p className='text-sm mx-8 text-black-0 font-medium'>{myListing.page} <span className='text-gray-2 font-normal'>dari</span> <span className='text-gray-2 font-normal'>{myListing.total}</span></p>
        <Button
          text="Selanjutnya"
          type="secondary"
          onClick={() => setMyListing((val) => ({ ...val, page: val.page + 1 }))}
          disabled={myListing.loading || (myListing.data.length == 0 || Number(myListing.page) == Number(myListing.total))}
        />
      </div>
    </div>
  )
}

export default ListHotListing