import React, { useState, useEffect } from 'react'

//Actions
import { getMarketingAgent } from "actions"

function useGetAgents() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
 
  const getAgents = async (count = 1) => {
    try {
      const _agents = await getMarketingAgent(`?page=${count}&_limit=50`)
      const newData = _agents?.data ?? []
      
      if (newData.length) {
        if (count == 1) {
          setData(newData)
        } else {
          setData(val => ([ ...val, ...newData ]))
        }
        // await getAgents(count + 1)
      } else {
        setLoading(false) 
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAgents()
  }, [])

  return {
    loading,
    data: data
  }
}

export default useGetAgents