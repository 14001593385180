import React from 'react'

//Styles
import Styles from './nilai-transaksi-properti.module.scss'

//Components
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box'
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function NilaiTransaksiProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()

  //State
  const [value, setValue] = React.useState(form.nilai_transaksi.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('nilai_transaksi', value)
    if (form.selected_listing?.value?.property_type?.id === 2) {
      setCurrentStep(STEPS.totalKomisiMarketing)
    } else setCurrentStep(STEPS.metodePembayaran)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium>
          Berapa nilai transaksi properti ini? <span className={Styles['required']}>*</span> <span className={Styles['shadow']}>(Rp)</span>
        </TextMedium>
      </div>
      <div className={Styles['input-wrapper']}>
        <InputCurrency disabled={isAprroval} onValueChange={(val) => setValue(val)} value={value || ''} placeholder="Tulis nilai transaksi" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {!!value && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

const withNilaiTransaksi = (Component) => (props) => {
  const { form } = useFormState();
  const { setCurrentStep } = useFormMethod();
  const [isRender, setIsRender] = React.useState(false)

  React.useEffect(() => {
    if (form.selected_listing.value.property_type.id === 2) {
      setCurrentStep(STEPS.totalKomisiMarketing)
    } else {
      setIsRender(true)
    }
  }, [form])

  if (!isRender) return null;
  return (
    <Component {...props} />
  )
}

export default withNilaiTransaksi(NilaiTransaksiProperti)