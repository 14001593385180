import { useMemo, useState, useEffect } from 'react'

//Styles
import Styles from './pilih-kantor-marketing-pembeli.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import SelectWithSearch from 'pages/dashboard/e-reporting/forms/components/reuseables/select-with-search/SelectWithSearch'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { useERState } from "pages/dashboard/e-reporting/ERCtx";
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";


function PilihKantorMarketingPembeli() {
  //Context
  const { companies, userLoginAuth } = useERState()
  const { form, isAprroval, isEditing } = useFormState()
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();

  //State
  const [selectedCompany, setSelectedCompany] = useState({
    ...form.kantor_marketing_pembeli.value,
    value: form.kantor_marketing_pembeli.value.id,
    label: form.kantor_marketing_pembeli.value.name
  })

  const mappedCompanies = useMemo(() => {
    if (!companies?.data?.length) return [];

    let _companies = [...companies.data]

    if (form.kantor_marketing_penjual.value.id == 0 || userLoginAuth?.company?.id != form.kantor_marketing_penjual.value.id) {
      _companies = _companies.filter(company => company.id == userLoginAuth?.company?.id)
    }

    return _companies.map(company => {
      return {
        value: company.id,
        label: company.name,
        ...company
      }
    })
  }, [companies])

  const onNext = () => {
    handlePrevState()
    formHandler('kantor_marketing_pembeli', selectedCompany)
    if (selectedCompany.id == 0) setCurrentStep(STEPS.tulisMarketingPembeliNonCentury)
    else setCurrentStep(STEPS.pilihMarketingPembeli)
  }

  useEffect(() => {
    if (userLoginAuth?.company?.id != form.kantor_marketing_penjual.value.id) {
      formHandler('kantor_marketing_pembeli', userLoginAuth?.company)
      if (selectedCompany.id == 0) setCurrentStep(STEPS.tulisMarketingPembeliNonCentury)
      else setCurrentStep(STEPS.pilihMarketingPembeli)
    }
  }, [])

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Pilih kantor marketing pembeli
        </TextMedium>

      </div>
      <div className={Styles['select-wrapper']}>
        <SelectWithSearch selectedData={selectedCompany} disabled={isAprroval || isEditing} onClick={setSelectedCompany} options={mappedCompanies} placeholder="Klik di sini atau klik simbol panah" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {selectedCompany.id !== null ? <ButtonSelanjutnya onClick={onNext} /> : null}
          </>
        </BoxFlex>

      </div>
    </WrapperAndBox>
  )
}

export default PilihKantorMarketingPembeli
