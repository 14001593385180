import React from 'react'
import { Outlet } from "react-router-dom";

const Main = () => {
  return (
    <div className='wrapper nav-content-wrapper'>
      <Outlet />
    </div>
  )
}

export default Main