import React from 'react'

//Styles
import Styles from "./order.module.scss";

//Hooks
import useClassHandler from 'hooks/useClassHandler';

function Order({ className, withBg, children, ...props }) {
  const classHandler = useClassHandler(Styles)
  return <span {...props} className={classHandler('order ' + (withBg ? "with-bg " : '') + className)}>{children}</span>
}

export default Order