import React, { useContext, useMemo, useState } from 'react'
import lodash from "lodash"

//Context
import { UploadListingContext } from "contexts"

//Components
import { CTextInputV1, CTextInputV2, TextPangkat, CSelectV1, CTextInputV3 } from "../../../../components";
import CheckBoxes from "components/molecules/CheckBoxes"
import PointCard from "pages/dashboard/upload-listing/components/atom/PointCard"

import RadioBtn from "assets/radio-2.png"

const ApartmentRadioBtn = ({ text, value, setSelectedStudio, selectedValue }) => {
  const selected = value === selectedValue;
  const onSelect = () => {
    setSelectedStudio(value)
  }
  return (
    <button onClick={onSelect} className='cursor-pointer flex items-center mr-[32px] last:mr-0'>
      {!!selected ? <img src={RadioBtn} alt='Radio btn' /> : <div className='rounded-[24px] border border-solid border-gray-0 bg-white w-4 h-4' />}
      <span className='text-xs ml-2'>{text}</span>
    </button>
  )
}

// Reuseable function
const isRender = (fields, key) => fields.includes(key);

// Reuseable label
const LabelMandatory = ({ label, PointComponent, className = "" }) => {
  return (
    <div className={`flex justify-between items-center ${className}`}>
      <p className='gsf-title'>{label} {PointComponent ? PointComponent : null}</p>
      <p className='mandatory-text'>*tidak boleh kosong</p>
    </div>
  )
}

const LabelOptional = ({ label, PointComponent, className = "" }) => {
  return (
    <div className={`flex justify-between items-center ${className}`}>
      <p className='gsf-title'>{label} {PointComponent ? PointComponent : null}</p>
      <p className='optional-text'>* boleh dikosongkan(optional)</p>
    </div>
  )
}

// Reuseable Form Section
const MarketingAssociate = () => {
  const { form, score } = useContext(UploadListingContext)

  return (
    <div className='marketing-associate'>
      <p className='assosiate-name'>Marketing associate dipilih : {" "}
        {form.agent.full_name ? <span>{form.agent.full_name}</span> : <span>Belum ada Marketing associate yang dipilih....</span>}
      </p>
      <p className='skor-listing'>Skor listing kamu : {" "} <button className='score-card'><span className='score-point'>{score}</span><span className='score-text'>poin</span></button></p>
    </div>
  )
}

const TahunDibangun = () => {
  const { form, onChangeForm } = useContext(UploadListingContext)

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Tahun dibangun</p>
        <p className='optional-text'>* boleh dikosongkan(optional)</p>
      </div>
      <div className='g-section-form apytd-form'>
        <CTextInputV1
          className="_field"
          placeholder="0"
          type="number"
          label="Tahun dibangun"
          value={form.year}
          onChange={onChangeForm}
          name="year"
        />
      </div>
    </div>
  )
}

const KondisiDibangun = ({ fields }) => {
  const { form, onSelectForm, initializer } = useContext(UploadListingContext)

  const optionsKondisiProperti = useMemo(() => {
    const data = lodash.get(initializer, "applications.static_data.PROPERTIES_CONDITION")

    if (data) {
      return data.map((e, k) => ({ name: e, id: k + 1 }))
    }
    return []
  }, [initializer])

  const optionsKondisiFurnitur = useMemo(() => {
    const data = lodash.get(initializer, "applications.static_data.PROPERTIES_FURNITURE_CONDITION")

    if (data) {
      return data.map((e, k) => ({ name: e, id: k + 1 }))
    }
    return []
  }, [initializer])

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Kondisi</p>
      </div>
      <div className='g-section-form apytd-form'>
        {/* Kondisi property */}
        {isRender(fields, "property_condition") ? <>
          <LabelMandatory label="Kondisi properti" PointComponent={<PointCard point={6} />} />
          <CSelectV1
            name="property_condition"
            className="_field"
            placeholder="Pilih kondisi"
            value={form.property_condition}
            options={optionsKondisiProperti}
            displayName="name"
            valueName="name"
            onSelect={onSelectForm}
          />
        </> : null}

        {/* Kondisi perabotan */}
        {isRender(fields, "furniture_condition") ? <>
          <LabelOptional label="Kondisi perabotan" />
          <CSelectV1
            className="_field"
            placeholder="Pilih kondisi perabotan"
            name="furniture_condition"
            value={form.furniture_condition}
            options={optionsKondisiFurnitur}
            displayName="name"
            valueName="name"
            onSelect={onSelectForm}
          />
        </> : null}
      </div>
    </div>
  )
}

const LuasTanah = ({ fields }) => {
  const { form, onChangeForm } = useContext(UploadListingContext)

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Luas tanah</p>
      </div>
      <div className='g-section-form apytd-form'>
        {isRender(fields, "luasTanah") ? <>
          <LabelMandatory label="Luas tanah" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixIcon={<TextPangkat text="m" suffix="2" />}
            value={form.surface_area}
            onChange={onChangeForm}
            name="surface_area"
          />
        </> : null}

        {isRender(fields, "lebarTanah") ? <>
          <LabelOptional label="Lebar tanah" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"m"}
            value={form.land_width}
            onChange={onChangeForm}
            name="land_width"
          />
        </> : null}

        {isRender(fields, "panjangTanah") ? <>
          <LabelOptional label="Panjang tanah" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"m"}
            value={form.land_length}
            onChange={onChangeForm}
            name="land_length"
          />
        </> : null}
      </div>
    </div>
  )
}

const LuasBangunan = ({ fields }) => {
  const { form, onChangeForm } = useContext(UploadListingContext)

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Luas bangunan</p>
      </div>
      <div className='g-section-form apytd-form'>
        {isRender(fields, "luasBangunan") ? <>
          <LabelMandatory label="Luas bangunan" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixIcon={<TextPangkat text="m" suffix="2" />}
            value={form.building_area}
            onChange={onChangeForm}
            name="building_area"
          />
        </> : null}

        {isRender(fields, "lebarBangunan") ? <>
          <LabelOptional label="Lebar bangunan" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"m"}
            value={form.building_width}
            onChange={onChangeForm}
            name="building_width"
          />
        </> : null}

        {isRender(fields, "panjangBangunan") ? <>
          <LabelOptional label="Panjang bangunan" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"m"}
            value={form.building_length}
            onChange={onChangeForm}
            name="building_length"
          />
        </> : null}

      </div>
    </div>
  )
}

const ParkirKendaraan = ({ fields }) => {
  const { form, onChangeForm } = useContext(UploadListingContext)

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Parkir kendaraan</p>
        <p className='optional-text'>* boleh dikosongkan(optional)</p>
      </div>
      <div className='g-section-form apytd-form'>
        {isRender(fields, "garasi") ? <>
          <LabelOptional label="Kapasitas garasi" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"mobil"}
            value={form.garage_capacity}
            onChange={onChangeForm}
            name="garage_capacity"
          />
        </> : null}

        {isRender(fields, "parkir") ? <>
          <LabelOptional label="Kapasitas parkir" />
          <CTextInputV2
            className="_field"
            placeholder="0"
            type="number"
            suffixPosition="right"
            suffixText={"mobil"}
            value={form.parking_capacity}
            onChange={onChangeForm}
            name="parking_capacity"
          />
        </> : null}

      </div>
    </div>
  )
}

const Fitur = ({ fields, ruko, apartment }) => {
  const { form, onChangeForm, manualSelectForm, selectedStudio, setSelectedStudio } = useContext(UploadListingContext)

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Fitur</p>
      </div>
      {!!apartment ? (
        <div className='g-section-form apytd-form'>
          {!!apartment && (
            <>
              <p className='text-xs font-medium text-gray-2 mb-2'>Tipe Apartemen</p>
              <div className='flex items-center mb-[32px]'>
                <ApartmentRadioBtn text="Studio" setSelectedStudio={setSelectedStudio} value={true} selectedValue={selectedStudio} />
                <ApartmentRadioBtn text="BR" setSelectedStudio={setSelectedStudio} value={false} selectedValue={selectedStudio} />
              </div>
            </>
          )}
          {!selectedStudio && <>
            {isRender(fields, "lantai") ? <>
              <LabelOptional label="Jumlah lantai" />
              <CTextInputV2
                className="_field"
                placeholder="0"
                type="number"
                mandatoryLabel={false}
                suffixPosition="right"
                suffixText={"lantai"}
                value={form.number_of_floors}
                onChange={onChangeForm}
                name="number_of_floors"
              />
            </> : null}

            {isRender(fields, "kamarTidur") ? <>
              {ruko ? (
                <LabelOptional className='mb-[8px]' label="Jumlah kamar tidur" PointComponent={<PointCard point={6} />} />
              ) : (
                <LabelMandatory className='mb-[8px]' label="Jumlah kamar tidur" PointComponent={<PointCard point={6} />} />
              )
              }
              <CTextInputV2
                className="_field"
                placeholder="0"
                type="number"
                suffixPosition="right"
                suffixText={"kamar"}
                value={form.bedroom}
                onChange={onChangeForm}
                name="bedroom"
              />
            </> : null}

            {isRender(fields, "kamarMandi") ? <>
              {ruko ? (
                <LabelOptional className='mb-[8px]' label="Jumlah kamar mandi" PointComponent={<PointCard point={6} />} />
              ) : (
                <LabelMandatory className='mb-[8px]' label="Jumlah kamar mandi" PointComponent={<PointCard point={6} />} />
              )
              }
              <CTextInputV2
                className="_field"
                placeholder="0"
                type="number"
                suffixPosition="right"
                suffixText={"kamar"}
                value={form.bathroom}
                onChange={onChangeForm}
                name="bathroom"
              />
            </> : null}

            {isRender(fields, "kamarTidurPembantu") ? <>
              <LabelOptional className='mb-[8px]' label="Jumlah kamar tidur pembantu" />
              <CTextInputV2
                className="_field"
                placeholder="0"
                type="number"
                suffixPosition="right"
                suffixText={"kamar"}
                value={form.additional_bedroom}
                onChange={onChangeForm}
                name="additional_bedroom"
              />
            </> : null}

            {isRender(fields, "kamarMandiPembantu") ? <>
              <LabelOptional className='mb-[8px]' label="Jumlah kamar mandi pembantu" />
              <CTextInputV2
                className="_field"
                placeholder="0"
                type="number"
                suffixPosition="right"
                suffixText={"kamar"}
                value={form.additional_bathroom}
                onChange={onChangeForm}
                name="additional_bathroom"
              />
            </> : null}
          </>}

          {isRender(fields, "listrik") ? <>
            <LabelOptional className='mb-[8px]' label="Daya listrik" />
            <CTextInputV3
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"watt"}
              value={form.electrical_power}
              name="electrical_power"
              onChange={(val) => manualSelectForm({ name: "electrical_power", value: val })}
            />
          </> : null}

        </div>
      ) : (
        <div className='g-section-form apytd-form'>
          {isRender(fields, "lantai") ? <>
            <LabelOptional label="Jumlah lantai" />
            <CTextInputV2
              className="_field"
              placeholder="0"
              type="number"
              mandatoryLabel={false}
              suffixPosition="right"
              suffixText={"lantai"}
              value={form.number_of_floors}
              onChange={onChangeForm}
              name="number_of_floors"
            />
          </> : null}

          {isRender(fields, "kamarTidur") ? <>
            {ruko ? (
              <LabelOptional className='mb-[8px]' label="Jumlah kamar tidur" PointComponent={<PointCard point={6} />} />
            ) : (
              <LabelMandatory className='mb-[8px]' label="Jumlah kamar tidur" PointComponent={<PointCard point={6} />} />
            )
            }
            <CTextInputV2
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"kamar"}
              value={form.bedroom}
              onChange={onChangeForm}
              name="bedroom"
            />
          </> : null}

          {isRender(fields, "kamarMandi") ? <>
            {ruko ? (
              <LabelOptional className='mb-[8px]' label="Jumlah kamar mandi" PointComponent={<PointCard point={6} />} />
            ) : (
              <LabelMandatory className='mb-[8px]' label="Jumlah kamar mandi" PointComponent={<PointCard point={6} />} />
            )
            }
            <CTextInputV2
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"kamar"}
              value={form.bathroom}
              onChange={onChangeForm}
              name="bathroom"
            />
          </> : null}

          {isRender(fields, "kamarTidurPembantu") ? <>
            <LabelOptional className='mb-[8px]' label="Jumlah kamar tidur pembantu" />
            <CTextInputV2
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"kamar"}
              value={form.additional_bedroom}
              onChange={onChangeForm}
              name="additional_bedroom"
            />
          </> : null}

          {isRender(fields, "kamarMandiPembantu") ? <>
            <LabelOptional className='mb-[8px]' label="Jumlah kamar mandi pembantu" />
            <CTextInputV2
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"kamar"}
              value={form.additional_bathroom}
              onChange={onChangeForm}
              name="additional_bathroom"
            />
          </> : null}

          {isRender(fields, "listrik") ? <>
            <LabelOptional className='mb-[8px]' label="Daya listrik" />
            <CTextInputV3
              className="_field"
              placeholder="0"
              type="number"
              suffixPosition="right"
              suffixText={"watt"}
              value={form.electrical_power}
              name="electrical_power"
              onChange={(val) => manualSelectForm({ name: "electrical_power", value: val })}
            />
          </> : null}
        </div>
      )}
    </div>
  )
}

const ArahProperti = () => {
  const { form, onSelectForm, initializer } = useContext(UploadListingContext)
  const options = useMemo(() => {
    const data = lodash.get(initializer, "applications.static_data.PROPERTIES_FACE")

    if (data) {
      return data.map((e, k) => ({ name: e, id: k + 1 }))
    }
    return []
  }, [initializer])
  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Properti menghadap ke arah mana?</p>
        <p className='optional-text'>* boleh dikosongkan(optional)</p>
      </div>
      <div className='g-section-form apytd-form'>
        <CSelectV1
          name="face"
          className="_field"
          placeholder="Pilih kondisi"
          value={form.face}
          label="Pilih arah"
          options={options}
          displayName="name"
          valueName="name"
          onSelect={onSelectForm}
        />
      </div>
    </div>
  )
}

const FasilitasRumah = ({ boxes }) => {
  const { fasilitasPribadi, setFasilitasPribadi, dataFasilitasPribadi } = useContext(UploadListingContext)

  const handleOnChange = (box) => {
    let newFasilitasPribadi = [...fasilitasPribadi]
    const findFasilitasPribadi = fasilitasPribadi.filter((f, k) => f?.facility_id == box.facility_id)
    if (findFasilitasPribadi.length) {
      newFasilitasPribadi = newFasilitasPribadi.filter(f => f?.facility_id != findFasilitasPribadi[0].facility_id)
    } else {
      newFasilitasPribadi.push(box)
    }

    setFasilitasPribadi(newFasilitasPribadi)
  }

  const _box = useMemo(() => {
    if (!dataFasilitasPribadi?.length || !boxes.length) return [];

    return dataFasilitasPribadi.filter(f => boxes.includes(f.name))
  }, [boxes, dataFasilitasPribadi])

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Fasilitas rumah <PointCard point={6} /></p>
        <p className='optional-text'>* boleh dikosongkan(optional)</p>
      </div>
      <div className='g-section-form apytd-form'>
        <CheckBoxes
          boxes={_box}
          data={fasilitasPribadi}
          onChange={handleOnChange}
        />
      </div>
    </div>
  )
}

const FasilitasSekitarRumah = ({ boxes }) => {
  const { fasilitasSekitar, setFasilitasSekitar, dataFasilitasSekitar } = useContext(UploadListingContext)
  const handleOnChange = (box) => {
    let newFasilitasSekitar = [...fasilitasSekitar]
    const findFasilitasPribadi = fasilitasSekitar.filter((f, k) => f?.facility_id == box.facility_id)
    if (findFasilitasPribadi.length) {
      newFasilitasSekitar = newFasilitasSekitar.filter(f => f?.facility_id != findFasilitasPribadi[0].facility_id)
    } else {
      newFasilitasSekitar.push(box)
    }

    setFasilitasSekitar(newFasilitasSekitar)
  }

  const _box = useMemo(() => {
    if (!dataFasilitasSekitar?.length || !boxes.length) return [];

    return dataFasilitasSekitar.filter(f => boxes.includes(f.name))
  }, [boxes, dataFasilitasSekitar])

  return (
    <div className='ilf-container'>
      <div className='g-section-text'>
        <p>Fasilitas sekitar</p>
        <p className='optional-text'>* boleh dikosongkan(optional)</p>
      </div>
      <div className='g-section-form apytd-form'>
        <CheckBoxes
          boxes={_box}
          onChange={handleOnChange}
          data={fasilitasSekitar}
        />
      </div>
    </div>
  )
}

export const FAll = ({ fields, ruko, apartment }) => {
  return (
    <div className='form-container spesifikasi-property-container'>
      {/* Marketing Associate */}
      {fields.marketingAssociate.length ? <MarketingAssociate /> : null}

      {/* Tahun dibangun */}
      {fields.tahunDibangun.length ? <TahunDibangun /> : null}

      {/* Kondisi dibangun */}
      {fields.kondisiBangunan.length ? <KondisiDibangun fields={fields.kondisiBangunan} /> : null}

      {/* Luas tanah */}
      {fields.luasTanah.length ? <LuasTanah fields={fields.luasTanah} /> : null}

      {/* Luas bangunan */}
      {fields.luasBangunan.length ? <LuasBangunan fields={fields.luasBangunan} /> : null}

      {/* Fitur */}
      {fields.fitur.length ? <Fitur apartment={apartment} fields={fields.fitur} ruko={ruko} /> : null}

      {/* Parkir kendaraan */}
      {fields.parkirKendaraan.length ? <ParkirKendaraan fields={fields.parkirKendaraan} /> : null}

      {/* Arah Property */}
      {fields.face.length ? <ArahProperti /> : null}

      {/* Fasilitas Rumah */}
      {fields.fasilitasRumah.length ? <FasilitasRumah boxes={fields.fasilitasRumah} /> : null}

      {/* Fasilitas Sekitar Rumah */}
      {fields.fasilitasSekitarRumah.length ? <FasilitasSekitarRumah boxes={fields.fasilitasSekitarRumah} /> : null}
    </div>
  )
}
