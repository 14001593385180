
import { api } from "utils";

//Constant
import {
  KEY_METRIC_DASHBOARD,
  KEY_METRIC_DASHBOARD_ADMIN
} from "constant/URL";

export const getUploadListing = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}/uploads${query}`)
export const getShareListing = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}/shares${query}`)
export const getWeeklyActiveAgent = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}/weekly-active-agents${query}`)
export const getSummary = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}/summary${query}`)
export const getRekapWeeklyActiveAgent = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}/summary-weekly-active-marketing${query}`)
export const getGetAllUsers = async (query = "") => api().get(`${KEY_METRIC_DASHBOARD}${query}`)
export const getRefreshSyncKeymetric = (query = "") => api().get(`${KEY_METRIC_DASHBOARD_ADMIN}${query}`)
