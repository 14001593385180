import React from 'react'

// Components
import ContentHeader from './components/content-header'
import ContentBody from './components/content-body'
import InfoHeader from './components/info-header'
import ContentFooter from './components/content-footer'
import ContentCard from './components/content-card'

// Ctx
import { TrainingProvider } from "./useTraining"

const Training = () => {
  return (
    <TrainingProvider>
      <div className='w-[calc(100vw-80px)] ml-[80px] bg-[#D8D8D9]'>
        <ContentHeader />
        <ContentBody>
          <InfoHeader />
          <ContentCard />
        </ContentBody>
        <ContentFooter />
      </div>
    </TrainingProvider>
  )
}

export default Training