import React, { useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { getInvoiceList } from "actions"

import toRupiah from 'helpers/toRupiah'
import tranformDateToSlash from 'helpers/tranformDateToSlash'

const useListInvoice = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const [date, setDate] = useState(null)
  const [status, setStatus] = useState("menunggu")

  const handleMapData = useMemo(() => (!data.length ? [] : data.map((item, index) => (
    {
      id: index + 1,
      company_name: item.company.name,
      tagihan: toRupiah(item.total_bill_amount),
      periode: item.period,
      tanggal_diputihkan: item.whitelisted_at ? dayjs(tranformDateToSlash(item.whitelisted_at)).format("DD-MM-YYYY") : "-",
      tanggal_pembayaran: item.paided_at ? dayjs(item.paided_at).format("DD-MM-YYYY") : "-",
      remaining_days: item.remaining_days || 0,
      // remaining_days: item.remaining_days ? item.remaining_days.replaceAll("-", "") : 0,
      invoice_number: item.invoice_number,
      jatuh_tempo: item.expiration_date? dayjs(tranformDateToSlash(item.expiration_date)).format("DD-MM-YYYY") : "-",
      invoice_id: item.id,
      fullData: item
    }
  ))), [data])

  const COLLUMNS = useMemo(() => ([
    {
      Header: 'No',
      accessor: 'id', // accessor is the "key" in the data
    },
    {
      Header: 'Nomor Invoice',
      accessor: 'invoice_number', // accessor is the "key" in the data
    },
    {
      Header: 'Tagihan',
      accessor: 'tagihan', // accessor is the "key" in the data
    },
    {
      Header: 'Periode',
      accessor: 'periode', // accessor is the "key" in the data
    },
    status === "menunggu" ?
      {
        Header: 'Jatuh Tempo',
        accessor: 'jatuh_tempo', // accessor is the "key" in the data
      }
      : status === "lunas" ?
        {
          Header: 'Tanggal Pembayaran',
          accessor: 'tanggal_pembayaran', // accessor is the "key" in the data
        }
        : status === "diputihkan" ?
          {
            Header: 'Tanggal Diputihkan',
            accessor: 'tanggal_diputihkan', // accessor is the "key" in the data
          }
          : null
  ]), [status, data])

  const handleGetInvoiceList = async () => {
    try {
      setLoading(true)
      let params = `?invoice_status=${status}`
      if(date) {
        params += `&month=${dayjs(date).month()}&year=${dayjs(date).year()}`
      }
      const response = await getInvoiceList(params)
      setData(response?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetInvoiceList()
  }, [status, date])

  return {
    data: handleMapData,
    COLLUMNS,
    status,
    date, 
    setStatus,
    setDate,
    loading,
  }
}

export default useListInvoice