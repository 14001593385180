import React, { useMemo } from 'react'

//Component - Table transaksi
import TTIndex from "pages/dashboard/e-reporting/TableTransaksi"

//Component - Ereporting form
import ERFIndex from "pages/dashboard/e-reporting/ERForms"
import Form from "pages/dashboard/e-reporting/forms"

//Context
import { useERState } from "pages/dashboard/e-reporting/ERCtx"

const ERMain = () => {
  const { page } = useERState()

  const RenderPage = useMemo(() => {
    // return <Form />

    switch (page) {
      case "table-transaksi":
        return <TTIndex />
      case "form": 
        // return <ERFIndex />
        return <Form />
      default:
        return null
    }
  }, [page])

  return (
    <div className='nav-content-wrapper h-screen overflow-y-auto'>
      {RenderPage}
    </div>
  )
}

export default ERMain