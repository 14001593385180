import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';

//Styles
import "./style.scss"

//Assets
import { ReactComponent as ArrowLeft } from "assets/arrow-left-tail-white.svg"
import { ReactComponent as Signout } from "assets/sign-out.svg"

//Context
import { GlobalContext } from "contexts";

function Header() {
  const navigate = useNavigate();
  const { logoutAction } = useContext(GlobalContext);

  return (
    <div id='kmod-header'>
      <div className='title'>
        <p>Key Metric Dashboard</p>
        <p>ONE21</p>
      </div>
      <div className='buttons'>
        <button onClick={() => navigate("/")}>
          <ArrowLeft />
          <span>Kembali ke Beranda</span>
        </button>

        <Signout className='signout' onClick={() => logoutAction(() => navigate("/login"))} />
      </div>
    </div>
  )
}

export default Header