import React from 'react'
import "./style.scss"

function InputLoading({ }) {
  return (
    <div className='input-loading'>
      <div className={`loader`}></div>
    </div>
  )
}

export default InputLoading