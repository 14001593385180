import React, { useState, useEffect } from 'react'
import _ from "lodash"
import "./style.scss"

//Assets
import PrevArrow from "assets/prev-arrow-white.png"
import NextArrow from "assets/next-arrow-white.png"
import XIcon from "assets/x-white.png"

//Context
import { useDetailListingCtx } from "../Context"

function ImageModal() {
  const { detailImages, setIsOpenModalImages } = useDetailListingCtx()

  const [position, setPosition] = useState(1)
  const [img, setImg] = useState({})

  const renderImage = () => {
    if (detailImages.data.length) {
      const img = detailImages.data.filter((img) => img.position == position)
      if (img?.length) {
        setImg(img[0])
      }
    } else {
      setImg("")
    }
  }

  const handlePrevImg = () => {
    let newPosition = position
    if(newPosition > 1){
      newPosition = newPosition - 1
    }
    setPosition(newPosition)
  }

  const handleNextImg = () => {
    let newPosition = position
    if(newPosition < (detailImages.data.length)){
      newPosition = newPosition + 1
    }
    setPosition(newPosition)
  }

  useEffect(() => {
    renderImage()
  }, [position])

  return (
    <div className='properti-detail-image-modal'>
      <div className='properti-modal-body'>
        <div className='image-modal-close' onClick={() => setIsOpenModalImages(false)}><img className='img-close' src={XIcon} alt="x" /> <span>Tutup</span></div>

        <p className='image-modal-page'>{position}/{detailImages.data.length}</p>
        <div className='image-modal-content'>
          <img onClick={handlePrevImg} src={PrevArrow} alt="prev-arrow" className="prev-arrow" />
          <div className='image-wrapper'>
            <img src={_.get(img, "image.url") || ""} alt='...' />
          </div>
          <img
            onClick={handleNextImg}
            src={NextArrow} alt="next-arrow" className="next-arrow" />

        </div>
        <p className='image-modal-tag'>{_.get(img, "name") || ""}</p>
      </div>
    </div>
  )
}

export default ImageModal