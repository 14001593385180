import React from 'react'

//Styles
import Styles from "./button-icon-custom.module.scss";

//Utils
import useEnterKey from "hooks/useEnterKey";

function ButtonIconCustom({ Icon, outlineOnly, ...props }) {
  useEnterKey(() => {
    props.onClick && props.onClick();
  })
  return (
    <button {...props} className={`${Styles['btn']} ${Styles[outlineOnly ? 'outline-only' : '']} ${props.className || ""}`}>
      <span className={Styles['text']}>{props.text}</span>
      {Icon && <Icon className={Styles['icon']} />}
    </button>
  )
}

export default ButtonIconCustom