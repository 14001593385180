import React from 'react'

import MapButton from './MapButton';
import CTextareaInputV2 from "../atomic/Input/CTextareaInputV2"

import { ReactComponent as PinIcon } from "../../assets/map-pin.svg";

function MapFieldV1({ selectedStreet, pinMapStreet, onChange, CustomTitle, isSelected, ...res }) {
  return (
    <div className='map-field-v1'>
      {CustomTitle ? CustomTitle : <p className='map-field-title'>Lokasi di peta</p>}
      <div className='map-field-wrapper'>
        <div className='outter-map-field'>
          <CTextareaInputV2
            className="map-field-textarea"
            value={selectedStreet}
            placeholder="Belum pilih lokasi di peta nih.."
            onChange={onChange}
            disabled={!isSelected}
          />
          {pinMapStreet ? <div className='map-address'>
            <PinIcon className='map-pin' />
            <p className='map-pin-street'>{pinMapStreet}</p>
          </div> : null}
        </div>
        <MapButton {...res} />
      </div>
    </div>
  )
}

export default MapFieldV1