import React, { useState } from 'react';
import { Controller } from "react-hook-form";

import CurrencyInput from 'react-currency-input-field';

function UCInputWithOptionV1({
  label,
  name,
  disabled,
  error,
  className,
  suffixPosition,
  mandatoryLabel,
  control,
  setValue,
  register,
  onChange
}) {
  const [optionType, setOptionType] = useState("%")

  return (
    <div className={`uc-input-option-v1 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      {mandatoryLabel ? <span className='addon-label'>{"("}<span className='mandatory-label'>* tidak boleh kosong</span>{")"}</span> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          {/* <Controller
            control={control}
            name={name}
            rules={{ required: true }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <CurrencyInput
                  disabled={disabled}
                  name={name}
                  value={value}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(amount) => {
                    if(optionType == "%" && Number(amount || "0") >= 100){
                      onChange("100")
                    } else {
                      onChange(amount)
                    }
                  }}
                  allowDecimals={false}
                  allowNegativeValue={false}
                />
              )
            }}
          /> */}

          <Controller
            control={control}
            name={name}
            rules={{ required: true }}
            render={({ field: { name, value } }) => {
              return (
                <input
                  name={name}
                  value={value}
                  type="number"
                  onChange={onChange} />
              )
            }}
          />
        </div>
        <div className='suffix-wrapper'>
          {/* <select onChange={(e) => {
            setValue(name, "0")
            setOptionType(e.target.value)
          }}>
            <option value="%">%</option>
            <option value="Rp">Rp</option>
          </select> */}
          <span>%</span>
        </div>
      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

UCInputWithOptionV1.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  type: "text",
  disabled: false,
  mandatoryLabel: false,
  onChange: () => { }
}

export default UCInputWithOptionV1