import dayjs from "dayjs";
import { setToLocalstorage, getLocalstorage, removeLocalstorage } from "./localStorage";

const TOKEN_KEY = "_cduastau_access_token";

export const setToken = (token, expiry) => {
  const value = {
    token,
    expiry: dayjs(expiry).format()
  }

  return setToLocalstorage(TOKEN_KEY, value)
}

export const getToken = () => getLocalstorage(TOKEN_KEY);

export const removeToken = () => removeLocalstorage(TOKEN_KEY);