import React from 'react'
import _ from "lodash"

import "./style.scss"

//Assets
import RoundDivider from "assets/round-divider.png";

//Context
import { useDetailListingCtx } from "../../Context"

function UkuranProperti() {
  const { listDetail } = useDetailListingCtx()
  const getValue = (str = "") => _.get(listDetail, str)

  return (
    <div className='properti-detail-ukuran-properti'>
      <p className='ukuran-properti-header'>Ukuran Properti</p>
      <div className='ukuran-properti-content'>
        {+getValue("surface_area") ? <p><img src={RoundDivider} alt="dot" /> Luas tanah {getValue("surface_area")} m<sup>2</sup></p> : null}
        {+getValue("building_area") ? <p><img src={RoundDivider} alt="dot" /> Luas bangunan {getValue("building_area")} m<sup>2</sup></p> : null}
        {+getValue("land_length") ? <p><img src={RoundDivider} alt="dot" /> Panjang tanah {getValue("land_length")} m</p> : null}
        {+getValue("building_length") ? <p><img src={RoundDivider} alt="dot" /> Panjang bangunan {getValue("building_length")} m</p> : null}
        {+getValue("land_width") ? <p><img src={RoundDivider} alt="dot" /> Lebar tanah {getValue("land_width")} m</p> : null}
        {+getValue("building_width") ? <p><img src={RoundDivider} alt="dot" /> Lebar bangunan {getValue("building_width")} m</p> : null}
        {!+getValue("surface_area") && !+getValue("building_area") && !+getValue("land_length") && !+getValue("building_length") && !+getValue("land_width") && +getValue("building_width") ? <p>Tidak Ada Data</p> : null}
      </div>
    </div>
  )
}

export default UkuranProperti
