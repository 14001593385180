import React, { useEffect, useState, useCallback } from 'react'
import Geocode from "react-geocode";
import {
  GoogleMap,
  Marker,
  InfoWindow
} from "@react-google-maps/api";

function CGoogleMap(props) {
  const { 
    edited,
    latitude,
    longitude,
    street,
    setLatitude,
    setLongitude,
    setStreet
  } = props;
  const [loading, setLoading] = useState(edited ? false : true);

  const position = { lat: latitude, lng: longitude }

  const onMarkerCallback = useCallback(
    (value) => {
      const currentLatitude = value.latLng.lat().toString();
      const currentLongitude = value.latLng.lng().toString();
      Geocode.fromLatLng(currentLatitude, currentLongitude).then((response) => {
        setLatitude(currentLatitude)
        setLongitude(currentLongitude)
        setStreet(response.results[0].formatted_address)
      });
    },
    []
  );


  const onMapDoubleClick = (e) => {
    const currentLatitude = e.latLng.lat()
    const currentLongitude = e.latLng.lng()

    Geocode.fromLatLng(currentLatitude, currentLongitude).then((response) => {
      setLatitude(currentLatitude)
      setLongitude(currentLongitude)
      setStreet(response.results[0].formatted_address)
    });
  }

  useEffect(() => {
    Geocode.setApiKey("AIzaSyAV06y2yu3mJx4tEBcGMisY3kxxOgg4aU4");
    Geocode.setLanguage("id");
    Geocode.setRegion("id");

    if (!edited) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            Geocode.fromLatLng(
              position.coords.latitude,
              position.coords.longitude
            ).then((response) => {
        
              setLatitude(position.coords.latitude)
              setLongitude(position.coords.longitude)
              setStreet(response.results[0].formatted_address)

              setLoading(false);
            });
          },
          function () {
            setLoading(false);
          },
          { timeout: 30000, enableHighAccuracy: true, maximumAge: 75000 }
        );
      } else {
        setLoading(false);
      }
    }
  }, [])

  if (loading) return <div style={{
    height: "500px",
    width: "100%"
  }} />

  return (
      <GoogleMap
        id="circles-example"
        mapContainerStyle={{
          height: "500px",
          width: "100%"
        }}
        zoom={18}
        center={position}
        smoothCenter={position}
        onDblClick={onMapDoubleClick}
      >
        <Marker
          position={position}
          draggable={true}
          onDragEnd={onMarkerCallback}
        />
        <InfoWindow
          onClose={(err) => console.log(err)}
          position={{
            lat: Number(latitude) + 0.0002,
            lng: Number(longitude) || 0,
          }}
        >
          <div>
            <span style={{ padding: 0, margin: 0 }}>
              {`${street}`}
              <strong style={{ fontWeight: 800 }}> (Titik Lokasi)</strong>
            </span>
          </div>
        </InfoWindow>
      </GoogleMap>
  )
}

// export default withGoogleMap(CGoogleMap)
export default CGoogleMap