import React from 'react'

import Styles from "./box-flex.module.scss";

function BoxFlex({ children, className }) {
  return (
    <div className={`${Styles['wrapper']} ${className}`}>
      {children}
    </div>
  )
}

export default BoxFlex