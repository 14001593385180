import React, { useState, useCallback } from 'react'
import dayjs from "dayjs"
import { useDropzone } from 'react-dropzone'

// Style
import Style from "./modal-pjp.module.scss"

// Components
import { CModal } from 'components';

import { createPJPOffline } from "actions"

// Assets
import { ReactComponent as ArrowLeft } from "assets/arrow-left-tail.svg";
import { ReactComponent as SelectedImage } from "assets/selected-image.svg";
import { ReactComponent as SelectedPdf } from "assets/selected-pdf.svg";
import { ReactComponent as FileIcon } from "assets/file-icon.svg";
import { ReactComponent as ImageIcon } from "assets/image-icon.svg";
import { ReactComponent as XRed } from "assets/x-red.svg";
import { ReactComponent as Info } from "assets/info-black.svg";
import { ReactComponent as ArrowDown } from "assets/arrow-bottom.svg";
import CrossIcon from "assets/round-x-red.png"
import Disc from "assets/disc-white.png"
import Loader from 'components/molecules/Loader';
import Button from "components/v1/Button"

const MASA_BERLAKU = ["3 Bulan", "6 Bulan", "9 Bulan", "12 Bulan"]

const ImageCard = ({
  selectedImage,
  onDelete
}) => {
  return (
    <div className='w-[150px] mr-[24px] relative h-[150px] rounded-md'>
      <img onClick={onDelete} className='cursor-pointer absolute top-[-5px] right-[-5px] w-[16px] h-[16px]' src={CrossIcon} alt="close" />
      <img className='w-full rounded-md h-full object-cover' src={selectedImage} alt="selected-image" />
    </div>
  )
}

const ModalPJP = ({ onClose, propertyId }) => {
  const [effectiveDate, setEffectiveDate] = useState("")
  const [validyPeriod, setValidityPeriod] = useState("")
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const onDrop = (acceptedFiles) => {
    setFiles(val => {
      let newVal = [...val];

      for (let i = 0; i < acceptedFiles.length; i++) {
        if (newVal.length >= 5) {
          break;
        }

        newVal.push(acceptedFiles[i])
      }

      return newVal
    })
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const onDeleteFile = (index) => {
    setFiles(files.filter((f, i) => i !== index))
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      let formData = new FormData()
      formData.append(`marketing_agreement[property_id]`, propertyId)
      formData.append(`marketing_agreement[validity_period]`, "1200 Bulan")
      formData.append(`marketing_agreement[effective_date]`, dayjs().format())

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`marketing_agreement[marketing_agreement_attachments_attributes[${i}][attachment]]`, files[i])
        }
      }

      await createPJPOffline(formData)
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CModal>
      {loading && <div className='absolute z-[999999] flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
        <Loader />
      </div>}
      <div className={Style["wrapper"]}>
        <div className={`${Style["header"]} flex justify-between`}>
          <div>
            <p className={Style["header-main"]}>Buat Perjanjian Jasa Pemasaran</p>
            <p className={Style["header-sub"]}>Silahkan unggah Perjanjian Jasa Pemasaran untuk listing yang dipilih.</p>
          </div>
          {/* <div className="flex items-center">
            <div className="mr-6 w-[200px]">
              <p className='text-gray-1 mb-[8px] text-xs'>Sejak Kapan PJP Berlaku <span className='text-red-1'>*</span></p>
              <div className='border-solid rounded-md border shadow-black-rgba-0 border-black-rgba-0 flex items-center py-[10px] px-[16px]'>
                <input type='date' value={effectiveDate} onChange={(e) => setEffectiveDate(e.target.value)} />
              </div>
            </div>
            <div className='w-[200px]'>
              <p className='text-gray-1 mb-[8px] text-xs'>Sejak Kapan PJP Berlaku <span className='text-red-1'>*</span></p>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <div className='w-[200px] border-solid rounded-md border shadow-black-rgba-0 border-black-rgba-0 py-[10px] px-[16px]  flex items-center justify-between'>
                    <input className='w-[80%] text-black-0 text-sm' placeholder='Pilih masa berlaku' value={validyPeriod} readOnly />
                    <ArrowDown />
                  </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className='w-[200px] bg-white border z-[100002]'>
                  {MASA_BERLAKU.map((v, i) => (
                    <DropdownMenu.Item onClick={() => setValidityPeriod(v)} key={`masa-berlaku-${i}`} className="cursor-pointer w-full text-sm p-2">
                      {v}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          </div> */}
        </div>

        {!!files?.length && (
          <>
            <div className='w-full mb-[54px] flex items-center justify-center'>
              {files.map((f, i) => (<ImageCard onDelete={() => onDeleteFile(i)} selectedImage={URL.createObjectURL(f)} key={`selected-files-${i}`} />))}
            </div>
          </>
        )}

        <div {...getRootProps({ className: `${Style['body']} dropzone` })}>
          {!files?.length && (
            <div className={Style["unselect-file"]}>
              <ImageIcon />
            </div>
          )}
          <p className={Style["body-header"]}>Tarik dan letakkan gambar atau dokumen PJP</p>
          <p className={Style["body-sub"]}>File bisa dalam format jpg dan png - Besar file maksimal 50MB</p>

          <div className={Style["select-file-wrapper"]}>
            <span className={Style["or"]}>atau</span>
            <button className={Style["btn-select-file"]}>Pilih file dari komputer</button>
          </div>

          <span className={Style["info"]}>*Bisa unggah lebih dari 1 gambar</span>
          <input {...getInputProps()} />
        </div>
        <div className={`${Style["footer"]} flex items-center justify-between`}>
          <Button text="Detail Listing" size="xl" type="secondary" icon={ArrowLeft} onClick={onClose} />
          <Button text="Simpan" icon={Disc} iconPosition="right" size="xl" disabled={!files?.length} onClick={onSubmit} />
        </div>
      </div>
    </CModal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalPJP {...props} />
}

export default Wrapper
