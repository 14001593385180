import React, { Fragment, useEffect, useState, useContext } from 'react'
import dayjs from 'dayjs';
import lodash from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

// Assets
import { ReactComponent as Dot } from 'assets/dot.svg';
import { ReactComponent as CheckIcon } from 'assets/green-circle-check.svg';
import { ReactComponent as WarningIcon } from 'assets/red-warning.svg';
import { ReactComponent as InfoIcon } from 'assets/info-bg-black.svg';
import { ReactComponent as NextIcon } from 'assets/next.svg';
import { ReactComponent as PrevIcon } from 'assets/previous.svg';

// Components
import ModalLoader from 'components/organism/ModalLogout';

// Context
import { GlobalContext } from "contexts";

// API
import { getRumah123 } from "actions"

const FilterButton = ({ className = "", children, isActive, ...props }) => <button {...props} className={`py-[10px] px-4 border-[1px] border-solid border-black-3 ${className} ${isActive ? "bg-black-0 text-white" : "bg-white"}`}>{children}</button>

const TableSize = {
  no: "7.71%",
  date: "20.13%",
  name: "20.13%",
  phoneNumber: "17.7%",
  listingId: "20.13%",
  adsUrl: "14.2%",
}

const HeaderText = "text-xs text-gray-0";

const CopyButton = ({ payload }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(payload))

    toast.success("Berhasil menyalin", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  return <button className='cursor-pointer text-blue-0 underline text-sm' onClick={handleCopy}>Copy JSON</button>
}

const TableRow = ({ no, date, hour, name, phoneNumber, listingId, status, adsUrl, message, payload }) => (
  <Fragment>
    <div className='w-full flex'>
      <div style={{ width: TableSize.no }} className={`pt-6 pb-4 mr-2 text-sm`}>{no}</div>
      <div style={{ width: TableSize.date }} className={`pt-6 pb-4 mr-2 text-sm`}>
        <div className='flex items-center'>
          <span className='text-gray-2 text-sm'>{date}</span>
          <Dot className="w-2 h-2 px-4" />
          <span className='text-gray-2 text-sm'>{hour}</span>
        </div>
      </div>
      <div style={{ width: TableSize.name }} className={`pt-6 pb-4 mr-2 text-sm`}>{name}</div>
      <div style={{ width: TableSize.phoneNumber }} className={`pt-6 pb-4 mr-2 text-sm font-sfpro-medium`}>{phoneNumber}</div>
      <div style={{ width: TableSize.listingId }} className={`pt-6 pb-4 mr-2 text-sm font-sfpro-medium`}>
        <div className='flex items-center'>
          <span className='text-gray-2 text-sm'>#{listingId}</span>
          <Dot className="w-2 h-2 px-4" />
          <span className={`${status === "Error" ? "text-red-0" : status === "Completed" ? "text-green-1" : "text-gray-2"} text-sm font-sfpro-medium`}>{status}</span>
        </div>
      </div>
      <div style={{ width: TableSize.adsUrl }} className={`pt-6 pb-4`}>
        <div className='flex items-center justify-between'>
          <a href={adsUrl} target="_blank" className={`underline text-sm ${!!(status === "Completed") ? "text-blue-0" : "text-black-3"}`}>Lihat Iklan</a>
          <CopyButton payload={payload} />
        </div>
      </div>
    </div>
    <div className='w-full pt-6 pb-4 bg-white-4 border-t-[1px] border-solid border-t-white-3'>
      <div className='flex'>
        {status === "Error" ? <WarningIcon className='w-4 h-4 mr-2' /> : status === "Completed" ? <CheckIcon className='w-4 h-4 mr-2' /> : <InfoIcon className='w-4 h-4 mr-2' />}
        <div>
          <p className='text-xs text-gray-0 mb-[2px]'>Respon Rumah123</p>
          <p className='text-xs mb-[12px]'>{message}</p>
        </div>
      </div>
      <div className='h-[4px] w-full bg-white-0' />
    </div>
  </Fragment>
)

const ProgressTrackerRumah123 = () => {
  const { profile } = useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [dataListing, setDataListing] = useState([])
  const [inputPage, setInputPage] = useState(currentPage)
  const [loading, setLoading] = useState(false)

  const handleNextPage = () => {
    if (currentPage === totalPage) return;
    setCurrentPage(val => val + 1)
  }

  const handlePrevPage = () => {
    if (currentPage === 0) return;

    setCurrentPage(val => val - 1)
  }

  const handleOnChangeShortcut = (e) => {
    
    const { value } = e.target ?? 1;
    setInputPage(+value)
  }

  const handleGetRumah123 = async () => {
    try {
      setLoading(true)
      const response = await getRumah123(`?page=${currentPage}`)
      const totalItem = response?.headers?.["x-total"]
      const totalPage = lodash.ceil(totalItem / 10) || 0

      setTotalPage(totalPage)
      setDataListing(response?.data?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetRumah123()
  }, [currentPage])

  return (
    <>
      <div className='relative w-[calc(100vw-80px)] ml-[80px] flex flex-col items-center bg-white-2'>
        {/** Header */}
        <div className='bg-white w-full p-8 flex items-end justify-between border-b-[0.5px] border-solid border-white-0 box-shadow-0'>
          <div>
            <p className='text-3xl font-sfpro-medium mb-2'>Rumah123 Listing Progress Tracker</p>
            <p className='text-gray-2 text-base'>Selamat datang kembali, {profile?.full_name ?? ""}!</p>
          </div>

          {/* <div className='rounded-md flex items-center h-[40px]'>
            <FilterButton isActive={true} className='rounded-l-md'>Rumah123</FilterButton>
            <FilterButton isActive={false}>Site 2</FilterButton>
            <FilterButton isActive={false}>Site 3</FilterButton>
            <FilterButton isActive={false} className='rounded-r-md'>Site 4</FilterButton>
          </div> */}
        </div>

        {/** TABEL */}
        <div className='w-[calc(100%-64px)] mt-[55px] mb-[123px] bg-white py-4 px-8 rounded-md border-[0.5px] border-solid border-white-0'>
          <div className='w-full'>
            <div className='py-4 flex w-full'>
              <div style={{ width: TableSize.no }} className={`mr-2 ${HeaderText}`}>No</div>
              <div style={{ width: TableSize.date }} className={`mr-2 ${HeaderText}`}>Tanggal & Jam</div>
              <div style={{ width: TableSize.name }} className={`mr-2 ${HeaderText}`}>Nama User</div>
              <div style={{ width: TableSize.phoneNumber }} className={`mr-2 ${HeaderText}`}>Nomor HP</div>
              <div style={{ width: TableSize.listingId }} className={`mr-2 ${HeaderText}`}>ID listing</div>
              <div style={{ width: TableSize.adsUrl }} className={`mr-2 ${HeaderText}`}>URL Iklan</div>
            </div>

            {!!dataListing?.length && dataListing.map((d, i) => {
              const listing = d?.response?.listing ?? d?.response?.data?.[0]?.listing;
              const errorMsg = listing?.errors?.[0];
              const statusNot200 = listing?.status !== 200;
              const message = listing?.message;
              const mappedData = {
                no: i + 1,
                date: !!d?.updated_at ? dayjs(d.updated_at).format('DD MMM, YYYY') : "",
                hour: !!d?.updated_at ? dayjs(d.updated_at).format('HH:mmA') : "",
                name: d?.user?.full_name ?? "",
                phoneNumber: d?.user?.phone ?? "",
                listingId: d?.property?.id,
                status: d?.status ?? "",
                adsUrl: d?.url_listing ?? "#",
                message: (!!errorMsg && statusNot200) ? `${d?.status}, ${errorMsg}` : message,
                payload: d?.payload ?? {}
              }
              return <TableRow key={`detail-listing-${i + 1}`} {...mappedData} />
            })}
          </div>
        </div>

        {/** FOOTER */}
        <div className='fixed justify-between left-[80px] bottom-0 w-[calc(100vw-80px)] bg-white py-[40px] px-[32px] flex items-center box-shadow-1 border-[0.5px] border-solid border-white-0'>
          <div className='flex items-center'>
            <p className='text-sm text-gray-2 mr-[32px]'>Pergi ke halaman</p>
            <div className='flex items-center border-gray-2 border-[1px] border-solid rounded-[8px] px-4 py-[10px]'>
              <input min={1} onChange={handleOnChangeShortcut} value={inputPage} max={totalPage} className='w-[32px] h-[20px] mr-2' placeholder='0' type='number' />
              <NextIcon onClick={() => setCurrentPage(+inputPage)} className='w-[14px] h-[14px] object-contain cursor-pointer' />
            </div>
          </div>

          <div className='flex items-center'>
            <button onClick={handlePrevPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'><PrevIcon className='w-[14px] h-[14px] object-contain mr-2' /> Sebelumnya</button>
            <p className='px-[32px] text-sm'>{currentPage} <span className='text-gray-2'>dari</span> {totalPage} <span className='text-gray-2'>halaman</span></p>
            <button onClick={handleNextPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'>Selanjutnya <NextIcon className='w-[14px] h-[14px] object-contain ml-2' /></button>
          </div>
        </div>

      </div>
      {loading && <ModalLoader open={true} />}
    </>
  )
}

export default ProgressTrackerRumah123