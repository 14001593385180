import React from 'react'

//Styles
import Styles from './alamat-email-pemilik-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import InfoIconGolden from 'pages/dashboard/e-reporting/forms/components/reuseables/info-icon-golden/InfoIconGolden'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets
import { ReactComponent as Envelop } from 'assets/envelop-simple.svg'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function AlamatEmailPemilikProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()

  //State
  const [value, setValue] = React.useState(form.email_pemilik_properti.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('email_pemilik_properti', value)
    setCurrentStep(STEPS.provinsiPemilikProperti)
  }
  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium>
          Alamat email pemilik properti
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText value={value} disabled={isAprroval} Icon={Envelop} type="email" onChange={(e) => setValue(e.target.value || '')} placeholder="Tulis alamat email" />
        <InfoIconGolden className={Styles['info']}>
          Tidak wajib diisi (optional)
        </InfoIconGolden>
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            <ButtonSelanjutnya onClick={onNext} />
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default AlamatEmailPemilikProperti