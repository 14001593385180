import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

function CDateInputV2({
  label,
  name,
  placeholder,
  disabled,
  errors,
  className,
  onChange,
  value
}) {
  return (
    <div className={`c-date-input-v2 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`textarea-wrapper ${disabled ? "disabled" : ""}`}>
        <input
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          type="date"
          value={value}
          onChange={onChange}
        />
      </div>
      {errors ? <ErrorMessage
        errors={errors}
        name="gender"
        render={({ message }) => <p className='text-xs text-red-0'>{message}</p>}
      /> : ""}
    </div>
  )
}

CDateInputV2.defaultProps = {
  label: "",
  error: "",
  name: "",
  placeholder: "",
  disabled: false,
  onChange: () => { }
}

export default CDateInputV2