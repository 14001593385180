import React from 'react'
import dayjs from "dayjs";

//Styles
import Styles from './pilih-tanggal-transaksi-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order/Order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import InputTanggal from 'pages/dashboard/e-reporting/forms/components/reuseables/input-tanggal'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihTanggalTransaksiProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { form, isAprroval, isEditing } = useFormState()

  //State
  const [value, setValue] = React.useState(form.tanggal_transaksi.value ? dayjs(form.tanggal_transaksi.value).toDate() : dayjs().toDate())

  const onNext = () => {
    handlePrevState()
    formHandler('tanggal_transaksi', value)

    if (form.selected_listing?.value?.property_type?.id === 2) setCurrentStep(STEPS.pilihDurasiSewa)
    else setCurrentStep(STEPS.nilaiTransaksi)
  }

  const isDateAfterToday = dayjs(value).isAfter(dayjs().toDate())
  const isDateBelow1Month = dayjs(value).isBefore(dayjs().subtract(1, "months").startOf('month').toDate())

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Kapan transaksi dilakukan?
        </TextMedium>
      </div>
      <div className={Styles['input-wrapper']}>
        <InputTanggal
          value={value}
          placeholderText='Pilih tanggal'
          selected={value}
          onChange={(date) => setValue(date)}
          disabled={isAprroval}
        />
        {isDateAfterToday && <p className={Styles['error-message']}>Tanggal transaksi tidak boleh lebih dari hari ini</p>}
        {isDateBelow1Month && <p className={Styles['error-message']}>Tanggal transaksi tidak kurang dari {dayjs().subtract(1, "month").startOf('month').format("DD MMMM YYYY")}</p>}
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {((!!value) || (isAprroval || isEditing)) && (!isDateAfterToday && !isDateBelow1Month) && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>

    </WrapperAndBox>
  )
}

export default PilihTanggalTransaksiProperti