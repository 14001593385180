import React from 'react'

//Classes
import { classModuleGrouping } from "utils/classModuleGrouping";

function useClassHandler(Styles) {
  const classHandler = (strngs) => classModuleGrouping(Styles, strngs);

  return classHandler
}

export default useClassHandler