import React, { useMemo } from 'react'

//Styles
import "./style.scss";
import Styles from './header.module.scss'

//Components
import { ButtonSvgIcon } from "components";

//Assets
import { ReactComponent as PrevArrow } from "assets/prev-arrow.svg";
import { ReactComponent as DoubleCheck } from "assets/double-check.svg";
import { useFormState, useFormMethod, STEPS } from "pages/dashboard/e-reporting/forms/context";

const Header = () => {
  const { isAprroval, userLoginAuth, isEditing, currentStep } = useFormState();
  const { submitPayload } = useFormMethod();
  const isMb = useMemo(() => userLoginAuth?.role?.name == "Member Broker" ? true : false, [userLoginAuth])
  const isVMb = useMemo(() => userLoginAuth?.role?.name == "Vice Member Broker" ? true : false, [userLoginAuth])
  const onConfirm = () => submitPayload()
  const isRekapKomisi = STEPS.rekapKomisi === currentStep

  return (
    <div className={`laporan-form-header`}>
      <p>Laporan transaksi</p>
      <div className='btns-wrapper'>
        {(isAprroval && isMb) && <ButtonSvgIcon
          icon={<DoubleCheck />}
          text="Setujui Transaksi"
          className='approval-btn !text-white'
          action={() => submitPayload()}
        />}
        {(!isAprroval && isRekapKomisi) && <div className={Styles['btn-wrapper']}>
          {(!isEditing || (isEditing && isVMb)) && <button className={`${Styles['btn']} ${Styles['black']}`} onClick={onConfirm}>Simpan data transaksi</button>}
        </div>}
        <button onClick={() => window.location.reload()}>
          <PrevArrow />
          <span>Kembali ke halaman utama</span>
        </button>
      </div>
    </div>
  )
}

export default Header