import React from 'react'

//Styles
import Styles from "./input-biaya-referal-penjual.module.scss";

//Components
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya'
import InfoIconGolden from 'pages/dashboard/e-reporting/forms/components/reuseables/info-icon-golden/InfoIconGolden'
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium/TextMedium';
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'

//Utils
import { classModuleGrouping } from "utils/classModuleGrouping";
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function InputBiayaReferalPenjual() {
  const { formHandler, setCurrentStep, handlePrevState } = useFormMethod();
  const { form, isAprroval } = useFormState();

  const classHandler = (strngs) => classModuleGrouping(Styles, strngs);

  const handleNext = () => {
    handlePrevState()
    if (form.is_co_broking.value === 'tidak') {
      setCurrentStep(STEPS.biayaReferralPembeli)
    } else setCurrentStep(STEPS.pilihKantorMarketingPembeli)
  }

  return (
    <div className={classHandler('wrapper')}>
      <div className={classHandler('box')}>
        <div className={"!ml-[30px]"}>
          <div className={"!mb-[8px]"}>
            <TextMedium>
              Apakah ada biaya <span className={Styles['gold']}>refferal penjual</span>? Silahkan masukkan berapa persen biaya refferal yang disisihkan dari komisi yang diterima penjual. Jika ada
            </TextMedium>
          </div>
          <div className={"!mb-[8px]"}>
            <InputCurrency
              disabled={isAprroval}
              onValueChange={(e) => formHandler('biaya_refferal_penjual', e)}
              value={form.biaya_refferal_penjual.value || ""}
              placeholder={'Berapa rupiah?'}
            />
          </div>

          <InfoIconGolden>
            <span className={Styles['bold']}>Biaya refferal</span> adalah biaya yang dikeluarkan oleh marketing sebagai komisi kepada orang (non marketing) yang membantu marketing tersebut dalam melakukan transaksi.
          </InfoIconGolden>
          <BoxFlex>
            <>
              <ButtonSebelumnya />
              <ButtonSelanjutnya onClick={handleNext} />
            </>
          </BoxFlex>
        </div>
      </div>
    </div>
  )
}

const withInputBiayaReferalPenjual = (Component) => (props) => {
  const { form, userLoginAuth, isAprroval, isEditing } = useFormState();
  const { setCurrentStep } = useFormMethod();
  const [isRender, setIsRender] = React.useState(false)

  React.useEffect(() => {
    const kantorPenjual = form.kantor_marketing_penjual.value.id;

    if (kantorPenjual !== userLoginAuth.company.id) {

      if (form.is_co_broking.value === 'tidak') {
        if (isAprroval || isEditing) setCurrentStep(STEPS.entryDataPropertiDanTransaksi)
        else setCurrentStep(STEPS.entryPilihListing)
      } else setCurrentStep(STEPS.pilihKantorMarketingPembeli)

    } else {
      setIsRender(true)
    }
  }, [form.is_co_broking.value])

  if (!isRender) return null;
  return (
    <Component {...props} />
  )
}

export default withInputBiayaReferalPenjual(InputBiayaReferalPenjual)