import React from 'react'
import "./style.scss"

//Context
import { useDetailListingCtx } from "../../Context"

function KontakOwner() {
  const { getValue } = useDetailListingCtx()
  return (
    <div className='properti-detail-kontak-owner'>
      <p className='kontak-owner-header'>Kontak Owner</p>
      <p className='kontak-owner-contact'>{`${getValue("property_owner.name") || ""}${getValue("property_owner.phone") ? "," : ""}`} {getValue("property_owner.phone") || ""}</p>
      {(!getValue("property_owner.name") && !getValue("property_owner.phone")) ? <p>Tidak Ada Data</p> : null}
    </div>
  )
}

export default KontakOwner