import React, { useState } from 'react'
import lodash from 'lodash'
import dayjs from 'dayjs'

import Lampiran from "./Lampiran"

// Styles
import Styles from './detail.module.scss'

import toRupiah from 'helpers/toRupiah'
import tranformDateToSlash from 'helpers/tranformDateToSlash'

// Assets
import { ReactComponent as InfoPPH23 } from 'assets/info-pph23.svg'
import CarretIcon from 'assets/carret-up.png'

const LampiranRow = ({
  userId,
  userName,
  personalCommission
}) => {
  return (
    <div className='grid grid-cols-3 p-2 gap-x-[4px] pb-2 mb-2 border-bottom-[1px] border-solid border-white-0'>
      <p className='text-xs text-black-5'>#{userId}</p>
      <p className='text-xs text-black-5'>{userName}</p>
      <p className='text-xs text-black-5 justify-self-end'>Rp{personalCommission}</p>
    </div>
  )
}

const ListMarketingHeader = () => {
  return (
    <div className='grid grid-cols-3 w-full py-[4px] px-[8px]'>
      <p className='text-xs text-black-5 w-full'>ID User</p>
      <p className='text-xs text-black-5 w-full'>Nama</p>
      <p className='text-xs text-black-5 w-full text-end'>Tagihan</p>
    </div>
  )
}
const ListMarketingRow = ({ id, name, tagihan }) => {
  return (
    <div className='grid grid-cols-3 w-full py-[4px] px-[8px]'>
      <p className='text-xs text-black-5 w-full'>#{id}</p>
      <p className='text-xs text-black-6 w-full'>{name}</p>
      <p className='text-xs text-black-6 w-full text-end'>Rp{tagihan}</p>
    </div>
  )
}

const DownloadableDetail = ({ data, htmlToPdfRef }) => {
  const [openCDetails, setOpenCDetails] = useState(true)

  const RenderTotalText = (status) => {
    switch (status) {
      case "lunas":
        return "Total yang telah dibayar"
      case "menunggu":
        return "Total yang harus dibayarkan"
      case "diputihkan":
        return "Total Pembayaran"
      default: return ""
    }
  }

  const paymentDate = () => {
    switch (data?.invoice_status) {
      case "lunas": return (
        <div className={`${Styles['row']} ${Styles['detail-biaya']}`}>
          <div>
            <p className={`${Styles['grey-small']} mb-[8px]`}>Tanggal pembayaran</p>
            <p className={Styles['black-regular-bold']}>{dayjs(data?.paided_at).format("DD-MM-YYYY")}</p>
          </div>

          <div>
            <p className={`${Styles['grey-small']} mb-[8px]`}>Waktu pembayaran</p>
            <p className={Styles['black-regular-bold']}>{dayjs(data?.paided_at).format("hh:mm")} WIB</p>
          </div>
        </div>
      )
      case "menunggu": return (
        <>
          <div className={`${Styles['row']} ${Styles['detail-biaya']}`}>
            <div>
              <p className={`${Styles['grey-small']} mb-[8px]`}>Jatuh tempo</p>
              <p className={`${Styles['black-regular-bold']} ${Styles[typeof data?.remaining_days === "string" ? "" : 'red-text']}`}>{dayjs(tranformDateToSlash(data?.expiration_date)).format("DD-MM-YYYY")}</p>
            </div>

            <div>
              <p className={`${Styles['grey-small']} mb-[8px] ${Styles[typeof data?.remaining_days === "string" ? 'red-text' : ""]} text-right`}>{typeof data?.remaining_days === "string" ? 'Keterlambatan' : "Waktu tersisa"}</p>
              <p className={`${Styles['black-regular-bold']} ${Styles[typeof data?.remaining_days === "string" ? 'red-text' : ""]} text-right`}>{handleWaktuPembayaran(data?.remaining_days)} hari</p>
            </div>
          </div>
        </>
      )
      default: return
    }
  }

  const handleWaktuPembayaran = (remainingDays) => {
    if (!remainingDays) return "";
    if (typeof remainingDays === "number") return `${remainingDays}`;

    return `Lewat ${remainingDays.replaceAll("+-", "")}`;
  }

  return (
    <div className='fixed left-[500vw] z-[-10] pb-6 w-[700px]' ref={htmlToPdfRef}>
      <div style={{ margin: 0 }} className={Styles['content']}>
        <div className={Styles['detail-body']}>
          {!!(data?.invoice_status === "menunggu") && <div className={`${Styles['note-pph23']} mb-[48px]`}>
            <div className={Styles['top-note']}>
              <InfoPPH23 />
              <p>Sebelum melakukan pembayaran:</p>
            </div>
            <p className={Styles['note']}>Kami menyarankan untuk melakukan pengecekan informasi secara seksama dimulai dari GCC hingga informasi PC yang dilampirkan bersama dengan invoice ini.</p>
          </div>}

          <p className='text-[30px] font-semibold leading-[38px] mb-[28px]'>Detail</p>

          <div className={`${Styles['row']} ${Styles['info-kantor']}`}>
            <div className={Styles['kantor']}>
              <p className={`${Styles['grey-small']} mb-[8px]`}>Nama Kantor</p>
              <p className={`${Styles['black-large-bold']} mb-[8px]`}>Century 21 {data?.company?.name ?? ''}</p>
              <p className={Styles['grey-regular']}>{data?.company?.street ?? ''}</p>
            </div>

            <div className={Styles['periode']}>
              <p className={`${Styles['grey-small']} mb-[8px]`}>Periode</p>
              <p className={Styles['black-large']}>{data?.period ?? ''}</p>
            </div>
          </div>

          {paymentDate()}
          <div className='py-[16px]'>
            <p className='text-xs text-gray-4 mb-[4px]'>GCC (Gross Closed Commission)</p>
            <p className='text-base font-semibold text-gold-1'>Rp{toRupiah(data?.gcc)}</p>
          </div>

          <div className={`${Styles['row']} ${Styles['detail-biaya']}`}>
            <p className={Styles['black-regular-bold']}>Detail biaya</p>
          </div>

          {!!lodash.toNumber(data?.royalty) && <div className={`${Styles['row']} ${Styles['biaya-normal']} mb-[8px]`}>
            <p className={Styles['black-regular']}>Royalti</p>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.royalty ?? '')}</p>
          </div>}

          {!!lodash.toNumber(data?.management_fee) && <div className={`${Styles['row']} ${Styles['biaya-normal']} mb-[8px]`}>
            <p className={Styles['black-regular']}>Jasa Manajemen</p>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.management_fee ?? '')}</p>
          </div>}

          {!!lodash.toNumber(data?.naf) && <div className={`${Styles['row']} ${Styles['biaya-normal']} mb-[8px]`}>
            <p className={Styles['black-regular']}>NAF</p>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.naf ?? '')}</p>
          </div>}

          {!!lodash.toNumber(data?.c_plus) && <div className={`${Styles['row']} ${Styles['biaya-double']} mb-[8px]`}>
            <div>
              <p className={`${Styles['black-regular']}`}>C+</p>
              {!!data?.c_plus_details?.length && <p className={Styles['grey-small']}>({data.c_plus_details.length} Marketing)</p>}
            </div>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.c_plus ?? '')}</p>
          </div>}
          {!!data?.c_plus_details?.length && <div className='w-full'>
            <div className='bg-white-3 py-[4px] px-[8px] flex items-center justify-between cursor-pointer' onClick={() => setOpenCDetails(!openCDetails)}>
              <p>Lihat Detail C+</p>
              <img className={`w-[14px] h-[14px] ${!openCDetails && "rotate-180"}`} src={CarretIcon} alt="Carret Icon" />
            </div>
            <div className={`${openCDetails ? "block" : "hidden"}`}>
              <ListMarketingHeader />
              <div className='border-[1px] border-solid border-white-3'>
                {data.c_plus_details.map((detail, key) => {
                  return (
                    <ListMarketingRow
                      id={detail?.user?.id}
                      name={detail?.user?.full_name}
                      tagihan={toRupiah(detail?.amount)}
                      key={`list-marketing-${key}`}
                    />
                  )
                })}
              </div>
            </div>
          </div>}

          {!!lodash.toNumber(data?.annual_fee) && <div className={`${Styles['row']} ${Styles['biaya-normal']} mb-[8px]`}>
            <p className={`${Styles['black-regular']}`}>Annual Fee</p>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.annual_fee ?? '')}</p>
          </div>}

          {!!lodash.toNumber(data?.ppn_amount) && <div className={`${Styles['row']} ${Styles['biaya-double']} mb-[8px]`}>
            <div>
              <p className={`${Styles['black-regular']}`}>PPN {data?.ppn_percent}%</p>
              <p className={Styles['grey-small']}>(Royalti + Jasa Manajemen)</p>
            </div>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.ppn_amount ?? '')}</p>
          </div>}

          {(!!lodash.toNumber(data?.pph23_royalty?.amount) || !!lodash.toNumber(data?.pph23_management_fee?.amount)) && <div className={Styles['pph-23']}>
            <p className={`${Styles['black-regular']} mb-[8px]`}>PPh 23</p>
            {!!lodash.toNumber(data?.pph23_royalty?.amount) && <div className={Styles['row']}>
              <p className={`${Styles['black-small']} mb-[8px]`}>{data?.pph23_royalty?.percent ?? 0}% x {toRupiah(data?.royalty)}</p>
              <p className={Styles['black-small']}>Rp{toRupiah(data?.pph23_royalty?.amount)}</p>
            </div>}
            {!!lodash.toNumber(data?.pph23_management_fee?.amount) && < div className={Styles['row']}>
              <p className={`${Styles['black-small']} mb-[8px]`}>{data?.pph23_management_fee?.percent ?? 0}% x {toRupiah(data?.management_fee)}</p>
              <p className={Styles['black-small']}>Rp{toRupiah(data?.pph23_management_fee?.amount)}</p>
            </div>}
          </div>}

          <div className={Styles['total']}>
            <p className={Styles['grey-regular']}>{RenderTotalText(data?.invoice_status)}</p>
            <p className={Styles['black-regular']}>Rp{toRupiah(data?.total_bill_amount ?? '')}</p>
          </div>

          {!!(data?.invoice_status === "menunggu") && <div className={Styles['note-pph23']}>
            <div className={Styles['top-note']}>
              <InfoPPH23 />
              <p>Catatan:</p>
            </div>
            <p className={Styles['note']}>Bukti potong PPh 23 harap dikirim ke Century 21 Indonesia Pusat <span>maksimal akhir bulan berikutnya.</span></p>
          </div>}
        </div>
      </div>
{/* 
      <div className='h-4 w-full bg-white-3 my-[72px]' />

      <Lampiran listTrx={data?.transaction_list ?? []} />

      <div className='flex justify-center mb-[80px] pb-6'>
        <div className='w-[624px]'>
          <div className="flex items-center justify-between mb-[4px]">
            <p className="text-sm font-medium">Daftar Personal Commission</p>
          </div>
          <p className='text-xs text-black-5 mb-2'>({data?.personal_commissions?.length ?? 0} Marketing)</p>

          <div className='border-[1px] border-solid border-white-3 '>
            <div className='grid grid-cols-3 p-2 bg-white-3 gap-x-[4px]'>
              <p className={`text-xs text-black-5`}>ID User</p>
              <p className={`text-xs text-black-5`}>Nama</p>
              <p className={`text-xs text-black-5 justify-self-end`}>Personal Commission</p>
            </div>

            {!!data?.personal_commissions?.length && (
              data.personal_commissions.map((pc) => {
                return <LampiranRow
                  userId={pc?.user?.id ?? ""}
                  userName={pc?.user?.full_name ?? ""}
                  personalCommission={toRupiah(pc?.amount ?? 0)}
                />
              })
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default DownloadableDetail