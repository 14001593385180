import React from 'react'

//Styles
import Styles from "./title-and-number.module.scss";

function TitleAndNumber({ orderBg, order, title }) {
  return (
    <div className={Styles['title-and-number']}>
      {!!order && <span className={`${Styles['number']} ${orderBg ? Styles[orderBg] : ''}`}>{order}</span>}
      <span className={Styles['text']}>{title}</span>
    </div>
  )
}

export default TitleAndNumber