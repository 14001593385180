import React from 'react'

//Styles
import Styles from './pembeli-non-century.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PembeliNonCentury() {
  //Context
  const { form, isAprroval, isEditing } = useFormState()
  const { setCurrentStep, setForm, handlePrevState } = useFormMethod();

  //State
  const [value1, setValue1] = React.useState((isAprroval || isEditing) ? form.kantor_marketing_pembeli.value.name : '')
  const [value2, setValue2] = React.useState((isAprroval || isEditing) ? form.marketing_pembeli.value.full_name : '')

  const onNext = () => {
    handlePrevState()
    const newForm = { ...form };
    newForm.kantor_marketing_pembeli.value = { id: 0, name: value1 };
    newForm.marketing_pembeli.value = { id: 0, name: value2, full_name: value2 };
    setForm(newForm)

    if (isAprroval || isEditing) setCurrentStep(STEPS.entryDataPropertiDanTransaksi)
    else setCurrentStep(STEPS.entryPilihListing)
  }
  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Isi data marketing pembeli
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <div className={"!mb-[40px]"}>
          <TextMedium required>
            Nama kantor marketing pembeli
          </TextMedium>
          <InputText value={value1} disabled={isAprroval || isEditing} onChange={(e) => setValue1(e.target.value || '')} placeholder="Tulis nama kantor marketing pembeli" />
        </div>
        <div>
          <TextMedium required>
            Nama marketing pembeli
          </TextMedium>
          <InputText value={value2} disabled={isAprroval || isEditing} onChange={(e) => setValue2(e.target.value || '')} placeholder="Tulis nama marketing pembeli" />
        </div>
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {((!!value1 && !!value2) || isAprroval || isEditing) && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default PembeliNonCentury