import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// Styles
import Styles from './payment-result.module.scss'

// Assets
import { ReactComponent as SuccessIcon } from 'assets/invoice-payment-success.svg'
import { ReactComponent as FailedIcon } from 'assets/invoice-payment-failed.svg'

const PaymentResult = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const status = React.useMemo(() => {
    return location?.pathname?.split("/")?.pop()
  }, [location])
  if (status == "failed") return (
    <div className={Styles['wrapper']}>
      <div className={Styles['content']}>
        <FailedIcon />
        <p className={Styles['title']}>Yah, pembayarannya gagal!</p>
        <p className={Styles['sub-title']}>
          Maaf, pembayaran gagal. Sepertinya ada kendala <br />
          teknis pada gateway pembayaran kami. Terima <br />
          kasih atas pengertian Anda. Silahkan coba lagi <br />
          setelah beberapa saat kemudian.</p>
        <button className={Styles['btn']} onClick={() => navigate("invoice")}>
          <span>Lihat invoice saya</span>
        </button>
      </div>
    </div>
  )
  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['content']}>
        <SuccessIcon />
        <p className={Styles['title']}>Pembayaran berhasil!</p>
        <p className={Styles['sub-title']}>Terima kasih. Pembayaran telah kami terima. Status <br /> invoice berubah menjadi Lunas.</p>
        <button className={Styles['btn']} onClick={() => navigate("invoice")}>
          <span>Cek status invoice saya</span>
        </button>
      </div>
    </div>
  )
}

export default PaymentResult