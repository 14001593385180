import React from 'react'

import ShareNetwork from "assets/share-network.svg"

const SharedHotListing = () => {
  return (
    <div className='mb-4'>
      <div className='overflow-hidden mb-4 relative p-4 rounded-md jumlah-sharelisting flex items-start justify-between'>
        <img className='absolute top-[-2px] left-0 rotate-[16.406deg] w-[96px] h-[96px] object-contain' src={ShareNetwork} alt='Share network' />

        <p className='relative z-10 text-base font-semibold mr-2'>Jumlah Share Listing</p>

        <div className='flex items-center'>
          <div className='mr-4 pr-4 border-r-[1px] border-solid border-black-3'>
            <p className='text-xs text-gray-2'>Total Share</p>
            <p className='text-base font-semibold'>N/A</p>
          </div>
          <div>
            <p className='text-xs text-gray-2'>Total Share <span className='text-semibold text-black-0'>Hot Listing</span></p>
            <p className='text-base font-semibold'>N/A</p>
            {/* <p className='text-base text-green-1 font-semibold'>+16</p> */}
          </div>
        </div>
      </div>

      <div className='rounded-md kenapa-hotlisting px-4 py-2'>
        <p className='text-base text-gold-1'>Kenapa Hot Listing?</p>
        <p className='text-xs'>Ini hanya alasan Ini hanya alasan Ini hanya alasan Ini hanya alasan Ini hanya alas</p>
      </div>
    </div>
  )
}

export default ({ isHotListing }) => !!isHotListing ? <SharedHotListing /> : null
