import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

function CDateInputV1({
  label,
  disabled,
  className
}) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className={`c-date-input-v1 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`textarea-wrapper ${disabled ? "disabled" : ""}`}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          value={null}
          readOnly={true}
        />
      </div>
    </div>
  )
}

CDateInputV1.defaultProps = {
  label: "",
  error: "",
  name: "",
  placeholder: "",
  disabled: false,
  onChange: () => { }
}

export default CDateInputV1