import React from 'react'

function CTextInputV1({ 
  label,
  name,
  placeholder,
  disabled,
  onChange,
  error,
  value,
  className,
  type,
  onBlur,
  CustomInfo,
  onKeyDown,
  ComponentLabel,
  ...rest
}) {
  return (
    <div className={`c-textinput-v1 ${error ? "error" : ""} ${className}`}>
      <p className='label'>{label} {ComponentLabel ? ComponentLabel : null}</p>
      <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
        <input 
          {...rest}
          name={name} 
          value={value || ''}
          placeholder={placeholder} 
          disabled={disabled}
          onChange={onChange}
          type={type}
          onBlur={onBlur ? onBlur : null }
          onKeyDown={onKeyDown ? onKeyDown : null}
        />
      </div>
      {CustomInfo ? <CustomInfo /> : null}
      {error ? <p className='print-error'>{error}</p> : null}
    </div>
  )
}

CTextInputV1.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  type: "text",
  placeholder: "",
  disabled: false,
  onChange: () => {}
}

export default CTextInputV1