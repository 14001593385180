import React from 'react'

function CSearchV1({ value, placeholder, onChange, Icon, className }) {
  return (
    <div className={`c-search-v1 ${className}`}>
      {Icon ? <Icon className="search-icon" /> : null}
      <input
        className='search-input'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}

CSearchV1.defaultProps = {
  className: ""
}

export default CSearchV1