import React from 'react'

//Styles
import Styles from './label.module.scss'

function Label({ data }) {
  if (!data) return null
  return (
    <div className={Styles['wrapper']}>
      {!!data.name && <p className={Styles['name']}>{data.name}</p>}
      <p className={Styles['value']}>{data.value}</p>
    </div>
  )
}

export default Label