import React from 'react'

//Components
import { ModalConfirmationV1 } from "../../../components";

function ModalRemindChooseAgent({ open, confirmAction, declineAction }) {
  if(!open) return null
  return (
    <ModalConfirmationV1
      title="Oops!"
      info="Kamu harus pilih marketing associate dulu sebelum membuat listing."
      confirmText="Saya mau ke menu lain"
      declineText="Oke, pilih marketing associate"
      confirmAction={confirmAction}
      declineAction={declineAction}
    />
  )
}

export default ModalRemindChooseAgent