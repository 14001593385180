import { useContext } from 'react'

//Style
import "./style.scss";

//Components
import { CModal } from "components";

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";

//Context
import { DashboardCtx } from "../../context/LeadDashboard";

function KonfirmasiGantiLeadStatus() {
  const { onCloseModalKonfirmasi, onContinueModalKonfirmasi  } = useContext(DashboardCtx);

  return (
    <CModal toggle={onCloseModalKonfirmasi} id="konfirmasi-ganti-lead-status">
      <div className='modal-content'>
        <div className='close-icon-wrapper'>
          <CrossIcon />
        </div>
        <p className='title'>Kamu yakin ingin mengubah status leads?</p>
        <p className='info'>
          Setelah memilih <span>“ya”</span>, leads yang kamu pilih akan dipindahkan dalam tabel menunggu.
        </p>

        <div className='button-wrapper'>
          <button className='ya' onClick={onContinueModalKonfirmasi}>
            <span>Ya, ubah status leads</span>
          </button>
          <button className='batal' onClick={onCloseModalKonfirmasi}>
            <span>Batal, jangan ubah status leads</span>
          </button>
        </div>
      </div>
    </CModal>
  )
}

export default KonfirmasiGantiLeadStatus