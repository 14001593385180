import React, { useState } from 'react'

//Styles
import "./style.scss"

//Components
import Header from "../header";
import NavTab from "../nav-tab";
import DataKeseluruhan from "../data-keseluruhan";
import DataKantor from "../data-kantor"

function Main() {
  const [currentScreen, setCurrentScreen] = useState('keseluruhan');

  const RenderScreen = React.useMemo(() => {
    return currentScreen == 'keseluruhan' ? <DataKeseluruhan /> : <DataKantor />
  }, [currentScreen])
  return (
    <div id='key-metric-office-dashboard'>
      <Header />
      <NavTab setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} />
      {RenderScreen}
    </div>
  )
}

export default Main