import React from 'react'

//Assets
import { ReactComponent as TrashBin } from "../../../assets/trash-bin.svg";

//Components
import { ModalConfirmationV1 } from "../../../components";

function ModalConfirmasiPindahHalaman({ open, confirmAction, declineAction, noImage }) {
  if(!open) return null
  return (
    <ModalConfirmationV1
      noImage={noImage}
      Image={({ className }) => <TrashBin className={className} />}
      title="Yakin pindah halaman?"
      info="Jika kamu pindah halaman, 
      maka seluruh isian kamu akan dihapus. 
      Kamu perlu membuat kembali listing baru dengan 
      mengisi form buat listing dari awal lagi."
      confirmText="Ya, pindah halaman"
      declineText="Batal, tetap di halaman ini"
      confirmAction={confirmAction}
      declineAction={declineAction}
    />
  )
}

export default ModalConfirmasiPindahHalaman