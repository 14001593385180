import React from 'react'

//Styles
import Styles from './nama-lengkap-pembeli-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function NamaLengkapPembeliProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form, isAprroval, isEditing, isRent } = useFormState()

  //State
  const [value, setValue] = React.useState(form.nama_pembeli_properti.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('nama_pembeli_properti', value)
    setCurrentStep(STEPS.teleponPembeliProperti)
  }
  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Nama lengkap {isRent ? "penyewa" : "pembeli"} properti
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText value={value} disabled={disabledAll} onChange={(e) => setValue(e.target.value || '')} placeholder={`Tulis nama ${isRent ? "penyewa" : "pembeli"}`} />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {((!!value) || (isAprroval || isEditing)) && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default NamaLengkapPembeliProperti