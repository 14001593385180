import React from 'react'

//Styles
import Styles from "./title-input-with-name.module.scss";

function TitleInputWithName({ label, text, name, required, className, props }) {
  return (
    <p className={`${Styles['title-input']} ${className || ""}`} {...props}>
      {label ? <span className={Styles['label']}>({label}){" "}</span> : null}
      {text ? <span className={Styles['text']}>{text}{" "}</span> : null}
      {name ?
        <>
          <span className={Styles['name']}>{name}</span>
          <span className={Styles['text']}>?{" "}</span>
        </>
        : null}
      {required ? <span className={Styles['asterisk']}>*</span> : null}
    </p>
  )
}

export default TitleInputWithName