import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

//Reusable-components
import Button from "components/v1/Button";
import { ModalAddAgent, ModalRemindChooseAgent } from "../../components";

//Context
import { UploadListingContext } from "../../../../contexts"

function ListingHeader() {
  const navigate = useNavigate()
  const { marketingAgent, manualSelectForm, form, slug, loadingMarketingAgent } = useContext(UploadListingContext)
  const [openModal, setOpenModal] = useState(slug ? false : true)
  const [openReminder, setOpenReminder] = useState(false)

  const onSelectAgent = (agent) => {
    manualSelectForm({ name: "agent", value: agent })
    setOpenModal(false)
  }

  const selectedAgent = () => {
    return form.agent.full_name
  }

  const onCloseHandler = () => {
    setOpenReminder(true)
    setOpenModal(false)
  }

  const declineAction = () => {
    setOpenModal(true)
    setOpenReminder(false)
  }

  return (
    <div className='listing-header flex justify-between items-center'>
      <div>
        <p className='title'>Upload Listing</p>
        <p className='sub-title'>Silahkan melengkapi data properti untuk membuat listing.</p>
      </div>
      <div>
        <Button
          onClick={() => setOpenModal(true)}
          text={selectedAgent() ? "Ubah marketing" : "Pilih marketing associate"}
          type={`${selectedAgent() ? "secondary" : "primary"}`}
        />
      </div>

      <ModalRemindChooseAgent
        open={openReminder}
        confirmAction={() => navigate("/")}
        declineAction={declineAction}
      />
      <ModalAddAgent
        open={openModal}
        onClose={onCloseHandler}
        marketingAgent={marketingAgent}
        onSelect={onSelectAgent}
        isLoading={loadingMarketingAgent}
      />
    </div>
  )
}

export default ListingHeader