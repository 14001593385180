import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

function UCSelectV1({
  options,
  label,
  placeholder,
  className,
  displayName,
  valueName,
  register,
  errors,
  disabled,
  name
}) {

  return (
    <div className={`uc-select-v1 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`select-wrapper ${disabled ? "disabled" : ""}`}>
        <select {...register} disabled={disabled}>
          <option disabled={true} className='default' value={""}>{placeholder}</option>

          {options.length ? options.map((option, key) => {
              return (
                <option key={key} value={option[valueName]}>
                  {option[displayName]}
                </option>
              )
            }): null}
        </select>
      </div>
      {errors ? <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-xs text-red-0'>{message}</p>}
      /> : ""}
    </div>
  )
}

UCSelectV1.defaultProps = {
  options: [],
  placeholder: "Select you option",
  displayName: "",
  valueName: "",
  config: {},
  disabled: false
}

export default UCSelectV1