import React, { useState, useEffect } from 'react'

const useForm = (initForm = {}) => {
  const [form, setForm] = useState(initForm)

  const onChangeForm = (e) => {
    const { name, value = "" } = e.target
    const newFrom = { ...form }
    newFrom[name] = value

    setForm(newFrom)
  }

  const onSelectForm = (e, options, valueName) => {
    const { name, value } = e.target

    const newFrom = { ...form }
    newFrom[name] = options.filter((option) => option[valueName] == value)[0]

    setForm(newFrom)
  }
  const manualSelectForm = ({ name, value }) => {
    const newFrom = { ...form }
    newFrom[name] = value

    setForm(newFrom)
  }

  const formValidation = (keys = []) => {
    if (!keys?.length) return []

    const filled = []
    keys.forEach(key => {
      if (!form?.key?.value) {
        filled.push(key)
      }
    });

    return filled
  }

  return {
    form,
    onSelectForm,
    manualSelectForm,
    onChangeForm,
    formValidation,
    setForm
  }
}

export default useForm