import React from 'react'

function Button({ text, disabled, action, className, loading, type = "button" }) {
  const onClickHandler = () => {
    if (disabled || typeof action !== "function") return;

    action()
  }
  return (
    <button type={type} onClick={onClickHandler} className={`custom-btn btn-active ${(disabled || loading) ? "disabled" : ""} ${className}`}>
      {loading ? "Loading..." : text}
    </button>
  )
}

export default Button
