import { useState, useMemo } from 'react'

//Styles
import Styles from './pilih-asisten-pembeli.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import SelectWithSearch from 'pages/dashboard/e-reporting/forms/components/reuseables/select-with-search/SelectWithSearch'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

//hoc
import withFetch from './withFetch'

function PilihAsistenPembeli({ data }) {
  //Context
  const { form, isAprroval, isEditing } = useFormState();
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();

  //State
  const [selectedData, setSelectedData] = useState({
    ...form.asisten_pembeli.value,
    value: form.asisten_pembeli.value.id,
    label: form.asisten_pembeli.value.full_name
  })

  const mappedData = useMemo(() => {
    if (!data?.length) return [];
    return data.map(datum => {
      return {
        value: datum.id,
        label: datum.full_name,
        ...datum
      }
    })
  }, [data])

  const onNext = () => {
    handlePrevState()
    formHandler('asisten_pembeli', selectedData)
    setCurrentStep(STEPS.komisiPembeli)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          <span className={Styles['prefix']}>(co-selling)</span> Siapa marketing pembeli selain <span className={Styles['name']}>{form.marketing_pembeli.value.full_name}</span>?
        </TextMedium>

      </div>
      <div className={Styles['select-wrapper']}>
        <SelectWithSearch selectedData={selectedData} disabled={isAprroval || isEditing} onClick={setSelectedData} options={mappedData} placeholder="Klik di sini atau klik simbol panah" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {selectedData.id !== null ? <ButtonSelanjutnya onClick={onNext} /> : null}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default withFetch(PilihAsistenPembeli)