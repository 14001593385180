import React, { useMemo } from 'react'

//Components
import LTTable from "pages/dashboard/e-reporting/TableTransaksi/TTComponents/LTTable";
import { ModalConfirmationV1 } from "components";

//Assets
import { ReactComponent as NoTransaction } from "assets/no-transaction.svg";

//Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

function LTBody() {
  const {
    transactionList,
    fetchingTransaction,
    selectedIds,
    setujuiSemua,
    setOpenModalKonfirmasiApproval,
    openModalKonfirmasiApproval
  } = useTTCtx();

  const RenderComponent = useMemo(() => {
    /* NO DATA */
    if (!transactionList?.length) {
      return (
        <div className='lt-body-nodata'>
          <NoTransaction className='no-transaction mb-[48px]' />
          <p className='nots-text-1 mb-[16px]'>Yah, belum ada transaksi nih!</p>
          <p className='nots-text-2'>Segera laporkan transaksi di periode ini yuk. Biar gak sepi kyk gini.</p>
        </div>
      )
    }

    /* TABLES */
    return (
      <div className='lt-table-container'>
        <LTTable />
      </div>
    )
  }, [transactionList, fetchingTransaction, selectedIds])

  return (
    <div className='lt-body-wrapper'>
      {RenderComponent}

      {openModalKonfirmasiApproval ? <ModalConfirmationV1
        className='modal-konfirmasi-approval'
        noImage={true}
        title="Kamu yakin ingin menyimpan data dan menyetujui semua transaksi yang dipilih?"
        info="Setelah memilih “setujui transaksi”, kamu tidak bisa lagi mengubah data transaksi ini. Kami sarankan sebelum memilih “setujui transaksi”, kamu mengecek kembali data transaksi dengan memilih detail pada setiap transaksi."
        confirmText="Batal, saya ingin lihat detail"
        declineText="Ya, setujui transaksi terpilih"
        confirmAction={() => setOpenModalKonfirmasiApproval(false)}
        declineAction={() => setujuiSemua()}
        onClose={() => setOpenModalKonfirmasiApproval(false)}
      /> : null}
    </div >
  )
}

export default LTBody
