import { useState } from 'react'

//Components
import SelectAddress from 'pages/dashboard/e-reporting/forms/components/reuseables/select-address/SelectAddress'

//Hoc
import withFetch from './withFetch'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihProvinsiPembelikProperti({ data }) {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form, isAprroval, isEditing, isRent } = useFormState()

  const [selectedData, setSelectedData] = useState({
    ...form.provinsi_pembeli_properti.value,
    value: form.provinsi_pembeli_properti.value.id,
    label: form.provinsi_pembeli_properti.value.name
  })

  const onNext = () => {
    handlePrevState()
    formHandler('provinsi_pembeli_properti', selectedData)
    setCurrentStep(STEPS.kotaPembeliProperti)
  }

  return (
    <SelectAddress
      order="d. "
      question={`Di provinsi mana domisili ${isRent ? "penyewa" : "pembeli"} properti?`}
      placeholder="Pilih provinsi"
      required
      data={data}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      onNext={onNext}
      disabled={disabledAll}
      showButton={isAprroval || isEditing}
    />
  )
}

export default withFetch(PilihProvinsiPembelikProperti)