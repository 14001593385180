import React from 'react';

function CInputWithOptionV2({
  label,
  disabled,
  className,
  suffixPosition,
  mandatoryLabel,
  name,
  onSelect,
  displayName,
  valueName,
  optionName,
  optionValue,
  onChange,
  options,
  error,
  value
}) {
  const onSelectHandler = (e) => {
    onSelect(e, options, valueName)
  }
  return (
    <div className={`c-input-option-v2 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      {mandatoryLabel ? <span className='addon-label'>{"("}<span className='mandatory-label'>* tidak boleh kosong</span>{")"}</span> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <input name={name} value={value} onChange={onChange} placeholder="0" disabled={disabled} />
        </div>
        <div className='suffix-wrapper'>
          <select
            name={optionName}
            value={optionValue[valueName] || ""}
            onChange={onSelectHandler}
            disabled={disabled}
          >
            {options?.length ? options?.map((opt, key) => <option key={`option-${optionName}-${key}`} value={opt[valueName]}>{opt[displayName]}</option>): null}
          </select>
        </div>
      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

CInputWithOptionV2.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  type: "text",
  disabled: false,
  mandatoryLabel: false,
  onChange: () => { }
}

export default CInputWithOptionV2