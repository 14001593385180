import React from 'react'

//Styles
import Styles from './telepon-pemilik-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets
import { ReactComponent as Phone } from 'assets/phone.svg'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function TeleponPemilikProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()

  //State
  const [value, setValue] = React.useState(form.telepon_pemilik_properti.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('telepon_pemilik_properti', value)
    setCurrentStep(STEPS.emailPemilikProperti)
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Remove leading zeros
    const sanitizedValue = inputValue.replace(/^0+/, '');

    // Allow only numbers
    if (/^\d*$/.test(sanitizedValue)) {
      setValue(sanitizedValue);
    }
  };

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Nomor telepon pemilik properti
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText value={value} disabled={isAprroval} type="tel" Icon={Phone} onChange={handleChange} placeholder="Tulis nomor telepon" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {!!value && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default TeleponPemilikProperti