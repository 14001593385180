import React from 'react'

//Styles
import Styles from "./entry-pilih-listing.module.scss";

//Components
import TitleAndNumber from 'pages/dashboard/e-reporting/forms/components/reuseables/title-and-number/TitleAndNumber'
import TextLarge from 'pages/dashboard/e-reporting/forms/components/reuseables/text-large/TextLarge'
import ButtonIconCustom from 'pages/dashboard/e-reporting/forms/components/reuseables/button-icon-custom/ButtonIconCustom'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets
import { ReactComponent as ArrowDown } from 'assets/arrow-down-with-tail.svg'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";
import { useERState } from "pages/dashboard/e-reporting/ERCtx";

//Utils
import { handleAgentRole } from "../../handlePayload"

function EntryPilihListing() {
  const { form, isAprroval, isEditing } = useFormState();
  const { setCurrentStep, handlePrevState } = useFormMethod();
  const { userLoginAuth } = useERState()

  const handleNext = () => {
    handlePrevState()
    if (handleAgentRole(form, userLoginAuth) === 'S0B1' || handleAgentRole(form, userLoginAuth) === 'SXB1') {
      if (isAprroval || isEditing) {
        setCurrentStep(STEPS.pilihDurasiSewa)
      } else {
        setCurrentStep(STEPS.pilihTipeListing)
      }
    }
    else setCurrentStep(STEPS.pilihListing)
  }

  const title = handleAgentRole(form, userLoginAuth) === 'S0B1' || handleAgentRole(form, userLoginAuth) === 'SXB1' ?
    <TextLarge>
      Pilih tipe listing yang akan dibuatkan laporan
    </TextLarge>
    : <TextLarge>
      Pilih listing <span className={Styles['golden']}>{form.marketing_penjual.value.full_name}</span> yang akan dibuatkan laporan
    </TextLarge>

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['box']}>
        <TitleAndNumber
          title={'Listing'}
        />
        <div className={Styles['ml-45']}>
          {title}
          <BoxFlex>
            <>
              <ButtonSebelumnya />
              <ButtonIconCustom
                text={'Pilih Listing'}
                Icon={ArrowDown}
                onClick={handleNext}
              />
            </>
          </BoxFlex>
        </div>
      </div>
    </div>
  )
}

export default EntryPilihListing