import React from 'react'
import "./style.scss"

//Assets
import RoundDivider from "assets/round-divider.png";

//Context
import { useDetailListingCtx } from "../../Context"

function FasilitasSekitar() {
  const { getValue } = useDetailListingCtx()
  const facilities = getValue("environment_facilities")
  return (
    <div className='properti-detail-fasilitas-sekitar'>
      <p className='fasilitas-sekitar-header'>Fasilitas Sekitar</p>
      <div className='fasilitas-sekitar-content'>
        {facilities?.length ? facilities.map((f, k) => f?.name ? <p key={`fasilitas-${k}`}><img src={RoundDivider} alt="dot" />{f?.name}</p> : null) : <p>Tidak Ada Data</p>}
      </div>
    </div>
  )
}

export default FasilitasSekitar