import React from 'react'
import dayjs from 'dayjs';

const useFormatDate = () => {
  const formatDate = (date = "", format) => {
    if (!date) return "";
    let result = dayjs(date).format(format)

    if (result === "Invalid Date") {
      const newDate = date.replace(/-/g, '/')
      result = dayjs(newDate).format(format)
    }

    return result
  }

  return {
    formatDate
  }
}

export default useFormatDate