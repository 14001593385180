import React, { useEffect, useState } from 'react'

function LoadingBar({start, show}) {
  const [barWidth, setBarWidth] = useState(0)
  
  useEffect(() => {
    if (!start && barWidth == 0) return;
    if (start && barWidth >= 80) return
    if (barWidth >= 100) {
      setBarWidth(100)
      return
    }

    const random = Math.floor(Math.random() * 30) + 1;

    setTimeout(() => {
      setBarWidth(barWidth + 1)
    }, random)

  }, [barWidth])

  useEffect(() => {
    if(show){
      setBarWidth(0)
    }
  }, [show])

  useEffect(() => {
    if (start && barWidth == 0) setBarWidth(1);
    else if (!start && barWidth >= 80) setBarWidth(barWidth + 1)
  }, [start])

  
  if (barWidth == 0) return null;
  return (
    <div className='loading-bar'>
      <div className='loading-text'>
        <span>Sedang mengunggah gambar</span>
        <span>{barWidth}%</span>
      </div>
      <div className='progress-bar' style={{ width: `${barWidth}%` }} />
    </div>
  )
}

export default LoadingBar