import { useContext } from 'react'
import Modal from './modal-v2'

// Global context
import { GlobalContext } from 'contexts'

function GlobalLoader({ className = "" }) {
  const { showLoading } = useContext(GlobalContext);

  return (
    <Modal
      isOpen={showLoading}
      className="z-50"
    >
      <div className={`loader ${className}`}></div>
    </Modal>
  )
}

export default GlobalLoader