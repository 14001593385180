import { createContext, useContext, useState, useEffect } from "react";
import _ from "lodash"

//Actions
import {
  getColleagues,
  getColleagueDetail
} from "actions";

//Functions
const MenuMarketingCtx = createContext(null);

export const MenuMarketingProvider = ({ children }) => {
  //Colleagues
  const [colleagues, setColleagues] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(false)
  const [totalData, setTotalData] = useState(0)

  const [openModalLoader, setOpenModalLoader] = useState(false);

  //Get colleagues
  const getColleaguesData = async (_page) => {
    try {
      setPage(_page)
      setLoading(true);
      const query = `?page=${_page}&_limit=30${'&q=' + search}`
      
      const response = await getColleagues(query);
      const data = response?.data?.data ?? [];
      const _totalData = response?.headers?.['x-total'] ?? 0;
      setTotalData(_totalData)
      setHasMore(data?.length > 0)
      setColleagues(val => ([ ...val, ...data]))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const triggerSearch = () => {
    setPage(1)
    getColleaguesData(1)
    setColleagues([])
  }

  const triggeredInfiniteScroll = () => {
    getColleaguesData(page+1)
  }

  useEffect(() => {
    getColleaguesData(1)
  }, [])
  
  return (
    <MenuMarketingCtx.Provider value={{
      colleagues,
      openModalLoader,
      setOpenModalLoader,
      loading,
      setSearch,
      setPage,
      page,
      hasMore,
      search,
      setSearch,
      triggerSearch,
      triggeredInfiniteScroll,
      totalData
    }}>
      {children}
    </MenuMarketingCtx.Provider>
  )
}

//Custom hooks for the values
export const useDetailListingState = () => {
  const ctx = useContext(MenuMarketingCtx);

  if (!ctx) {
    throw new Error("useDetailListingState must be used within the MenuMarketingProvider");
  }

  return ctx;
};
