import React from 'react'
import styled from "styled-components"

import InfoIcon from "assets/info.png"


const TextLengthCounterStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  .tlc-icon{
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right:12px;
  }
  .tcl-left-text{
    display: flex;
    align-items: center;
  }
  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #676767;
    margin: 0;
  }
`


function TextLengthCounter({ leftText, current, max, fullFilled }) {
  return (
    <TextLengthCounterStyle>
      <div className='tcl-left-text'>
        <img className='tlc-icon' src={InfoIcon} alt="..." />
        <p>{leftText}</p>
      </div>
      <p><span className={`text-red-0 ${fullFilled ? "text-green-0" : ""}`}>{current}</span>{max}</p>
    </TextLengthCounterStyle>
  )
}

export default TextLengthCounter