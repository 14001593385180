import React, { useMemo } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

// Hoc
import withMain from './withMain'

//Components
import Header from './components/header'
import StepsLabels from './components/reuseables/steps-labels'
import IsCoBroking from './components/is-co-broking'
import PilihKantorMarketingPenjual from './components/pilih-kantor-marketing-penjual'
import PilihMarketingPenjual from './components/pilih-marketing-penjual'
import IsCoListingPenjual from './components/is-co-listing-penjual'
import PilihAsistenPenjual from './components/pilih-asisten-penjual'
import KomisiPenjual from './components/komisi-penjual'
import InputBiayaReferalPenjual from './components/input-biaya-referal-penjual'
import PilihKantorMarketingPembeli from './components/pilih-kantor-marketing-pembeli'
import PilihMarketingPembeli from './components/pilih-marketing-pembeli'
import IsCoListingPembeli from './components/is-co-listing-pembeli'
import PilihAsistenPembeli from './components/pilih-asisten-pembeli'
import KomisiPembeli from './components/komisi-pembeli'
import InputBiayaReferalPembeli from './components/input-biaya-referal-pembeli'

import EntryPilihListing from './components/entry-pilih-listing'
import PilihListing from './components/pilih-listing'
import EntryPropertiDanTransaksi from "./components/entry-properti-dan-transaksi/EntryPropertiDanTransaksi"
import PilihProvinsiProperti from './components/pilih-provinsi-properti/PilihProvinsiProperti'
import PilihKotaProperti from './components/pilih-kota-properti'
import PilihAreaProperti from "./components/pilih-area-properti"
import AlamatLengkapProperti from "./components/alamat-lengkap-properti"
import PilihTanggalTransaksiProperti from './components/pilih-tanggal-transaksi-properti/PilihTanggalTransaksiProperti'
import NilaiTransaksiProperti from './components/nilai-transaksi-properti'
import MetodePembayaranProperti from './components/metode-pembayaran-properti/MetodePembayaranProperti'
import BankYangDigunakan from './components/bank-yang-digunakan'
import TotalKomisiMarketing from './components/total-komisi-marketing'
import EntryDataPemilik from './components/entry-data-pemilik'

import NamaLengkapPemilikProperti from './components/nama-lengkap-pemilik-properti'
import TeleponPemilikProperti from './components/telepon-pemilik-properti'
import AlamatEmailPemilikProperti from './components/alamat-email-pemilik-properti'
import PilihProvinsiPemilikProperti from './components/pilih-provinsi-pemilik-properti'
import PilihKotaPemilikProperti from './components/pilih-kota-pemilik-properti/PilihKotaPemilikProperti'
import PilihAreaPemilikProperti from './components/pilih-area-pemilik-properti'
import AlamatLengkapPemilikProperti from './components/alamat-pemilik-properti'
import IsPemilikBadanUsaha from './components/is-pemilik-badan-usaha'

import EntryDataPembeli from './components/entry-data-pembeli'
import NamaLengkapPembeli from './components/nama-lengkap-pembeli-properti'
import TeleponPembeliProperti from './components/telepon-pembeli-properti'
import AlamatEmailPembeliProperti from './components/alamat-email-pembeli-properti'
import PilihProvinsiPembelikProperti from './components/pilih-provinsi-pembeli-properti'
import PilihKotaPembeliProperti from './components/pilih-kota-pembeli-properti'
import PilihAreaPembeliProperti from './components/pilih-area-pembeli-properti'
import AlamatLengkapPembeliProperti from './components/alamat-pembeli-properti'

import PenjualNonCentury from "./components/penjual-non-century"
import PembeliNonCentury from "./components/pembeli-non-century"
import PilihDurasiSewa from "./components/pilih-durasi-sewa"
import NilaiTransaksiSewa from "./components/nilai-transaksi-sewa"
import PilihTipeListing from "./components/pilih-tipe-listing"
import RekapKomisi from "./components/rekap-komisi"
import ApprovalPage from "./components/approval-page"
import NewApprovalPage from "./components/new-approval-page"
import PilihKlarifikasiProperti from "./components/pilih-klarifikasi-properti"
import PlafonKredit from "./components/plafon-kredit"
import NamaDebitur from "./components/nama-debitur"
import TeleponDebitur from "./components/telepon-debitur"

//Modal
import ModalSubmit from "./components/reuseables/modal-submit"
import { SuccessModal } from "pages/dashboard/e-reporting/ERComponents";
import ModalLoader from 'components/organism/ModalLoader'

//Context
import { useFormState, STEPS } from 'pages/dashboard/e-reporting/forms/context'

function Main() {
  const {
    currentStep,
    isOpenModalSubmit,
    isOpenModalSuccess,
    formType,
    openLoader,
    errorMessage
  } = useFormState();

  const steps = useMemo(() => {

    switch (currentStep) {
      case STEPS.approvalPage: {
        return <NewApprovalPage />
      }

      case STEPS.isCoBroking:
        return <IsCoBroking />
      case STEPS.pilihKantorMarketingPenjual:
        return <PilihKantorMarketingPenjual />
      case STEPS.tulisMarketingNonCentury:
        return <PenjualNonCentury />
      case STEPS.pilihMarketingPenjual:
        return <PilihMarketingPenjual />
      case STEPS.entryCoListingPenjual:
        return <IsCoListingPenjual />
      case STEPS.pilihAsistenPenjual:
        return <PilihAsistenPenjual />
      case STEPS.komisiPenjual:
        return <KomisiPenjual />
      case STEPS.biayaReferralPenjual:
        return <InputBiayaReferalPenjual />

      case STEPS.pilihKantorMarketingPembeli:
        return <PilihKantorMarketingPembeli />
      case STEPS.tulisMarketingPembeliNonCentury:
        return <PembeliNonCentury />
      case STEPS.pilihMarketingPembeli:
        return <PilihMarketingPembeli />
      case STEPS.entryCoListingPembeli:
        return <IsCoListingPembeli />
      case STEPS.pilihAsistenPembeli:
        return <PilihAsistenPembeli />
      case STEPS.komisiPembeli:
        return <KomisiPembeli />
      case STEPS.biayaReferralPembeli:
        return <InputBiayaReferalPembeli />

      case STEPS.entryPilihListing:
        return <EntryPilihListing />
      case STEPS.pilihListing:
        return <PilihListing />
      case STEPS.pilihTipeListing:
        return <PilihTipeListing />
      case STEPS.klasifikasiProperty:
        return <PilihKlarifikasiProperti />
      case STEPS.pilihDurasiSewa:
        return <PilihDurasiSewa />
      case STEPS.nilaiTransaksiSewa:
        return <NilaiTransaksiSewa />

      case STEPS.entryDataPropertiDanTransaksi:
        return <EntryPropertiDanTransaksi />
      case STEPS.pilihProvinsiProperti:
        return <PilihProvinsiProperti />
      case STEPS.pilihKotaProperti:
        return <PilihKotaProperti />
      case STEPS.pilihAreaProperti:
        return <PilihAreaProperti />
      case STEPS.tulisAlamatProperti:
        return <AlamatLengkapProperti />
      case STEPS.tanggalTransaksi:
        return <PilihTanggalTransaksiProperti />
      case STEPS.nilaiTransaksi:
        return <NilaiTransaksiProperti />
      case STEPS.metodePembayaran:
        return <MetodePembayaranProperti />
      case STEPS.bankYangDigunakan:
        return <BankYangDigunakan />
      case STEPS.plafonKredit:
        return <PlafonKredit />
      case STEPS.namaDebitur:
        return <NamaDebitur />
      case STEPS.teleponDebitur:
        return <TeleponDebitur />
      case STEPS.totalKomisiMarketing:
        return <TotalKomisiMarketing />

      case STEPS.entryDataPemilik:
        return <EntryDataPemilik />
      case STEPS.namaPemilikProperti:
        return <NamaLengkapPemilikProperti />
      case STEPS.teleponPemilikProperti:
        return <TeleponPemilikProperti />
      case STEPS.emailPemilikProperti:
        return <AlamatEmailPemilikProperti />
      case STEPS.provinsiPemilikProperti:
        return <PilihProvinsiPemilikProperti />
      case STEPS.kotaPemilikProperti:
        return <PilihKotaPemilikProperti />
      case STEPS.areaPemilikProperti:
        return <PilihAreaPemilikProperti />
      case STEPS.alamatPemilikProperti:
        return <AlamatLengkapPemilikProperti />
      case STEPS.isPemilikBadanUsaha:
        return <IsPemilikBadanUsaha />

      case STEPS.entryDataPembeli:
        return <EntryDataPembeli />
      case STEPS.namaPembeliProperti:
        return <NamaLengkapPembeli />
      case STEPS.teleponPembeliProperti:
        return <TeleponPembeliProperti />
      case STEPS.emailPembeliProperti:
        return <AlamatEmailPembeliProperti />
      case STEPS.provinsiPembeliProperti:
        return <PilihProvinsiPembelikProperti />
      case STEPS.kotaPembeliProperti:
        return <PilihKotaPembeliProperti />
      case STEPS.areaPembeliProperti:
        return <PilihAreaPembeliProperti />
      case STEPS.alamatPembeliProperti:
        return <AlamatLengkapPembeliProperti />
      case STEPS.rekapKomisi:
        return <NewApprovalPage isCreating={true} />
      default:
        return null
    }
  }, [currentStep])

  const showSteps = useMemo(() => {
    let number = 1;
    let text = "Marketing";

    switch (currentStep) {
      case STEPS.pilihKantorMarketingPenjual:
      case STEPS.tulisMarketingNonCentury:
      case STEPS.pilihMarketingPenjual:
      case STEPS.entryCoListingPenjual:
      case STEPS.pilihAsistenPenjual:
      case STEPS.komisiPenjual:
      case STEPS.biayaReferralPenjual:
      case STEPS.pilihKantorMarketingPembeli:
      case STEPS.tulisMarketingPembeliNonCentury:
      case STEPS.pilihMarketingPembeli:
      case STEPS.entryCoListingPembeli:
      case STEPS.pilihAsistenPembeli:
      case STEPS.komisiPembeli:
      case STEPS.biayaReferralPembeli:
        break;

      case STEPS.pilihListing:
      case STEPS.pilihTipeListing: {
        number = 2;
        text = "Listing";
        break;
      }

      case STEPS.pilihDurasiSewa:
      case STEPS.pilihProvinsiProperti:
      case STEPS.pilihKotaProperti:
      case STEPS.pilihAreaProperti:
      case STEPS.tulisAlamatProperti:
      case STEPS.tanggalTransaksi:
      case STEPS.nilaiTransaksi:
      case STEPS.metodePembayaran:
      case STEPS.bankYangDigunakan:
      case STEPS.totalKomisiMarketing: {
        number = 3;
        text = "Data properti & transaksi";
        break;
      }

      case STEPS.namaPemilikProperti:
      case STEPS.teleponPemilikProperti:
      case STEPS.emailPemilikProperti:
      case STEPS.provinsiPemilikProperti:
      case STEPS.kotaPemilikProperti:
      case STEPS.areaPemilikProperti:
      case STEPS.alamatPemilikProperti:
      case STEPS.isPemilikBadanUsaha: {
        number = 4;
        text = "Isi Data Penjual";
        break;
      }

      case STEPS.entryDataPembeli:
      case STEPS.namaPembeliProperti:
      case STEPS.teleponPembeliProperti:
      case STEPS.emailPembeliProperti:
      case STEPS.provinsiPembeliProperti:
      case STEPS.kotaPembeliProperti:
      case STEPS.areaPembeliProperti:
      case STEPS.alamatPembeliProperti: {
        number = 4;
        text = "Isi Data Pembeli";
        break;
      }
      default:
        return null
    }
    return <StepsLabels number={number} text={text} />
  }, [currentStep, formType])

  React.useEffect(() => {
    if (!!errorMessage) {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }, [errorMessage])

  return (
    <div>
      <Header />
      {showSteps}
      {steps}
      <ModalSubmit open={isOpenModalSubmit} />
      <SuccessModal open={isOpenModalSuccess} onSuccess={() => window.location.reload()} />
      <ModalLoader open={openLoader} />

      <ToastContainer />
    </div>
  )
}

export default withMain(Main)