//Utils
import { api } from "../utils";

//Constant
import {
  //URL
  MARKETING_AGENT,
  PROPERTY_TYPES,
  PROPERTY_CATEGORIES,
  COUNTRIES,
  PROVINCES,
  CITIES,
  OWNERSHIP_STATUS,
  UPLOAD_LISTING,
  PROPERTY_PRIVACIES,
  UNIT_PRICE,
  FACILITIES,
  INITIALIZER
} from "../constant/URL";

const getMarketingAgent = async () =>  await api().get(`${MARKETING_AGENT}`)
const getPropertyTypes = async () =>  await api().get(PROPERTY_TYPES)
const getPropertyBaseOnType = async (type) =>  await api().get(`${PROPERTY_TYPES}/${type}`)
const getPropertyCategory = async (query = "") =>  await api().get(`${PROPERTY_CATEGORIES}${query}`)
const getPropertyPrivacies = async () =>  await api().get(`${PROPERTY_PRIVACIES}`)
const getProvincesByCountriId = async (id = 1, query = "") =>  await api().get(`${COUNTRIES}/${id}/provinces${query}`)
const getCitiesByCountriId = async (id = 1, query = "") =>  await api().get(`${PROVINCES}/${id}/cities${query}`)
const getAreasByCountriId = async (id = 1, query = "") =>  await api().get(`${CITIES}/${id}/areas${query}`)
const getOwnershipStatus = async () =>  await api().get(`${OWNERSHIP_STATUS}`)
const getUnitPriceId = async (query) =>  await api().get(`${UNIT_PRICE}${query}`)
const getFacilities = async (query = "") => await api().get(`${FACILITIES}${query}`)
const get = async (id, payload) =>  await api().get("/public/v1/properties") // Get properties to see if it is saved

const uploadListing = async (id, payload) =>  await api().post(`${UPLOAD_LISTING}${id}`, payload)
const getInitializer = async () =>  await api().get(`${INITIALIZER}`)

const uploadListingAction = {
  getMarketingAgent,
  getPropertyTypes,
  getPropertyBaseOnType,
  getPropertyCategory,
  getPropertyPrivacies,
  getProvincesByCountriId,
  getCitiesByCountriId,
  getAreasByCountriId,
  getOwnershipStatus,
  uploadListing,
  get,
  getUnitPriceId,
  getFacilities,
  getInitializer
}

export default uploadListingAction