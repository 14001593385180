import { useQuery } from '@tanstack/react-query'
import {
  getCalonMA,
  getTraining,
  getTrainingBatch,
  getAllTrainingAndBatch,
  getDetailBatch,
  getDetailCalonMA,
  getPassedParticipant,
  getJuniorMa,
  getListInvoiceTraining,
  getInvoiceTrainingCounter,
  getDetailParticipant
} from "./api"

export const useGetCalonMa = ({ params }) => {
  return useQuery({
    queryKey: ['get-list-calon-ma', params],
    queryFn: () => getCalonMA(params),
  })
}

export const useGetDetailMa = ({ userId }) => {
  return useQuery({
    queryKey: ['get-detail-calon-ma', userId],
    queryFn: () => getDetailCalonMA(userId),
  })
}

export const useGetTraining = ({ params }) => {
  return useQuery({
    queryKey: ['get-list-training', params],
    queryFn: () => getTraining(params),
  })
}

export const useGetDetailParticipant = ({ userId }) => {
  return useQuery({
    queryKey: ['get-detail-participant', userId],
    queryFn: () => getDetailParticipant(userId),
  })
}

export const useGetPassedParticipant = ({ params }) => {
  return useQuery({
    queryKey: ['get-passed-training', params],
    queryFn: () => getPassedParticipant(params),
  })
}

export const useGetJuniorMa = ({ params }) => {
  return useQuery({
    queryKey: ['get-junior-ma', params],
    queryFn: () => getJuniorMa(params),
  })
}

export const useGetAllTrainingAndBatch = ({ params }) => {
  return useQuery({
    queryKey: ['get-all-list-training-and-batches', params],
    queryFn: () => getAllTrainingAndBatch(params),
  })
}

export const useGetDetailBatch = ({ batchId, params }) => {
  return useQuery({
    queryKey: ['get-detail-batch', batchId, params],
    queryFn: () => getDetailBatch(batchId, params),
    enabled: !!batchId
  })
}

export const useGetTrainingBatch = ({ trainingId, params }) => {
  return useQuery({
    queryKey: ['get-list-training-batch', trainingId, params],
    queryFn: () => getTrainingBatch(trainingId, params),
    enabled: !!trainingId
  })
}

export const useGetInvoiceTraining = ({ params }) => {
  return useQuery({
    queryKey: ['get-invoice-training-list', params],
    queryFn: () => getListInvoiceTraining(params)
  })
}

export const useGetInvoiceTrainingCounter = ({ params }) => {
  return useQuery({
    queryKey: ['get-invoice-training-list-counter', params],
    queryFn: () => getInvoiceTrainingCounter(params)
  })
}


