import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { QRCodeSVG } from 'qrcode.react';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';

//Styles
import Styles from './detail-marketing.module.scss'

//Components
import Modal from 'components/modal'
import Loader from 'components/molecules/Loader';
import ButtonV2LeftIcon from 'components/atomic/button-v2-left-icon'
import Button from 'components/atomic/button-v2'
import { FrontCardV2, BackCardV2 } from 'pages/dashboard/marketing/components/id-card-v2';

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as CrossBlackIcon } from "assets/cross-black.svg";
import { ReactComponent as DoubleCheck } from "assets/double-check.svg";
import { ReactComponent as Pencil } from "assets/pencil-line-grey.svg";
import { ReactComponent as Gallery } from "assets/gallery.svg";
import { ReactComponent as IdentificationCard } from "assets/identification-card.svg";
import IDCardFront from "assets/card-front-no-start.jpg";
import IDCardBack from "assets/back-card-img.png";
import DummyProfilePicture from "assets/dummy-new-profile.png";
import MapIcon from "assets/map-icon.png";
import PhoneIcon from "assets/phone-icon.png";
import CenturyLogo from "assets/century-logo.png";

//Hooks
import useOutsideAlerter from 'hooks/useOutsideAlerter'

//Actions
import {
  updateColleague
} from "actions";

const LISTING_URL = process.env.REACT_APP_LISTING_URL;

const DefaultImage = "https://d3hq2iy4ol95dk.cloudfront.net/assets/default/image-90efdb05b35f99324bb28b2d9827c138c2574cc8fafbeec6e37fdeea501fb6e6.png"

const RenderIDCardFront = ({
  refID,
  fullname,
  companyName,
  registrationNumber,
  role,
  userImage
}) => {

  return (
    <div className={`${Styles['hidden-wrraper']} mr-4`}>
      <div className={`${Styles["id-card-wrapper"]} ${Styles['front-card']}`} ref={refID}>
        <img className={Styles['front-card-bg']} src={IDCardFront} alt="id-card-front" />
        <img className={Styles['front-card-img']} src={userImage} alt="profile-picture" />
        <p className={Styles['company-name']}>{companyName}</p>
        <p className={Styles['fullname']}>{fullname}</p>
        <p className={Styles['role']}>{role}</p>
        <p className={Styles['registration-number']}>REG. {registrationNumber}</p>
      </div>
    </div>
  )
}

const RenderIDCardBack = ({
  refID,
  fullname,
  companyName,
  registrationNumber,
  role,
  phone,
  street,
  qrCodeUrl
}) => {

  return (
    <div className={Styles['hidden-wrraper']}>
      <div className={`${Styles["id-card-wrapper"]} ${Styles['back-card']}`} ref={refID}>
        <img className={Styles['back-card-bg']} src={IDCardBack} alt="id-card-front" />
        <div className={Styles['wrapper-century-logo']}>
          <img className={Styles['century-logo']} src={CenturyLogo} alt="century-logo" />
        </div>
        <img src={qrCodeUrl} className={Styles['qr-code']} />
        <p className={Styles['company-name']}>{companyName}</p>
        <div className={Styles['name-role-reg']}>
          <p className={Styles['fullname']}>{fullname}</p>
          <p className={Styles['role']}>{role}</p>
        </div>
        <div className={Styles['phone']}>
          <img src={PhoneIcon} alt='phone icon' className={Styles['phone-icon']} />
          <p>{phone}</p>
        </div>
        <p className={Styles['street']}><img src={MapIcon} alt='map icon' className={Styles['map-icon']} />{street}</p>
        <p className={Styles['info']}>
          Each office is independently owned and operated <br />
          Every payment must only be received by corporate bank account
        </p>
      </div>
    </div>
  )
}

const Input = ({ label, ...props }) => {
  return (
    <div className={`${Styles['input-wrapper']}`}>
      <p className={Styles['label']}>{label}</p>
      <input className={Styles["input"]} {...props} />
    </div>
  )
}

const InputPhone = ({ label, disabled, ...props }) => {
  return (
    <div className={`${Styles['input-wrapper']}`}>
      <p className={Styles['label']}>{label}</p>
      <div className={`${Styles['input-phone']} ${!!disabled && Styles["disabled"]}`}>
        <span className={`mr-2 ${Styles['prefix']}`}>+62</span>
        <input disabled={disabled} className='w-full' {...props} />
      </div>
    </div>
  )
}

function removePrefix(number) {
  // Check if the number starts with either +62 or 0
  if (number.startsWith("+62")) {
    // Remove "+62" from the beginning
    return number.slice(3);
  } else if (number.startsWith("0")) {
    // Remove "0" from the beginning
    return number.slice(1);
  } else {
    // If the number doesn't start with either prefix, return it as is
    return number;
  }
}

const DetailMarketing = ({ data, onClose }) => {
  const frontCardRef = useRef(null);
  const backCardRef = useRef(null);
  const modalIdCardRef = useRef(null);

  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const {
    image,
    name,
    username,
    email,
    phone,
    id,
    imageId
  } = useMemo(() => ({
    image: data?.user_image?.public?.url ?? '',
    name: data?.full_name ?? '',
    username: data?.username ?? '',
    email: data?.email ?? '',
    phone: !!(typeof data?.phone === "string") ? removePrefix(data.phone) : '',
    id: data?.id,
    imageId: data?.user_image?.id
  }), [data]);

  const [form, setForm] = useState({
    full_name: name,
    username,
    email,
    phone,
  })

  const [currentName, setCurrentName] = useState(data?.full_name ?? "")
  const [isOpenIdCard, setIsOpenIdCard] = useState(false)

  const onChangeForm = (e) => {
    const { name, value } = e.target
    const newForm = { ...form }

    if (name === 'phone') {
      if (/^\d*$/.test(value) && !/^0/.test(value)) {
        newForm[name] = value
      }
    } else {
      newForm[name] = value
    }

    setForm(newForm)
  }

  const [loading, setLoading] = useState(false)

  //Update photo
  const [currentPhoto, setCurrentPhoto] = useState(null)

  const onDrop = useCallback(acceptedFiles => {
    setCurrentPhoto(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [editing, setEditing] = useState(false)

  const submit = async () => {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append(`user[full_name]`, form.full_name);
      formData.append(`user[username]`, form.username);
      formData.append(`user[email]`, form.email);
      formData.append(`user[phone]`, `+62${form.phone}`);

      if (currentPhoto) {
        formData.append(`user[user_image_attributes[id]]`, imageId);
        formData.append(`user[user_image_attributes[public]]`, currentPhoto);
        formData.append(`user[user_image_attributes[internal]]`, currentPhoto);
      }

      await updateColleague(id, formData);
      window.location.reload()
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const generateQRCode = async () => {
    try {
      const options = {
        color: {
          dark: '#000000', // QR code color
          light: '#ffffff00' // Background color (transparent in this example)
        }
      };
      const url = await QRCode.toDataURL(`${LISTING_URL}/${username}?id=${id}`, options);
      setQrCodeUrl(url);
    } catch (err) {
      console.error(err);
    }
  };

  const convertToPdf = async () => {
    try {
      setLoading(true)

      const input = frontCardRef.current;
      const input2 = backCardRef.current;

      const w = 188
      const h = 321

      const scale = 10;
      const canvasOptions = {
        scale: scale,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
      const pdfOptions = {
        orientation: 'p',
        unit: 'mm',
        format: [w, h],
      };

      await html2canvas(input, canvasOptions).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF(pdfOptions);
        pdf.addImage(imgData, 'PNG', 0, 0, w, h);
        pdf.save('front-card.pdf');
      });

      await html2canvas(input2, canvasOptions)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF(pdfOptions);

          pdf.addImage(imgData, 'PNG', 0, 0, w, h); // Set the size to 4 meters by 1 meter
          pdf.save('back-card.pdf');
        });
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  };

  const formatCareerLevel = useMemo(() => {
    // const name = data?.role?.name ?? "";
    const name = data?.career_level?.name ?? "";
    if (!name) return "";

    const words = name.split(' ');
    if (words.length <= 2) {
      return name;
    }

    const abbreviatedWords = words.slice(0, 2).concat(words.slice(2).map(word => word.charAt(0) + '.'));
    return abbreviatedWords.join(' ');
  }, [])

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ""

    if (phoneNumber.startsWith('+62')) {
      phoneNumber = `0${phoneNumber.slice(3)}`
    }

    // Menghapus semua karakter selain angka
    phoneNumber = phoneNumber.replace(/\D/g, '');

    return phoneNumber.replace(/(\d{4})(?=\d)/g, '$1.')
  }

  const disabled = useMemo(() => !editing || loading, [editing, loading])

  useEffect(() => {
    generateQRCode()
  }, []);

  const RenderButtons = useMemo(() => {
    if (editing) {
      return (
        <div className={Styles['btn-wrapper']}>
          <button disabled={loading} onClick={() => setEditing(false)} className={`${Styles['btn']} ${Styles['white']}`}>
            <CrossBlackIcon />
            <span>Batal</span>
          </button>
          <button onClick={submit} className={`${Styles['btn']} ${Styles['black']}`}>
            <DoubleCheck />
            <span>Simpan perubahan</span>
          </button>
        </div>
      )
    }

    return (
      <div className={Styles['btn-wrapper']}>
        <button onClick={() => setEditing(true)} className={`${Styles['btn']} ${Styles['white']}`}>
          <Pencil />
          <span>Ubah</span>
        </button>
      </div>
    )

  }, [editing, form, loading, currentPhoto, imageId, id, onClose])

  const RenderFieldFotoProfile = useMemo(() => {
    if (editing) {
      return (
        <div className={Styles['upload-new-profile-wrapper']}>
          <p className={Styles['title-unggah-foto']}>Unggah foto profil web</p>
          <div {...getRootProps({ className: Styles['dropzone'] })}>
            <Gallery />
            <p className={Styles['text-pilih-foto']}>Pilih foto</p>
            <input {...getInputProps()} />
          </div>
          <p className={Styles['footer-unggah-foto']}>*Foto yang diunggah akan ditampilkan di web pribadi marketing.</p>
        </div>
      )
    }

    // if(userDontHavePhoto) {
    if (data?.user_image?.online_profile?.url === DefaultImage) {
      return (
        <div className={Styles['wrapper-info-profile-web']}>
          <p className={Styles['title-profile-web']}>Foto profil web</p>
          <p className={Styles['desc-profile-web']}>Belum ada foto profil untuk web.</p>
          <p className={Styles['footer-profile-web']}>*Foto profil ini ditampilkan di web pribadi marketing.</p>
        </div>
      )
    }

    // user have photo
    return (
      <div className={Styles['wrapper-info-profile-web']}>
        <p className={Styles['title-profile-web']}>Foto profil web</p>
        <p className={`${Styles['desc-profile-web']} ${Styles['green']}`}>Foto telah diupload.</p>
        <p className={Styles['footer-profile-web']}>*Foto profil ini ditampilkan di web pribadi marketing.</p>
      </div>
    )
  }, [editing, form, loading, currentPhoto, imageId, id, onClose])
  console.log(data)
  useOutsideAlerter(modalIdCardRef, () => setIsOpenIdCard(false))
  return (
    <Modal toggle={!loading && onClose}>

      <div className={Styles['scrolled-content']}>
        <div className={Styles['wrapper-content']}>
          <div className={Styles['wrapper-close']}>
            <CrossIcon onClick={onClose} />
          </div>

          <p className={Styles['text-detail-marketing']}>Detail Marketing</p>
          {editing ? (
            <div className={Styles['image-wrapper']} {...getRootProps()}>
              <input {...getInputProps()} />
              {currentPhoto ? <img src={URL.createObjectURL(currentPhoto)} alt="..." /> : <img src={image} alt="..." />}
              <button className={Styles['btn-ubah']}><span>Ubah</span></button>
            </div>
          ) : (
            <div className={Styles['image-wrapper']}>
              {currentPhoto ? <img src={URL.createObjectURL(currentPhoto)} alt="..." /> : <img src={image} alt="..." />}
            </div>
          )}

          <Input
            label="Nama Lengkap"
            placeholder="Masukkan Nama Lengkap"
            type="text"
            onChange={onChangeForm}
            value={form.full_name}
            name={'full_name'}
            disabled={disabled}
          />
          <Input
            label="Username"
            placeholder="Masukkan Username"
            type="text"
            onChange={onChangeForm}
            value={form.username}
            name={'username'}
            disabled={true}
          />
          <InputPhone
            label="Nomor Handphone/WhatsApp"
            placeholder="Masukkan Handphone/WhatsApp"
            type="text"
            onChange={onChangeForm}
            value={form.phone}
            name={'phone'}
            disabled={disabled}
          />
          <Input
            label="Alamat Email"
            placeholder="Masukkan Nama Lengkap"
            type="text"
            onChange={onChangeForm}
            value={form.email}
            name={'email'}
            disabled={disabled}
          />
          {RenderFieldFotoProfile}
          <div className={Styles["wrapper-id-card-button"]}>
            <p className={Styles["title-id-card"]}>ID Card</p>
            <Button
              Icon={IdentificationCard}
              text={"Buat Kartu Nama"}
              bg='black'
              onClick={() => setIsOpenIdCard(true)}
              disabled={!data?.user_image?.name_card?.url}
              feedback={"inactive"}
            />
          </div>
          {RenderButtons}
        </div>

      </div>

      {loading &&
        <div className={Styles['loader-wrapper']}>
          <Loader />
        </div>
      }

      {isOpenIdCard && <div className='fixed bg-backdrop z-[100002] flex justify-center items-center w-screen h-screen'>
        <div ref={modalIdCardRef} className='p-4 bg-white rounded-md'>
          <div className='mb-4'>
            <p className='text-base mb-[12px]'>Nama Marketing</p>
            <input value={currentName} onChange={(e) => setCurrentName(e.target.value || "")} className='rounded-md py-2 px-4 w-full text-base border-[1px] border-solid border-black-rgba-0' />
          </div>
          <div className='flex'>
            {/* <RenderIDCardFront
              refID={frontCardRef}
              fullname={currentName}
              companyName={data?.company.name}
              registrationNumber={data?.registration_number}
              role={formatCareerLevel}
              userImage={data?.user_image?.name_card?.url}
            />

            <RenderIDCardBack
              refID={backCardRef}
              fullname={currentName}
              companyName={data?.company.name}
              registrationNumber={data?.registration_number}
              role={formatCareerLevel}
              phone={formatPhoneNumber(data?.company?.phone)}
              street={data?.company?.street}
              qrCodeUrl={qrCodeUrl}
            /> */}

            <FrontCardV2
              refID={frontCardRef}
              fullname={currentName}
              companyName={data?.company.name}
              registrationNumber={data?.registration_number}
              role={formatCareerLevel}
              userImage={data?.user_image?.name_card?.url}
            />
            <BackCardV2
              refID={backCardRef}
              fullname={currentName}
              companyName={data?.company.name}
              registrationNumber={data?.registration_number}
              role={formatCareerLevel}
              phone={formatPhoneNumber(data?.phone)}
              street={data?.company?.street}
              qrCodeUrl={qrCodeUrl}
              email={data?.email}
            />
          </div>

          <div className={Styles["wrapper-id-card-button"]}>
            <p className={Styles["title-id-card"]}>Kartu Nama</p>
            <Button
              Icon={IdentificationCard}
              text={"Download Kartu Nama"}
              bg='black'
              onClick={convertToPdf}
              disabled={!data?.user_image?.name_card?.url}
              feedback={"inactive"}
            />
          </div>
        </div>
      </div>}

    </Modal>
  )
}

export default DetailMarketing