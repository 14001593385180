//Utils
import { api } from "../utils";

//Constant
import {
  //URL
  CHECK_EMAIL_LOGIN,
  URL_LOGIN_WEB,
  GET_PROFILE,
  SIGN_OUT
} from "../constant";

const checkEmail = async (data) =>  await api().post(CHECK_EMAIL_LOGIN, data)
const requestLoginWeb = async (data) => await api().post(URL_LOGIN_WEB, data)
const getUserDetail = async () => await api().get(GET_PROFILE)
const signOut = async () => await api().delete(SIGN_OUT)

const authAction = {
  checkEmail,
  requestLoginWeb,
  getUserDetail,
  signOut
}

export default authAction