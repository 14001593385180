import React from 'react'
import CurrencyInput from 'react-currency-input-field';

//Styles
import Styles from './input-currency.module.scss'

function InputCurrency({ className, Icon, iconPosition = '', ...props }) {
  return (
    <div className={`${Styles['wrapper']} ${Styles[`position-${iconPosition}`]} ${className || ""}`}>
      {Icon && <Icon className={Styles['icon']} />}
      <CurrencyInput
        decimalSeparator=","
        groupSeparator="."
        {...props}
      />
    </div>
  )
}

export default InputCurrency