import React, { useContext } from 'react'

//Styles
import "./style.scss"

//Assets
import { ReactComponent as PreviousIcon } from "assets/previous.svg";
import { ReactComponent as NextIcon } from "assets/next.svg";

//Context
import { DashboardCtx } from "../context/LeadDashboard";

function Footer() {
  const { page, getNextLeads, getPreviousLeads, currentTab } = useContext(DashboardCtx);
  const currentLeadStatus = React.useMemo(() => currentTab == "Menunggu" ? 'menunggu' : 'dikontak', [currentTab])
  return (
    <div className='dashboard-leads-footer'>
      <div className='pagination-wrapper'>
        <button disabled={page == 1} onClick={() => getPreviousLeads(currentLeadStatus)}><PreviousIcon className='prev-icon' /> Sebelumnya</button>
        {/* <p><span>{page}</span> dari <span>10</span> halaman</p> */}
        <p>halaman <span>{page}</span></p>
        <button onClick={() => getNextLeads(currentLeadStatus)}>Selanjutnya <NextIcon className='next-icon' /></button>
      </div>
    </div>
  )
}

export default Footer