import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

function UCTextInputV1({
  label,
  name,
  placeholder,
  disabled,
  errors,
  className,
  type,
  register,
  onChange,
  value
}) {

  return (
    <div className={`uc-textinput-v1 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
        <input
          {...register}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          onChange={onChange}
          value={value}
        />
      </div>
      {errors[name] ? <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-xs text-red-0'>{message}</p>}
      /> : ""}
    </div>
  )
}

UCTextInputV1.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  type: "text",
  placeholder: "",
  disabled: false,
  errors: {}
}

export default UCTextInputV1