import React, { useState, useEffect } from 'react'
import Modal from "components/organism/modal-v2"

import { ReactComponent as XIcon } from "assets/x-black.svg"
import Radio from "assets/radio-2.png"
import Button from 'components/v1/Button'
import { getLeadsDistributionMarketingList } from "actions"

const ModalPilihMarketing = ({ currentMarketing, setCurrentMarketing, onNext, onClose, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [listMarketing, setListMarketing] = useState([]);

  const getListMarketing = async (page = 1) => {
    try {
      setLoading(true)
      const response = await getLeadsDistributionMarketingList(`?page=${page}`);
      const newData = response?.data?.data ?? [];

      setListMarketing(val => ([...val, ...newData]))

      // if(!!newData?.length){
      //   await getListMarketing(page + 1)
      // } else {
      //   setLoading(false)
      // }

    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getListMarketing()
  }, [])

  return (
    <Modal {...props}>
      <div className='z-[42]  bg-white-3 w-[488px] h-[550px] rounded-2xl py-6 px-8'>
        <div className='flex justify-end mb-4'>
          <XIcon onClick={onClose} className='cursor-pointer w-4 h-4 object-contain' />
        </div>
        <p className='text-lg mb-4 pb-4 border-b border-solid border-white-0'>Pilih marketing yang menangani leads</p>

        <div className='h-[354px] overflow-y-auto border-[0.5px] border-solid border-white-0'>
          {!!listMarketing?.length && listMarketing.map((l, i) => {
            const selected = currentMarketing?.id === l?.id;

            const onClick = () => {
              setCurrentMarketing(l)
            }
            return (
              <div
                onClick={onClick}
                key={`list-marketing-${i}`}
                className={`${selected ? "bg-yellow-7 font-bold" : ""} w-full flex justify-between items-center p-4 !border-b-[0.5px] border-solid border-white-0 last:!border-0`}
              >
                <span className='text-sm'>{l?.full_name ?? ""}</span>

                {selected ?
                  <img src={Radio} alt='radio active' className='w-4 h-4 object-contain' />
                  :
                  <div className='w-4 h-4 rounded-[8px] border border-solid border-gray-4' />
                }
              </div>
            )
          })}
        </div>

        <div className='flex justify-center mt-2'>
          <Button onClick={onNext} disabled={!currentMarketing} text="Selanjutnya" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalPilihMarketing isOpen={isOpen} {...props} />
}

export default Wrapper