import React from 'react';

function UCInputWithOptionV2({
  label,
  disabled,
  error,
  className,
  suffixPosition,
  mandatoryLabel,
  register,
  name
}) {
  return (
    <div className={`uc-input-option-v2 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      {mandatoryLabel ? <span className='addon-label'>{"("}<span className='mandatory-label'>* tidak boleh kosong</span>{")"}</span> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <input {...register(name)} placeholder="0" disabled={disabled} />
        </div>
        <div className='suffix-wrapper'>
          <select {...register("tipeDurasiSewa")} disabled={disabled}>
            <option value="Tahun">Tahun</option>
            <option value="Bulan">Bulan</option>
          </select>
        </div>
      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

UCInputWithOptionV2.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  type: "text",
  disabled: false,
  mandatoryLabel: false,
  onChange: () => { }
}

export default UCInputWithOptionV2