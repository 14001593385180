import React, { useState, useMemo, useEffect } from 'react'

// Assets
import { ReactComponent as IconX } from "assets/x-black.svg"
import { ReactComponent as TrashIcon } from "assets/trash-red-line.svg"

// Components
import CModal from 'components/v1/modal';
import CInput from './components/c-input';
import CSearch from './components/c-search';
import CDropdown from './components/c-dropdown';
import Button from 'components/v1/Button';

// Api
import { useGetListAreaOnly } from 'api/common/query';
import { useGetDetailParticipant } from 'api/training/query';
import { useGlobalCtx } from 'contexts';
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const ModalEditParticipant = ({ onSuccess, onDelete, userId, ...props }) => {
  const { profile } = useGlobalCtx()
  const { ListEducation } = useTrainingCtx()

  const { data: detailMa } = useGetDetailParticipant({ userId })

  const [paramsListArea, setParamsListArea] = useState({ q: "" })

  const { data: listAreaOnly } = useGetListAreaOnly({ params: paramsListArea })
  const filteredArea = useMemo(() => {
    if (!listAreaOnly?.data?.data?.length) return []

    return listAreaOnly.data.data.map((area) => ({ value: area?.id, label: area?.name }))
  }, [listAreaOnly])

  const [paramsEditCalonMa, setParamsEditCalonMa] = useState({
    "company_id": profile?.company?.id,
    "full_name": "",
    "card_number": "",
    "dob": "",
    "phone": "",
    "email": "",
    "area_id": "",
    "street": "",
    "last_education": ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    const newParams = { ...paramsEditCalonMa }
    newParams[name] = value

    setParamsEditCalonMa(newParams)
  }

  useEffect(() => {
    if (detailMa?.data?.data) {
      const newData = detailMa?.data?.data ?? {}
      setParamsEditCalonMa({ ...paramsEditCalonMa, ...newData })
    }
  }, [detailMa])

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded-md'>
        <div className='py-2 px-6 flex items-center justify-between border-b border-solid border-[#D8D8D9]'>
          <span className='text-[17px] font-semibold'>Calon Marketing Associate</span>
          <IconX onClick={props.onRequestClose} className='cursor-pointer w-[14px] h-[14px] object-contain' />
        </div>
        <div className='flex items-center border-y border-solid border-[#D8D8D9] py-2 px-6'>
          <Button
            icon={TrashIcon}
            text="Keluar dari Training"
            className="mr-2"
            size="xs"
            type="negative"
            onClick={() => {
              props.onRequestClose()
              onDelete()
            }}
          />
        </div>
        <div className='py-2 px-4'>
          <CInput
            label="Nama Lengkap"
            placeholder="contoh: John Doe"
            required
            onChange={handleChange}
            name="full_name"
            disabled={true}
            value={paramsEditCalonMa.full_name}
          />
          <CInput
            label="No. KTP"
            placeholder="minimal 16 digit kombinasi angka"
            required
            onChange={handleChange}
            name="card_number"
            disabled={true}
            value={paramsEditCalonMa.card_number}
          />
          <CInput
            label="Tanggal Lahir"
            placeholder="hh / bb / tttt"
            required
            onChange={handleChange}
            type="date"
            name="dob"
            disabled={true}
            value={paramsEditCalonMa.dob}
          />
          <CInput
            label="Telepon"
            placeholder="contoh: 085478560909"
            required
            name="phone"
            onChange={handleChange}
            disabled={true}
            value={paramsEditCalonMa.phone}
          />
          <CInput
            label="Alamat email"
            placeholder="contoh: johndoe@gmail.com"
            required
            onChange={handleChange}
            disabled={true}
            name="email"
            value={paramsEditCalonMa.email}
          />
          <CSearch
            label="Domisili"
            placeholder="Masukkan area"
            required
            selectedValue={paramsEditCalonMa.area_id}
            list={filteredArea}
            disabled={true}
            name="area_id"
            onSearch={(value) => setParamsListArea({ ...paramsListArea, q: value })}
            onSelect={(value) => setParamsEditCalonMa({ ...paramsEditCalonMa, area_id: value })}
          />
          <CInput
            label="Alamat detail"
            placeholder="contoh: Jl. Antasari No 15D"
            required
            onChange={handleChange}
            name="street"
            value={paramsEditCalonMa.street}
            disabled={true}
          />
          <CDropdown
            label="Pendidikan terakhir"
            placeholder="Pilih jenjang pendidikan"
            required
            selectedValue={paramsEditCalonMa.last_education}
            list={ListEducation}
            name="last_education"
            disabled={true}
            onSelect={(value) => setParamsEditCalonMa({ ...paramsEditCalonMa, last_education: value })}
          />
        </div>
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalEditParticipant isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}
