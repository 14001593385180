import React from 'react'

//Styles
import Styles from "./entry-properti-dan-transaksi.module.scss";

//Components
import TitleAndNumber from 'pages/dashboard/e-reporting/forms/components/reuseables/title-and-number/TitleAndNumber'
import TextLarge from 'pages/dashboard/e-reporting/forms/components/reuseables/text-large/TextLarge'
import ButtonIconCustom from 'pages/dashboard/e-reporting/forms/components/reuseables/button-icon-custom/ButtonIconCustom'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets
import { ReactComponent as ArrowDown } from 'assets/arrow-down-with-tail.svg'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function EntryPropertiDanTransaksi() {
  const { form } = useFormState();
  const { setCurrentStep, handlePrevState } = useFormMethod();

  const onNext = () => {
    handlePrevState()
    setCurrentStep(STEPS.pilihProvinsiProperti)
  }

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['box']}>
        <TitleAndNumber
          title={'Data properti & transaksi'}
          order={'3'}
        />
        <div className={Styles['margin-left']}>
          <TextLarge>
            Lengkapi detail properti dan transaksi dari <span className={Styles['golden']}>{form.marketing_penjual.value.full_name}</span> yang akan dibuatkan laporan
          </TextLarge>
          <BoxFlex>
            <>
              <ButtonSebelumnya />
              <ButtonIconCustom
                text={'Mulai mengisi'}
                Icon={ArrowDown}
                onClick={onNext}
              />
            </>
          </BoxFlex>
        </div>
      </div>
    </div>
  )
}

export default EntryPropertiDanTransaksi