import React from 'react'

//Styles
import Styles from "./button-sebelumnya.module.scss";

//Assets - svg
import { ReactComponent as Back } from "assets/arrow-left-tail.svg";

//Context
import { useFormMethod } from "pages/dashboard/e-reporting/forms/context";

function ButtonSebelumnya(props) {
  //Context
  const { handleBackStep } = useFormMethod()

  return (
    <button {...props} onClick={handleBackStep} className={`${Styles['btn']} ${props.className || ""}`}>
      <Back className={Styles['icon']} />
      <span className={Styles['text']}>Sebelumnya</span>
    </button>
  )
}

export default ButtonSebelumnya