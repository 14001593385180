import React from 'react'

//Components
import { ButtonSvgIcon, Divider } from "components";
import { CTextInputV1, CTextInputV2, CSelectV1, CTextareaInputV1 } from "components/atomic/Input";
import { StepsProcess, ConfimasiSimpanDataTransaksi, SuccessModal } from "pages/dashboard/e-reporting/ERComponents";

//Assets
import { ReactComponent as BackIcon } from "assets/arrow-left-tail.svg";
import { ReactComponent as NextIcon } from "assets/next.svg";
import { ReactComponent as PrevIcon } from "assets/previous.svg";
import { ReactComponent as PhoneIcon } from "assets/phone.svg";

//Context
import { useERState, useERMethod } from "pages/dashboard/e-reporting/ERCtx"
import { useFPembeliCtx } from "pages/dashboard/e-reporting/ERForms/ERFComponents/formPembeli/FPembeliCtx"
import { useERFState } from "pages/dashboard/e-reporting/ERForms/ERFCtx"

function FPembeliMain() {
  const { companies } = useERState()
  const { backToHomepage } = useERMethod()

  const {
    transaksiDipilih,
    isPropRent,
    formFlow,
    faForm,
    haveASA,
    haveABA,
    process,
    updating
  } = useERFState()
  const {
    onSubmit,
    onBack,
    provinces,
    cities,
    areas,

    openModalKonfirmasi,
    setOpenModalKonfirmasi,
    submitting,
    submitNewTransaction,
    approveTransaction,
    komisiFinal,
    openModalSuccess,
    disableForm,
    disableButtonNext,
    onSelectAddresses,
    onChangeForm,
    form,
    nilaiTransaksiSaatIni,
    nilaiTransaksiBerubah
  } = useFPembeliCtx()

  const backText = () => {
    if (formFlow == 3) {
      return "Cek data property & transaksi"
    }

    if (formFlow == 4) {
      return "Cek data agent"
    }

    if (isPropRent) {
      return "Cek data pemberi sewa"
    }
    return "Cek data penjual"
  }

  return (
    <div className='data-porperty-dan-transaksi'>
      {/* HEADER */}
      <div className='header-wrapper whitespace-nowrap'>

        <p className='text-3xl text-gold-0 font-medium'>Laporan Transaksi</p>
        <ButtonSvgIcon
          text="Kembali ke halaman utama"
          icon={<BackIcon />}
          className="back-btn"
          action={() => backToHomepage()}
        />
      </div>
      {/* BODY */}

      <div className='data-prop-body'>
        {<StepsProcess flow={formFlow} process={process} />}

        <div className='dpb-form-wrapper'>
          <p className='text-2xl mb-[8px]'>{isPropRent ? "Isi Data Penyewa" : "Isi Data Pembeli"}</p>
          <div className='box-between'>
            <p className='text-sm text-gray-0'>Silahkan isi identitas pembeli dan domisili pembeli.</p>
            <p className='text-sm text-gray-0'>Properti dipilih : <span className='text-black-0'>{transaksiDipilih?.title ?? ""}</span></p>
          </div>

          <Divider classname="mt-[8px] mb-[8px]" />

          <div className='reporting-form-wrapper'>
            <div className='rf-left'>
              <div className='label-wrapper'>
                <p className='label-field'>Identitas {isPropRent ? "Penyewa" : "Pembeli"}</p>
                <p className='mandatory-field'>*Tidak boleh kosong</p>
              </div>

              <div className='input-wrapper'>
                <CTextInputV1
                  className="_field"
                  label={isPropRent ? "Nama lengkap penyewa" : "Nama lengkap pembeli"}
                  name="nama"
                  placeholder="Isi nama pembeli"
                  disabled={disableForm}
                  onChange={onChangeForm}
                  value={form.nama.value}
                />
                <CTextInputV2
                  placeholder="0"
                  className="_field"
                  type="number"
                  label={isPropRent ? "Nomor handphone penyewa" : "Nomor handphone pembeli"}
                  suffixPosition="left"
                  suffixText={<PhoneIcon />}
                  name="phone"
                  disabled={disableForm}
                  onChange={onChangeForm}
                  value={form.phone.value}
                />
                <CTextInputV2
                  placeholder="Isi alamat email"
                  label={isPropRent ? "Alamat email penyewa" : "Alamat email pembeli"}
                  optionalLabel={true}
                  suffixPosition="left"
                  suffixText={"@"}
                  name="email"
                  disabled={disableForm}
                  onChange={onChangeForm}
                  value={form.email.value}
                />
              </div>
            </div>
            <div className='rf-right'>
              <div className='label-wrapper'>
                <p className='label-field'>Domisili {isPropRent ? "Penyewa" : "Pembeli"}</p>
                <p className='mandatory-field'>*Tidak boleh kosong</p>
              </div>

              <div className='input-wrapper'>
                <div className="_field">
                  <CSelectV1
                    name="province"
                    placeholder="Pilih Provinsi"
                    label="Provinsi"
                    options={provinces.data}
                    displayName="name"
                    valueName="id"
                    disabled={disableForm}
                    value={form.province}
                    onSelect={onSelectAddresses}
                    useLoading={updating}
                  />
                </div>
                <div className="_field">
                  <CSelectV1
                    name="city"
                    placeholder="Pilih Kota/Kabupaten"
                    label="Kota/Kabupaten"
                    options={cities.data}
                    displayName="name"
                    valueName="id"
                    disabled={disableForm}
                    onSelect={onSelectAddresses}
                    value={form.city}
                    useLoading={updating}
                  />
                </div>
                <div className="_field">
                  <CSelectV1
                    name="area"
                    placeholder="Pilih Area"
                    label="Area"
                    options={areas.data}
                    displayName="name"
                    valueName="id"
                    disabled={disableForm}
                    onSelect={onSelectAddresses}
                    value={form.area}
                    useLoading={updating}
                  />
                </div>

                <CTextareaInputV1
                  name="street"
                  className="_field"
                  placeholder="Tuliskan alamat lengkap (termasuk jalan, blok, nomor, dll)"
                  label="Alamat lengkap properti"
                  info="Tuliskan alamat properti selengkap mungkin."
                  disabled={disableForm}
                  onChange={onChangeForm}
                  value={form.street.value}
                />

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className='dpt-footer-wrapper'>
        <ButtonSvgIcon
          text={backText()}
          icon={<PrevIcon />}
          className="prev-btn"
          action={onBack}
        />
        <ButtonSvgIcon
          text={"Selesai"}
          icon={<NextIcon />}
          className="next-btn"
          action={onSubmit}
          disabled={disableButtonNext()}
        />
      </div>

      <ConfimasiSimpanDataTransaksi
        onClose={() => setOpenModalKonfirmasi(false)}
        open={openModalKonfirmasi && !openModalSuccess}
        simpan={() => updating ? approveTransaction() : submitNewTransaction()}
        disabled={submitting}
        faForm={faForm}
        companies={companies}
        haveASA={haveASA}
        haveABA={haveABA}
        komisi={komisiFinal}
        updating={updating}
        disableForm={disableForm}
        nilaiTransaksiSaatIni={nilaiTransaksiSaatIni}
        nilaiTransaksiBerubah={nilaiTransaksiBerubah}
      />
      <SuccessModal updating={updating} open={openModalSuccess} onSuccess={() => window.location.reload()} />
    </div>
  )
}

export default FPembeliMain