import lodash from "lodash";
import dayjs from "dayjs";

export const handleAgentRole = (form, loggedInUser) => {
  const kantorLoginUser = loggedInUser?.company?.id ?? null;
  const kantorPenjual = form.kantor_marketing_penjual.value.id;
  const kantorPembeli = form.kantor_marketing_pembeli.value.id;

  let role = "S1B1";
  if ((kantorPenjual == kantorLoginUser) && (kantorPembeli == kantorLoginUser)) {
    role = "S1B1";
  } else if ((kantorPenjual == kantorLoginUser) && kantorPembeli && (kantorPembeli != kantorLoginUser)) {
    role = "S1B0";
  } else if ((kantorPenjual == kantorLoginUser) && !kantorPembeli) {
    role = "S1XX";
  } else if (kantorPenjual && (kantorPenjual != kantorLoginUser) && (kantorPembeli == kantorLoginUser)) {
    role = "S0B1";
  } else if (!kantorPenjual && (kantorPembeli == kantorLoginUser)) {
    role = "SXB1";
  }
  return role;
}

const handlePropertiLocation = (form) => {
  return {
    province_id: lodash.toNumber(form.provinsi_properti.value.id),
    city_id: lodash.toNumber(form.kota_properti.value.id),
    area_id: lodash.toNumber(form.area_properti.value.id),
    street: form.alamat_properti.value
  }
}

const handleMarketingPenjualC21 = (form) => {
  const haveAsisten = form.is_co_listing_penjual.value == 'ya' ? true : false;
  const commisionRate = haveAsisten ? lodash.toNumber(form.komisi_penjual_utama.value) : 100;
  const refferalAmount = lodash.toNumber(form.biaya_refferal_penjual.value)
  return {
    kind: "main",
    company_id: lodash.toNumber(form.kantor_marketing_penjual.value.id),
    user_id: lodash.toNumber(form.marketing_penjual.value.id),
    commission_rate: commisionRate,
    referral_amount: refferalAmount
  }
}

const handleAssitentMarketingPenjualC21 = (form) => {
  const commisionRate = lodash.toNumber(form.komisi_penjual_asisten.value);

  return {
    kind: "assisting",
    company_id: lodash.toNumber(form.kantor_marketing_penjual.value.id),
    user_id: lodash.toNumber(form.asisten_penjual.value.id),
    commission_rate: commisionRate
  }
}
const handleMarketingPenjualNonC21 = (form) => {
  return {
    kind: "main",
    company_name: form.kantor_marketing_penjual.value.name,
    agent_name: form.marketing_penjual.value.name
  }
}

const handleMarketingPembeliC21 = (form) => {
  const haveAsisten = form.is_co_listing_pembeli.value == 'ya' ? true : false;
  const commisionRate = haveAsisten ? lodash.toNumber(form.komisi_pembeli_utama.value) : 100
  const refferalAmount = lodash.toNumber(form.biaya_refferal_pembeli.value) || 0;

  return {
    kind: "main",
    company_id: lodash.toNumber(form.kantor_marketing_pembeli.value.id),
    user_id: lodash.toNumber(form.marketing_pembeli.value.id),
    commission_rate: commisionRate,
    referral_amount: refferalAmount
  }
}

const handleAssitentMarketingPembeliC21 = (form) => {
  const commisionRate = lodash.toNumber(form.komisi_pembeli_asisten.value) || 0;
  return {
    kind: "assisting",
    company_id: lodash.toNumber(form.kantor_marketing_pembeli.value.id),
    user_id: lodash.toNumber(form.asisten_pembeli.value.id),
    commission_rate: commisionRate
  }
}
const handleMarketingPembeliNonC21 = (form) => {
  return {
    kind: "main",
    company_name: form.kantor_marketing_pembeli.value.name,
    agent_name: form.marketing_pembeli.value.name
  }
}

const handleCoTypesAttributes = (form, agent_role_code) => {
  let result = [];
  const haveAsistenPenjual = form.asisten_penjual.value.id ? true : false;
  const haveAsistenPembeli = form.asisten_pembeli.value.id ? true : false;
  switch (agent_role_code) {
    case "S1B1":
    case "S1B0":
    case "S0B1": {
      result = [
        {
          kind: "co-listing",
          agents_attributes: lodash.compact([
            handleMarketingPenjualC21(form),
            haveAsistenPenjual && handleAssitentMarketingPenjualC21(form)
          ])
        },
        {
          kind: "co-selling",
          agents_attributes: lodash.compact([
            handleMarketingPembeliC21(form),
            haveAsistenPembeli && handleAssitentMarketingPembeliC21(form),
          ])
        }
      ]
      break;
    }
    case "S1XX": {
      result = [
        {
          kind: "co-listing",
          agents_attributes: lodash.compact([
            handleMarketingPenjualC21(form),
            haveAsistenPenjual && handleAssitentMarketingPenjualC21(form)
          ])
        },
        {
          kind: "co-selling",
          agents_attributes: lodash.compact([
            handleMarketingPembeliNonC21(form)
          ])
        }
      ]
      break;
    }
    case "SXB1": {
      result = [
        {
          kind: "co-listing",
          agents_attributes: lodash.compact([
            handleMarketingPenjualNonC21(form)
          ])
        },
        {
          kind: "co-selling",
          agents_attributes: lodash.compact([
            handleMarketingPembeliC21(form),
            haveAsistenPembeli && handleAssitentMarketingPembeliC21(form),
          ])
        }
      ]
      break;
    }
    default:
      break;
  }

  return result;
}

const handleConvertTextToHari = (text, num) => {
  let result = num;
  switch (text) {
    case "Tahun":
      result = num * 365;
      break;
    case "Bulan":
      result = num * 30;
      break;
    case "Minggu":
      result = num * 7;
      break;
    case "Hari":
      result = num;
      break;
    default:
      break;
  }
  return result;
}

const handleRentPeriod = (period) => {
  switch (period) {
    case "Hari":
      return "daily"
    case "Minggu":
      return "weekly"
    case "Bulan":
      return "monthly"
    case "Tahun":
      return "yearly"
    default:
      return ""
  }
}

const handleDetailAttributes = (form) => {
  const isKpr = form.metode_pembayaran.value?.id == 2 ? true : false;
  const isRent = form.selected_listing.value?.property_type?.id === 2 ? true : false;

  let result = {
    amount: lodash.toNumber(form.nilai_transaksi.value),
    is_rent: isRent,
    payment_method: isKpr ? "kpr" : "cash",
    is_secondary: form.is_secondary.value,

  }

  if (isRent) {
    result.rent_period_total = lodash.toNumber(form.rent_period_total.value)
    result.rent_period = handleRentPeriod(form?.rent_period?.value?.label)
  }

  if (isKpr) {
    result.kpr_id = lodash.toNumber(form.bank_yang_digunakan.value.id)
    result.plafond = lodash.toNumber(form.plafond.value)
    result.debtor_name = form.debtor_name.value
    result.debtor_phone = form.debtor_phone.value
  }



  return result;
}

const handlePropertyOwnerAttributes = (form) => {
  const result = {
    province_id: lodash.toNumber(form.provinsi_pemilik_properti.value.id),
    city_id: lodash.toNumber(form.kota_pemilik_properti.value.id),
    area_id: lodash.toNumber(form.area_pemilik_properti.value.id),
    street: form.alamat_pemilik_properti.value,
    name: form.nama_pemilik_properti.value,
    phone: `+62${form.telepon_pemilik_properti.value}`,
    email: form.email_pemilik_properti.value || "",
    as_business_entity: form.is_pemilik_badan_usaha.value === "ya" ? true : false,
  }
  !result.email && delete result.email;
  return result;
}

const handleBuyerAttributes = (form) => {
  const result = {
    province_id: lodash.toNumber(form.provinsi_pembeli_properti.value.id),
    city_id: lodash.toNumber(form.kota_pembeli_properti.value.id),
    area_id: lodash.toNumber(form.area_pembeli_properti.value.id),
    street: form.alamat_pembeli_properti.value,
    name: form.nama_pembeli_properti.value,
    phone: `+62${form.telepon_pembeli_properti.value}`,
    email: form.email_pembeli_properti.value || "",
    as_business_entity: false
  }

  !result.email && delete result.email;
  return result;
}

export function handlePayloadSubmit(form, loggedInUser, updateListingData) {
  const by_another_agent = form.is_co_broking.value === "ya" ? true : false;
  const agent_role_code = handleAgentRole(form, loggedInUser)
  const user_id = loggedInUser?.id ?? null;
  const property_location_attributes = handlePropertiLocation(form);
  const co_types_attributes = handleCoTypesAttributes(form, agent_role_code);
  const detail_attributes = handleDetailAttributes(form);
  const property_owner_attributes = handlePropertyOwnerAttributes(form);
  const buyer_attributes = handleBuyerAttributes(form);
  const property_id = form.selected_listing.value.id;
  const transaction_date = dayjs(form.tanggal_transaksi.value).format("YYYY-MM-DD");
  const rent_duration_in_month = lodash.toNumber(form?.periode_sewa?.value);
  const rent_payment_frequency_in_month = lodash.toNumber(form?.periode_pembayaran?.value);

  let payload = {
    received_commission: lodash.toNumber(form.received_commission.value),
  }

  if (updateListingData?.id) {
    payload.id = updateListingData.id
  }

  if (!!lodash.toNumber(form.commission_rate.value)) {
    payload.commission_rate = lodash.toNumber(form.commission_rate.value)
  }

  if (!!lodash.toNumber(form.commission_amount.value)) {
    payload.commission_amount = lodash.toNumber(form.commission_amount.value)
  }

  switch (agent_role_code) {
    case "S1B1": {
      payload = {
        ...payload,
        by_another_agent,
        agent_role_code,
        user_id,
        property_location_attributes,
        co_types_attributes,
        detail_attributes,
        property_owner_attributes,
        buyer_attributes,
        property_id,
        transaction_date,
        rent_duration_in_month,
        rent_payment_frequency_in_month,
      }
      break;
    }
    case "S1B0":
    case "S1XX": {
      payload = {
        ...payload,
        by_another_agent,
        agent_role_code,
        user_id,
        property_id,
        co_types_attributes,
        property_location_attributes,
        property_owner_attributes,
        detail_attributes,
        transaction_date,
        rent_duration_in_month,
        rent_payment_frequency_in_month,
      }
      break;
    }
    case "S0B1": {
      payload = {
        ...payload,
        by_another_agent,
        agent_role_code,
        user_id,
        co_types_attributes,
        detail_attributes,
        transaction_date,
        buyer_attributes,
        rent_duration_in_month,
        rent_payment_frequency_in_month,
      }
      break;
    }
    case 'SXB1': {
      payload = {
        ...payload,
        user_id,
        by_another_agent,
        agent_role_code,
        detail_attributes,
        co_types_attributes,
        transaction_date,
        buyer_attributes,
        rent_duration_in_month,
        rent_payment_frequency_in_month,
      }
      break
    }
    default:
      break;
  }

  return payload;
}

export function handlePayloadCalculateCommision(form, loggedInUser, updateListingData) {
  const by_another_agent = form.is_co_broking.value === "ya" ? true : false;
  const agent_role_code = handleAgentRole(form, loggedInUser)
  const user_id = loggedInUser?.id ?? null;
  const property_location_attributes = handlePropertiLocation(form);
  const co_types_attributes = handleCoTypesAttributes(form, agent_role_code);
  const detail_attributes = handleDetailAttributes(form);
  const property_owner_attributes = handlePropertyOwnerAttributes(form);
  const property_id = form.selected_listing?.value?.id;
  const transaction_date = dayjs(form.tanggal_transaksi.value).format("YYYY-MM-DD");

  let payload = {
    received_commission: lodash.toNumber(form.received_commission.value),
  }

  if (updateListingData?.id) {
    payload.id = updateListingData.id
  }

  if (!!lodash.toNumber(form.commission_rate.value)) {
    payload.commission_rate = lodash.toNumber(form.commission_rate.value)
  }

  if (!!lodash.toNumber(form.commission_amount.value)) {
    payload.commission_amount = lodash.toNumber(form.commission_amount.value)
  }

  switch (agent_role_code) {
    case "S1B1": {
      payload = {
        ...payload,
        user_id,
        by_another_agent,
        agent_role_code,
        property_id,
        property_location_attributes,
        detail_attributes,
        property_owner_attributes,
        co_types_attributes,
      }
      break;
    }
    case 'S1XX':
    case 'S1B0': {
      payload = {
        ...payload,
        user_id,
        by_another_agent,
        agent_role_code,
        property_id,
        property_location_attributes,
        detail_attributes,
        property_owner_attributes,
        co_types_attributes,
      }
      break
    }
    case 'S0B1': {
      payload = {
        ...payload,
        user_id,
        by_another_agent,
        agent_role_code,
        detail_attributes,
        co_types_attributes,
        transaction_date
      }
      break
    }
    case 'SXB1': {
      payload = {
        ...payload,
        user_id,
        by_another_agent,
        agent_role_code,
        detail_attributes,
        co_types_attributes,
      }
      break
    }

    default:
      break;
  }

  return payload;
}
