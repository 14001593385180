import { useState, useMemo } from "react"
import dayjs from "dayjs"

import Modal from "components/organism/modal-v2"
import Button from "components/v1/Button"

const InfoApprovalTrx = ({ isOpen, onClose, ...props }) => {
  const thisMonth = useMemo(() => dayjs().format('MMMM'), []);
  const thisYear = useMemo(() => dayjs().year(), []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <div className="relative z-[42] w-full max-w-[350px] bg-white py-[24px] px-[32px] 
          border-[0.5px] border-solid border-white-0 box-shadow-2 rounded-[6px]">
        <p className="text-base mb-4 text-center">Jangan lupa batas terakhir untuk approval transaksi adalah tanggal <br /> <span className="text-red-0">4 {thisMonth} {thisYear}</span></p>
        <div className="flex items-center justify-center">
          <Button onClick={onClose} text={"OK"} />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ ...props }) => {
  const thisDate = useMemo(() => +dayjs().format("D"), []);

  const defaultOpen = useMemo(() => {
    const storageDate = localStorage.getItem("info-trx-approval");
    const dateToShow = thisDate >= 1 && thisDate <= 4;

    if(!dateToShow) {
      localStorage.removeItem("info-trx-approval")
      return false
    }
    
    if(!!storageDate && +storageDate === thisDate){
      return false
    } 

    localStorage.setItem("info-trx-approval", String(thisDate));
    return true
  }, []);

  const [isOpen, setIsOpen] = useState(defaultOpen);

  const onClose = () => {
    setIsOpen(false)
  }

  if (!isOpen) return null;
  return <InfoApprovalTrx onClose={onClose} isOpen={isOpen} {...props} />
}

export default Wrapper