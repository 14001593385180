import React, { useState } from 'react'
import { toast } from 'react-toastify';

// Components
import ModalAddCalonMa from 'pages/dashboard/training/components/modal-add-ma';
import ModalSukses from "pages/dashboard/training/components/modal-sukses"
import ModalDaftarTraining from "pages/dashboard/training/components/modal-daftar-training"

// Assets
import { ReactComponent as PlusIcon } from "assets/plus-black.svg"

// Ctx
import { useTrainingCtx } from "pages/dashboard/training/useTraining"
import { useRegisterParticipant } from 'api/training/mutation';

const InfoHeader = () => {
  const { refetchAll, listAllTrainingAndBatches } = useTrainingCtx()

  const { mutate: mutateRegisterParticipant } = useRegisterParticipant()

  const [calonMa, setCalonMa] = useState("")
  
  const [isOpenModalAddCalonMa, setIsOpenModalAddCalonMa] = useState(false)
  const [isOpenModalSuksesAddCalonMa, setIsOpenModalSuksesAddCalonMa] = useState(false)
  const [isOpenModalRegisterCalonMa, setIsOpenModalRegisterCalonMa] = useState(false)

  const handleOpenModalAddCalonMa = () => setIsOpenModalAddCalonMa(true)
  const handleCloseModalAddCalonMa = () => setIsOpenModalAddCalonMa(false)

  const handleOpenModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(true)
  const handleCloseModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(false)

  const handleOpenModalSuksesAddCalonMa = () => setIsOpenModalSuksesAddCalonMa(true)
  const handleCloseModalSuksesAddCalonMa = () => {
    setIsOpenModalSuksesAddCalonMa(false)
    refetchAll()
  }

  const handleRegisterParticipant = (batchId) => {
    const payload = {
      id: batchId,
      data: {
        "training_batch_participant": {
          "user_id": calonMa?.id
        }
      }
    }
    mutateRegisterParticipant(payload, {
      onSuccess: () => {
        handleCloseModalRegisterCalonMa()
        refetchAll()
        toast.success("Berhasil mendaftarkan participant")
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
  }

  return (
    <div className='rounded bg-white py-[10px] px-4 mb-4'>
      <button onClick={handleOpenModalAddCalonMa} className='cursor-pointer rounded px-2 py-[10px] flex items-center border border-solid border-[#D8D8D9]'>
        <PlusIcon className='w-[14px] h-[14px] mr-2' />
        <span className='font-semibold text-[11px]'>
          Calon Marketing Associate
        </span>
      </button>

      <ModalAddCalonMa
        isOpen={isOpenModalAddCalonMa}
        onRequestClose={handleCloseModalAddCalonMa}
        onSuccess={handleOpenModalSuksesAddCalonMa}
        setCalonMa={setCalonMa}
        handleOpenModalRegisterCalonMa={handleOpenModalRegisterCalonMa}
      />

      <ModalSukses
        isOpen={isOpenModalSuksesAddCalonMa}
        onRequestClose={handleCloseModalSuksesAddCalonMa}
        title="Berhasil ditambahkan"
        info="Marketing yang ditambahkan bisa dilihat pada list Calon Marketing Associate."
      />

      <ModalDaftarTraining
        isOpen={isOpenModalRegisterCalonMa}
        onRequestClose={handleCloseModalRegisterCalonMa}
        onSubmit={handleRegisterParticipant}
        list={listAllTrainingAndBatches?.data?.data ?? []}
      />
    </div>
  )
}

export default InfoHeader