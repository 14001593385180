import React, { useState } from 'react'

import ModalSearch from "components/v1/modal-search"

import { ReactComponent as CarretDown } from "assets/arrow-down.svg"

const SelectWithModalSearch = ({ value, onSelect, label, className, ...props }) => {
  const [openModalSearch, setOpenModalSearch] = useState(false);

  const handleOpenModalSearch = () => {
    setOpenModalSearch(true)
  }

  const handleCloseModalSearch = () => {
    setOpenModalSearch(false)
  }

  const handleSelect = (selectedArea) => {
    onSelect(selectedArea)
    handleCloseModalSearch()
  }

  return (
    <div className='mb-[32px]'>
      <p className='text-xs mb-2 text-gray-2'>{label}</p>
      <div onClick={handleOpenModalSearch} className='cursor-pointer w-full bg-white flex items-center px-4 py-[10px] rounded-md border border-solid border-black-3'>
        <input
          className={`cursor-pointer w-[calc(100%-16px-16px)] bg-white text-sm placeholder:text-gray-2 ${className}`}
          value={value?.name ?? ""}
          readOnly
          {...props}
        />

        <CarretDown className='w-4 h-4 object-contain' />
      </div>
      <ModalSearch
        onClose={handleCloseModalSearch}
        isOpen={openModalSearch}
        handleSelect={handleSelect}
      />
    </div>
  )
}

export default SelectWithModalSearch