import React from 'react'

//CSS
import "./style.scss";

//Assets
import { ReactComponent as NoDataImage } from "assets/no-transaction.svg"

function EmptyData() {
  return (
    <div className='dashboard-leads-empty-data'>
      <NoDataImage className='no-data-image' />
      <p>Yah, belum ada data nih!</p>
    </div>
  )
}

export default EmptyData