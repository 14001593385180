import { Fragment } from 'react'

// Hooks
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

// Components
import ModalWrapper from "./ModalWrapper";
import Divider from "./Divider";
import Button from 'components/v1/Button';

// Assets
import PlusIcon from "assets/plus-1.png";

const ModalInformation = ({
  selectedTrx,
  onNextModal,
  onOpenCancelCommision,
  commisionHistory,
  trxId,
  data,
  ...props
}) => {
  const { formatDate } = useFormatDate()

  return (
    <ModalWrapper {...props}>
      <div>
        <p className='text-lg font-semibold font-inter mb-2'>Informasi transaksi bertahap</p>
        <p className='text-sm font-inter text-gray-2'>Berikut ini adalah rincian transaksi ID Transaksi <span className='font-medium text-black-0'>#{selectedTrx?.id}</span>.</p>
        <Divider />
        <div className='grid grid-cols-2 gap-x-[32px]'>
          <div className='w-full'>
            <p className='text-xs font-inter text-gray-2 mb-0.5'>Total Komisi</p>
            <p className='text-sm font-inter font-semibold text-gold-1'>Rp{data?.totalCommision ?? 0}</p>
          </div>
          <div className='w-full'>
            <p className='text-xs font-inter text-gray-2 mb-0.5'>Komisi yang Belum Diterima</p>
            <p className='text-sm font-inter font-semibold text-red-0'>-Rp{data?.pendingCommision ?? 0}</p>
          </div>
        </div>
        <Divider />

        <div className='rounded-md p-4 border-[0.5px] border-solid border-white-0 bg-white'>
          <div className='flex justify-between items-center py-2'>
            <span className='text-xs font-inter text-gray-0'>Tanggal Terima</span>
            <span className='text-xs font-inter text-gray-0'>Komisi yang Diterima</span>
          </div>
          <Divider className="my-2" />
          {!!commisionHistory?.length && commisionHistory.map((history, key) => {
            return (
              <Fragment key={`received-history-${key}`}>
                <div className='flex justify-between items-center py-2'>
                  <span className='text-sm font-inter'>{formatDate(history?.paided_at, "D MMMM YYYY")}</span>
                  <span className='text-sm font-inter'>Rp{toRupiah(history?.amount ?? 0)}</span>
                </div>
                <Divider className="my-2" />
              </Fragment>
            )
          })}
          <div className='flex justify-between items-center py-2'>
            <span className='text-xs font-inter text-gray-0'>Total yang sudah diterima</span>
            <span className='text-xs font-inter font-semibold'>Rp{data?.totalReceivedCommission ?? 0}</span>
          </div>
        </div>

        <div className='flex flex-col items-center mt-4'>
          <Button
            icon={PlusIcon}
            text="Tambah Komisi yang Diterima"
            onClick={onNextModal}
            size="xl"
            className="!mb-4"
          />
          <Button
            text="Batalkan Sisa Komisi"
            onClick={onOpenCancelCommision}
            type="negative"
            className="!font-semibold"
          />

        </div>
      </div>
    </ModalWrapper>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalInformation isOpen={isOpen} {...props} />
}

export default Wrapper