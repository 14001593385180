import React from 'react'

const ContentHeader = () => {
  return (
    <div className='bg-white py-[14px] px-6'>
      <p className='text-[22px]'>Training Jenjang Karir Marketing</p>
      <div className='w-[310px] h-[0.5px] bg-[linear-gradient(90deg,#D8D8D9_0%,#FFF_100%)]' />
      <p className='text-[13px] text-[#808285]'>Pantau keikutsertaan marketing pada training jenjang karir.</p>
    </div>
  )
}

export default ContentHeader