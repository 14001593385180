import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import dayjs from 'dayjs'

//Styles
import Styles from './table.module.scss'

//Assets
import { ReactComponent as Info } from 'assets/info.svg'

//Components
import ToggleSwitchRounded from 'components/toggle-switch-rounded'
import DetailMarketing from '../detail-marketing/DetailMarketing'
import Loader from 'components/molecules/Loader'
import ModalLoader from 'components/organism/ModalLogout';

//Context
import { useDetailListingState } from 'pages/dashboard/marketing/context'


//Actions
import {
  updateColleague
} from "actions";

const Row = ({ data, index, setOpenModalLoader, lastElementRef }) => {
  const {
    image,
    name,
    role,
    email,
    phone,
    registrationNumber,
    no,
    isActive,
    id
  } = useMemo(() => ({
    image: data?.user_image?.public?.url ?? '',
    name: data?.full_name ?? '',
    role: data?.role?.name ?? '',
    email: data?.email ?? '',
    phone: data?.phone ?? '',
    registrationNumber: data?.registration_number ?? '',
    no: index + 1,
    isActive: !data?.suspended_at,
    id: data?.id
  }), [data, index]);

  const [status, setStatus] = useState(isActive);
  const [openModal, setOpenModal] = useState(false);
  const [openInternalLoader, setOpenInternalLoader] = useState(false);

  const hitActiveEnpoint = async () => {
    try {
      setOpenInternalLoader(true)
      const formData = new FormData()
      formData.append(`user[suspended_at]`, status ? dayjs().format('YYYY-MM-DD hh:mm:ss') : null);

      const response = await updateColleague(id, formData);

      setStatus(!response?.data?.suspended_at)
    } catch (error) {
      console.log(error)
    } finally {
      setOpenInternalLoader(false)
    }
  }

  useEffect(() => {
    setStatus(isActive)
  }, [isActive])

  return (
    <div ref={lastElementRef}>
      <div className={Styles['row']}>
        <div className={`${Styles['col']} ${Styles['number']}`}>{no}</div>
        <div className={`${Styles['col']} ${Styles['image']}`}>
          <img src={image} alt="image-name" />
        </div>
        <div className={`${Styles['col']} ${Styles['double']} ${Styles['name']}`}>
          <p className={`${Styles['first']}`}>{name}</p>
          <p className={`${Styles['last']}`}>{role}</p>
        </div>
        <div className={`${Styles['col']} ${Styles['double']} ${Styles['email']}`}>
          <p className={`${Styles['first']}`}>{email}</p>
          <p className={`${Styles['last']}`}>email</p>
        </div>
        <div className={`${Styles['col']} ${Styles['double']} ${Styles['telepon']}`}>
          <p className={`${Styles['first']}`}>{phone}</p>
          <p className={`${Styles['last']}`}>telepon</p>
        </div>
        <div className={`${Styles['col']} ${Styles['double']} ${Styles['no-registrasi']}`}>
          <p className={`${Styles['first']}`}>{registrationNumber}</p>
          <p className={`${Styles['last']}`}>No. Registrasi</p>
        </div>
        <div className={`${Styles['col']} ${Styles['info']}`}>
          <div className={Styles['info-wrapper']}>
            <Info onClick={() => setOpenModal(true)} />
          </div>
        </div>
        <div className={`${Styles['col']} ${Styles['active']}`}>
          <ToggleSwitchRounded active={status} toggle={hitActiveEnpoint} />
          <p className={Styles['active-text']}>{status ? "Aktif" : "Tidak aktif"}</p>
        </div>
      </div>

      {openInternalLoader && <ModalLoader open={openInternalLoader} />}
      {openModal && <DetailMarketing setOpenModalLoader={setOpenModalLoader} key={`colleague-tables-${index}-${id}`} onClose={() => setOpenModal(false)} data={data} />}
    </div>
  )
}

const Table = () => {
  const {
    colleagues,
    setOpenModalLoader,
    loading,
    triggeredInfiniteScroll,
    hasMore,
    page
  } = useDetailListingState();

  // Infinitescroll Observer
  const observer = useRef()

  const lastElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        triggeredInfiniteScroll()
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  return (
    <div className={Styles['wrapper']} >
      {!!colleagues.length && (
        colleagues.map((colleague, index) => (
          colleagues.length === index + 1 ?
            <Row lastElementRef={lastElementRef} setOpenModalLoader={setOpenModalLoader} key={`colleague-${index}`} index={index} data={colleague} />
            :
            <Row setOpenModalLoader={setOpenModalLoader} key={`colleague-${index}`} index={index} data={colleague} />
        ))
      )}


      {!!((page > 1) && loading) && <div className={Styles['centered']}>
        <Loader />
      </div>}
    </div>
  )
}

export default Table