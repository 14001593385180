import React from 'react'
import Modal from "components/organism/modal-v2"
import { ReactComponent as CheckList } from "assets/checklist-v2.svg"
import Button from 'components/v1/Button'

const ModalKonfirmasi = (props) => {
  console.log(props.marketing_associate)
  return (
    <Modal {...props}>
      <div className='flex flex-col items-center z-[42] bg-white-3 w-[488px] rounded-2xl py-6 px-8'>
        <CheckList className='w-[68px] h-[68px] object-contain mb-4' />
        <p className='text-lg mb-2'>{props.title}</p>
        <p className='text-sm text-gray-2 text-center'>Segera hubungi <span className='font-bold'>{props.marketingName}</span>. Marketing diharapkan langsung menghubungi calon klien agar listing properti dapat segera ditindak lanjuti.</p>

        <div className='flex justify-center pt-2 mt-4 border-t border-solid border-white-0'>
          <Button onClick={props.onClose} text="Tutup" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalKonfirmasi isOpen={isOpen} {...props} />
}

export default Wrapper