//Actions
import { updateLeads } from "actions"

function useUpdateLeads() {
  const handleUpdateLeads = async (id, data) => await updateLeads(id, data)

  return {
    handleUpdateLeads
  }
}

export default useUpdateLeads
