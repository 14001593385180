import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input-field';
import { Controller } from "react-hook-form";

function UCTextInputV3({
  label,
  name,
  disabled,
  error,
  className,
  suffixPosition,
  suffixText,
  suffixIcon,
  control
}) {
  return (
    <div className={`uc-textinput-v3 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className='suffix-wrapper'>
          {suffixIcon ? suffixIcon : <span>{suffixText}</span>}
        </div>

        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <Controller
            control={control}
            name={name}
            rules={{ required: true }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <CurrencyInput
                  name={name}
                  value={value || "0"}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(amount) => {
                    !disabled && onChange(amount)
                  }}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  disabled={disabled}
                />
              )
            }}
          />
        </div>

      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

UCTextInputV3.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  disabled: false,
  onChange: () => { }
}

export default UCTextInputV3