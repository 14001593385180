import React from 'react'

//Styles
import Styles from "./text-medium.module.scss";

//Utils
import { classModuleGrouping } from "utils/classModuleGrouping";

function TextMedium({children, required, ...props}) {
  return <p {...props} className={classModuleGrouping(Styles, 'text-medium ' + props.className)}>{children} {required ? <span className={Styles['required']}>*</span> : null}</p>
}

export default TextMedium