import { useState } from 'react'

import { ReactComponent as InfoOrange } from 'assets/warning-orange.svg'
import { ReactComponent as XBlack } from 'assets/x-black.svg'
import { ReactComponent as Checked } from 'assets/checked-square-black.svg'

import Button from 'components/v1/Button'

const Confirmation = ({
  onClose,
  handleGetPayments
}) => {
  const [isChecked, setIsChecked] = useState()

  const handleChecked = () => setIsChecked(!isChecked);

  const handleSubmit = () => {
    handleGetPayments()
    onClose()
  }
  return (
    <div className='fixed w-screen h-screen z-40 top-0 left-0 flex justify-center items-center'>
      <div onClick={onClose} className='absolute w-full h-full z-[41px] modal-backdrop' />
      <div className='bg-white my-[50px] absolute z-[42px] w-[488px] 
        px-[24px] py-[16px] rounded'>

        <div className='flex items-center justify-between mb-[8px]'>
          <div className='flex items-center'>
            <InfoOrange width={24} height={24} className='mr-[6px]' />
            <p className='text-[18px] leading-[28px] font-semibold'>Lanjutkan Pembayaran?</p>
          </div>

          <XBlack onClick={onClose} className='w-4 h-4 cursor-pointer' />
        </div>
        <p className='text-sm text-gray-2 mb-[16px]'>Sebelum melanjutkan pembayaran, pastikan informasi GCC, Invoice, dan PC (Personal Commission) sudah benar.</p>
        <div className='flex items-start pt-[8px] border-[0.5] border-solid border-black-3'>
          {!isChecked ? <div onClick={handleChecked} className='w-4 h-4 rounded-[2px] border-[1px] border-solid border-gray-4 bg-white' /> : <Checked onClick={handleChecked} className='w-4 h-4' />}
          <p className='text-xs ml-[8px] mr-[16px] w-[333px]'>Dengan ini saya menyatakan bahwa informasi yang ditampilkan sudah sesuai.</p>
          <Button 
            text="Bayar"
            size="xl"
            className="!px-[14px] !py-[10px] !text-sm"
            disabled={!isChecked}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default ({ isOpen, ...props }) => isOpen ? <Confirmation {...props} /> : null;