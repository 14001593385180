import React, { useState, useEffect } from 'react'

//Actions
import ERAction from "actions/eReportingAction";

//Components
import ModalLoader from "components/organism/ModalLoader";

function withFetch(WrappedComponent) {

  const WithFetch = (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    //make function recursive to fetch data from api
    const fetchData = async (count = 1, tempData = []) => {
      try {
        setLoading(true)

        const list = await ERAction.getKPR(`?page=${count}&_limit=50`)

        const newData = list?.data ?? []

        if (newData.length) {
          await fetchData(count + 1, [...tempData, ...newData])
        } else {
          setData(tempData)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
   
    //fetch data when component mounted
    useEffect(() => {
      fetchData()
    }, [])

    if (loading) return <ModalLoader open={true} />
    return <WrappedComponent {...props} data={data} />
  }

  return WithFetch
}

export default withFetch