import React from 'react'

function LTCard({ title, price = 0, unit = 0, className }) {
  return (
    <div className={`lt-card ${className}`}>
      <div className='lt-card-price'><p><span>Rp</span>{price.toLocaleString('id-ID')}</p></div>
      <div className='lt-card-info'>
        <p className='title'>{title}</p>
        <p className='unit'><span className='mr-[4px]'>{unit}</span>unit</p>
      </div>
    </div>
  )
}

export default LTCard