import styled from "styled-components"

const PointCardStyle = styled.button`
  background: #ECECEC;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #101010;
`

const PointCard = ({ point, ml = "" }) => {
  return <PointCardStyle ><span>+{point}</span> poin</PointCardStyle>
}

export default PointCard