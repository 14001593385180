import React from 'react'

// Styles
import Styles from './button-v2-left-icon.module.scss'

const ButtonV2LeftIcon = ({ text, Icon, ...props }) => {
  return (
    <button className={Styles['wrapper']} {...props}>
      <Icon />
      <span>
        {text}
      </span>
    </button>
  )
}

export default ButtonV2LeftIcon