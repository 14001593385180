//Styles
import "./style.module.scss";

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

//Components - reuseables
import BinaryQuestion from "pages/dashboard/e-reporting/forms/components/reuseables/binary-question";

function IsCoBroking() {
  const { form, isAprroval, isEditing, userLoginAuth } = useFormState();
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();

  //Functions
  const handleNextStep = (val) => {
    handlePrevState()
    formHandler('is_co_broking', val)
    if (val == 'tidak') {
      setCurrentStep(STEPS.pilihMarketingPenjual);
      formHandler('kantor_marketing_penjual', userLoginAuth.company)
      formHandler('kantor_marketing_pembeli', userLoginAuth.company)
    } else if (val == 'ya') {
      setCurrentStep(STEPS.pilihKantorMarketingPenjual);
    }
  }

  return (
    <BinaryQuestion
      title="Marketing"
      question="Apakah ada co-broking?"
      onNext={handleNextStep}
      value={form.is_co_broking.value}
      disabledAll={isAprroval || isEditing}
      hideBack={true}
    />
  )
}

export default IsCoBroking