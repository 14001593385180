import React from 'react'
import _ from "lodash"
import { Oval } from "react-loader-spinner";
import "./style.scss"

//Assets
import RoundDivider from "assets/round-divider.png";

//Context
import { useDetailListingCtx } from "../../Context"

// Component
import SharedHotListing from "../SharedHotListing"
import ButtonDetailPjp from "../button-detail-pjp"

function GambaranUmum() {
  const {
    detailImages,
    setIsOpenModalImages,
    getValue,
    filter,
    listDetail,
  } = useDetailListingCtx()

  const handleLocation = () => {
    const locations = getValue("summary.property_locations")

    if (locations?.length) {
      const { area, city, province } = locations[0] || {}
      return `${area?.name}, ${city?.name}, ${province?.name}`
    }

    return ""
  }

  const handleAddtionalRoom = (val) => {
    if (val) return `+${val}`
    return ""
  }

  const getImageUrl = (position, type) => {
    if (detailImages.data.length) {
      const img = _.find(detailImages.data, { position })
      if (type == "tag") {
        return img?.name
      }

      if (img?.image?.url) {
        return img?.image?.url
      }
      return ""
    }
    return ""
  }

  return (
    <div className='gambaran-umum-wrapper'>
      <p className='properti-header'>{getValue("title")}</p>
      <p className='properti-location'>{handleLocation()}</p>
      <div className='properti-labels-wrapper'>
        <span className='properti-label'>{getValue("property_privacy.name")}</span>
        <span className='properti-label'>{getValue("property_type.name")}</span>
        <span className='properti-label'>{getValue("property_category.name")}</span>
        <span className='properti-label'>{getValue("ownership_status.name")}</span>
        <span className='properti-label'>#{getValue("id")}</span>
      </div>

      <SharedHotListing isHotListing={listDetail?.is_hot_listing && filter === "hot-listing"} />
      {!!listDetail?.is_pjp && <ButtonDetailPjp
        propertyLocation={listDetail?.summary?.property_locations?.[0]}
        title={listDetail?.title ?? ""}
        marketingAgreementUUID={listDetail?.summary?.marketing_agreements?.uuid}
        idListing={getValue("id")}
      />}

      {
        detailImages.loading ? <div className='w-100 flex justify-center align-center' style={{ height: 263 }}>
          <Oval
            height={60}
            width={60}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={4}
            strokeWidthSecondary={4}

          />
        </div> : detailImages?.data?.length ? (
          <div className='images-wrapper'>
            <div className='properti-main-image'>
              {getImageUrl(1, "tag") ? <span className='properti-image-tag'>{getImageUrl(1, "tag")}</span> : null}
              {getImageUrl(1) ? <img src={getImageUrl(1)} alt='...' /> : null}
            </div>
            <div className='properti-secondary-image'>
              <div className='secondary-top'>
                <div className='main-image'>
                  {getImageUrl(2, "tag") ? <span className='properti-image-tag'>{getImageUrl(2, "tag")}</span> : null}
                  {getImageUrl(2) ? <img src={getImageUrl(2)} alt='...' /> : null}
                </div>
                <div className='main-image'>
                  {getImageUrl(3, "tag") ? <span className='properti-image-tag'>{getImageUrl(3, "tag")}</span> : null}
                  {getImageUrl(3) ? <img src={getImageUrl(3)} alt='...' /> : null}
                </div>
              </div>
              <div className='secondary-bottom'>
                <div className='main-image'>
                  {getImageUrl(4, "tag") ? <span className='properti-image-tag'>{getImageUrl(4, "tag")}</span> : null}
                  {getImageUrl(4) ? <img src={getImageUrl(4)} alt='...' /> : null}
                </div>
                <div className='main-image'>
                  {getImageUrl(5, "tag") ? <span className='properti-image-tag'>{getImageUrl(5, "tag")}</span> : null}
                  {getImageUrl(5) ? <img src={getImageUrl(5)} alt='...' /> : null}
                </div>
              </div>
            </div>
          </div>
        ) : <div className='w-100 flex justify-center align-center' style={{ height: 263 }}><p>Tidak Ada Data</p></div>
      }

      <div className='properti-tampilkan-semua-btn' onClick={() => !detailImages.loading && detailImages.data.length && setIsOpenModalImages(true)}><span>Tampilkan Semua Foto</span></div>
      <p className='properti-price'>Rp {getValue("price")?.toLocaleString("id-ID")}</p>
      <div className='properti-room-info'>
        <span>{getValue("bedroom")}{handleAddtionalRoom(getValue("additional_bedroom"))} kamar tidur</span>
        <img src={RoundDivider} alt="dot divider" className='mr-[10px] ml-[10px]' />
        <span>{getValue("bathroom")}{handleAddtionalRoom(getValue("additional_bathroom"))} kamar mandi</span>
        <img src={RoundDivider} alt="dot divider" className='mr-[10px] ml-[10px]' />
        <span>{getValue("number_of_floors")} lantai</span>
        <img src={RoundDivider} alt="dot divider" className='mr-[10px] ml-[10px]' />
        <span>Komisi {_.toNumber(getValue("commission_rate"))}%</span>
      </div>
    </div>
  )
}

export default GambaranUmum