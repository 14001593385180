import React, { useMemo } from 'react'

//Styles
import Styles from "./modal-submit.module.scss";

//Components
import Modal from 'pages/dashboard/e-reporting/forms/components/reuseables/modal'
import Label from './labels'
import Loader from 'components/molecules/Loader';

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";

//Hoc
import withModal from './withModal'

//Context
import { useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function ModalSubmit() {
  //Context
  const { form, fetchingCommision, disabledAll } = useFormState();
  const { modalHandler, submitPayload } = useFormMethod();

  //Memo
  const isFetchingCommision = useMemo(() => fetchingCommision, [fetchingCommision])

  const agentsInfo = useMemo(() => ({
    penjualUtama: {
      name: form.kantor_marketing_penjual.value.name,
      value: form.marketing_penjual.value.full_name
    },
    penjualAsisting: {
      value: form.asisten_penjual.value.full_name
    },
    pembeliUtama: {
      name: form.kantor_marketing_pembeli.value.name,
      value: form.marketing_pembeli.value.full_name
    },
    pembeliAsisting: {
      value: form.asisten_pembeli.value.full_name
    },
    totalAgent: {
      name: 'Jumlah Agent',
      value: 2 + (form.is_co_listing_penjual.value ? 1 : 0) + (form.is_co_listing_pembeli.value ? 1 : 0)
    },
    totalCommision: {
      name: 'Besaran Komisi',
      value: form.calculated_commission.value
    },
  }), [])

  //Handlers
  const onConfirm = () => submitPayload()

  const onClose = () => modalHandler('modalSubmit', false)
  return (
    <Modal toggle={onClose}>
      {isFetchingCommision ? <Loader /> : (
        <div className={Styles['wrapper-content']}>
          <div className={Styles['wrapper-close']}>
            <CrossIcon onClick={onClose} />
          </div>
          <div className={Styles['wrapper-info']}>
            <p className={Styles['title']}>Kamu yakin ingin menyimpan data transaksi?</p>
            <p className={Styles['info']}>Setelah memilih “simpan data”, kamu tidak bisa lagi mengubah data yang telah kamu isi sebelumnya. Kami sarankan sebelum memilih “simpan data”, kamu mengecek kembali isian kamu. </p>
          </div>
          <div className={Styles['box-content']}>
            <p className={Styles['title']}>Komisi yang Diterima Seluruh Marketing Associate</p>

            <div className={Styles['wrapper-agents']}>
              <Label data={agentsInfo.penjualUtama} />
              <Label data={agentsInfo.pembeliUtama} />
            </div>

            <div className={Styles['wrapper-agents']}>
              <Label data={agentsInfo.penjualAsisting} />
              <Label data={agentsInfo.pembeliAsisting} />
            </div>
            <div className={Styles['wrapper-agents']}>
              <Label data={agentsInfo.totalAgent} />
              <Label data={agentsInfo.totalCommision} />
            </div>
          </div>

          <div className={Styles['wrapper-btn']}>
            <button className={Styles['batal']} onClick={onClose}>Cek isian saya</button>
            <button className={Styles['lanjutkan']} onClick={onConfirm}>Ya, {disabledAll ? 'setujui' : 'simpan'} data transaksi</button>
          </div>
        </div>
      )}
    </Modal>
  )
}


export default withModal(ModalSubmit)