import React from 'react'

import styled from "styled-components"

import InfoGrey from "assets/info-grey.png"

const FormulaJudulStyle = styled.button`
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  margin-bottom: 32px;
  img{
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  p{
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #676767;
    display: flex;
    align-items: center;
  }
  ul{
    margin-left: 38px;
    li{
      text-align: left;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #676767;
    }
  }
`


function FormulaJudul({ header, list }) {
  return (
    <FormulaJudulStyle>
      <p><img src={InfoGrey} alt="..." /> {header}</p>
      <ul>
        {list.map((l, k) => <li key={`formula-judul-${k}`}>{l}</li>)}
      </ul>
    </FormulaJudulStyle>
  )
}

export default FormulaJudul