import React from 'react'

//Styles
import Styles from "./title-per-section.module.scss";

function TitlePerSection({ className, order, label, buyer }) {
  return (
    <p className={`${Styles['wrapper']} ${className || ""}`}> <span className={Styles['label']}>{ }</span> Berapa persen yang didapatkan masing-masing marketing {buyer ? 'pembeli' : "penjual"}? <span className={Styles['required']}>*</span></p>
  )
}

export default TitlePerSection