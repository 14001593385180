import React from 'react'

//Components
import { ButtonSvgIcon } from "components";

//Assets
import { ReactComponent as NextIcon } from "assets/next.svg";
import { ReactComponent as PreviousIcon } from "assets/previous.svg";

//Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

function LTFooter() {
  const { 
    fetchingTransaction, 
    totalTransactionPage, 
    transactionPage,
    getNextTransaction,
    getPrevTransaction
  } = useTTCtx();
  
  return (
    <div className='lt-footer-wrapper'>
      <div className='pagination-wrapper whitespace-nowrap'>
        <ButtonSvgIcon
          text="Sebelumnya"
          icon={<PreviousIcon />}
          className="prev-btn mr-[32px]"
          disabled={fetchingTransaction}
          action={() => getNextTransaction()}
        />
        <p className='pagination-text mr-[32px]'><span>{transactionPage}</span> dari <span>{totalTransactionPage}</span> halaman</p>
        <ButtonSvgIcon
          text="Selanjutnya"
          icon={<NextIcon />}
          className="next-btn"
          disabled={fetchingTransaction}
          action={() => getPrevTransaction()}
        />
      </div>
    </div>
  )
}

export default LTFooter