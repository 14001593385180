// Assets
import C21Logo from "assets/watermark-c21.png"

function useAddWatermark() {

  const addWaterMark = (backgroundImageSrc, originalFile) => {

    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");

      const backgroundImage = new Image();
      backgroundImage.src = backgroundImageSrc;

      backgroundImage.onload = () => {
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;

        const watermarkImage = new Image()
        watermarkImage.src = C21Logo

        watermarkImage.onload = () => {
          const overlayWidth = canvas.width * 0.2;
          const overlayHeight = (watermarkImage.height / watermarkImage.width) * overlayWidth;

          const x = (canvas.width - overlayWidth) / 2;
          const y = ((canvas.height - overlayHeight) / 2) - 200;

          ctx.drawImage(backgroundImage, 0, 0);
          ctx.drawImage(watermarkImage, x, y, overlayWidth, overlayHeight);

          canvas.toBlob(async (blob) => {
            const compressedFile = new File([blob], originalFile.file.type.replace("/", "."), { type: originalFile.file.type });
            const result = { ...originalFile, preview: URL.createObjectURL(compressedFile), file: compressedFile }
            canvas.remove()

            resolve(result)
          }, originalFile.file.type)
        }

        watermarkImage.onerror = (error) => {
          reject(error)
        }
      };

      backgroundImage.onerror = (error) => {
        reject(error)
      }
    })

  }

  return addWaterMark;
}

export default useAddWatermark;
