import { useState } from 'react'
import Button from "components/v1/Button";

// Assets
import RedTrash from "assets/red-trash.png";

// Components
import ModalConfirmationHapus from './modal-confirmation-hapus-hot-listing';

const HapusHotListing = ({ handleDeleteHotListing, hotListingId, listDetail }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true)
  const handleCloseModal = () => setIsOpenModal(false)

  const handleSubmit = () => {
    handleDeleteHotListing(hotListingId, handleCloseModal)
  }

  return (
    <>
      <div className='flex items-center justify-end'>
        <Button
          type="negative"
          text="Hapus dari Hot Listing"
          icon={RedTrash}
          size="xl"
          className="mb-[22px]"
          onClick={handleOpenModal}
        />
      </div>
      <ModalConfirmationHapus 
        isOpen={isOpenModal} 
        onClose={handleCloseModal} 
        onContinue={handleSubmit}
      />
    </>
  )
}

export default ({ filter, haveReason, ...props }) => !!(filter === "hot-listing" && haveReason) ? <HapusHotListing {...props} /> : null