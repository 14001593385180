import React from 'react'
import CModal from './CModal'

function ModalLoader({ open, className = "", modalContentClassName = "" }) {
  if(!open) return null;
  
  return (
    <CModal
      id="modal-loader"
      toggle={() => null}
    >
      <div className={`modal-content ${modalContentClassName}`}>
        <div className={`loader ${className}`}></div>
      </div>
    </CModal>
  )
}

export default ModalLoader