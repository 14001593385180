import React from "react"

// Components
import ModalWrapper from './ModalWrapper'
import Divider from './Divider'

// Assets
import { ReactComponent as Warning } from "assets/orange-warning.svg";

const ModalConfirmation = ({ onSubmit, ...props }) => {
  return (
    <ModalWrapper {...props} className="z-[44]">
      <div>
        <p className='text-lg font-semibold font-inter mb-2 flex items-center'><Warning className='w-6 h-6 mr-1' /> Yakin Tambah Komisi?</p>
        <p className='text-sm font-inter mb-4 text-gray-2'><span className='font-medium text-black-0'>Perhatian:</span> Setelah menambahkan komisi, tidak dapat diubah atau dihapus. Pastikan Anda yakin sebelum melanjutkan.</p>
        <Divider />
        <div className='grid grid-cols-2 gap-x-[24px]'>
          <button onClick={props.onClose} className='w-full flex items-center justify-center p-2.5 rounded-md cursor-pointer 
            border-[1px] border-solid border-black-3 box-shadow-2 text-sm font-medium'>
            Kembali
          </button>
          <button onClick={onSubmit} className='w-full bg-black-0 flex items-center justify-center py-2.5 px-4 rounded-md cursor-pointer
            border-[1px] border-solid border-black-0 box-shadow-2 text-sm font-medium text-white'>
            Ya, Tambah Komisi
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalConfirmation;