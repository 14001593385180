import { useRef, useState, useMemo } from 'react'

//Styles
import Styles from "./select-with-search.module.scss";

//Assets
import { ReactComponent as ArrowDown } from "assets/arrow-bottom.svg";
import { ReactComponent as XBlack } from "assets/x-black.svg";

//Hooks
import useOutsideAlerter from "hooks/useOutsideAlerter"

//Utils
import randomNumber from 'utils/randomNumber';

function SelectWithSearch({ selectedData, moduleClass, disabled, options = [], onClick, ...props }) {
  const selectRef = useRef(null)

  //State
  const [openDropdown, setOpenDropdown] = useState(false)
  const [selectedValue, setSelectedValue] = useState(selectedData || { id: '', label: '' })

  const [filter, setFilter] = useState(selectedData?.label ?? "");

  // Fungsi untuk memfilter pilihan berdasarkan kata kunci
  function filterOptions(keyword) {
    return options.filter((option) =>
      option.label.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  // Menangani perubahan nilai pada input search
  function handleFilterChange(event) {
    setOpenDropdown(true)
    setFilter(event.target.value);
    onClick((val) => ({ ...val, id: null }))
  }

  // Menangani perubahan nilai pada select element
  function handleSelectChange(value) {
    setSelectedValue({ ...selectedValue, id: null })
    setFilter(value.label);
    onClick && onClick(value)
  }

  useOutsideAlerter(selectRef, () => setOpenDropdown(false))

  const handleModuleClass = useMemo(() => {
    const classes = moduleClass?.split(' ')

    if (!classes?.length) return "";

    return classes.map(classname => Styles[classname]).join(' ')
  }, [moduleClass])

  return (
    <div ref={selectRef} 
      className={`${Styles['dropdown-wrapper']} ${disabled ? Styles["disabled"] : ''} ${handleModuleClass}`} 
      onClick={() => !disabled && setOpenDropdown(val => !val)}>

      <div className={Styles['selected-value']}>
        <input
          {...props}
          value={filter}
          disabled={disabled}
          onChange={handleFilterChange}
        /> {!selectedValue.id ? <ArrowDown /> : <XBlack onClick={() => !disabled && setSelectedValue('')} />}
      </div>

      {openDropdown && <div className={Styles['dropdown']}>
        {filterOptions(filter).map((option) => (
          <button key={`${option.id}-${randomNumber(1000, 1)}`} onClick={() => handleSelectChange(option)}><span>{option.label}</span></button>
        ))}
      </div>}
    </div>
  )
}

export default SelectWithSearch