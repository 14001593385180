import React, { useContext } from 'react'

// Components
import { FAll } from "./SpekPropsFields";

//Context
import { UploadListingContext } from "contexts"

// Fields per cateogry
const FieldsRumah = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "kamarTidurPembantu", "kamarMandiPembantu", "listrik"],
  parkirKendaraan: ["garasi", "parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "Taman", "CCTV", "Kolam Renang" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "Taman", "Masjid", "Kolam Renang", "Jogging Track", "CCTV" ]
}

const FieldsApartment = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: [],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["kamarTidur", "kamarMandi", "kamarTidurPembantu", "kamarMandiPembantu", "listrik"],
  parkirKendaraan: ["parkir"],
  face: [],
  fasilitasRumah: [ "Line Telepon", "AC", "CCTV" ],
  fasilitasSekitarRumah: [ "Masjid", "Kolam Renang", "CCTV", "Jogging Track", "Keamanan 24 Jam", "Taman" ]
}

const FieldsRuko = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "kamarTidurPembantu", "kamarMandiPembantu", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "CCTV" ],
  fasilitasSekitarRumah: [ "CCTV", "Keamanan 24 Jam" ]
}

const FieldsTanah = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: [],
  kondisiBangunan: [],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: [],
  fitur: [],
  parkirKendaraan: [],
  face: ["face"],
  fasilitasRumah: [],
  fasilitasSekitarRumah: []
}

const FieldsGudang = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "CCTV" ]
}

const FieldsGedung = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "Taman", "CCTV" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "CCTV" ]
}

const FieldsHotel = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "Taman", "CCTV" ,"Kolam Renang" ],
  fasilitasSekitarRumah: [ "Line Telepon", "AC", "Taman", "CCTV", "Kolam Renang", 6 ]
}

const FieldsKios = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "CCTV" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam" ,"CCTV" ]
}

const FieldsKos = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "listrik"],
  parkirKendaraan: ["garasi", "parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "Taman", "CCTV", "Kolam Renang" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "Taman", "Masjid", "Kolam Renang", "Jogging Track", "CCTV" ]
}

const FieldsPabrikIndustri = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "CCTV" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "CCTV" ]
}

const FieldsRumahKantor = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: ["luasTanah", "lebarTanah", "panjangTanah"],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarTidur", "kamarMandi", "kamarTidurPembantu", "kamarMandiPembantu", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: ["Line Telepon", "AC", "CCTV" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "CCTV" ]
}

const FieldsSpaceKantor = {
  marketingAssociate: ["marketingAssociate"],
  tahunDibangun: ["tahunDibangun"],
  kondisiBangunan: ["property_condition", "furniture_condition"],
  luasTanah: [],
  luasBangunan: ["luasBangunan", "lebarBangunan", "panjangBangunan"],
  fitur: ["lantai", "kamarMandi", "listrik"],
  parkirKendaraan: ["parkir"],
  face: ["face"],
  fasilitasRumah: [ "Line Telepon", "AC", "CCTV" ],
  fasilitasSekitarRumah: [ "Keamanan 24 Jam", "CCTV" ]
}

function SpesifikasiProperty() {
  const { form, propertyCategories } = useContext(UploadListingContext)

  const getPropsCateogryName = () => {
    try {
      if (!propertyCategories.length) return ""

      const category = propertyCategories.filter((cat) => cat.id == form.property_category.id)
      if (!category.length) return ""

      return category[0].name
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  switch (getPropsCateogryName()) {
    case "Rumah": return <FAll fields={FieldsRumah} />
    case "Apartment": return <FAll fields={FieldsApartment} apartment={true} />
    case "Ruko": return <FAll fields={FieldsRuko} ruko={true} />
    case "Tanah": return <FAll fields={FieldsTanah} />
    case "Gudang": return <FAll fields={FieldsGudang} />
    case "Gedung": return <FAll fields={FieldsGedung} />
    case "Hotel": return <FAll fields={FieldsHotel} />
    case "Kios": return <FAll fields={FieldsKios} />
    case "Kos": return <FAll fields={FieldsKos} />
    case "Pabrik / Industri": return <FAll fields={FieldsPabrikIndustri} />
    case "Rumah Kantor": return <FAll fields={FieldsRumahKantor} />
    case "Space Kantor": return <FAll fields={FieldsSpaceKantor} />
    default: return null
  }
}

export default SpesifikasiProperty