import React, { useContext } from 'react'
import { Navigate } from "react-router-dom";

//Context
import { GlobalContext } from "../contexts";

function AuthRoute({ children }) {
  const { tokenValidation } = useContext(GlobalContext);
  
  if(!tokenValidation()) return <Navigate to="/login" />
  return children;
}

export default AuthRoute