// Constant
import { PAGE_VIEW } from "constant/firebaseEventParams"

const handlePageView = (pathname) => {
  const splittedPath = pathname.split("/");
  const splitIdx1 = splittedPath[1];

  let result = {
    ...PAGE_VIEW,
    page_path: pathname,
  };
  
  if (!splitIdx1) {
    result.page_title = "daftar-listing"
    return result;
  } 
  
  if (splitIdx1 === "upload-listing") {
    if (!!splittedPath[3]) {
      result.page_title = "edit-listing"
      result.page_slug = splittedPath[3]
    } else {
      result.page_title = splitIdx1
    }

    return result;
  }

  return result;
}

export default handlePageView