import React from 'react'

import "./style.scss"

function NavTab({setCurrentScreen, currentScreen}) {
  const activeClass = (screen) => {
    return screen == currentScreen ? 'active' : ''
  }
  return (
    <div id='kmod-navtab'>
      <div className='btn-wrapper'>
        <button onClick={() => setCurrentScreen('keseluruhan')} className={`left ${activeClass('keseluruhan')}`}><span>Statistik Data Keseluruhan</span></button>
        <button onClick={() => setCurrentScreen('kantor')} className={`right ${activeClass('kantor')}`}><span>Rekap Weekly Active Marketing</span></button>
      </div>
    </div>
  )
}

export default NavTab