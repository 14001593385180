import React, { useState, useRef } from 'react'

// Assets
import { ReactComponent as IconMagnifier } from "assets/magnifier.svg"

import useOutsideAlerter from 'hooks/useOutsideAlerter';

const CDropdown = ({
  list,
  label,
  required,
  name,
  selectedValue,
  onSelect,
  onSearch,
  disabled,
  ...props
}) => {
  const dropdownRef = useRef()
  const [search, setSearch] = useState("")
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleSelect = (item) => {
    onSelect(item.value)
    setSearch(item.label)
    handleCloseDropdown()
  }

  const handleOpenDropdown = () => {
    setOpenDropdown(true)
  }

  const handleCloseDropdown = () => {
    setOpenDropdown(false)
  }

  useOutsideAlerter(dropdownRef, handleCloseDropdown)
  return (
    <div className='py-2 flex items-center justify-between border-b border-solid border-[#D8D8D9]'>
      <label className='text-[13px] w-[126px] py-2'>{label}{required && <span className='text-red-0'>*</span>}</label>
      <div ref={dropdownRef} className='relative'>
        <div onClick={!disabled && handleOpenDropdown} className={`flex items-center justify-between w-[236px] rounded border border-solid border-[#D8D8D9] p-2 `}>
          <input
            value={search}
            name={name}
            className='w-[calc(100%-22px)] text-[13px] placeholder:text-[#D8D8D9]'
            readOnly
            disabled={disabled}
            {...props}
          />
          <IconMagnifier className='w-[14px] h-[14px]' />
        </div>

        {openDropdown && (
          <div className="absolute top-[-305px] h-[300px] overflow-y-auto w-[236px] bg-[#fff] shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] px-4">
            {!!list?.length && list.map((l, i) => {
              return (
                <div
                  onClick={() => handleSelect(l)}
                  key={`c-search-${i}`}
                  className="py-2 border-b-[0.5px] border-solid border-[#D8D8D9] last:border-b-0"
                >
                  <p className='text-[13px]'>{l?.label ?? ""}</p>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default CDropdown
