import React, { useEffect, useRef } from 'react'

function useScroll(dependency) {
  const topElement = useRef()

  useEffect(() => {
    topElement?.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [dependency])
  return {
    topElement
  }
}

export default useScroll