import React from 'react'

// Components
import CModal from 'components/v1/modal';
import Button from 'components/v1/Button';

// Assets
import { ReactComponent as IconX } from "assets/x-black.svg"

const ModalKonfirmasiNegative = ({ title, info, onSubmit, ...props }) => {

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded'>
        <div className='py-2 px-6 flex items-center justify-between border-b-[0.5px] border-solid border-[#D8D8D9]'>
          {title && <p className='text-[17px] font-semibold leading-[27.5px] tracking-[-0.4px] mr-2'>{title}</p>}
          <IconX onClick={props.onRequestClose} width="14px" height="14px" className='cursor-pointer' />
        </div>
        <div className='py-2 flex flex-col items-center justify-center px-6 rounded-t'>
          {info && <p className='text-[13px] text-[#808285] leading-[21px] tracking-[-0.4px] mb-2'>{info}</p>}
        </div>
        <div className='flex items-center justify-end pt-1 pb-4 px-6 border-t-[0.5px] border-solid border-[#D8D8D9] rounded-b'>
          <Button onClick={onSubmit} text="Hapus" className="mr-2" type="negative" />
          <Button onClick={props.onRequestClose} text="Kembali" type="secondary" />
        </div>
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalKonfirmasiNegative isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}