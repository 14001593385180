import React, { useState, useContext } from 'react'

//SVG
import { ReactComponent as DiscIcon } from "../../../../assets/disc.svg";

//Components
import { ButtonSvgIcon } from "../../../../components";
import { ModalSuccessUploadListing } from "../../components";
import IdentitasListing from "./IdentitasListing";
import SpesifikasiProperty from "./SpesifikasiProperty";
import UploadGambar from "./UploadGambar";
import Button from "components/v1/Button"
import LMSToggler from "./lms-toggler"

//Context
import { UploadListingContext } from "../../../../contexts"

import { useScroll } from "../../../../hooks"

// Assets
import ArrowIcon from "assets/arrow-right-white.png"

function ListingForms() {
  const {
    process,
    identitasListingValidation,
    saveIdentitasListing,
    savedIdentitasListing,
    spesifikasiPropertyValidation,
    saveSpesifikasiProperty,
    UploadListingHandler,
    successUploadListing,
    savedGambar,
    confirmSuccessSubmit,
    isMB
  } = useContext(UploadListingContext)

  const { topElement } = useScroll(process)


  switch (process) {
    case "identitas-listing": {
      return (
        <div ref={topElement} className='update-listing-forms-container'>
          <div className='form-container-outer'>
            <IdentitasListing />
          </div>

          {/* Render button */}
          <div className='identitas-listing-btn-wrapper'>
            <Button
              text={"Lanjutkan"}
              icon={ArrowIcon}
              disabled={!identitasListingValidation()}
              onClick={() => saveIdentitasListing()}
              iconPosition="right"
            />
          </div>
        </div>
      )
    }
    case "spesifikasi-property": {
      return (
        <div ref={topElement} className='update-listing-forms-container'>
          <div className='form-container-outer'>
            <SpesifikasiProperty />
          </div>

          {/* Render button */}
          <div className='identitas-listing-btn-wrapper'>
            <Button
              text="Lanjutkan"
              icon={ArrowIcon}
              disabled={!spesifikasiPropertyValidation()}
              onClick={() => saveSpesifikasiProperty()}
              iconPosition="right"
            />
          </div>
        </div>
      )
    }
    case "upload-gambar": {
      return (
        <div ref={topElement} className='update-listing-forms-container'>
          <div className='form-container-outer'>
            <UploadGambar />
          </div>

          {/* Render button */}
          <div className='identitas-listing-btn-wrapper'>
            <LMSToggler />
            <Button
              text="Lanjutkan"
              icon={ArrowIcon}
              disabled={savedGambar}
              onClick={UploadListingHandler}
              iconPosition="right"
            />
          </div>

          <ModalSuccessUploadListing open={successUploadListing} confirmAction={confirmSuccessSubmit} isMB={isMB} />
        </div>
      )
    }
    default: <div />
  }

  return <div />
}

export default ListingForms