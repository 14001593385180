import React, { useState } from 'react'

//Components
import ModalMap from './ModalMap'
import Button from "components/v1/Button"

//Assets
import MapIcon from "../../assets/map.png"

function MapButton({ action, className = "" }) {
  const [openMap, setOpenMap] = useState(false)

  return (
    <>
      <div>
        <Button
          onClick={() => {
            setOpenMap(!openMap)
          }}
          icon={MapIcon}
          iconPosition="right"
          text="Buka peta"
          className="text-nowrap w-[115px] ml-4"
        />
      </div>
      {openMap ? <ModalMap action={action} toggle={() => setOpenMap(!openMap)} /> : null}
    </>
  )
}

export default MapButton