import { useState } from 'react'
import { toast } from 'react-toastify';

// Assets
import { ReactComponent as IconInfo } from "assets/info-black.svg"

// Components
import Button from 'components/v1/Button';
import ModalEditCalonMa from 'pages/dashboard/training/components/modal-edit-ma';
import ModalSukses from "pages/dashboard/training/components/modal-sukses"
import ModalKonfirmasiNegative from "pages/dashboard/training/components/modal-konfirmasi-negative"
import ModalDaftarTraining from "pages/dashboard/training/components/modal-daftar-training"

// Api
import { useDeleteCalonMa, useRegisterParticipant } from 'api/training/mutation';

// Ctx
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const CardCalonMa = ({ calonMa }) => {
  const {
    listAllTrainingAndBatches,
    refetchAll,
    isFreeBatchOpen,
    isPaidBatchOpen,
  } = useTrainingCtx()

  const { full_name, company, id } = calonMa || {};

  const { mutate: mutateDeleteCalonMa } = useDeleteCalonMa()
  const { mutate: mutateRegisterParticipant } = useRegisterParticipant()

  // Modals
  const [isOpenModalEditCalonMa, setIsOpenModalEditCalonMa] = useState(false)
  const [isOpenModalSuksesEditCalonMa, setIsOpenModalSuksesEditCalonMa] = useState(false)
  const [isOpenModalKonfirmasiHapusCalonMa, setIsOpenModalKonfirmasiHapusCalonMa] = useState(false)
  const [isOpenModalSuksesHapusCalonMa, setIsOpenModalSuksesHapusCalonMa] = useState(false)
  const [isOpenModalRegisterCalonMa, setIsOpenModalRegisterCalonMa] = useState(false)

  // Modals Handler
  const handleOpenModalEditCalonMa = () => setIsOpenModalEditCalonMa(true)
  const handleCloseModalEditCalonMa = () => setIsOpenModalEditCalonMa(false)

  const handleOpenModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(true)
  const handleCloseModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(false)

  const handleOpenModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(true)
  const handleCloseModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(false)

  const handleOpenModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(true)
  const handleCloseModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(false)

  const handleOpenModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(true)
  const handleCloseModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(false)

  const handleDeleteCalonMa = () => {
    mutateDeleteCalonMa(id, {
      onSuccess: () => {
        handleCloseModalKonfirmasiHapusCalonMa()
        handleOpenModalSuksesHapusCalonMa()
        refetchAll()
        toast.success("Berhasil menghapus calon Marketing Associate")
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
  }

  const handleRegisterParticipant = (batchId) => {
    const payload = {
      id: batchId,
      data: {
        "training_batch_participant": {
          "user_id": calonMa?.id
        }
      }
    }
    mutateRegisterParticipant(payload, {
      onSuccess: () => {
        handleCloseModalRegisterCalonMa()
        refetchAll()
        toast.success("Berhasil mendaftarkan participant")
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
  }

  return (
    <div className='rounded border-[0.5px] border-solid border-[#D8D8D9] mb-4 last:mb-0'>
      <div className='px-4 py-2 '>
        <div className='flex items-center justify-between mb-2'>
          <p className='text-[13px] truncate mr-2'>{full_name}</p>
          <button onClick={handleOpenModalEditCalonMa} className='cursor-pointer w-[12px] h-[12px] object-contain '>
            <IconInfo />
          </button>
        </div>
        {company?.name ? <p className='text-[11px] text-[#808285]'>{company?.name}</p> : <p className='text-[11px] text-[#808285] line-through'>Belum ada kantor</p>}
      </div>

      {(isFreeBatchOpen || isPaidBatchOpen) && <div className='px-4 py-2 flex justify-end border-t-[0.5px] border-dashed border-[#D8D8D9]'>
        <Button onClick={handleOpenModalRegisterCalonMa} text="Daftarkan Training" />
      </div>}

      <ModalEditCalonMa
        isOpen={isOpenModalEditCalonMa}
        onRequestClose={handleCloseModalEditCalonMa}
        onSuccess={handleOpenModalSuksesEditCalonMa}
        onDelete={handleOpenModalKonfirmasiHapusCalonMa}
        userId={id}
      />
      <ModalSukses
        isOpen={isOpenModalSuksesEditCalonMa}
        onRequestClose={handleCloseModalSuksesEditCalonMa}
        title="Berhasil diubah"
        info="Informasi calon marketing telah diubah."
      />
      <ModalKonfirmasiNegative
        isOpen={isOpenModalKonfirmasiHapusCalonMa}
        onRequestClose={handleCloseModalKonfirmasiHapusCalonMa}
        title="Yakin hapus calon marketing ini?"
        info={`Informasi “${full_name}” akan dihapus. Perlu mendaftarkan ulang jika ingin memasukkan kembali ke list calon marketing.`}
        onSubmit={handleDeleteCalonMa}
      />
      <ModalDaftarTraining
        isOpen={isOpenModalRegisterCalonMa}
        onRequestClose={handleCloseModalRegisterCalonMa}
        onSubmit={handleRegisterParticipant}
        list={listAllTrainingAndBatches?.data?.data ?? []}
      />
      <ModalSukses
        isOpen={isOpenModalSuksesHapusCalonMa}
        onRequestClose={handleCloseModalSuksesHapusCalonMa}
        title="Calon marketing dihapus"
      />
    </div>
  )
}

export default CardCalonMa