export const getLocalstorage = (key) => {
  const data = localStorage.getItem(key)
  return !data ? null : JSON.parse(data);
}

export const setToLocalstorage = (key, value) => {
  try {
    if (!key || !value) return { success: false, error: true }

    localStorage.setItem(key, JSON.stringify(value))

    return { success: true, error: false }
  } catch (err) {
    if (!key || !value) return { success: false, error: true }
  }
}

export const removeLocalstorage = (key) => localStorage.removeItem(key)