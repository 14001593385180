import React from 'react';

//Context
import { ERProvider } from "pages/dashboard/e-reporting/ERCtx";

//Components
import ERMain from "pages/dashboard/e-reporting/ERMain";

const ERIndex = () => <ERProvider><ERMain /></ERProvider>

export default ERIndex