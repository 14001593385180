import React, {useState} from 'react'
import CurrencyInput from 'react-currency-input-field';

function CTextInputV3({
  label,
  name,
  placeholder,
  disabled,
  onChange,
  error,
  value,
  className,
  suffixPosition,
  suffixText,
  suffixIcon
}) {
  return (
    <div className={`c-textinput-v3 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className='suffix-wrapper'>
          {suffixIcon ? suffixIcon : <span>{suffixText}</span>}
        </div>

        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <CurrencyInput
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onValueChange={onChange}
            decimalSeparator=","
            groupSeparator="."
          />
        </div>

      </div>
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

CTextInputV3.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  disabled: false,
  onChange: () => { }
}

export default CTextInputV3