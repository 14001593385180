import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

function UCTextInputV2({
  label,
  name,
  placeholder,
  disabled,
  errors,
  className,
  suffixPosition,
  suffixText,
  suffixIcon,
  type,
  mandatoryLabel,
  register,
  optionalLabel
}) {
  return (
    <div className={`uc-textinput-v2 ${className}`}>
      {label ? <p className='label'>{label}</p> : null}
      {mandatoryLabel ? <span className='addon-label'>{"("}<span className='mandatory-label'>* tidak boleh kosong</span>{")"}</span> : null}
      {optionalLabel ? <span className='addon-label'>{"("}<span className='optional-label'>boleh kosong</span>{")"}</span> : null}
      <div className={`input-w-suffix ${suffixPosition}`}>
        <div className='suffix-wrapper'>
          {suffixIcon ? suffixIcon : <span>{suffixText}</span>}
        </div>

        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <input
            {...register}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
          />
        </div>

      </div>
      {errors ? <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-xs text-red-0'>{message}</p>}
      /> : ""}
    </div>
  )
}

UCTextInputV2.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  type: "text",
  disabled: false,
  mandatoryLabel: false,
  optionalLabel: false,
  onChange: () => { }
}

export default UCTextInputV2