import React, { useMemo } from 'react'

//Styles
import Styles from "./select-address.module.scss";

//Components
import SelectWithSearch from "pages/dashboard/e-reporting/forms/components/reuseables/select-with-search"
import TextLarge from 'pages/dashboard/e-reporting/forms/components/reuseables/text-large/TextLarge'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order/Order'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import useClassHandler from 'hooks/useClassHandler';

function SelectAddress({ disabled, data, selectedData, setSelectedData, onNext, order, question, placeholder, required, showButton }) {
  const classHandler = useClassHandler(Styles)

  const mappedData = useMemo(() => {
    if (!data?.length) return [];
    return data.map(datum => {
      return {
        value: datum.id,
        label: datum.name,
        ...datum
      }
    })
  }, [data])

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['box']}>
        <div className={Styles['title']}>
          <TextLarge required={required}>
            {question}
          </TextLarge>
        </div>
        <SelectWithSearch selectedData={selectedData} disabled={disabled} onClick={setSelectedData} options={mappedData} placeholder={placeholder} className={classHandler("margin-left-50")} />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {(selectedData.id !== null) ? <ButtonSelanjutnya onClick={onNext} className={classHandler("margin-left-50")} /> : null}
          </>
        </BoxFlex>
      </div>
    </div>
  )
}

export default SelectAddress