import React from "react";

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";
import { useERState } from "pages/dashboard/e-reporting/ERCtx";

//Components - reuseables
import BinaryQuestion from "pages/dashboard/e-reporting/forms/components/reuseables/binary-question";

//Utils
import { handleAgentRole } from "../../handlePayload"

function IsPemilikBadanUsaha() {
  const { form, disabledAll } = useFormState();
  const { setCurrentStep, formHandler, calculateCommision, handlePrevState } = useFormMethod();
  const { userLoginAuth } = useERState()

  //Functions
  const handleNextStep = (val) => {
    formHandler('is_pemilik_badan_usaha', val)

    if (handleAgentRole(form, userLoginAuth) === 'S1B0' || handleAgentRole(form, userLoginAuth) === 'S1XX') {
      calculateCommision()
    } else {
      handlePrevState()
      setCurrentStep(STEPS.entryDataPembeli);
    }
  }

  return (
    <BinaryQuestion
      order="h."
      orderBg="none"
      title="Apakah pemilik properti merupakan badan usaha?"
      onNext={handleNextStep}
      value={form.is_pemilik_badan_usaha.value}
      disabledAll={disabledAll}
    />
  )
}

const withIsPemilikBadanUsaha = (Component) => {
  return () => {
    const notRender = true
    const { form } = useFormState();
    const { setCurrentStep } = useFormMethod();

    const handleNextStep = () => {
      if (form.is_pemilik_badan_usaha.value === 'true') {
        setCurrentStep(STEPS.pilihProvinsiBadanUsaha)
      } else {
        setCurrentStep(STEPS.entryDataPembeli)
      }
    }

    React.useEffect(() => {
      handleNextStep()
    }, [form.is_pemilik_badan_usaha.value])

    if (notRender) return null
    return (
      <Component />
    )
  }
}

export default withIsPemilikBadanUsaha(IsPemilikBadanUsaha)