import React, { useEffect } from 'react'

function ModalV2({ onClose, children, className, id }) {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, []);

  return (
    <div id={id} className={`fixed w-screen h-screen top-0 left-0 
      bg-black/[.4] z-40 flex items-center justify-center 
      ${className}`}>

      <div className='z-[41] h-full w-full absolute' onClick={onClose && onClose} />

      {children}
    </div>
  )
}

function Wrapper({ isOpen, children, ...props }) {
  if (!isOpen) return null;
  return <ModalV2 {...props}>{children}</ModalV2>
}

export default Wrapper
