import React from 'react';

// Component
import CModal from '../organism/CModal';
import { Divider } from "../atomic";
import Button from "components/v1/Button";

// Assets
import { ReactComponent as CrossIcon } from "../../assets/cross.svg"

function ModalConfirmationV1({ Image, title, info, confirmText, confirmAction, declineText, declineAction, className = "", onClose }) {
  return (
    <CModal id='confirmation-modal-v1' className={className}>
      <div className='modal-content'>
        <CrossIcon onClick={onClose && onClose} className='close-icon' />

        <div className='modal-body'>
          {Image ? <Image className="header-image" /> : null}
          <p className='title'>{title}</p>
          <p className='info'>{info}</p>
        </div>

        <Divider />

        <div className='modal-footer'>
          <Button onClick={confirmAction} text={confirmText} type="secondary" className="w-full mr-4 !justify-center" />
          <Button onClick={declineAction} text={declineText} className="w-full !justify-center" />
        </div>
      </div>
    </CModal>
  )
}

export default ModalConfirmationV1