import { useEffect } from "react";
//Styles
import "./is-co-listing-pembeli.module.scss";

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

//Components - reuseables
import BinaryQuestion from "pages/dashboard/e-reporting/forms/components/reuseables/binary-question";

function IsCoListingPembeli() {
  const { form, isAprroval, isEditing, userLoginAuth } = useFormState();

  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();

  //Functions
  const handleNextStep = (val) => {
    handlePrevState()
    formHandler('is_co_listing_pembeli', val)
    if (val == 'tidak') {
      setCurrentStep(STEPS.biayaReferralPembeli);
    } else if (val == 'ya') {
      setCurrentStep(STEPS.pilihAsistenPembeli);
    }
  }

  useEffect(() => {
    const kantorPembeli = form.kantor_marketing_pembeli.value.id;

    if(kantorPembeli != userLoginAuth.company.id) {
      formHandler('is_co_listing_pembeli', 'tidak')
      setCurrentStep(STEPS.biayaReferralPembeli);
    }
  }, [])

  return (
    <BinaryQuestion
      order="i."
      orderBg="none"
      title="Marketing"
      question="Apakah ada co-selling?"
      onNext={handleNextStep}
      value={form.is_co_listing_pembeli.value}
      disabledAll={isAprroval || isEditing}
    />
  )
}

export default IsCoListingPembeli