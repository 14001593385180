import React from 'react'

import "./style.scss";

//Component
import ListListing from '../ListListing/ListListing'
import DetailListing from '../DetailListing/DetailListing'
import ImageModal from '../ImageModal'

//Context
import { useDetailListingCtx, DetailListingProvider } from "../Context"

function ContentRenderer() {
  const { isOpenModalImages } = useDetailListingCtx()
  return (
    <div id="list-listing-wrapper">
      <ListListing />
      <DetailListing />
      {isOpenModalImages && <ImageModal />}
    </div>
  )
}

const Wrapper = () => <DetailListingProvider><ContentRenderer /></DetailListingProvider>
export default Wrapper
