import React from 'react'
import "./style.scss"

function Deskripsi({ description }) {
  return (
    <div className='properti-detail-deskripsi'>
      <p className='detail-deskripsi-header'>Deskripsi</p>
      <p className='detail-deskripsi-content'>
        {description}
      </p>
      <p className='detail-descripsi-selanjutnya'>Selengkapnya</p>
    </div>
  )
}

export default Deskripsi