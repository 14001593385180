import React from 'react'

//Styles
import Styles from './input-text.module.scss'

function InputText({ Icon, ...props }) {
  return (
    <div className={Styles['wrapper']}>
      {Icon && <Icon className={Styles['icon']} />}
      {props.type === "tel" && <span className={Styles["suffix"]}>+62</span>}
      <input {...props} />
    </div>
  )
}

export default InputText