import { useState } from 'react'
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

// Components
import ModalWrapper from './ModalWrapper'
import Divider from './Divider'

// Assets
import { ReactComponent as Calendar } from "assets/calendar-grey.svg";

const ModalAddInformation = ({
  repportDate,
  setRepportDate,
  receivedAmount,
  setReceivedAmount,
  onNextModal,
  selectedTrx,
  idTrx,
  ...props
}) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)

  const handleCloseDatePicker = () => setIsOpenDatePicker(false)
  const handleOpenDatePicker = () => false && setIsOpenDatePicker(true)
  const handleSelectRepportDate = (selectedDate) => {
    setRepportDate(dayjs(selectedDate))
    handleCloseDatePicker()
  }

  return (
    <ModalWrapper {...props} className="z-43">
      <div>
        <p className='text-lg font-semibold font-inter mb-2'>Tambah informasi baru komisi yang diterima</p>
        <p className='text-sm font-inter text-gray-2'>Masukkan besaran yang baru diterima marketing terhadap transaksi dengan ID Transaksi <span className='font-medium text-black-0'>#{idTrx ?? ""}</span>.</p>
        <Divider />
        <div className='flex justify-between'>
          <div className='w-[98px]'>
            <p className='text-sm font-inter mb-2 font-semibold'>Transaksi</p>
            <p className='text-[10px] font-inter mb-2 font-semibold text-red-0'>* tidak boleh kosong</p>
          </div>
          <div className='w-[262px] mb-10'>
            <p className='text-xs text-gray-2 font-semibold mb-2'>Tanggal Lapor</p>
            <div className='relative bg-white-4 rounded-md border-[1px] border-solid border-black-3 
              mb-8 flex items-center justify-between py-2.5 px-4 text-gray-0'>
              <input onClick={handleOpenDatePicker} value={repportDate.format("DD/MM/YYYY")} readOnly className='bg-white-4 text-sm font-inter text-black-0' />
              <Calendar onClick={handleOpenDatePicker} className='w-[18px] h-[18px]' />
              {isOpenDatePicker && <DatePicker
                selected={repportDate.toDate()}
                onChange={(dates) => handleSelectRepportDate(dates)}
                startDate={repportDate}
                calendarClassName='!absolute !top-[60px] !left-0'
                className='absolute top-[60px] left-0'
                inline
              />}
            </div>

            <p className='text-xs text-gray-2 font-semibold mb-2'>Besaran yang sudah diterima</p>
            <div className='bg-white-4 rounded-md flex items-center justify-between'>
              <span className='border-[1px] h-[40px] border-solid border-black-3 rounded-l-md py-2.5 px-4 text-sm bg-black-3'>Rp</span>
              <CurrencyInput
                value={receivedAmount ?? ""}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(amount) => setReceivedAmount(amount)}
                allowDecimals={false}
                allowNegativeValue={false}
                placeholder='0'
                className='w-[calc(100%-15px)] border-[1px] border-solid border-black-3 border-l-[none] h-[40px] bg-white-4 py-2.5 px-4 rounded-r-md'
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className='grid grid-cols-2 gap-x-[24px]'>
          <button onClick={props.onClose} className='w-full flex items-center justify-center p-2.5 rounded-md cursor-pointer 
            border-[1px] border-solid border-black-3 box-shadow-2 text-sm font-medium'>
            Batal
          </button>
          <button onClick={onNextModal} className='w-full bg-black-0 flex items-center justify-center py-2.5 px-4 rounded-md cursor-pointer
            border-[1px] border-solid border-black-0 box-shadow-2 text-sm font-medium text-white'>
            Tambah komisi diterima
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalAddInformation