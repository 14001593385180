import React, { useMemo, useState } from 'react'

//Styles
import Styles from './nilai-transaksi-sewa.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function NilaiTransaksiSewa() {
  const { setCurrentStep, setForm, handlePrevState } = useFormMethod();
  const { form } = useFormState()

  //State
  const [nilaiTransaksi, setNilaiTransaksi] = useState(form.nilai_transaksi.value ?? '')

  const onNext = () => {
    handlePrevState()

    let newForm = { ...form }

    newForm.nilai_transaksi.value = nilaiTransaksi

    setForm(newForm)

    setCurrentStep(STEPS.totalKomisiMarketing)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['input-wrapper']}>
        <TextMedium>
          Berapa total nilai transaksi ini? <span className={Styles['required']}>*</span> <span className={Styles["shadow"]}>(Rp)</span>
        </TextMedium>

        <InputCurrency
          disabled={false}
          onValueChange={(val) => setNilaiTransaksi(val)}
          value={nilaiTransaksi || ''}
          placeholder="Tulis nilai transaksi"
          decimalSeparator="."
          groupSeparator=","
        />
      </div>

      <BoxFlex>
        <>
          <ButtonSebelumnya />
          {!!nilaiTransaksi && <ButtonSelanjutnya onClick={onNext} />}
        </>
      </BoxFlex>
    </WrapperAndBox>
  )
}

export default NilaiTransaksiSewa