import { useState, useEffect, useMemo } from 'react'
import lodash from 'lodash'

//Actions
import { 
  getListingKantor
 } from "actions";

const LIMIT = 9

function useFetch(id) {
  const _id = useMemo(() => id, [id])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [valueSearch, setValueSearch] = useState('')
  
  const fetchData = async (search) => {
    try {
      setLoading(true)
      let query = `?page=${currentPage}&_limit=${LIMIT}&user_id=${_id}`;

      if(!!search) {
        query += `&keywords=${search}`
      }

      const list = await getListingKantor(query)

      const totalItem = list?.headers?.["x-total"];
      const newData = list?.data?.data ?? []

      const totalPage = lodash.ceil(totalItem / LIMIT) || 0;
      setTotalPage(totalPage)
      setData(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(valueSearch)
  }, [currentPage])

  return {
    data,
    loading,
    totalPage,
    currentPage,
    valueSearch, 
    setCurrentPage,
    fetchData,
    setValueSearch
  }
}

export default useFetch