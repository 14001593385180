import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

import InfoIcon from "../../../assets/info.png"

function UCTextareaInputV1({
  label,
  name,
  placeholder,
  disabled,
  errors,
  info,
  className,
  register
}) {
  return (
    <div className={`uc-textarea-v1 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`textarea-wrapper ${disabled ? "disabled" : ""}`}>
        <textarea
          {...register}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {info ? <div className='print-info'><img className='info-icon' src={InfoIcon} /><span>{info}</span></div> : null}
      {errors ? <ErrorMessage
        errors={errors}
        name="gender"
        render={({ message }) => <p className='text-xs text-red-0'>{message}</p>}
      /> : ""}
    </div>
  )
}

UCTextareaInputV1.defaultProps = {
  label: "",
  error: "",
  name: "",
  placeholder: "",
  disabled: false,
  onChange: () => { }
}

export default UCTextareaInputV1