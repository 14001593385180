import React from 'react'

//Styles
import Styles from './wrapper-and-box.module.scss'

function WrapperAndBox({ children }) {
  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['box']}>{children}</div>
    </div>
  )
}

export default WrapperAndBox