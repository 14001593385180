import { useMemo, useState } from 'react'

//Styles
import Styles from './pilih-tipe-listing.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import SelectWithSearch from 'pages/dashboard/e-reporting/forms/components/reuseables/select-with-search/SelectWithSearch'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihTipeListing() {
  const options = useMemo(() => {
    return [
      {
        id: 1,
        name: "Jual"
      },
      {
        id: 2,
        name: "Sewa"
      }
    ]
  })
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form } = useFormState()

  //State
  const [selectedData, setSelectedData] = useState(
    form.selected_listing?.value?.property_type ? {
      ...form.selected_listing.value.property_type,
      value: form.selected_listing.value.property_type.id,
      label: form.selected_listing.value.property_type.name
    } : { id: null, val: '' })

  const mappedData = useMemo(() => {
    if (!options?.length) return [];
    return options.map(datum => {
      return {
        value: datum.id,
        label: datum.name,
        ...datum
      }
    })
  }, [options])

  const onNext = () => {
    handlePrevState()
    formHandler('selected_listing', { 
      ...selectedData, 
      property_type: { 
        id: selectedData.value, 
        name: selectedData.label 
      } 
    })
    if(selectedData.value === 1 || selectedData.value === "1"){
      setCurrentStep(STEPS.klasifikasiProperty)
    } else {
      setCurrentStep(STEPS.tanggalTransaksi)
    }
  }
  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Pilih tipe listing
        </TextMedium>
      </div>
      <div className={Styles['select-wrapper']}>
        <SelectWithSearch selectedData={selectedData} disabled={disabledAll} onClick={setSelectedData} options={mappedData} placeholder="Pilih tipe listing" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {!!selectedData.id && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default PilihTipeListing