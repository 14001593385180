import { useState, useEffect } from 'react'
import dayjs from 'dayjs';
import lodash, { result } from "lodash"

//Styles
import "./style.scss"

//API
import { getGetAllUsers, getRefreshSyncKeymetric} from "actions/quartery-reporting";

//Components
import InformasiYangDitampilkan from "pages/dashboard/key-metric-office-dashboard/reuseable-component/informasi-yang-ditampilkan"
import Table from "./Table";
import Calendar from "pages/dashboard/key-metric-office-dashboard/reuseable-component/calendar"
import Loader from 'components/molecules/Loader'
import AuthAction from "actions/authAction";

function DataKantor() {
  const [auth, setAuth] = useState(null);
  const [numberOfRange, setNumberOfRange] = useState(1);
  const [periodRange, setPeriodRange] = useState({
    label: 'Minggu lalu',
    value: 'weeks'
  });

  const [lowest, setLowest] = useState(null)
  const [highest, setHighest] = useState(null)
  const [total, setTotal] = useState(null)

  const [rekap, setRekap] = useState({
    loading: false,
    data: []
  })
  const [filter, setFilter] = useState({
    filter: '',
    type: 'asc'
  })

  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [currentDate, setCurrentDate] = useState({
    from: "",
    to: ""
  })

  //Functions
  const handleLowestHighest = (data) => {
    let _lowest = {
      total_upload: lodash.minBy(data, "total_upload")?.total_upload,
      total_share: lodash.minBy(data, "total_share")?.total_share,
      search: null,
      property: null,
      daily_active_agent_total: lodash.minBy(data, "daily_active_agent.total")?.daily_active_agent,
      daily_active_agent_percent: lodash.minBy(data, "daily_active_agent.percent")?.daily_active_agent,
      weekly_active_agent_total: lodash.minBy(data, "weekly_active_agent.total")?.weekly_active_agent,
      weekly_active_agent_percent: lodash.minBy(data, "weekly_active_agent.percent")?.weekly_active_agent,
    }
    let _highest = {
      total_upload: lodash.maxBy(data, "total_upload")?.total_upload,
      total_share: lodash.maxBy(data, "total_share")?.total_share,
      search: null,
      property: null,
      daily_active_agent_total: lodash.maxBy(data, "daily_active_agent.total")?.daily_active_agent,
      daily_active_agent_percent: lodash.maxBy(data, "daily_active_agent.percent")?.daily_active_agent,
      weekly_active_agent_total: lodash.maxBy(data, "weekly_active_agent.total")?.weekly_active_agent,
      weekly_active_agent_percent: lodash.maxBy(data, "weekly_active_agent.percent")?.weekly_active_agent
    }
    let _total = {
      total_upload: lodash.sumBy(data, "total_upload"),
      total_share: lodash.sumBy(data, "total_share"),
      search: null,
      property: null,
      daily_active_agent: {
        total: lodash.sumBy(data, "daily_active_agent.total"),
        percent: lodash.sumBy(data, "daily_active_agent.percent"),
      },
      weekly_active_agent: {
        total: lodash.sumBy(data, "weekly_active_agent.total"),
        percent: lodash.sumBy(data, "weekly_active_agent.percent"),
      }
    }

    if (!data?.length) return {
      _lowest,
      _highest,
      _total
    };

    return {
      _lowest,
      _highest,
      _total
    };
  }

  const getRekapData = async (start_date, end_date) => {
    try {
      setRekap(val => ({ ...val, loading: true, error: null }))
      const params = `?date[from]=${start_date}&date[to]=${end_date}`
      const _detail = await getGetAllUsers(params)

      const data = _detail?.data ?? null
      setRekap(val => ({ ...val, data }))

      const { _lowest, _highest, _total } = handleLowestHighest(data);
      setLowest(_lowest)
      setHighest(_highest)
      setTotal(_total)
    } catch (error) {
      setRekap(val => ({ ...val, error: "Failed to get data" }))
      console.log(error)
    } finally {
      setRekap(val => ({ ...val, loading: false }))
    }
  }

  const getRekapDataRefresh = async () => {
    try {
      // const month = result(downloadForm, 'month.id', '');
      const month = dayjs(currentDate.to).format("M");
      const year = dayjs(currentDate.to).format("YYYY");
      const authCompany = result(auth, 'company.id', '');
      // const nextParam = `/trx.xlsx?year=${year}&month=${month}&company_id=${authCompany}`
      setRekap(val => ({ ...val, loading: true, error: null }))
      const paramsRefresh = `/sync-keymetric?company_id=${authCompany}&year=${year}&month=${month}`
      const _detailRefresh = await getRefreshSyncKeymetric(paramsRefresh)
      if(result(_detailRefresh, 'response.status', '') === 200){
        const params = `?date[from]=${currentDate.from}&date[to]=${currentDate.to}`
        const _detail = await getGetAllUsers(params)
        const data = _detail?.data ?? null
        setRekap(val => ({ ...val, data }))

        const { _lowest, _highest, _total } = handleLowestHighest(data);
        setLowest(_lowest)
        setHighest(_highest)
        setTotal(_total)
      }
    } catch (error) {
      setRekap(val => ({ ...val, error: "Failed to get data" }))
      console.log(error)
    } finally {
      setRekap(val => ({ ...val, loading: false }))
    }
  }
  const filterSetter = (name) => {
    const type = filter.filter === name ? filter.type === 'asc' ? 'desc' : 'asc' : 'asc';
    setFilter({ filter: name, type })
  }

  const handleSearch = () => {
    const { filter: __filter, type } = filter;
    let data = [...rekap.data]

    switch (__filter) {
      case "upload": {
        data = lodash.orderBy(data, "total_upload", type)
        break;
      }
      case "share": {
        data = lodash.orderBy(data, "total_share", type)
        break;
      }
      case 'search': {
        data = lodash.orderBy(data, "search", type)
        break;
      }
      case 'property': {
        data = lodash.orderBy(data, "property", type)
        break;
      }
      case 'active': {
        if (type === 'asc') {
          let __data = data.sort(function (obj1, obj2) {
            var sum1 = obj1.total_upload + obj1.total_share;
            var sum2 = obj2.total_upload + obj2.total_share;
            return sum1 - sum2;
          });
          data = __data
        } else {
          let __data = data.sort(function (obj1, obj2) {
            var sum1 = obj1.total_upload + obj1.total_share;
            var sum2 = obj2.total_upload + obj2.total_share;
            return sum2 - sum1;
          });
          data = __data
        }
        break;
      }
      default: break;
    }

    setRekap(val => ({ ...val, data }))
    const { _lowest, _highest, _total } = handleLowestHighest(data);
    setLowest(_lowest)
    setHighest(_highest)
    setTotal(_total)
  }
  const getUserProfile = async () => {
    try {

      const list = await AuthAction.getUserDetail()
      const newData = list?.data ?? {}

      setAuth(newData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (filter.filter) {
      handleSearch()
    }
    getUserProfile();
  }, [filter])

  useEffect(() => {
    setCurrentDate({
      from: dayjs(selectedDate).subtract(numberOfRange || 1, periodRange.value).add(1, 'day').format("YYYY-MM-DD"),
      to: dayjs(selectedDate).format("YYYY-MM-DD")
    })
  }, [numberOfRange, periodRange, selectedDate])

  useEffect(() => {
    if (currentDate.from && currentDate.to) {
      getRekapData(currentDate.from, currentDate.to)
    }
  }, [currentDate])

  return (
    <div id="kmod-data-kantor">
      <div className='info-container'>
        <Calendar
          selectedDate={selectedDate.toDate()}
          currentDate={currentDate}
          setDate={(date) => setSelectedDate(dayjs(date))}
        />

        <InformasiYangDitampilkan
          periodRange={periodRange}
          setPeriodRange={setPeriodRange}
          numberOfRange={numberOfRange}
          setNumberOfRange={setNumberOfRange}
          hideIcon
        />
      </div>

      <div className='kmod-data-kantor-table'>
        {
          rekap.loading ? <div className='loader-wrapper'>
            <Loader />
          </div> : (

            <Table
              lowest={lowest}
              highest={highest}
              total={total}
              rekap={rekap}
              filterSetter={filterSetter}
              numberOfRange={numberOfRange}
              getRekapDataRefresh={getRekapDataRefresh}
            />
          )
        }
      </div>
    </div>
  )
}

export default DataKantor