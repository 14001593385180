import React from 'react'

const ContentBody = ({ children }) => {
  return (
    <div className='bg-[#D8D8D9] p-6 min-h-[calc(100vh-36px-81px)]'>
      {children}
    </div>
  )
}

export default ContentBody