import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs';

import Styles from './new-approval-page.module.scss'

// Assets
import { ReactComponent as ArrowDown } from "assets/arrow-bottom.svg";
import { ReactComponent as ArrowUp } from "assets/arrow-up.svg";

// Context
import { useFormState, useFormMethod, STEPS } from "pages/dashboard/e-reporting/forms/context";

//Helpers
import formatRupiah from "utils/toRupiah";

const SECTION = {
  1: "Jika 2 marketing berasal dari Century 21 + Refferal + Metode transaksi cash",
  2: "Jika 3 marketing berasal dari Century 21 + Refferal pembeli",
  3: "Jika 4 marketing berasal dari Century 21",
  4: "Jika 2 marketing berasal dari Century 21 + 1 marketing non-Century + Refferal penjual",
  5: "Jika 1 marketing berasal dari Century 21 sebagai penjual dan pembeli",
}

const FieldConent = ({ title, content, mr }) => {
  return (
    <div className={`${Styles['field-content']} ${Styles[`margin-right-${mr}`]}`}>
      <p className={Styles['title']}>{title}</p>
      <p className={Styles['konten']}>{content}</p>
    </div>
  )
}

const MetodeTransaksi = ({ tanggal, metode, bank }) => {
  return (
    <div className={Styles['wrapper-metode']}>
      <div className={Styles['metode-transaksi']}>
        <div className={Styles['section']}>
          <p className={Styles['title']}>TANGGAL TRANSAKSI</p>
          <p className={Styles['konten']}>{tanggal}</p>
        </div>
        {!!metode && <div className={`${Styles['section']} ${Styles['middle']}`}>
          <p className={Styles['title']}>METODE TRANSAKSI</p>
          <p className={Styles['konten']}>{metode}</p>
        </div>}
        {!!(!!metode && metode !== "CASH") && <div className={Styles['section']}>
          <p className={Styles['title']}>BANK</p>
          <p className={Styles['konten']}>{bank}</p>
        </div>}
      </div>
    </div>
  )
}

const JumlahMarketing = ({
  jumlah,
  nilai,
  gcc,
  royalti,
  commisionAmount,
}) => {

  return (
    <div className={Styles['wrapper-jumlah-marketing']}>
      <div className={Styles['section']}>
        <p className={Styles['title']}>JUMLAH MARKETING</p>
        <p className={Styles['konten']}>{jumlah}</p>
      </div>
      <div className={Styles['section']}>
        <p className={Styles['title']}>NILAI TRANSAKSI</p>
        <p className={Styles['konten']}><span>Rp</span>{formatRupiah(nilai)}</p>
      </div>
      <div className={Styles['section']}>
        <p className={Styles['title']}>TOTAL KOMISI KANTOR ANDA</p>
        <p className={Styles['konten']}><span>Rp</span>{formatRupiah(commisionAmount)}</p>
      </div>
      <div className={Styles['section']}>
        <p className={Styles['title']}>GROSS CLOSED COMMISSION</p>
        <p className={Styles['konten']}><span>Rp</span>{gcc}</p>
      </div>
      {/* <div className={Styles['section']}>
        <p className={Styles['title']}>ROYALTI YANG HARUS DIBAYARKAN</p>
        <p className={Styles['konten']}><span>Rp</span>{royalti}</p>
      </div> */}
    </div>
  )
}

const MarketingUtama = ({ type, company, agent, personal_commission, isRent }) => {
  return (
    <div className={`${Styles['marketing']} ${Styles[`marketing-${type}`]}`}>
      <span className={Styles['label']}>{type === "penjual" ? "JUAL" : "BELI"}</span>
      <p className={Styles['title']}>Marketing {type === "penjual" ? "Penjual" : isRent ? "Penyewa" : "Pembeli"}</p>

      <div className={Styles['name-and-commision']}>
        <div className={Styles['section']}>
          <p className={Styles['title']}>CENTURY 21 {company}</p>
          <p className={Styles['konten']}>{agent}</p>
        </div>

        <div className={Styles['section']}>
          <p className={Styles['title']}>PERSONAL COMMISSION</p>
          <p className={Styles['konten']}>Rp{personal_commission}</p>
        </div>
      </div>
    </div>
  )
}

const MarketingUtamaNonCentury = ({ type, company, agent, isRent }) => {
  return (
    <div className={`${Styles['marketing-non-century']} ${Styles[`marketing-${type}-non-century`]}`}>
      <span className={Styles['label']}>{type === "penjual" ? "JUAL" : "BELI"}</span>
      <p className={Styles['title']}>Marketing {type === "penjual" ? "Penjual" : isRent ? "Penyewa" : "Pembeli"}</p>

      <div className={Styles['name-and-commision']}>
        <div className={Styles['section']}>
          <p className={Styles['title']}>NAMA KANTOR</p>
          <p className={Styles['konten']}>{company}</p>
        </div>

        <div className={Styles['section']}>
          <p className={Styles['title']}>NAMA MARKETING</p>
          <p className={Styles['konten']}>{agent}</p>
        </div>
      </div>
    </div>
  )
}

const MarketingAssistant = ({ type, company, agent, personal_commission, isHidden, isRent }) => {
  return (
    <div className={`${Styles['marketing']} ${Styles[`marketing-${type}`]} ${isHidden && "hidden"}`}>
      <span className={Styles['label']}>{type === "penjual" ? "CO-LISTING" : "CO-SELLING"}</span>
      <p className={Styles['title']}>Marketing {type === "penjual" ? "Penjual" : isRent ? "Penyewa" : "Pembeli"}</p>

      <div className={Styles['name-and-commision']}>
        <div className={Styles['section']}>
          <p className={Styles['title']}>CENTURY 21 {company}</p>
          <p className={Styles['konten']}>{agent}</p>
        </div>

        <div className={Styles['section']}>
          <p className={Styles['title']}>PERSONAL COMMISSION</p>
          <p className={Styles['konten']}>Rp{personal_commission}</p>
        </div>
      </div>
    </div>
  )
}

const NonMarketing = ({ type, persentase, nominal, isRent }) => {
  return (
    <div className={`${Styles['non-marketing']} ${Styles[`non-marketing-${type}`]}`}>
      <span className={Styles['label']}>NON-MARKETING</span>
      <p className={Styles['title']}>Refferal {type === "penjual" ? "Penjual" : isRent ? "Penyewa" : "Pembeli"}</p>

      <div className={Styles['name-and-commision']}>
        <div className={Styles['section']}>
          <p className={Styles['title']}>BIAYA REFFERAL</p>
          <p className={Styles['konten']}>Rp.{persentase}</p>
        </div>

        {/* <div className={Styles['section']}>
          <p className={Styles['title']}>NOMINAL</p>
          <p className={Styles['konten']}>Rp{nominal}</p>
        </div> */}
      </div>
    </div>
  )
}

const DetailProperty = ({
  image,
  type,
  address,
  title,
  category,
  status,
  id
}) => {
  return (
    <div className={`${Styles['box-between']} ${Styles['box-detail-property']}`}>
      <img src={image} alt="detail-property" className={Styles['image']} />

      <div className={Styles['detail-property']}>
        <div className={Styles['labels']}>
          <span className={`${Styles['label']} ${Styles[type]}`}>{type === "penjual" ? "JUAL" : "BELI"}</span>
          <span className={Styles['label']}>{category}</span>
          <span className={Styles['label']}>{status}</span>
          <span className={Styles['label']}>#{id}</span>
        </div>

        <p className={Styles['title']}>{title}</p>

        <span className={Styles['title-address']}>LOKASI PROPERTI</span>
        <p className={Styles['address']}>{address}</p>
      </div>
    </div>
  )
}

const SpecProperty = ({
  kamarTidur,
  kamarMandi,
  lantai,
  luasTanah,
  lebarTanah,
  panjangTanah,
  panjangBangunan,
  lebarBangunan,
  luasBangunan
}) => {
  return (
    <div className={`${Styles['box-between']} ${Styles['box-spec-property']}`}>
      <div className={Styles['section']}>
        <p className={Styles["section-header"]}>Spesifikasi Properti</p>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="KAMAR TIDUR"
            content={kamarTidur}
            mr="48"
          />
          <FieldConent
            title="KAMAR MANDI"
            content={kamarMandi}
            mr="48"
          />
          <FieldConent
            title="JUMLAH LANTAI"
            content={lantai}
          />
        </div>
      </div>
      <div className={Styles['section']}>
        <p className={Styles["section-header"]}>Ukuran Properti</p>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="LUAS TANAH"
            content={luasTanah}
            mr="48"
          />
          <FieldConent
            title="P. TANAH"
            content={panjangTanah}
            mr="48"
          />
          <FieldConent
            title="L. TANAH"
            content={lebarTanah}
          />
        </div>
        <div className={Styles['divider']}></div>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="LUAS BANGUNAN"
            content={luasBangunan}
            mr="48"
          />
          <FieldConent
            title="P. BANGUNAN"
            content={panjangBangunan}
            mr="48"
          />
          <FieldConent
            title="L. BANGUNAN"
            content={lebarBangunan}
          />
        </div>
      </div>
    </div>
  )
}

const DetailPenjualPembeli = ({
  isPenjual,
  isBadanUsaha,
  nama,
  telepon,
  email,
  lokasi,
  alamat
}) => {
  return (
    <div className={`${Styles['box-between']} ${Styles['box-penjual-pembeli']}`}>
    </div>
  )
}

const DataPenjualPembeli = ({
  isPenjual = "-",
  isBadanUsaha = "-",
  nama = "-",
  telepon = "-",
  email = "-",
  lokasi = "-",
  alamat = "-",
  isRent
}) => {
  return (
    <div className={`${Styles['box-data-penjual-pembeli']}`}>
      <div className={Styles['section']}>
        <p className={Styles["section-title"]}>{isPenjual ? "PENJUAL" : isRent ? "PENYEWA" : "PEMBELI"} {!!(isPenjual && isBadanUsaha) && <span>BADAN USAHA</span>}</p>
        <p className={Styles["section-header"]}>Identitas</p>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="NAMA"
            content={nama}
            mr="48"
          />
          <FieldConent
            title="NOMOR TELEPON"
            content={telepon}
          />
        </div>
        <div className={Styles['divider']}></div>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="ALAMAT EMAIL"
            content={email}
          />
        </div>
      </div>
      <div className={Styles['section']}>
        <p className={Styles["section-title"]}>{isPenjual ? "PENJUAL" : isRent ? "PENYEWA" : "PEMBELI"} </p>
        <p className={Styles["section-header"]}>Domisili</p>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="AREA/KOTA/PROVINSI"
            content={lokasi}
          />
        </div>
        <div className={Styles['divider']}></div>
        <div className={Styles['section-detail']}>
          <FieldConent
            title="ALAMAT LENGKAP"
            content={alamat}
          />
        </div>
      </div>
    </div>
  )
}

const InformasiPenjualDanPembeli = ({
  dataPenjual,
  dataPembeli,
  isRenderPenjual,
  isRenderPembeli,
  isRent
}) => {

  return (
    <div className={`${Styles['box-informasi-penjual-pembeli']}`}>
      <div className={`${Styles['wrapper-title']}`}>
        {
          (isRenderPenjual && isRenderPembeli) ? (
            <p className={Styles['title']}>Informasi Penjual dan {isRent ? "Penyewa" : "Pembeli"} Properti</p>
          ) : isRenderPenjual ? (
            <p className={Styles['title']}>Informasi Penjual Properti</p>
          ) : isRenderPembeli ? (
            <p className={Styles['title']}>Informasi {isRent ? "Penyewa" : "Pembeli"} Properti</p>
          ) : null
        }
      </div>

      {!!isRenderPenjual && <DataPenjualPembeli
        isPenjual={dataPenjual.isPenjual}
        isBadanUsaha={dataPenjual.isBadanUsaha}
        nama={dataPenjual.nama}
        telepon={dataPenjual.telepon}
        email={dataPenjual.email}
        lokasi={dataPenjual.lokasi}
        alamat={dataPenjual?.alamat}
        isRent={isRent}
      />}

      {!!isRenderPembeli && <DataPenjualPembeli
        isPenjual={dataPembeli.isPenjual}
        isBadanUsaha={dataPembeli.isBadanUsaha}
        nama={dataPembeli.nama}
        telepon={dataPembeli.telepon}
        email={dataPembeli.email}
        lokasi={dataPembeli.lokasi}
        alamat={dataPembeli?.alamat}
        isRent={isRent}
      />}
    </div>
  )
}

const NewApprovalPage = ({ isCreating }) => {
  const [showDetailProperty, setShowDetailProperty] = useState(false)
  const { form, userLoginAuth, isEditing, isRent } = useFormState();
  const { detectAgentRole, submitPayload, setCurrentStep } = useFormMethod();

  const isVMb = useMemo(() => userLoginAuth?.role?.name == "Vice Member Broker" ? true : false, [userLoginAuth])

  //Memo
  const agentsInfo = useMemo(() => {

    let result = {
      penjualUtama: {
        company: form.kantor_marketing_penjual.value.name,
        agent: form.marketing_penjual.value.full_name,
        commision: formatRupiah(form.calculated_commission.value?.data?.summary?.listing_participants?.[0]?.personal_commission?.amount ?? 0),
        rate: form.calculated_commission.value?.data?.summary?.listing_participants?.[0]?.personal_commission?.rate,
        isCompanyC21: form.kantor_marketing_penjual.value.id == userLoginAuth.company.id,
      },
      pembeliUtama: {
        company: form.kantor_marketing_pembeli.value.name,
        agent: form.marketing_pembeli.value.full_name,
        commision: formatRupiah(form.calculated_commission.value?.data?.summary?.selling_participants?.[0]?.personal_commission?.amount ?? 0),
        rate: form.calculated_commission.value?.data?.summary?.selling_participants?.[0]?.personal_commission?.rate,
        isCompanyC21: form.kantor_marketing_pembeli.value.id == userLoginAuth.company.id,
      },
      biayaRefferalPenjual: formatRupiah(_.toNumber(form.biaya_refferal_penjual.value)) || 0,
      biayaRefferalPembeli: formatRupiah(_.toNumber(form.biaya_refferal_pembeli.value)) || 0,
      totalAgent: 2 + (form.is_co_listing_penjual.value == 'ya' ? 1 : 0) + (form.is_co_listing_pembeli.value == 'ya' ? 1 : 0),
      totalCommision: formatRupiah(form.calculated_commission.value?.data?.summary?.gross_closed_commission ?? 0),
      royalty: formatRupiah(form.calculated_commission.value?.data?.summary?.net_royalty ?? 0),
    }

    const totalCommision = result.totalCommision / 2 ?? 0;

    if (form.is_co_listing_penjual.value == 'ya') {
      result.penjualAsisting = {
        agent: form.asisten_penjual.value.full_name,
        commision: formatRupiah(form.calculated_commission.value?.data?.summary?.listing_participants?.[1]?.personal_commission?.amount ?? 0),
        rate: form.calculated_commission.value?.data?.summary?.listing_participants?.[1]?.personal_commission?.rate,
      }

      result.amountReferalPenjual = totalCommision - result.penjualUtama.commision - result.penjualAsisting.commision;
    } else {
      result.amountReferalPenjual = totalCommision - result.penjualUtama.commision;
    }

    if (form.is_co_listing_pembeli.value == 'ya') {
      result.pembeliAsisting = {
        agent: form.asisten_pembeli.value.full_name,
        commision: formatRupiah(form.calculated_commission.value?.data?.summary?.selling_participants?.[0]?.personal_commission?.amount ?? 0),
        rate: form.calculated_commission.value?.data?.summary?.selling_participants?.[0]?.personal_commission?.rate,
      }
      result.amountReferalPembeli = totalCommision - result.pembeliUtama.commision - result.pembeliAsisting.commision;
    } else {
      result.amountReferalPembeli = totalCommision - result.pembeliUtama.commision;
    }

    return result
  }, [form])

  const data = React.useMemo(() => {
    const listing = form.selected_listing.value;
    const location = listing?.summary?.property_locations?.[0] ?? {};
    let result = {
      title: _.get(listing, 'title') || '',
      // address: `${location?.area?.name ?? ''} ${location?.city?.name ?? ''} ${location?.province?.name ?? ''}`,
      address: location?.street,
      kamarTidur: _.toNumber(listing?.bedroom) ? `${listing?.bedroom || 0} ${listing?.additional_bedroom ? `+ ${listing?.additional_bedroom}` : ''}` : 0,
      kamarMandi: _.toNumber(listing?.bathroom) ? `${listing?.bathroom || 0} ${listing?.additional_bathroom ? `+ ${listing?.additional_bathroom}` : ''}` : 0,
      lantai: _.toNumber(listing?.number_of_floors || 0),
      type: _.get(listing, "property_type.name"),
      category: _.get(listing, "property_category.name"),
      status: _.get(listing, "ownership_status.name"),
      id: _.get(listing, "id"),
      price: _.get(listing, "price") || 0,
      luasTanah: _.toNumber(_.get(listing, "surface_area")) || 0,
      lebarTanah: _.toNumber(_.get(listing, "land_width")) || 0,
      panjangTanah: _.toNumber(_.get(listing, "land_length")) || 0,
      luasBangunan: _.toNumber(_.get(listing, "building_area")) || 0,
      lebarBangunan: _.toNumber(_.get(listing, "building_width")) || 0,
      panjangBangunan: _.toNumber(_.get(listing, "building_length")) || 0,
      image: _.get(listing, "summary.property_images.image.url") || '',
      komisiRate: form.calculated_commission.value?.data?.pure_commission?.commission_from_owner?.percent ?? 0,
      alamatProperty: {
        area: _.get(location, "area.name") || '',
        city: _.get(location, "city.name") || '',
        province: _.get(location, "province.name") || '',
        address: _.get(location, "street") || '',
      },
      transaksi: {
        tanggal: _.get(form, 'tanggal_transaksi.value'),
        nilaiTransaksi: _.get(form, 'nilai_transaksi.value') || 0,
        commisionRate: _.get(form, 'commission_rate.value') || 0,
        commisionAmount: _.toNumber(_.get(form, 'commission_amount.value')) || 0,
        metode_pembayaran: _.toNumber(_.get(form, 'metode_pembayaran.value.name')) || '',
        bank: _.get(form, 'bank_yang_digunakan.value.name') || '',
      },
      identitalPenjual: {
        nama: _.get(form, 'nama_pemilik_properti.value') || '',
        telepon: _.get(form, 'telepon_pemilik_properti.value') || '',
        email: _.get(form, 'email_pemilik_properti.value') || '',
      },
      domisiliPenjual: {
        province: _.get(form, 'provinsi_pemilik_properti.value.name') || '',
        city: _.get(form, 'kota_pemilik_properti.value.name') || '',
        area: _.get(form, 'area_pemilik_properti.value.name') || '',
        address: _.get(form, 'alamat_pemilik_properti.value') || '',
        isBadanUsaha: _.get(form, 'is_pemilik_badan_usaha.value') == 'ya',
      },
      identitalPembeli: {
        nama: _.get(form, 'nama_pembeli_properti.value') || '',
        telepon: _.get(form, 'telepon_pembeli_properti.value') || '',
        email: _.get(form, 'email_pembeli_properti.value') || '',
      },
      domisiliPembeli: {
        province: _.get(form, 'provinsi_pembeli_properti.value.name') || '',
        city: _.get(form, 'kota_pembeli_properti.value.name') || '',
        area: _.get(form, 'area_pembeli_properti.value.name') || '',
        address: _.get(form, 'alamat_pembeli_properti.value') || '',
        isBadanUsaha: _.get(form, 'is_pembeli_badan_usaha.value') == 'ya',
      },
    }

    if (!!result.transaksi.tanggal) {
      result.transaksi.tanggal = dayjs(result.transaksi.tanggal).format('DD MMMM YYYY')
    }
    return result;
  }, [form])

  const isPenjualDiluarCentury = useMemo(() => {
    if (detectAgentRole() == 'SXB1' || detectAgentRole() == 'S0B1') return true;
    return false;
  }, [form])

  const isPembeliDiluarCentury = useMemo(() => {
    if (detectAgentRole() == 'S1B0' || detectAgentRole() == 'S1XX') return true;
    return false;
  }, [form])

  const isS1B1 = useMemo(() => {
    if (detectAgentRole() == 'S1B1') return true;
    return false;
  }, [form])

  const isRenderPenjual = detectAgentRole().includes("S1")
  const isRenderPembeli = detectAgentRole().includes("B1")

  //Handlers
  const onConfirm = () => submitPayload()
  const onClose = () => setCurrentStep(STEPS.alamatPembeliProperti)

  return (
    <div className={Styles['new-approval-page']}>
      <MetodeTransaksi
        tanggal={data?.transaksi?.tanggal}
        metode={data?.transaksi?.metode_pembayaran}
        bank={data?.transaksi?.bank}
        isRent={isRent}
      />
      <JumlahMarketing
        jumlah={agentsInfo.totalAgent}
        komisi={data.komisiRate}
        commisionAmount={data?.transaksi?.commisionAmount}
        gcc={agentsInfo.totalCommision}
        royalti={agentsInfo.royalty}
        nilai={data?.transaksi?.nilaiTransaksi}
        isRent={isRent}
      />
      <div className={Styles['wrapper-marketing-and-commision']}>
        <div className={Styles['wrapper-marketing']}>
          {
            isPenjualDiluarCentury ?
              <MarketingUtamaNonCentury
                type="penjual"
                company={agentsInfo?.penjualUtama?.company}
                agent={agentsInfo?.penjualUtama?.agent}
                isRent={isRent}
              /> :
              <MarketingUtama
                type="penjual"
                company={agentsInfo?.penjualUtama?.company}
                agent={agentsInfo?.penjualUtama?.agent}
                personal_commission={agentsInfo?.penjualUtama?.commision}
                isRent={isRent}
              />
          }
          {
            isPembeliDiluarCentury ?
              <MarketingUtamaNonCentury
                type="pembeli"
                company={agentsInfo?.pembeliUtama?.company}
                agent={agentsInfo?.pembeliUtama?.agent}
                isRent={isRent}
              /> :
              <MarketingUtama
                type="pembeli"
                company={agentsInfo?.pembeliUtama?.company}
                agent={agentsInfo?.pembeliUtama?.agent}
                personal_commission={agentsInfo?.pembeliUtama?.commision}
                isRent={isRent}
              />
          }
        </div>

        {(!!agentsInfo.penjualAsisting || !!agentsInfo.pembeliAsisting) && <div className={Styles['wrapper-co-marketing']}>
          <MarketingAssistant
            type="penjual"
            company={agentsInfo?.penjualAsisting?.company}
            agent={agentsInfo?.penjualAsisting?.agent}
            personal_commission={agentsInfo?.penjualAsisting?.commision}
            isHidden={!agentsInfo.penjualAsisting}
            isRent={isRent}
          />
          <MarketingAssistant
            type="pembeli"
            company={agentsInfo?.pembeliAsisting?.company}
            agent={agentsInfo?.pembeliAsisting?.agent}
            personal_commission={agentsInfo?.pembeliAsisting?.commision}
            isHidden={!agentsInfo.pembeliAsisting}
            isRent={isRent}
          />
        </div>}

        {(!!agentsInfo.biayaRefferalPenjual || !!agentsInfo.biayaRefferalPembeli) && <div className={Styles['wrapper-refferal']}>
          {!isPenjualDiluarCentury && <NonMarketing isRent={isRent} type="penjual" persentase={agentsInfo.biayaRefferalPenjual} />}
          {!isPembeliDiluarCentury && <NonMarketing isRent={isRent} type="pembeli" persentase={agentsInfo.biayaRefferalPembeli} />}
        </div>}
      </div>

      <InformasiPenjualDanPembeli
        dataPenjual={{
          isPenjual: true,
          isBadanUsaha: data.domisiliPenjual.isBadanUsaha,
          nama: data?.identitalPenjual?.nama,
          telepon: data?.identitalPenjual?.telepon,
          email: data?.identitalPenjual?.email || "-",
          lokasi: `${data?.domisiliPenjual?.province}, ${data?.domisiliPenjual?.city}, ${data?.domisiliPenjual?.area}`,
          alamat: data?.domisiliPenjual?.address || "-"
        }}
        dataPembeli={{
          isPenjual: false,
          isBadanUsaha: false,
          nama: data?.identitalPembeli?.nama,
          telepon: data?.identitalPembeli?.telepon,
          email: data?.identitalPembeli?.email || "-",
          lokasi: `${data?.domisiliPembeli?.province}, ${data?.domisiliPembeli?.city}, ${data?.domisiliPembeli?.area}`,
          alamat: data?.domisiliPembeli?.address || "-"
        }}
        isRenderPembeli={isRenderPembeli}
        isRenderPenjual={isRenderPenjual}
        isRent={isRent}
      />

      {isRenderPenjual && <div className={Styles['wrapper-lihat-detail-property']} onClick={() => setShowDetailProperty(!showDetailProperty)}>
        <div>
          <p className={Styles["title"]}>Lihat Detail Properti</p>
          <p className={Styles["info"]}>Klik untuk lihat detail properti</p>
        </div>
        {showDetailProperty ? <ArrowUp className={Styles['wrapper-arrow-icon']} /> : <ArrowDown className={Styles['wrapper-arrow-icon']} />}
      </div>}
      <div className={`${Styles['wrapper-detail-property']} ${showDetailProperty ? Styles['show'] : ''}`}>
        <DetailProperty
          image={data.image}
          address={data.address}
          title={data.title}
          type={data.type === "Dijual" ? "penjual" : "pembeli"}
          category={data.category}
          status={data.status}
          id={data.id}
          isRent={isRent}
        />

        <SpecProperty
          kamarTidur={data.kamarTidur || "-"}
          kamarMandi={data.kamarMandi || "-"}
          lantai={data.lantai || "-"}

          luasTanah={data.luasTanah || "-"}
          lebarTanah={data.lebarTanah || "-"}
          panjangTanah={data.panjangTanah || "-"}

          luasBangunan={data.luasBangunan || "-"}
          lebarBangunan={data.lebarBangunan || "-"}
          panjangBangunan={data.panjangBangunan || "-"}
          isRent={isRent}
        />

      </div>

      {!!isCreating && <div className={Styles['btn-wrapper']}>
        <button className={`${Styles['btn']} ${Styles['white']}`} onClick={onClose}>Cek isian saya</button>
        {(!isEditing || (isEditing && isVMb)) && <button className={`${Styles['btn']} ${Styles['black']}`} onClick={onConfirm}>Simpan data transaksi</button>}
      </div>}
    </div>
  )
}

export default NewApprovalPage