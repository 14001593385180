import React from 'react'

function ButtonIcon({text, icon, disabled, action, className, type = "button"}) {
  return (
    <button
      type={type} 
      onClick={!disabled ? action : undefined} 
      className={`button-v2 custom-btn-with-icon ${disabled ? "disabled" : ""} ${className}`}
    >
      {icon}
      {text}
    </button>
  )
}

export default ButtonIcon