import { useMemo } from 'react'

// Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

// Hooks
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

const Tag = ({ tag }) => {
  if (tag.toLowerCase() === "cancelled") {
    return <span className='w-fit font-inter rounded-[80px] px-[16px] py-[2px] text-white bg-green-5 text-[12px] font-medium leading-[18px]'>Lunas - Komisi Dibatalkan</span>
  }
  if (tag.toLowerCase() === "lunas") {
    return <span className='w-fit font-inter rounded-[80px] px-[16px] py-[2px] text-white bg-green-2 text-[12px] font-medium leading-[18px]'>{tag}</span>
  }
  return <span className='w-fit font-inter rounded-[80px] px-[16px] py-[2px] text-black-0  text-[12px] bg-yellow-1 font-medium leading-[18px]'>{tag}</span>
}

const BodyCollumn = ({ thisTx }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    amount,
    gcc,
    type,
    trxId,
    tag,
    isCanceled,
    cancelAmount
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.approved_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    amount: toRupiah(thisTx?.detail?.amount ?? ""),
    gcc: toRupiah(thisTx?.gcc ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    trxId: thisTx?.id ?? "",
    tag: thisTx?.tag ?? "",
    isCanceled: thisTx?.tgcc_status === "cancelled",
    cancelAmount: toRupiah(thisTx?.due_tgcc ?? ""),
  }), [thisTx])

  return (
    <div className='py-[16px] flex justify-between items-start'>
      <div className='w-[133px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px]'>{createdAt}</p>
      </div>
      <div className='w-[164px] mr-[16px]'>
        {!!agents?.length && agents.map((agent, index) =>
          <p className='font-inter text-[14px] leading-[20px] overflow-hidden 
          text-ellipsis whitespace-nowrap px-2 py-1 text-black-0 bg-white-0 mb-2 rounded-md' key={`agents-${index}`}>{agent}</p>
        )}
      </div>
      <div className='w-[188px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] text-gray-2'>Rp{amount}</p>
      </div>
      <div className='w-[188px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] font-semibold'>Rp{gcc}</p>
      </div>
      <div className='w-[128px] mr-[16px]'>
        <span className='font-inter text-[12px] font-medium leading-[18px]
        py-[2px] px-[16px] rounded-[80px] bg-white-0'>{type}</span>
      </div>
      <div className='w-[123px] mr-[16px]'>
        <p className='font-inter text-gray-2 text-[14px] leading-[20px]'>#{trxId}</p>
      </div>
      <div className='w-[100px] mr-[16px]'>
        <p className='font-inter text-[14px] font-medium leading-[20px] text-green-0'>Disetujui</p>
      </div>
      <div className='w-[224px] mr-[16px] whitespace-nowrap flex flex-col'>
        <Tag tag={isCanceled ? "cancelled" : tag} />
        {isCanceled && <p className='text-xs text-gray-0 mt-[10px]'>Dibatalkan: <span className='text-red-8'>Rp{cancelAmount}</span></p>}
      </div>
    </div>
  )
}

const TableDisetujui = () => {
  const { transactionList } = useTTCtx();

  return (
    <div className='overflow-x-auto hide-scrollbar'>
      <div className='min-w-[1312px] px-[32px] py-[16px] rounded-[6px] border-[0.5px] border-solid border-white-0 bg-white'>
        {/*Table Head*/}
        <div className='py-[16px] flex justify-between'>
          <div className='w-[133px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Tanggal Disetujui</p>
          </div>
          <div className='w-[164px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Marketing</p>
          </div>
          <div className='w-[188px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Nominal</p>
          </div>
          <div className='w-[188px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>GCC</p>
          </div>
          <div className='w-[128px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Tipe</p>
          </div>
          <div className='w-[123px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Trx ID</p>
          </div>
          <div className='w-[100px] mr-[16px]'>
            <p className='text-xs text-gray-0 font-inter'>Status</p>
          </div>
          <div className='w-[224px] mr-[16px]' />
        </div>

        {/*Table Body*/}
        {!!transactionList?.length && transactionList.map((datum, key) => <BodyCollumn thisTx={datum} key={`body-column-${key}`} />)}
      </div>
    </div>
  )
}

export default TableDisetujui
