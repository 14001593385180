import React,{ useMemo } from 'react'
import _ from 'lodash'

// Style
import Styles from './approval-page.module.scss'

// Component
import Listing from './listing'
import RekapKomisi from './rekap-komisi'
import InformasiTransaksi from './informasi-transaksi'
import Button from "../reuseables/button-icon-custom"

import { useFormState, useFormMethod } from "pages/dashboard/e-reporting/forms/context";
import { ReactComponent as DoubleCheck } from "assets/double-check.svg";

function ApprovalPage() {
  const { form, userLoginAuth, isAprroval } = useFormState();
  const { detectAgentRole, submitPayload } = useFormMethod();

  const data = React.useMemo(() => {
    const listing = form.selected_listing.value;
    const location = listing?.summary?.property_locations?.[0] ?? {};
    let result = {
      title: _.get(listing, 'title') || '',
      address: `${location?.area?.name ?? ''} ${location?.city?.name ?? ''} ${location?.province?.name ?? ''}`,
      komisiRate: _.toNumber(_.get(listing, 'commission_rate')),
      kamarTidur: _.toNumber(listing?.bedroom) ? `${listing?.bedroom || 0} ${listing?.additional_bedroom ? `+ ${listing?.additional_bedroom}` : ''}` : 0,
      kamarMandi: _.toNumber(listing?.bathroom) ? `${listing?.bathroom || 0} ${listing?.additional_bathroom ? `+ ${listing?.additional_bathroom}` : ''}` : 0,
      lantai: _.toNumber(listing?.number_of_floors || 0),
      type: _.get(listing, "property_type.name"),
      category: _.get(listing, "property_category.name"),
      status: _.get(listing, "ownership_status.name"),
      id: _.get(listing, "id"),
      price: _.get(listing, "price") || 0,
      luasTanah: _.toNumber(_.get(listing, "surface_area")) || 0,
      lebarTanah: _.toNumber(_.get(listing, "land_width")) || 0,
      panjangTanah: _.toNumber(_.get(listing, "land_length")) || 0,
      luasBangunan: _.toNumber(_.get(listing, "building_area")) || 0,
      lebarBangunan: _.toNumber(_.get(listing, "building_width")) || 0,
      panjangBangunan: _.toNumber(_.get(listing, "building_length")) || 0,
      image: _.get(listing, "summary.property_images.image.url") || '',
      alamatProperty: {
        area: _.get(location, "area.name") || '',
        city: _.get(location, "city.name") || '',
        province: _.get(location, "province.name") || '',
        address: _.get(location, "street") || '',
      },
      transaksi: {
        tanggal: _.get(form, 'tanggal_transaksi.value'),
        nilaiTransaksi: (_.get(form, 'nilai_transaksi.value') || 0).toLocaleString('id-ID'),
        metode_pembayaran: _.get(form, 'metode_pembayaran.value.name') || '',
        bank: _.get(form, 'bank_yang_digunakan.value.name') || '',
      },
      identitalPenjual: {
        nama: _.get(form, 'nama_pemilik_properti.value') || '',
        telepon: _.get(form, 'telepon_pemilik_properti.value') || '',
        email: _.get(form, 'email_pemilik_properti.value') || '',
      },
      domisiliPenjual: {
        province: _.get(form, 'provinsi_pemilik_properti.value.name') || '',
        city: _.get(form, 'kota_pemilik_properti.value.name') || '',
        area: _.get(form, 'area_pemilik_properti.value.name') || '',
        address: _.get(form, 'alamat_pemilik_properti.value') || '',
        isBadanUsaha: _.get(form, 'is_pemilik_badan_usaha.value') == 'ya',
      },
      identitalPembeli: {
        nama: _.get(form, 'nama_pembeli_properti.value') || '',
        telepon: _.get(form, 'telepon_pembeli_properti.value') || '',
        email: _.get(form, 'email_pembeli_properti.value') || '',
      },
      domisiliPembeli: {
        province: _.get(form, 'provinsi_pembeli_properti.value.name') || '',
        city: _.get(form, 'kota_pembeli_properti.value.name') || '',
        area: _.get(form, 'area_pembeli_properti.value.name') || '',
        address: _.get(form, 'alamat_pembeli_properti.value') || '',
        isBadanUsaha: _.get(form, 'is_pembeli_badan_usaha.value') == 'ya',
      },
    }

    return result;
  }, [form])

  const isKantorPenjualSamaDenganYangLogin = React.useMemo(() => {
    if (detectAgentRole() == 'SXB1' || detectAgentRole() == 'S0B1') return false;
    return true;
  }, [form])

  const isKantorPembeliSamaDenganYangLogin = React.useMemo(() => {
    if (detectAgentRole() == 'S1B0' || detectAgentRole() == 'S1XX') return false;
    return true;
  }, [form])
  const isMb = useMemo(() => userLoginAuth?.role?.name == "Member Broker" ? true : false, [userLoginAuth])

  return (
    <div className={Styles['wrapper']}>
      <RekapKomisi />
      <div className={Styles['content']}>
        {!!data.title && <p className={Styles['deskripsi']}>{data.title}</p>}
        {!isKantorPenjualSamaDenganYangLogin && <p className={Styles['deskripsi']}>Informasi properti</p>}

        <div className={Styles['address-and-labels']}>

          {(isKantorPenjualSamaDenganYangLogin && !!data.address) && <p className={Styles['address']}>{data.address}</p>}

          {(!isKantorPenjualSamaDenganYangLogin) && <p className={Styles['address']}>Karena Marketing Century 21 City bertugas sebagai Marketing pembeli, maka tidak ada <br /> detail listing untuk transaksi ini.</p>}
          <div className={Styles['labels']}>
            {!!data.type && <span className={Styles['property-type']}>{data.type}</span>}
            {!!data.category && <span>{data.category}</span>}
            {!!data.status && <span>{data.status}</span>}
            {!!data.id && <span>#{data.id}</span>}
          </div>
        </div>

        {isKantorPenjualSamaDenganYangLogin && <Listing
          price={data.price ? data.price.toLocaleString('id-ID') : 0}
          komisiRate={data.komisiRate}
          kamarTidur={data.kamarTidur}
          kamarMandi={data.kamarMandi}
          lantai={data.lantai}
          luasTanah={data.luasTanah}
          lebarTanah={data.lebarTanah}
          panjangTanah={data.panjangTanah}
          luasBangunan={data.luasBangunan}
          lebarBangunan={data.lebarBangunan}
          panjangBangunan={data.panjangBangunan}
          image={data.image}
        />}
        <InformasiTransaksi
          alamatProperty={data.alamatProperty}
          transaksi={data.transaksi}
          identitalPenjual={data.identitalPenjual}
          domisiliPenjual={data.domisiliPenjual}
          identitalPembeli={data.identitalPembeli}
          domisiliPembeli={data.domisiliPembeli}
          isKantorPenjualSamaDenganYangLogin={isKantorPenjualSamaDenganYangLogin}
          isKantorPembeliSamaDenganYangLogin={isKantorPembeliSamaDenganYangLogin}
        />
      </div>
      <div className={Styles['btn-wrapper']}>
        {(isAprroval && isMb) && <Button
          Icon={DoubleCheck}
          text="Setujui Transaksi"
          onClick={() => submitPayload()}
        />}
      </div>
    </div>
  )
}

export default ApprovalPage