import { get, divide } from 'lodash'

export function useGetValueByLodash(obj) {
  const getValue = (path) => {
    return get(obj, path)
  }
  return getValue;
}

export function useDivideByLodash() {
  const divider = (num1, num2) => {
    return divide(num1, num2)
  }
  return divider;
}
