import React, { useRef, useState, useCallback } from 'react'
import lodash from 'lodash';

//Styles
import "./style.scss"

//Assets
import { ReactComponent as InfoBlue } from "assets/info-blue.svg";
import { ReactComponent as ArrowDown } from "assets/arrow-bottom.svg";
//Utils
import useOutsideAlerter from "hooks/useOutsideAlerter"

const periodEnum = {
  day: {
    label: 'Hari lalu',
    value: 'days'
  },
  week: {
    label: 'Minggu lalu',
    value: 'weeks'
  },
  month: {
    label: 'Bulan lalu',
    value: 'months'
  },
  year: {
    label: 'Tahun lalu',
    value: 'years'
  },
}

function InformasiYangDitampilkan({ hideIcon, setNumberOfRange, hideInfoLabel, periodRange, setPeriodRange }) {
  const selectRef = useRef(null)

  //State
  const [openDropdown, setOpenDropdown] = useState(false)
  const [range, setRange] = useState(1)
  const debounceFn = useCallback(lodash.debounce(setNumberOfRange, 1000), []);

  useOutsideAlerter(selectRef, () => setOpenDropdown(false))

  return (
    <div className='informasi-yang-ditampilkan'>
      {!hideInfoLabel ? <p className='info-label'><InfoBlue /> Informasi yang ditampilkan</p> : null}
      <div className='input-number'>
        <input value={range} type='number' onChange={(e) => {
          setRange(e.target.value)
          debounceFn(e.target.value)
        }} />
      </div>
      <div ref={selectRef} className="dropdown-wrapper" onClick={() => false && setOpenDropdown(val => !val)}>
        <span className='selected-value'>{periodRange?.label} {!hideIcon && <ArrowDown />}</span>
        {openDropdown && <div className='custom-dropdown'>
          <button onClick={() => setPeriodRange(periodEnum.week)}>Minggu lalu</button>
          <button onClick={() => setPeriodRange(periodEnum.month)}>Bulan lalu</button>
          <button onClick={() => setPeriodRange(periodEnum.year)}>Tahun lalu</button>
        </div>}
      </div>
    </div>
  )
}

export default InformasiYangDitampilkan