import React from 'react'
import _ from 'lodash'

//Styles
import Styles from "./komisi-penjual.module.scss";

//Components - atoms
import InputCommision from 'pages/dashboard/e-reporting/forms/components/reuseables/input-commision/InputCommision'
import TitleInputWithName from 'pages/dashboard/e-reporting/forms/components/reuseables/title-input-with-name/TitleInputWithName'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya'
import TitlePerSection from 'pages/dashboard/e-reporting/forms/components/reuseables/title-per-section'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Utils
import { classModuleGrouping } from "utils/classModuleGrouping";

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";
import lodash from 'lodash';

function KomisiPenjual() {
  const { form, isAprroval } = useFormState();
  const { setCurrentStep, setForm, handlePrevState } = useFormMethod();
  const classHandler = (strngs) => classModuleGrouping(Styles, strngs);

  const handleNext = () => {
    handlePrevState()
    setCurrentStep(STEPS.biayaReferralPenjual)
  }

  function handleChange(event) {
    const myString = event.target.value || '';
    const regex = /^[0-9.\s]+$/;

    const isMatch = regex.test(myString);
    if (isMatch) {
      return myString
    };

    const len = myString.length || 0;

    if (len <= 1) return 0;
    return (len ? myString.slice(len - 1) : 0)
  }

  const commisionHandler = (name, value) => {
    const _val = value || 0;
    const newForm = { ...form };

    if (_val > 100) return;

    if (name == 'komisi_penjual_utama') {
      newForm.komisi_penjual_utama.value = _val;
      newForm.komisi_penjual_asisten.value = 100 - _val;
    } else {
      newForm.komisi_penjual_asisten.value = _val;
      newForm.komisi_penjual_utama.value = 100 - _val;
    }

    setForm(newForm)
  }

  //make usememo with this name isShowBtnNext
  const isShowBtnNext = React.useMemo(() => {
    if (!form.komisi_penjual_utama.value || !form.komisi_penjual_utama.value) {
      return false
    }
    return true
  })

  return (
    <div className={classHandler('wrapper')}>
      <div className={classHandler('box')}>
        <TitlePerSection className={classHandler('mb-[16px]')} />
        <div className={classHandler('comission-wrapper')}>
          <div className={"mb-[8px]"}>
            <TitleInputWithName
              text={`Berapa persen yang didapatkan`}
              name={form.marketing_penjual.value.full_name}
              className={Styles['mb-[8px]']}
            />
          </div>
          <div className={"mb-[40px]"} >
            <InputCommision
              maxLength={100}
              value={form.komisi_penjual_utama.value || ''}
              className={Styles['mb-[40px]']}
              onChange={(e) => commisionHandler('komisi_penjual_utama', handleChange(e))}
              disabled={true}
            />
          </div>

          <div className={Styles['mb-[8px]']}>
            <TitleInputWithName
              text={`Berapa persen yang didapatkan`}
              name={form.asisten_penjual.value.full_name}
              className={Styles['mb-[8px]']}
            />
          </div>
          <div className={Styles['mb-[40px]']} >
            <InputCommision
              maxLength={100}
              disabled={true}
              value={form.komisi_penjual_asisten.value || ''}
              onChange={(e) => commisionHandler('komisi_penjual_asisten', handleChange(e))}
            />
          </div>
          <BoxFlex>
            <>
              <ButtonSebelumnya />
              {isShowBtnNext && <ButtonSelanjutnya onClick={handleNext} />}
            </>
          </BoxFlex>
        </div>
      </div>
    </div >
  )
}

export default KomisiPenjual