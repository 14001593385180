import { useQuery } from '@tanstack/react-query'
import {
  getListInvoiceListingCount
} from "./api"

export const useGetListInvoiceListingCount = ({ params }) => {
  return useQuery({
    queryKey: ['get-list-invoice-listing-count', params],
    queryFn: () => getListInvoiceListingCount(params),
  })
}
