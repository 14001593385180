import { useMemo } from 'react'

//Styles
import Styles from "./card-listing.module.scss";

//Hooks
import { useGetValueByLodash, useDivideByLodash } from 'hooks/useLodash'

function CardListing({ listing, ...props }) {
  const getValue = useGetValueByLodash(listing);
  const divide = useDivideByLodash();

  const list = useMemo(() => ({
    image_url: getValue('summary.property_images.image.thumbnail.url') ?? "https://dummyimage.com/389x373/#fff/000000",
    price: getValue('price')?.toLocaleString("id-ID"),
    street: getValue(["summary", "property_locations", "0", "street"]),
    title: getValue('title') ?? "",
    id: getValue('id') ?? "",
    type: getValue('property_type.name') ?? "",
    agent: getValue('user.full_name') ?? "",
    kantor_agent: getValue('user.company?.name') ?? "",
    isRent: getValue('property_type.name') == "Disewakan" ? true : false,
    period: (divide(getValue('min_rent'), 365) || 0) >= 1 ? "tahun" : "bulan"
  }), [listing])

  return (
    <div className={Styles['wrapper']} {...props}>
      <div className={Styles['image']}>
        <img src={list.image_url} alt="listing" />
      </div>

      <div className={Styles['content']}>
        <p className={Styles['price']}>Rp {list.price} {list.isRent ? <span>{` / ${list.period}`}</span> : null}</p>
        <p className={Styles['title']}>{list.title}</p>
        <p className={Styles['address']}>{list.street}</p>
        <div className={Styles['label-wrapper']}>
          <p className={list.isRent ? Styles['sewa'] : Styles['jual']}>{list.isRent ? 'Sewa' : 'Jual'}</p>
          <p className={Styles['id']}><span className={Styles['hash']}>#</span> {list.id}</p>
        </div>
      </div>
    </div>
  )
}

export default CardListing