import React from "react"

// Components
import Modal from "components/organism/modal-v2";

// Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";

const ModalWrapper = ({ children, onClose, isOpen, className, hideClose = false }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className={`relative z-[42] w-full max-w-[488px] bg-white py-[24px] px-[32px] 
      border-[0.5px] border-solid border-white-0 box-shadow-2 rounded-[6px] ${className}`}>
     {!hideClose && <div className='flex justify-end'>
        <CrossIcon className='cursor-pointer' onClick={onClose} />
      </div>}

      {children}
    </div>
  </Modal>
)

export default ModalWrapper