import React from 'react'

// Styles
import Styles from './button-v2.module.scss'

const WIDTH = [
  'auto',
  'full',
]

const ButtonV2 = ({ 
  text, 
  Icon, 
  bg = "", 
  width = 0, 
  className, 
  feedback = "active", 
  ...props
}) => {
  return (
    <button className={`${Styles['wrapper']} ${Styles['feedback-' + feedback]}  ${Styles[bg]} ${Styles[WIDTH[width]]} ${!!className && className}`}  {...props}>
      {Icon && <Icon />}
      <span>
        {text}
      </span>
    </button>
  )
}

export default ButtonV2