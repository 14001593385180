import React, { useMemo, useEffect } from 'react'

import CSelectV1 from "components/atomic/Input/CSelectV1";

import CrossIcon from "assets/round-x-red.png"

function ImageWithClose({ 
  source, 
  filesLength, 
  alt, 
  onLoad, 
  onClose, 
  onSelect, 
  tag, 
  position, 
  onSelectPosition, 
  usedTag 
}) {

  const tagOptions = useMemo(() => {
    let _tags = [
      {
        id: 1,
        name: "Tampak depan"
      },
      {
        id: 2,
        name: "Ruang tamu"
      },
      {
        id: 3,
        name: "Kamar tidur"
      },
      {
        id: 4,
        name: "Kamar mandi"
      },
      {
        id: 5,
        name: "Dapur"
      },
      {
        id: 6,
        name: "Foto lainnya"
      },
    ]

    return _tags;

    /** USE THIS CODE IF YOU WANT TO FILTERED TAGS */
    if (!usedTag.length) return _tags;


    let result = _tags.filter(t => !usedTag.includes(t.name));

    if (usedTag.includes(tag?.name)) {
      result.push(tag)
    };

    return result
  }, [usedTag])

  const positionOptions = useMemo(() => {
    return [
      {
        position: 1,
      },
      {
        position: 2,
      },
      {
        position: 3,
      },
      {
        position: 4,
      },
      {
        position: 5,
      },
      {
        position: 6,
      },
      {
        position: 7,
      },
      {
        position: 8,
      },
      {
        position: 9,
      },
      {
        position: 10
      }
    ].filter((f, idx) => (idx + 1) <= filesLength )
  }, [filesLength])

  return (
    <div className='image-options-wrapper'>
      <CSelectV1
        placeholder="Position"
        value={{ position }}
        options={positionOptions}
        displayName="position"
        valueName="position"
        onSelect={onSelectPosition}
        className="select-position"
      />
      <div className='image-with-close'>
        <img onClick={onClose} src={CrossIcon} alt="close" className='cross' />
        <img
          className="uploaded-image"
          src={source}
          alt={alt}
          onLoad={onLoad}
        />
      </div>
      <CSelectV1
        placeholder="Pilih tag"
        value={tag}
        options={tagOptions}
        displayName="name"
        valueName="name"
        onSelect={onSelect}
        disablePlaceholder={false}
      />
    </div>
  )
}

ImageWithClose.defaultProps = {
  onClose: () => { }
}

export default ImageWithClose