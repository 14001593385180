import React, { useState, useEffect } from 'react'
import lodash from "lodash";

//Actions
import { getLeadsDashboardList } from "actions"

function useGetLeads() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [dataPerFetch, setDataPerPage] = useState([])

  const getLeads = async (lead_status) => {
    try {
      setLoading(true)
      setPage(1)
      let query = `?page=1&lead_status=${lead_status}`
      const _leads = await getLeadsDashboardList(query)

      setData(_leads?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const getNextLeads = async (lead_status) => {
    try {
      setLoading(true)
      const __page = page + 1;
      setPage(__page)
      let query = `?page=${page}&lead_status=${lead_status}`
      const _leads = await getLeadsDashboardList(query)

      setData(_leads?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const getPreviousLeads = async (lead_status) => {
    try {
      if (page == 1) return;
      const __page = page - 1;
      setPage(__page)
      setLoading(true)
      let query = `?page=${__page}&lead_status=${lead_status}`
      const _leads = await getLeadsDashboardList(query)

      setData(_leads?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLeads('Menunggu')

    return () => {
      setLoading(true)
      setData([])
    }
  }, [])

  return {
    loading,
    data,
    dataPerFetch,
    getLeads,
    getNextLeads,
    getPreviousLeads,
    page
  }
}

export default useGetLeads