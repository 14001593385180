import React from 'react'
import DatePicker from "react-datepicker"
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";

//Styles
import Styles from './input-tanggal.module.scss'
import './input-tanggal.scss'

//Calendar
import { ReactComponent as CalendarIcon } from "assets/calendar-v2.svg"

function InputTanggal({ className, ...props }) {

  return (
    <div className={`${Styles['date']} create-ereporting-date ${className || ""}`}>
      <CalendarIcon />
      <DatePicker dateFormat={"dd/MM/yyyy"} {...props} />
    </div>
  )
}

export default InputTanggal