import React, { useState } from 'react'
import lodash from 'lodash'

//Styles
import Styles from './total-komisi-marketing.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'


//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";
import { handleAgentRole } from "../../handlePayload"

function TotalKomisiMarketing() {
  const { setCurrentStep, setForm, handlePrevState } = useFormMethod();
  const { form, userLoginAuth } = useFormState()

  //State
  const [totalKomisi, setTotalKomisi] = useState(!!form.commission_amount.value ? form.commission_amount.value : form.commission_rate.value)
  const [berapaYangSudahDiterima, setBerapaYangSudahDiterima] = useState(form.received_commission.value ?? '')

  const onNext = () => {
    handlePrevState()

    let newForm = { ...form }

    newForm.commission_amount.value = totalKomisi
    newForm.commission_rate.value = ""
    newForm.received_commission.value = berapaYangSudahDiterima

    setForm(newForm)

    if (handleAgentRole(form, userLoginAuth) === 'S0B1' || handleAgentRole(form, userLoginAuth) === 'SXB1') setCurrentStep(STEPS.entryDataPembeli)
    else setCurrentStep(STEPS.entryDataPemilik)
  }

  const hideButton = () => {
    // if (lodash.gt(lodash.toNumber(berapaYangSudahDiterima), lodash.toNumber(totalKomisi))) return false
    if ((!!totalKomisi && !!berapaYangSudahDiterima)) return true
    return false
  }

  return (
    <WrapperAndBox>
      <div className={Styles['komisi-wrapper']}>
        <p className={Styles['info']}>**(Sudah dikurangi biaya referral.)</p>
        <TextMedium>
          Berapa total komisi yang diterima kantor <span className={Styles['gold']}>Century 21 {userLoginAuth?.company?.name}</span> dari transaksi ini?<span className={Styles['required']}>*</span>
        </TextMedium>
        <div className={Styles['wrapper-currency']}>
          <InputCurrency
            disabled={false}
            onValueChange={(val) => setTotalKomisi(val)}
            value={totalKomisi || ''}
            placeholder={"Berapa rupiah?"}
            decimalSeparator="."
            groupSeparator=","
            className="hide-border"
            Icon={() => <span className={Styles['prefix']}>Rp</span>}
          />
        </div>
      </div>

      <div className={Styles['input-wrapper']}>
        <TextMedium>
          Dari total komisi transaksi, saat ini berapa yang sudah diterima? <span className={Styles['required']}>*</span> <span className={Styles["shadow"]}>(Rp)</span>
        </TextMedium>

        <InputCurrency
          disabled={false}
          onValueChange={(val) => setBerapaYangSudahDiterima(val)}
          value={berapaYangSudahDiterima || ''}
          placeholder="Komisi yang sudah diterima"
          decimalSeparator="."
          groupSeparator=","
          Icon={() => <span className={Styles['prefix']}>Rp</span>}
        />
      </div>

      <BoxFlex>
        <>
          <ButtonSebelumnya />
          {hideButton() && <ButtonSelanjutnya onClick={onNext} />}
        </>
      </BoxFlex>
    </WrapperAndBox>
  )
}


export default TotalKomisiMarketing
