import React from 'react'

//Styles
import Styles from "./input-icon-golden.module.scss";

//Assets - svg
import { ReactComponent as InfoIcon } from "assets/info-golden.svg";

function InfoIconGolden({ children, className, ...props }) {
  return (
    <div className={`${Styles['wrapper']} ${className || ""}`} {...props}>
      <InfoIcon className={Styles['icon']} />
      <p className={Styles['text']}>{children}</p>
    </div>
  )
}

export default InfoIconGolden