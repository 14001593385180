import React, { useEffect } from 'react'

//Components
import { CModal, Button, Divider } from "components";
import Buttonv1 from 'components/v1/Button';
import CSelectV2 from "components/atomic/Input/CSelectV2";
import { UCTextInputV1 } from "components/atomic/Input";

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import RadioActive from "assets/radio-active-1.png";

//Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";
import { isEmpty, size } from 'lodash';

const OPTIONS = [
  {
    text: "Transaksi yang disetujui",
    url: "trx.xlsx"
  },
  {
    text: "Gross Closed Commission (GCC)",
    url: "office-gcc.xlsx"
  },
  {
    text: "Personal Commisssion (PC)",
    url: "marketing-pc.xlsx"
  },
  {
    text: "Unit Transaction (Office)",
    url: "office-ut.xlsx"
  },
  {
    text: "Unit Transaction (Marketing)",
    url: "marketing-ut.xlsx"
  },
]

const ModalFilterLT = () => {
  const {
    setOpenModalDownload,
    setDownloadForm,
    downloadForm,
    downloadReportingCompany,
    currentOpt,
    setCurrentOpt,
  } = useTTCtx()

  //Function
  const onClose = () => { setOpenModalDownload(false) };

  const onSelectHandler = (e, options, valueName) => {
    const { name, value } = e.target

    const newFrom = { ...downloadForm }
    newFrom[name] = options.filter((option) => option[valueName] == value)[0]
    setDownloadForm(newFrom)
  }

  const onChangeForm = (value) => {
    if (size(value) <= 4) {
      const newFrom = { ...downloadForm }
      newFrom["year"] = value
      setDownloadForm(newFrom)
    }
  }

  const resetDownloadCurrent = () => {
    const newForm = {};
    newForm["month"] = "";
    newForm["year"] = "";

    setCurrentOpt(null)
    setDownloadForm(newForm);
  }

  useEffect(() => {
    return () => {
      resetDownloadCurrent()
    }
  }, [])
  return (
    <CModal toggle={onClose} id="modal-filter-lt">

      <div className='modal-content'>
        <div className='close-wrapper'>
          <CrossIcon className='cursor-pointer' onClick={onClose} />
        </div>
        <div>
          <p className='text-lg mb-[24px] font-medium text-black-0 title'>Pilih laporan yang akan diunduh</p>

          <div className='flex items-center justify-between mb-[32px]'>
            <CSelectV2
              label="Bulan"
              placeholder="Pilih Bulan"
              onSelect={onSelectHandler}
              value={downloadForm["month"]}
              displayName="name"
              valueName="id"
              name="month"
              className="!w-[calc(50%-8px)]"
              options={[
                {
                  id: 1,
                  name: "Januari"
                },
                {
                  id: 2,
                  name: "Februari"
                },
                {
                  id: 3,
                  name: "Maret"
                },
                {
                  id: 4,
                  name: "April"
                },
                {
                  id: 5,
                  name: "Mei"
                },
                {
                  id: 6,
                  name: "Juni"
                },
                {
                  id: 7,
                  name: "Juli"
                },
                {
                  id: 8,
                  name: "Agustus"
                },
                {
                  id: 9,
                  name: "September"
                },
                {
                  id: 10,
                  name: "Oktober"
                },
                {
                  id: 11,
                  name: "November"
                },
                {
                  id: 12,
                  name: "Desember"
                }
              ]}
            />

            <UCTextInputV1
              label="Tahun"
              placeholder="2020"
              type="number"
              value={downloadForm["year"]}
              name="year"
              className="!w-[calc(50%-8px)]"
              onChange={(value) => { onChangeForm(value.currentTarget.value) }}
            />
          </div>

          <Divider classname="mb-[8px]" />

          <div className='rounded'>
            {OPTIONS.map((opt, index) => {
              const isSelected = opt?.text === currentOpt?.text;
              const onSelect = () => { setCurrentOpt(opt) };

              return (
                <button
                  key={`laporan-diunduh-${index}`}
                  onClick={onSelect}
                  className={`p-4 w-full text-sm flex items-center font-inter justify-between border border-solid border-white-0
                    first:rounded-t last:rounded-b ${isSelected && "bg-yellow-7 font-bold"}`}
                >
                  <span>{opt?.text}</span>
                  {isSelected ? <img className='w-4 h-4' src={RadioActive} alt="Radio Active" /> : <div className='w-4 h-4 rounded-[8px] border border-solid border-grey-0' />}
                </button>
              )
            })}
          </div>

          <Divider classname="mb-[8px]" />
          <div className='box-between btn-wrapper'>
            <Buttonv1 className="mr-4" text="Atur ulang" type="secondary" onClick={resetDownloadCurrent} />
            <Buttonv1 text="Unduh" onClick={downloadReportingCompany} disabled={isEmpty(downloadForm["month"]) || downloadForm["year"] === '' || size(downloadForm["year"]) < 4 || !currentOpt} />
          </div>
        </div>
      </div>
    </CModal>
  )
}

const Wrapper = ({ ...props }) => {
  const { openModalDownload } = useTTCtx()

  if (!openModalDownload) return null;
  return <ModalFilterLT {...props} />
}

export default Wrapper;