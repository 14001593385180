import React from 'react'

//Styles
import Styles from './pagination.module.scss'

//Assets
import { ReactComponent as NextIcon } from "assets/next.svg";
import { ReactComponent as PreviousIcon } from "assets/previous.svg";

//Hooks
import useClassHandler from 'hooks/useClassHandler'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'

function Pagination({ currentPage, totalPage, setCurrentPage }) {
  const classHandler = useClassHandler(Styles);

  const nextHandler = () => {
    setCurrentPage(currentPage + 1)
  }

  const previousHandler = () => {
    setCurrentPage(currentPage - 1)
  }

  return (
    <div className={classHandler('wrapper')}>
      <div className={classHandler('box')}>
        <ButtonSebelumnya className={Styles['back-page-btn']} />

        <div className={classHandler('pagination')}>
          <button disabled={currentPage == 1} onClick={previousHandler} className={classHandler('btn sebelumnya')}>
            <PreviousIcon />
            <span>Sebelumnya</span>
          </button>
          <p className={classHandler('info')}><span>{currentPage}</span> dari <span>{totalPage}</span> halaman</p>
          <button onClick={nextHandler} disabled={currentPage === totalPage} className={classHandler('btn selanjutnya')}>
            <span>Selanjutnya</span>
            <NextIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination