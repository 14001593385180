// tslint:disable:file-name
import React, { useState, useRef } from 'react'

import ReactCrop from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

import "./crop-image.scss"

import ModalLoader from 'components/organism/ModalLogout'

export default function CropImage({ imgSrc, width, height, type }) {
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()


  function onImageLoad(e) {
    setCrop({
      unit: '%', // Use percentage units for crop values
      width, // Set the initial width of the crop area
      height, // Set the initial height of the crop area
      aspect: 1, // You can set the aspect ratio here
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const Styled = type === 4 ? {
    objectFit: 'contain',
    // width: "510px",
    height: "654.578px",
    position: "absolute",
    right: 40,
    bottom: 0,
  } : {
    objectFit: 'contain',
    // width: "515px",
    height: "538.578px",
    position: "absolute",
    right: "300px",
    bottom: 0,
  }

  return (
    <div>
      {!completedCrop && <ModalLoader open={true} />}
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <div id={"profile-picture-" + type}>
            <canvas
              ref={previewCanvasRef}
              style={Styled}
            />
          </div>
        </>
      )}
    </div>
  )
}
