import React from 'react'

//Styles
import Styles from './alamat-pemilik-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function AlamatPemilikProperti() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()
  //State
  const [value, setValue] = React.useState(form.alamat_pemilik_properti.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('alamat_pemilik_properti', value)
    setCurrentStep(STEPS.isPemilikBadanUsaha)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Tuliskan alamat pemilik selengkap mungkin
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText disabled={isAprroval} onChange={(e) => setValue(e.target.value || '')} value={value} placeholder="Alamat pemilik" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {!!value && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default AlamatPemilikProperti