import React from 'react'
import _ from "lodash"

import "./style.scss"

//Assets
import RoundDivider from "assets/round-divider.png";

//Context
import { useDetailListingCtx } from "../../Context"

function FasilitasRumah() {
  const { getValue } = useDetailListingCtx()
  const facilities = getValue("facilities")
  return (
    <div className='properti-detail-fasilitas-rumah'>
      <p className='fasilitas-rumah-header'>Fasilitas Rumah</p>
      <div className='fasilitas-rumah-content'>
        {+getValue("year") ? <p><img src={RoundDivider} alt="dot" /> Tahun dibangun {getValue("year")}</p> : null}
        {+getValue("electrical_power") ? <p><img src={RoundDivider} alt="dot" /> Daya listrik {getValue("electrical_power")} watt</p> : null}
        {getValue("property_condition") ? <p><img src={RoundDivider} alt="dot" /> Kondisi properti {getValue("property_condition")}</p> : null}
        {+getValue("parking_capacity") ? <p><img src={RoundDivider} alt="dot" /> Kapasitas parkir {getValue("parking_capacity")} mobil</p> : null}
        {+getValue("garage_capacity") ? <p><img src={RoundDivider} alt="dot" /> Kapasitas garasi {getValue("garage_capacity")} mobil</p> : null}
        {getValue("furniture_condition") ? <p><img src={RoundDivider} alt="dot" /> Kondisi perabotan {getValue("furniture_condition")}</p> : null}
        {getValue("face") ? <p><img src={RoundDivider} alt="dot" /> Menghadap ke {getValue("face")}</p> : null}

        {facilities?.length ? facilities.map((f, k) => f?.name ? <p key={`fasilitas-${k}`}><img src={RoundDivider} alt="dot" />{f?.name}</p> : null) : null}
        {!facilities?.length && !getValue("year") && !getValue("electrical_power") && !getValue("property_condition") && !+getValue("parking_capacity") && !+getValue("garage_capacity") && !getValue("furniture_condition") && !getValue("face") ? <p>Tidak Ada Data</p> : null}     
      </div>
    </div>
  )
}

export default FasilitasRumah