import React from 'react'

import "./style.scss"

function Loader() {
  return (
    <div className='loader'></div>
  )
}

export default Loader