import React, { useContext, useCallback } from 'react'

//CSS
import "./style.scss"

//Context
import { DashboardCtx } from "../../context/LeadDashboard";

function BodyNav() {
  const { currentTab, onSelectMenunggu, onSelectDikontak } = useContext(DashboardCtx);
  const handleSelectedClass = useCallback((tab) => currentTab == tab ? "selected" : "", [currentTab]);

  return (
    <div className='dashboard-leads-body-nav'>
      <div className='btn-nav-wrapper'>
        <button className={handleSelectedClass('Menunggu')} onClick={onSelectMenunggu}>Menunggu</button>
        <button className={handleSelectedClass('Dikontak')} onClick={onSelectDikontak}>Dikontak</button>
      </div>
    </div>
  )
}

export default BodyNav