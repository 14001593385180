import React, { useState } from 'react'

//Assets
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";

function CInputPassword({
  label,
  name,
  placeholder,
  disabled,
  onChange,
  error,
  value,
  className,
  onKeyDown
}) {
  const [hide, setHide] = useState(true)

  return (
    <div className={`c-password-input ${className}`}>
      <p className='label'>{label}</p>

      <div className={`input-w-suffix`}>
        <div className={`textinput-wrapper ${disabled ? "disabled" : ""}`}>
          <input
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            type={hide ? "password" : "text"}
            onKeyDown={onKeyDown ? onKeyDown : null}
          />
        </div>

        <div className='suffix-wrapper' onClick={() => setHide(!hide)}>
          <EyeIcon className='pwd-eye' />
        </div>
      </div>

      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

CInputPassword.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  suffixText: "",
  suffixPosition: "left",
  disabled: false,
  onChange: () => { }
}

export default CInputPassword