import { createContext, useState } from "react";

//Hooks
import useGetLeads from "../hooks/useGetLeads";
import useUpdateLeads from "../hooks/useUpdateLeads";
import useGetAgents from "../hooks/useGetAgents";

//Create Ctx
export const DashboardCtx = createContext({
  loadLists: true
});

export const LeadDashboardProvider = ({ children }) => {
  const { data: leadsLists, loading: loadLists, getLeads, getNextLeads, getPreviousLeads, page } = useGetLeads();
  const { data: agentsList, loading: loadAgents } = useGetAgents();
  const { loading: updatingLeads, handleUpdateLeads } = useUpdateLeads();

  const [currentTab, setCurrentTab] = useState('Menunggu')
  const [openModalKonfirmasi, setOpenModalKonfirmasi] = useState(false)
  const [openAgentModal, setOpenAgentModal] = useState(false);
  const [currentAgents, setCurrentAgents] = useState(null)
  const [selectedLeadList, setSelectedLeadList] = useState(null)
  const [isChangePIC, setIsChangePIC] = useState(false)

  const resetAllState = () => {
    setOpenModalKonfirmasi(false)
    setOpenAgentModal(false)
    setCurrentAgents(null)
    setSelectedLeadList(null)
    setIsChangePIC(false)
  }

  const onSelectMenunggu = async () => {
    try {
      if (currentTab == 'Menunggu') return;
      await getLeads("Menunggu")
      resetAllState()
      setCurrentTab("Menunggu")
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectDikontak = async () => {
    try {
      if (currentTab == 'Dikontak') return;
      await getLeads("Dikontak")
      resetAllState()
      setCurrentTab("Dikontak")
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectLeadList = (leadStatus, leadList) => {
    setSelectedLeadList({ leadStatus, leadList })

    if (leadStatus == "Dikontak") {
      setOpenAgentModal(true)
    } else {
      setOpenModalKonfirmasi(true)
    }
  }

  const onChangePIC = (leadStatus, leadList) => {
    setSelectedLeadList({ leadStatus, leadList })
    setIsChangePIC(true)
    setOpenAgentModal(true)
  }

  const onCloseModalKonfirmasi = () => {
    setCurrentAgents(null)
    setSelectedLeadList(null)
    setOpenModalKonfirmasi(false)
    setOpenAgentModal(false)
    if (currentTab == "Menunggu") {
      setCurrentTab("Menunggu")
    } else {
      setCurrentTab("Dikontak")
    }
  }

  const onCloseModalAgents = () => {
    setCurrentAgents(null)
    setSelectedLeadList(null)
    setOpenAgentModal(false)
  }

  const onContinueModalAgents = (agents) => {
    setCurrentAgents(agents)
    setOpenAgentModal(false)
    setOpenModalKonfirmasi(true)
  }

  const onContinueModalKonfirmasi = async () => {
    try {
      const updateLeadsRequest = {
        "ciputra_lead": {
          "lead_status": selectedLeadList?.leadStatus,
          "user_id": currentAgents?.id
        }
      }

      const updateLeads = await handleUpdateLeads(selectedLeadList?.leadList.id, updateLeadsRequest)

      if (updateLeads?.response?.status < 300) {
        
        if (!isChangePIC) {
          await getLeads(currentTab == "Menunggu" ? 'dikontak' : 'menunggu')
          setCurrentTab(currentTab == "Menunggu" ? "Dikontak" : 'Menunggu')
        } else {
          await getLeads('dikontak')
        }

      }
    } catch (error) {
      console.log(error)
    } finally {
      setOpenModalKonfirmasi(false)
      resetAllState()
    }
  }

  return (
    <DashboardCtx.Provider value={{
      leadsLists,
      loadLists,
      agentsList,
      currentAgents,
      loadAgents,
      openAgentModal,
      openModalKonfirmasi,
      currentTab,
      page,
      setCurrentAgents,
      setOpenAgentModal,
      onCloseModalAgents,
      setOpenModalKonfirmasi,
      onCloseModalKonfirmasi,
      onContinueModalAgents,
      onContinueModalKonfirmasi,
      onSelectLeadList,
      onSelectMenunggu,
      onSelectDikontak,
      onChangePIC,
      getNextLeads, 
      getPreviousLeads,
    }}>
      {children}
    </DashboardCtx.Provider>
  )
}