import { api } from "utils";
import { PUBLIC_V1 } from "constant/URL";
import { objectToQueryParams } from "utils/objectToQueryParams";

export const getCalonMA = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/users/marketing-candidates?${newParams}`)
}

export const getDetailCalonMA = async (id = {}) => {
  return api({}, true).get(`/${PUBLIC_V1}/users/show-marketing-candidates/${id}`)
}

export const getDetailParticipant = async (id) => {
  return api({}, true).get(`/${PUBLIC_V1}/training-participants/${id}`)
}

export const getTraining = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/trainings?${newParams}`)
}

export const getPassedParticipant = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/users/junior-marketing-candidates?${newParams}`)
}

export const getJuniorMa = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/users/junior-marketing-candidates?${newParams}`)
}

export const getAllTrainingAndBatch = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : "";
  return api({}, true).get(`/${PUBLIC_V1}/trainings/get_trainings_and_batches?${newParams}`)
}

export const getTrainingBatch = async (trainingId, params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/trainings/${trainingId}/batches?${newParams}`)
}

export const getDetailBatch = async (batchId, params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/training-batches/${batchId}?${newParams}`)
}

export const addCalonMA = async (data) => {
  return api({}, true).post(`/${PUBLIC_V1}/users/marketing-candidates`, data)
}

export const editCalonMA = async (id, data) => {
  return api({}, true).put(`/${PUBLIC_V1}/users/update-marketing-candidates/${id}`, data)
}

export const deleteCalonMA = async (id) => {
  return api({}, true).delete(`/${PUBLIC_V1}/users/delete-marketing-candidates/${id}`)
}

export const deleteTrainingParticipant = async (data) => {
  return api({}, true).delete(`/${PUBLIC_V1}/training-participants/delete_participant`, { data })
}

export const addTrainingParticipant = async (id, data) => {
  return api({}, true).post(`/${PUBLIC_V1}/training-batches/${id}/participants`, data)
}

export const getListInvoiceTraining = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `?${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/training-billings${newParams}`)
}

export const getInvoiceTrainingCounter = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `?${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/training-billings/count${newParams}`)
}

export const createTagihan = async (id) => {
  return api({}, true).post(`/${PUBLIC_V1}/training-billings/payment_link?id=${id}`)
}
