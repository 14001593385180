import { useMemo, useState } from 'react'

//Styles
import Styles from './metode-pembayaran-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import SelectWithSearch from 'pages/dashboard/e-reporting/forms/components/reuseables/select-with-search/SelectWithSearch'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function MetodePembayaranProperti() {
  const options = useMemo(() => {
    return [
      {
        id: 1,
        name: "Cash"
      },
      {
        id: 2,
        name: "KPR"
      },
    ]
  })
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { isAprroval, form } = useFormState()

  //State
  const [selectedData, setSelectedData] = useState({
    ...form.metode_pembayaran.value,
    label: form.metode_pembayaran.value.name,
    value: form.metode_pembayaran.value.id
  })

  const mappedData = useMemo(() => {
    if (!options?.length) return [];
    return options.map(datum => {
      return {
        value: datum.id,
        label: datum.name,
        ...datum
      }
    })
  }, [options])

  const onNext = () => {
    handlePrevState()
    formHandler('metode_pembayaran', selectedData)
    if (selectedData.id === 2) setCurrentStep(STEPS.bankYangDigunakan)
    else setCurrentStep(STEPS.totalKomisiMarketing)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Transaksi properti ini menggunakan metode pembayaran apa?
        </TextMedium>
      </div>
      <div className={Styles['select-wrapper']}>
        <SelectWithSearch isPascalCase selectedData={selectedData} disabled={isAprroval} onClick={setSelectedData} options={mappedData} placeholder="Pilih metode transaksi" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {!!selectedData.id && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default MetodePembayaranProperti