import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

//Style
import "./style.scss"

//Resuseable
import InfoCard from "pages/dashboard/key-metric-office-dashboard/reuseable-component/info-card"
import Calendar from "pages/dashboard/key-metric-office-dashboard/reuseable-component/calendar"
import CalendarRange from "pages/dashboard/key-metric-office-dashboard/reuseable-component/calendar-range"

//Assets
import { ReactComponent as Home } from "assets/house-white-v1.svg";
import { ReactComponent as Share } from "assets/share-v1.svg";
import { ReactComponent as Magnifier } from "assets/magnifier-v1.svg";
import { ReactComponent as Eye } from "assets/eye-v1.svg";

//API
import { getUploadListing, getShareListing } from "actions/quartery-reporting";

function BarisKetiga() {
  const [selectedDate, setSelectedDate] = useState({
    from: dayjs(),
    to: dayjs(),
  })
  const [currentDate, setCurrentDate] = useState({
    from: "",
    to: ""
  })

  //Upload
  const [upload, setUpload] = useState(0)
  const [loadUpload, setLoadUpload] = useState(true)

  //Share
  const [share, setShare] = useState(0)
  const [loadShare, setLoadShare] = useState(true)


  const handleUpload = async (from, to) => {
    try {
      setLoadUpload(true)
      const query = `?date[from]=${from}&date[to]=${to}`
      const getWAA = await getUploadListing(query)
      setUpload(getWAA?.data?.total ?? 0)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadUpload(false)
    }
  }
  const handleShare = async (from, to) => {
    try {
      setLoadShare(true)
      const query = `?date[from]=${from}&date[to]=${to}`
      const getWAA = await getShareListing(query)
      setShare(getWAA?.data?.total ?? 0)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadShare(false)
    }
  }

  /**USE EFFECTS */
  useEffect(() => {
    setCurrentDate({
      from: dayjs(selectedDate.from).format("YYYY-MM-DD"),
      to: dayjs(selectedDate.to).format("YYYY-MM-DD")
    })
  }, [selectedDate])

  useEffect(() => {
    const { from, to } = currentDate;
    if (from && to) {
      handleUpload(from, to)
      handleShare(from, to)
    }
  }, [currentDate])

  /** DISPLAY */
  return (
    <div className='baris-ketiga'>
      <div className='baris-ketiga-calendar-wrapper'>
        <CalendarRange
          initStart={selectedDate.from.toDate()}
          initEnd={selectedDate.to.toDate()}
          currentDate={currentDate}
          setDate={(start, end) => setSelectedDate({from: dayjs(start), to: dayjs(end)})}
        />
      </div>
      <div className='info-cards-wrapper'>
        <InfoCard
          Icon={Home}
          tag="# Upload Listing"
          point={upload}
          loading={loadUpload}
        />
        <InfoCard
          Icon={Share}
          tag="# Share Listing"
          point={share}
          loading={loadShare}
        />
        <InfoCard
          Icon={Magnifier}
          tag="# Search Listing"
          point="N/A"
        />
        <InfoCard
          Icon={Eye}
          tag="# Property View"
          point="N/A"
        />
      </div>
    </div>
  )
}

export default BarisKetiga