import React, { useState, useCallback } from 'react'
import { debounce } from 'lodash';
import Modal from 'components/organism/modal-v2';

// Component
import Button from 'components/v1/Button';

// Assets
import { ReactComponent as XIcon } from "assets/cross.svg"
import { ReactComponent as MagnifierIcon } from "../../assets/magnifier.svg";

// Api
import { getAllAreas } from 'actions';
import { useGlobalCtx } from 'contexts';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
const ModalSearch = ({
  onClose,
  selectBy = "id",
  handleSelect,
  ...props
}) => {
  const { setShowLoading } = useGlobalCtx()
  const [filteredList, setFilteredList] = useState([]);

  const [value, setValue] = useState("");

  const [selectedValue, setSelectedValue] = useState("")

  const handleOnChange = (e) => {
    const query = e.target.value ?? "";

    setValue(query)
    handleSearch(query)
  }

  const handleSearch = useCallback(
    debounce((searchText) => {
      handleGetAllAreas(searchText)
    }, 500),
    []
  );

  const handleGetAllAreas = async (q) => {
    try {
      setShowLoading(true)
      const res = await getAllAreas(q);
      const newData = res?.data?.data ?? [];

      setFilteredList(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  return (
    <Modal onClose={onClose} {...props}>
      <div className='z-[42] bg-white w-[420px] rounded py-6 px-8'>
        <div className='flex justify-end mb-4'>
          <XIcon onClick={onClose} className='cursor-pointer w-4 h-4 object-contain' />
        </div>

        <p className='text-2xl text-black-0 font-inter font-semibold mb-6'>Pilih Area Properti</p>
        <div className='mb-2 px-4 py-[10px] flex items-center w-[339px] rounded-[8px] border border-solid border-black-3 bg-white'>
          <MagnifierIcon className='w-6 h-6 object-contain mr-4' />
          <input value={value} onChange={handleOnChange} placeholder='Tulis area' className='w-[calc(100%-16px-24px)] text-sm placeholder:text-gray-0' />
        </div>
        <p className='text-gray-2 text-xs mb-4'>Contoh. "Setiabudi"</p>

        <div className='h-[260px] mb-[48px] overflow-auto hide-scrollbar'>
          {!!filteredList?.length && filteredList.map((list, idx) => {
            const isSelected = list?.[selectBy] === selectedValue?.[selectBy];
            return (
              <div onClick={() => setSelectedValue(list)} key={`search-list-${idx}`} className={`cursor-pointer font-inter w-full p-4 border-b border-solid border-white-0 ${isSelected && "bg-white-0 font-semibold"}`}>{capitalizeFirstLetter(list?.name)}</div>
            )
          })}
        </div>

        <Button disabled={!selectedValue} onClick={() => handleSelect(selectedValue)} size="xl" className="w-full" text="Pilih" />
      </div>
    </Modal>
  )
}

function Wrapper({ isOpen, ...props }) {
  if (!isOpen) return null;
  return <ModalSearch isOpen={isOpen} {...props} />
}

export default Wrapper