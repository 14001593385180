import React, { useContext, useState } from 'react'

//Styles
import Styles from './leads-distribution.module.scss'

//Assets
import { ReactComponent as Callendar } from 'assets/calendar-v2.svg'
import { ReactComponent as NextIcon } from 'assets/next.svg';
import { ReactComponent as PrevIcon } from 'assets/previous.svg';

// Context
import { GlobalContext } from "contexts";
import {
  LeadsDistributionProvider,
  useLeadsDistributionState,
  useLeadsDistributionMethod
} from "./useLeadsDistributionContext"

import Button from 'components/v1/Button';
import ModalPilihMarketing from "./modal-pilih-marketing"
import ModalKonfirmasi from "./modal-konfirmasi"
import ModalSuccess from "./modal-success"
import ModalLoader from 'components/organism/ModalLogout';
import { submitDistributionLeads } from "actions"

const COLWIDTH = {
  customerName: "w-[180px]",
  marketingName: "w-[180px]",
  phone: "w-[120px]",
  propertyLocation: "w-[200px]",
  category: "w-[80px]",
  propertyType: "w-[200px]",
  buttonLihat: "w-[132px]",
}

const RowHeader = () => {
  return (
    <div className={Styles['header-row']}>
      <div className={`${Styles['header-cell']} ${COLWIDTH.customerName}`}><span>Nama Customer</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.marketingName}`}><span>Nama Marketing</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.phone}`}><span>Nomor Telepon</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.propertyLocation}`}><span>Lokasi Properti</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.category}`}><span>Kategori</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.propertyType}`}><span>Tipe Properti</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.buttonLihat}`} />
    </div>
  )
}

const RowBody = ({
  name,
  marketing_associate,
  phone,
  city,
  area,
  property_category,
  property_type,
  leadStatus,
  id,
  handleGetLeads,
  ...rest
}) => {
  const { getDistributionLeads } = useContext(GlobalContext);

  const areaName = area?.name ?? "";
  const cityName = city?.name ?? "";

  const [loading, setLoading] = useState(false);

  const [openSelect, setOpenSelect] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [currentMarketing, setCurrentMarketing] = useState(marketing_associate ?? null);

  const propertyLocation = !!areaName ? `${areaName}${!!cityName ? ", " + cityName : ""}` : cityName;

  const handleOpenSelect = (currentModal) => {
    setOpenConfirmation(currentModal === "confirmation")
    setOpenSuccess(currentModal === "success")
    setOpenSelect(currentModal === "select")
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const payload = {
        "sale_rent_form": {
          "maid": currentMarketing.id
        }
      }

      await submitDistributionLeads(id, payload);
      getDistributionLeads()
      handleOpenSelect("success")
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  const propsSelect = {
    name,
    marketing_associate,
    phone,
    city,
    area,
    property_category,
    property_type,
    leadStatus,
    currentMarketing,
    setCurrentMarketing,
    ...rest
  }

  return (
    <>
      <div className={`${Styles['body-row']}`}>
        <div className={`${Styles['body-cell']} ${COLWIDTH.customerName}`}><span>{name}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.marketingName}`}><span className={`${!marketing_associate?.full_name ? "!text-gray-4" : ""}`}>{marketing_associate?.full_name ?? "Belum dipilih"}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.phone}`}><span>{phone}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.propertyLocation}`}><span>{propertyLocation}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.category}`}><span>{property_category?.name ?? ""}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.propertyType}`}>
          <span>{property_type?.name ?? ""}</span>
        </div>
        <div className={`${Styles['body-cell']} ${COLWIDTH.buttonLihat}`}>
          {leadStatus === "menunggu" ? <Button text="Tetapkan Marketing" onClick={() => handleOpenSelect("select")} /> : <Button text="Ubah" onClick={() => handleOpenSelect("select")} />}
        </div>
      </div>
      <ModalPilihMarketing
        onNext={() => handleOpenSelect("confirmation")}
        onClose={() => handleOpenSelect("")}
        isOpen={openSelect}
        {...propsSelect}
      />
      <ModalKonfirmasi
        onBack={() => handleOpenSelect("select")}
        onNext={handleSubmit}
        onClose={() => handleOpenSelect("")}
        isOpen={openConfirmation}
        nextText={leadStatus === "menunggu" ? "Tetapkan Marketing" : "Ubah Marketing"}
      />
      <ModalSuccess
        onClose={() => {
          handleGetLeads()
          handleOpenSelect("")
        }}
        isOpen={openSuccess}
        title={`Marketing Berhasil ${leadStatus === "menunggu" ? "Ditetapkan" : "Diubah"}`}
        marketingName={currentMarketing?.full_name ?? ""}
      />
      <ModalLoader open={loading} />
    </>
  )
}

const Table = ({ data, leadStatus, handleGetLeads }) => {
  const handleNavigate = (url) => {
    window.location.href = url
  };

  return (
    <div className={`mb-[124px] ${Styles['table']}`}>
      <RowHeader />
      {!!data.length && data.map((item, index) => {
        return (
          <RowBody
            {...item}
            leadStatus={leadStatus}
            key={`table-${index}`}
            handleNavigate={handleNavigate}
            no={index + 1}
            handleGetLeads={handleGetLeads}
          />
        )
      })}
    </div>
  )
}

const LeadsDistribution = () => {
  const { profile } = useContext(GlobalContext);
  const {
    page,
    totalPage,
    leadStatus,
    LEADS_STATUS,
    listLeads,
  } = useLeadsDistributionState()

  const {
    handleNextPage,
    handlePrevPage,
    setPage,
    handleChangeLeadStatus,
    handleGetLeads
  } = useLeadsDistributionMethod()

  const [inputPage, setInputPage] = useState(page)

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['header']}>
        <h1 className={Styles['title']}>Leads Listing</h1>
        <h1 className={Styles['welcome']}>Selamat datang kembali, {profile?.full_name}!</h1>

        <div className={Styles['actions-wrapper']}>
          <div className={Styles['btns-wrapper']}>
            <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.menunggu)} className={`${Styles['btn']} ${Styles[leadStatus === LEADS_STATUS.menunggu ? "active" : '']}`}><span>Menunggu</span></button>
            <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.ditangani)} className={`${Styles['btn']} ${Styles[leadStatus === LEADS_STATUS.ditangani ? "active" : '']}`}><span>Ditangani</span></button>
          </div>

          {/* <button className={Styles['btn-calendar']}>
            <Callendar />
            <span>Pilih periode</span>
          </button> */}
        </div>
      </div>

      <div className={`${Styles['content']}`}>
        <Table handleGetLeads={handleGetLeads} leadStatus={leadStatus} data={listLeads} />
      </div>

      {/** FOOTER */}
      <div className='fixed justify-between left-[80px] bottom-0 w-[calc(100vw-80px)] bg-white py-[40px] px-[32px] flex items-center box-shadow-1 border-[0.5px] border-solid border-white-0'>
        <div className='flex items-center'>
          <p className='text-sm text-gray-2 mr-[32px]'>Pergi ke halaman</p>
          <div className='flex items-center border-gray-2 border-[1px] border-solid rounded-[8px] px-4 py-[10px]'>
            <input min={0} onChange={(e) => setInputPage(e.target.value ?? "")} value={inputPage} max={totalPage} className='w-[32px] h-[20px] mr-2' placeholder='0' type='number' />
            <NextIcon onClick={() => setPage(inputPage)} className='w-[14px] h-[14px] object-contain cursor-pointer' />
          </div>
        </div>

        <div className='flex items-center'>
          <button page onClick={handlePrevPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'><PrevIcon className='w-[14px] h-[14px] object-contain mr-2' /> Sebelumnya</button>
          <p className='px-[32px] text-sm'>{page} <span className='text-gray-2'>dari</span> {totalPage} <span className='text-gray-2'>halaman</span></p>
          <button onClick={handleNextPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'>Selanjutnya <NextIcon className='w-[14px] h-[14px] object-contain ml-2' /></button>
        </div>
      </div>
    </div>
  )
}

export default ({ ...props }) => <LeadsDistributionProvider><LeadsDistribution {...props} /></LeadsDistributionProvider>
