import { useMemo } from 'react';
import lodash from "lodash";
import dayjs from 'dayjs';

// Hooks
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

// Components
import InfoSquare from "./InfoSquare";
import Tag from "./Tag";

// Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

const BodyCollumn = ({ thisTx, openModal }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    receivedCommission,
    gcc,
    type,
    listingId,
    tag,
    pendingCommision
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.approved_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    listingId: thisTx?.id ?? "",
    receivedCommission: toRupiah(thisTx?.total_commission ?? ""),
    gcc: toRupiah(thisTx?.detail?.amount ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    tag: thisTx?.tag ?? "",
    pendingCommision: toRupiah(Math.abs(lodash.toNumber(thisTx?.gcc) - lodash.toNumber(thisTx?.total_received_commission)))
  }), [thisTx])

  const umurTrx = useMemo(() => {
    const today = dayjs();
    const inputDate = dayjs(thisTx.approved_at);
    const difference = today.diff(inputDate, 'day');
    return difference ?? 0;

  }, [createdAt])

  return (
    <div className='py-[16px] flex justify-between items-center'>
      <div className='w-[133px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px]'>{createdAt}</p>
      </div>
      <div className='w-[164px] mr-[16px]'>
        {!!agents?.length && agents.map((agent, index) =>
          <p className='font-inter text-[14px] leading-[20px] overflow-hidden 
          text-ellipsis whitespace-nowrap px-2 py-1 text-black-0 bg-white-0 mb-2 rounded-md' key={`agents-${index}`}>{agent}</p>
        )}
      </div>
      <div className='w-[96px] mr-[16px]'>
        <p className='font-inter text-gray-2 text-[14px] leading-[20px]'>#{listingId}</p>
      </div>
      <div className='w-[104px] mr-[16px]'>
        <span className='font-inter text-[12px] font-medium leading-[18px]
        py-[2px] px-[16px] rounded-[80px] bg-white-0'>{type}</span>
      </div>
      <div className='w-[138px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px]'>Rp{gcc}</p>
      </div>
      <div className='w-[138px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] text-green-0 font-semibold'>Rp{receivedCommission}</p>
      </div>
      <div className='w-[150px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] text-red-0'>-Rp{pendingCommision}</p>
      </div>
      <div className='w-[138px] mr-[16px]'>
        <p className={`font-inter text-[14px] leading-[20px] ${umurTrx >= 150 && "!text-red-0"}`}>{umurTrx} hari</p>
      </div>
      <div className='w-[34px] mr-[16px]'>
        <InfoSquare openModal={openModal} />
      </div>
      <div className='w-[86px] mr-[16px] whitespace-nowrap'><Tag tag={tag} /></div>
    </div>
  )
}

export default BodyCollumn