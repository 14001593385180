import React from 'react'

//Styles
import Styles from './alamat-pembeli-properti.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Hooks
import { useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function AlamatPembeliProperti() {
  //Context
  const { calculateCommision, formHandler } = useFormMethod();
  const { disabledAll, form, isAprroval, isEditing, isRent } = useFormState()

  //State
  const [value, setValue] = React.useState(form.alamat_pembeli_properti.value ?? '')

  const onNext = () => {
    formHandler('alamat_pembeli_properti', value)
    calculateCommision()
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Tuliskan alamat {isRent ? "penyewa" : "pembeli"} selengkap mungkin
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText disabled={disabledAll} onChange={(e) => setValue(e.target.value || '')} value={value} placeholder={isRent ? "Alamat penyewa" :"Alamat pembeli"} />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {((!!value) || (isAprroval || isEditing)) && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default AlamatPembeliProperti