import React, { useState } from 'react'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

//Components - reuseables
import Button from "components/v1/Button"
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import ButtonSelanjutnya from "pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya";
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'

// Assets
import Checklist from "assets/round-x-green.png"

const ContentClass = "h-[calc(100vh-165px)] flex flex-col items-center justify-center"

const PilihKlarifikasiProperti = () => {
  const {
    setCurrentStep,
    formHandler,
    handlePrevState,
  } = useFormMethod();
  const { form } = useFormState()
  const [value, setValue] = useState(form?.is_secondary?.value || true)

  //Functions
  const handleNextStep = () => {
    handlePrevState()
    formHandler('is_secondary', value)
    setCurrentStep(STEPS.tanggalTransaksi)
  }

  return (
    <div className={`${ContentClass}`}>
      <div className='w-[654px] '>
        <TextMedium required>
          Pilih klasifikasi properti
        </TextMedium>

        <div className='flex items-center mt-4'>
          <Button
            text="primary"
            type="secondary"
            size="s"
            onClick={() => setValue(false)}
            className={`bg-white-3 rounded-lg mr-6 ${!value && "border-black-0 bg-white-0"}`}
            icon={!value && Checklist}
            iconPosition="right"
          />
          <Button
            text="secondary"
            type="secondary"
            size="s"
            onClick={() => setValue(true)}
            className={`bg-white-3 rounded-lg ${!!value && "border-black-0 bg-white-0"}`}
            icon={!!value && Checklist}
            iconPosition="right"
          />
        </div>

        <div className='flex items-center mt-4'>
          <ButtonSebelumnya />
          <ButtonSelanjutnya onClick={handleNextStep} />
        </div>
      </div>
    </div>
  )
}

export default PilihKlarifikasiProperti