import React from 'react'

import BGFront from "assets/front-id-card-v2.jpg";

const FrontCardV2 = ({
  refID,
  companyName
}) => {
  return (
    <div ref={refID} className='relative w-[188px] h-[321px]'>
      <img src={BGFront} alt='Front Card' className='absolute z-10 w-full h-full object-contain' />
      <p className='text-gold-2 absolute z-20 bottom-[62px] font-typold-extended-medium text-[8px] left-[24px]'>{companyName}</p>
    </div>
  )
}

export default FrontCardV2