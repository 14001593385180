import React from 'react'

//Components
import {
  CModal,
  Divider
} from "../../../../components";
import Button from "components/v1/Button";

//Assets
import { ReactComponent as CrossIcon } from "../../../../assets/cross.svg";


function SuccessModalBody({ onSuccess, updating }) {
  return (
    <div className='modal-content'>
      <div className='btn-wrapper'>
        <CrossIcon onClick={onSuccess} />
      </div>
      <p className='text-lg mb-[8px]'>Terima kasih!</p>
      <p className='text-gray-0 text-sm mb-[32px]'>
        {
          updating ?
            "Data transaksi yang telah kamu setujui telah kami simpan dan tidak dapat diubah lagi"
            :
            "Data transaksi telah disimpan. Selanjutnya data transaksi kamu akan ditinjau dan divalidasi. Kami akan segera memberitahu jika data transaksi kamu telah disetujui."
        }
      </p>
      <Divider classname="mb-[16px]" />
      <div className='box-center'>
        <Button
          text="Oke, kembali ke dashboard"
          onClick={onSuccess}
        />
      </div>
    </div>
  )
}

function SuccessModal({ open, onClose, ...rest }) {
  if (!open) return null

  return (
    <CModal toggle={onClose} id="modal-success-create-transaksi">
      <SuccessModalBody onClose={onClose} {...rest} />
    </CModal>
  )
}

export default SuccessModal