import { useState, useCallback } from 'react'
import _ from 'lodash';

//Styles
import Styles from "./pilih-listing.module.scss";

//Components
import CardListing from 'pages/dashboard/e-reporting/forms/components/reuseables/card-listing'
import Pagination from 'pages/dashboard/e-reporting/forms/components/reuseables/pagination'
import ModalListing from 'pages/dashboard/e-reporting/forms/components/reuseables/modal-listing'
import { ModalLoader } from "components/organism"

//Assets
import EmptyListing from 'assets/empty-listing.png'

//Hooks
import useFetch from './useFetch';

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihListing() {
  const { form } = useFormState();
  const { setCurrentStep, handlePrevState, setForm } = useFormMethod();

  const {
    data,
    loading,
    totalPage,
    currentPage,
    setCurrentPage,
    fetchData,
    valueSearch, 
    setValueSearch
  } = useFetch(form.marketing_penjual.value.id);

  //State
  const [selectedData, setSelectedData] = useState({ id: null })
  const [openModal, setOpenModal] = useState(false)

  const selectHandler = value => {
    setSelectedData(value)
    setOpenModal(true)
  }

  const onNext = () => {
    handlePrevState()

    let newForm = { ...form }
    newForm['selected_listing'].value = selectedData
    newForm['provinsi_properti'].value = selectedData?.summary?.property_locations?.[0]?.province ?? null
    newForm['kota_properti'].value = selectedData?.summary?.property_locations?.[0]?.city ?? null
    newForm['area_properti'].value = selectedData?.summary?.property_locations?.[0]?.area ?? null
    newForm['alamat_properti'].value = selectedData?.summary?.property_locations?.[0]?.street ?? null

    setForm(newForm)

    if(selectedData?.property_type?.id === 1) {
      setCurrentStep(STEPS.klasifikasiProperty)
    } else {
      setCurrentStep(STEPS.entryDataPropertiDanTransaksi)
    }
  }

  const debouncedSearch = useCallback(_.debounce(async (value) => {
    await fetchData(value)
  }, [1500]), [])

  const handleSearch = (e) => {
    const newValue = e.target.value || "";

    setValueSearch(newValue)
    debouncedSearch(newValue);
  }

  return (
    <div>
      <div className={Styles['wrapper-search']}>
        <p className={Styles["search-info"]}>Cari Berdasarkan </p>
        <input
          type="text"
          placeholder="Judul, alamat, deskripsi"
          valueSearch={valueSearch}
          onChange={handleSearch}
        />
      </div>
      <div className={Styles['wrapper']}>
        {
          !data.length ? (
            <div className={Styles['empty-listing']}>
              <img src={EmptyListing} alt="empty-listing" />
              <p>Listingnya ga ada</p>
              <p>Bilangin ke Marketing ybs buat upload listing yuk.</p>
            </div>
          ) : (
            <div className={Styles['cards-wrapper']}>
              {data.length ?
                (data.map((item, index) => (
                  <CardListing listing={item} key={index} onClick={() => selectHandler(item)} />
                ))) : null}
            </div>
          )
        }
      </div>

      <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
      <ModalListing onNext={onNext} listing={selectedData} open={openModal} onClose={() => setOpenModal(false)} />
      <ModalLoader open={loading} />
    </div>
  )
}

export default PilihListing