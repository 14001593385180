import React from 'react'

// Components
import CModal from 'components/v1/modal';
import Button from 'components/v1/Button';

// Assets
import Checklist from "assets/checklist-v1.png"

const ModalSukses = ({ title, info, ...props }) => {

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded'>
        <div className='flex flex-col items-center justify-center pt-4 pb-2 px-6 rounded-t'>
          <img className='w-[64px] h-[64px] object-contain mb-[10px]' src={Checklist} alt='Checklist' />
          {title && <p className='text-center text-[17px] font-semibold leading-[27.5px] tracking-[-0.4px]'>{title}</p>}
          {info && <p className='text-center text-[#808285] text-[13px] leading-[21px] tracking-[-0.4px] mb-2'>{info}</p>}
        </div>
        <div className='flex items-center justify-end pt-1 pb-4 px-6 border-t border-solid border-[#D8D8D9] rounded-b'>
          <Button onClick={props.onRequestClose} text="Tutup" className="mr-2" />
        </div>
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalSukses isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}