import React, { useState } from 'react'

//Components
import CModal from './CModal'
import { CGoogleMap, GoogleMapAutoComplete, Divider } from "../atomic"
import Button from "components/v1/Button"

//Assets
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/magnifier.svg";

function ModalMap({ toggle, action }) {
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [street, setStreet] = useState("")

  return (
    <CModal
      id="modal-map"
      toggle={toggle}
    >
      <div className='modal-content'>
        <div className='input-section'>
          <p className='search-header'>Di mana lokasi properti?</p>
          <GoogleMapAutoComplete
            onPlaceSelected={({ lat, lnt, street }) => {
              setLatitude(lat)
              setLongitude(lnt)
              setStreet(street)
            }}
            className="search-location"
            placeholder={"Cari di sini..."}
            Icon={({ className }) => <MagnifierIcon className={className} />}
          />
          <p className='search-info'>
            Jika kesulitan menemukan titik lokasi properti, 
            kamu bisa mulai dengan menuliskan area sekitar properti 
            (misalnya : “tangerang selatan”). 
            Kamu juga dapat menuliskan salah satu bangunan yang cukup dekat dengan properti 
            (misalnya “SPBU + nama daerah properti”).
          </p>
          <Divider />
          <p className='lokasi-header'>Lokasi properti</p>
          <p className='selected-location'>{street}</p>

          <Button
            text="Pilih titik lokasi"
            onClick={() => {
              action({ latitude, longitude, street })
              toggle()
            }}
            className="!justify-center"
            disabled={!street}
          />
        </div>

        <div className='map-section'>
          <div className='close-btn-wrapper'><CrossIcon onClick={toggle} className='close-icon' /></div>
          <p className='map-guide-text'>Arahkan pin ke lokasi pada peta untuk menandai.</p>
          <CGoogleMap
            latitude={latitude}
            longitude={longitude}
            street={street}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setStreet={setStreet}
            className="c-google-map"
          />
        </div>
      </div>
    </CModal>
  )
}

export default ModalMap