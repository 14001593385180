import React, { useRef, useMemo } from 'react'
import DatePicker from "react-datepicker"
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";
import "./style-calendar.scss";

//Assets - svg
import { ReactComponent as CalendarIcon } from "assets/calendar-v2.svg"
import { ReactComponent as ArrowDown } from "assets/arrow-down.svg"

function Calendar({ min, rangeDay, className, selectedDate = null, currentDate = {}, setDate, ...props }) {
  const dateRef = useRef(null)

  const onChange = async (date) => {
    setDate && setDate(date)

    const datepickerElement = dateRef.current;
    datepickerElement.setFocus(false);
  };

  const handleOpenDatePicker = () => {
    const datepickerElement = dateRef.current;
    datepickerElement.setFocus(true);
  }

  const from = useMemo(() => currentDate.from ? dayjs(currentDate.from).format('DD MMM/YYYY') : '', [currentDate])
  const to = useMemo(() => currentDate.to ? dayjs(currentDate.to).format('DD MMM/YYYY') : '', [currentDate])

  return (
    <div className={`datepicker-input ${className}`} onClick={() => handleOpenDatePicker()}>
      <CalendarIcon className='calendar-icon' />
      {min && <span>{min}</span>}
      <span>{`${from} - ${to}`}</span>
      <DatePicker
        selected={selectedDate}
        ref={dateRef}
        onChange={onChange}
        className="custom-date-picker"
        {...props}
      />
      <ArrowDown className='arrow-down' />
    </div>
  )
}

export default Calendar