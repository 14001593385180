import React from 'react'

//Css
import "./style.scss"

//Components
import ModalLoader from 'components/organism/ModalLogout';
import Header from "../header";
import Body from "../body";
import Footer from "../footer";
import ModalChooseAgent from "../modal/choose-agents"
import KonfirmasiGantiLeadStatus from "../modal/konfirmasi-ganti-lead-status"

//Context
import { LeadDashboardProvider, DashboardCtx } from "../context/LeadDashboard";

const Main = () => {
  return (
    <LeadDashboardProvider>
      <div id="dashboard-leads">
        <Header />
        <DashboardCtx.Consumer>
          {({ loadLists }) => (loadLists ? <ModalLoader open={true} /> : <Body />)}
        </DashboardCtx.Consumer>
        <Footer />
      </div>

      <DashboardCtx.Consumer>
        {({ openAgentModal }) => (openAgentModal ? <ModalChooseAgent /> : null)}
      </DashboardCtx.Consumer>

      <DashboardCtx.Consumer>
        {({ openModalKonfirmasi }) => (openModalKonfirmasi ? <KonfirmasiGantiLeadStatus /> : null)}
      </DashboardCtx.Consumer>
      
    </LeadDashboardProvider>
  )
}

export default Main