export const nominalBilangan = (nominal) => {
  let bilangan = ""
  let koma = ""

  let arrayNominal = ('' + parseInt(nominal)).split('').map(Number)

  if (arrayNominal.length <= 3) {
    return String(nominal)
  }

  if (arrayNominal.length > 3 && arrayNominal.length <= 6) {
    bilangan = 'Ribu'
    koma = arrayNominal.slice(arrayNominal.length - 3).slice(0, 2).join('')
    arrayNominal.splice(arrayNominal.length - 3, arrayNominal.length - 1)
  }

  if (arrayNominal.length > 6 && arrayNominal.length <= 9) {
    bilangan = 'Jt'
    koma = arrayNominal.slice(arrayNominal.length - 6).slice(0, 2).join('')
    arrayNominal.splice(arrayNominal.length - 6, arrayNominal.length - 1)
  }

  if (arrayNominal.length > 9 && arrayNominal.length <= 12) {
    bilangan = 'M'
    koma = arrayNominal.slice(arrayNominal.length - 9).slice(0, 2).join('')
    arrayNominal.splice(arrayNominal.length - 9, arrayNominal.length - 1)
  }

  if (arrayNominal.length > 12 && arrayNominal.length <= 15) {
    bilangan = 'T'
    koma = arrayNominal.slice(arrayNominal.length - 12).slice(0, 2).join('')
    arrayNominal.splice(arrayNominal.length - 12, arrayNominal.length - 1)
  }

  if (arrayNominal.length > 15) {
    bilangan = 'K'
    koma = arrayNominal.slice(arrayNominal.length - 15).slice(0, 2).join('')
    arrayNominal.splice(arrayNominal.length - 15, arrayNominal.length - 1)
  }
  
  const nominalOutput = koma === '00' ? Math.floor(arrayNominal.join('')) : `${arrayNominal.join('')},${koma}`

  return `${nominalOutput} ${bilangan}`
} 