import React from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import lodash from 'lodash'

// Helpers
import toRupiah from "helpers/toRupiah"

// Styles
import Styles from './table.module.scss'

const Table = ({ data, status, COLLUMNS }) => {
  const navigate = useNavigate();

  const memoizedColumns = React.useMemo(() => COLLUMNS, [COLLUMNS])
  const memoizedData = React.useMemo(() => data, [data])

  const tableInstance = useTable({ columns: memoizedColumns, data: memoizedData })

  const handleWaktuPembayaran = (remainingDays) => {
    if (!remainingDays) return "";
    if (typeof remainingDays === "number") return `${remainingDays}`;

    return `${remainingDays.replaceAll("+-", "")}`;
  }

  const TotalSaldoAkhir = React.useMemo(() => {
    if (!data?.length) return 0

    const total = lodash.sumBy(data, (item) => {
      return item.fullData.total_bill_amount
    })

    return lodash.toNumber(total)
  }, [data])

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <table {...getTableProps()} className={Styles['table']}>
      <thead className={Styles['table-head']}>
        {
          headerGroups.map((headerGroup, key) => (
            <tr key={`table-invoice-header-${key}`} {...headerGroup.getHeaderGroupProps()} className={Styles['table-row']}>
              {
                headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} className={`${Styles['table-head-cell']} `}>
                    <div className={Styles['content']}>
                      <span>
                        {column.render('Header')}
                      </span>
                    </div>
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>

      <tbody {...tableInstance.getTableBodyProps()} className={Styles['table-body']}>
        {rows.map((row, key) => {
          prepareRow(row)
          return (
            <tr key={`table-invoice-body-${key}`} {...row.getRowProps()} className={Styles['table-row']}>
              {row.cells.map((cell, i) => (
                <td {...cell.getCellProps()} className={`${Styles['table-body-cell']} `}>
                  <div className={Styles['content']}>
                    {
                      (i === 1) ? (
                        <button onClick={() => navigate(`/invoice/${cell.row.original.invoice_id}`)} className={Styles['invoice_number']}>
                          <span>
                            {cell.render('Cell')}
                          </span>
                        </button>
                      ) : (i === 4 && status === "menunggu") ? (
                        <div className={Styles['jatuh-tempo']}>
                          <span className={`${Styles['label']} ${(typeof row.original.remaining_days === "string") ? "" : "invisible"}`}>
                            Lewat {handleWaktuPembayaran(row.original.remaining_days)} hari
                          </span>
                          <span>
                            {cell.render('Cell')}
                          </span>
                        </div>
                      ) : (
                        <span>
                          {cell.render('Cell')}
                        </span>
                      )
                    }
                  </div>
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>

      {status === "menunggu" && <tfoot className={Styles['table-foot']}>
        <tr>
          <td colSpan={1}></td>
          <td colSpan={1}>Total Saldo Akhir</td>
          <td colSpan={2}>{TotalSaldoAkhir.toLocaleString("id-ID")}</td>
          <td colSpan={2}></td>
        </tr>
      </tfoot>}
    </table>
  )
}

export default Table