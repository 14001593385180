import React, { useContext, useMemo } from 'react'
import _ from "lodash"
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';

//Assets
import { ReactComponent as UploadImage } from "assets/upload-image.svg";

// Components
import { ImageWithClose, LoadingBar } from "components";
import Button from "components/v1/Button";
import VideoUploader from "./video-uploader"

//Context
import { UploadListingContext } from "contexts";

import useAddWatermark from "./useAddWatermark"

function UploadGambar() {
  const addWatermark = useAddWatermark()
  const {
    files,
    setFiles,
    savedGambar,
    startLoading,
    submitError,
    score,
  } = useContext(UploadListingContext);

  const filesTagUsed = useMemo(() => {
    if (!files?.length) return [];

    return _.compact(files.map(f => f?.name || f?.tag?.name))
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.jpeg', '.pdf']
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        toast.error(fileRejections[0].errors[0].message, {
          position: toast.POSITION.TOP_CENTER
        })
      }

      const currentFiles = [...files]

      const incomingFiles = acceptedFiles.map((file, key) => {
        const lastIdx = currentFiles[currentFiles.length - 1]?.position ?? 1;
        return { ...file, preview: URL.createObjectURL(file), position: (key + 1) + lastIdx, file }
      })

      const mappedFiles = [...currentFiles, ...incomingFiles].filter((_, idx) => idx < 10).map((img, idx) => ({ ...img, position: idx + 1 }))

      const addingWM = mappedFiles.map(async (f) => {
        let addedWM = await addWatermark(URL.createObjectURL(f.file), f)

        return addedWM
      })

      let completeFile = await Promise.all(addingWM)

      setFiles(completeFile || [])
    }
  });

  const removeItem = (id) => {
    const newFiles = [...files]
    setFiles(newFiles.filter((_, idx) => id !== _.position).map((img, idx) => ({ ...img, position: idx + 1 })))
  }

  const handleSelectTag = (e, options, valueName, file, type) => {
    try {
      const { name, value } = e.target

      if (type == "position") {
        const { position } = options.filter((option) => option[valueName] == value)[0] || {}

        const newFiles = [...files]
        let result = []

        if (newFiles.length) {
          let currentFile = newFiles.filter(f => f.position == position)[0];


          let removeFileAndCurrent = [...newFiles.filter(f => (f.position != file.position && f.position != currentFile.position)), { ...currentFile, position: file.position }, { ...file, position }]

          result = removeFileAndCurrent.sort((a, b) => a.position - b.position)
        }

        setFiles(result)
      } else {
        const tag = options.filter((option) => option[valueName] == value)[0]

        const newFiles = [...files]
        let result = []

        if (newFiles.length) {
          result = newFiles.map(f => {
            if (f.position == file.position) {
              return { ...f, tag }
            } else {
              return f
            }
          })
        }
        setFiles(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='upload-files-at-upload-listing'>
      <div className='skor-listing-wrapper'>
        <div className='skor-listing-box'>
          <p className='skor-listing'>Skor listing kamu <span className='ml-32'>:</span> <button className='score-card'><span className='score-point'>{score}</span><span className='score-text'>poin</span></button></p>
        </div>
      </div>
      <div className='upload-gambar-listing-wrapper'>
        <div>
          {
            !files?.length ?
              <div className='no-image'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <div className='no-image-wrapper'>
                    <UploadImage className='upload-image' />
                    <p className='drag-drop-text'>Tarik dan letakkan gambar</p>
                    <p className='img-requirement-text'>Gambar JPG dan PNG - Besar file maksimal 50MB</p>
                  </div>
                  <input {...getInputProps()} />
                  <div className='choose-file-btn-wrapper'>
                    <p className='atau'>atau</p>
                    <Button
                      text="Pilih file dari komputer"
                      type="secondary"
                    />
                  </div>
                </div>
                <div className='image-info-point'><span>+5 poin untuk 4 foto pertama. Setiap foto selanjutnya dan disertai tag, akan mendapatkan poin tambahan</span></div>
              </div>
              :
              <div className='no-image have-image'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <div className='no-image-wrapper'>
                    <p className='drag-drop-text'>Tarik dan letakkan gambar</p>
                    <p className='img-requirement-text'>Gambar JPG dan PNG - Besar file maksimal 50MB</p>
                  </div>
                  <input {...getInputProps()} />
                  <div className='choose-file-btn-wrapper'>
                    <p className='atau'>atau</p>
                    <Button
                      text="Pilih file dari komputer"
                      type="secondary"
                    />
                  </div>
                </div>

                <div className='images-wrapper'>
                  {
                    files.map((file, key) => {
                      return <ImageWithClose
                        onClose={() => removeItem(file.position)}
                        key={key}
                        source={file?.image?.url || file.preview}
                        onLoad={() => { URL.revokeObjectURL(file.data) }}
                        tag={file.tag || { name: file.name } || {}}
                        onSelect={(e, options, valueName) => handleSelectTag(e, options, valueName, file, "tag")}
                        onSelectPosition={(e, options, valueName) => handleSelectTag(e, options, valueName, file, "position")}
                        position={file?.position}
                        usedTag={filesTagUsed}
                        filesLength={files?.length ?? 0}
                      />
                    })
                  }
                </div>
              </div>
          }
        </div>
      </div>
      {/* <VideoUploader /> */}
      <div className='box-center'>
        {!submitError && <div className='loading-wrapper'>
          <LoadingBar show={savedGambar} start={startLoading} />
        </div>}
      </div>
      <ToastContainer />
    </div>
  )
}

export default UploadGambar