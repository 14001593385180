import React, { useState, useEffect } from 'react'

//Context
import { useFormState } from "pages/dashboard/e-reporting/forms/context";

//Actions
import ERAction from "actions/eReportingAction";

//Components
import ModalLoader from "components/organism/ModalLoader";

function withFetch(WrappedComponent) {

  const WithFetch = (props) => {
    const { form } = useFormState();

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    //make function recursive to fetch data from api
    const fetchData = async (id, count = 1, tempData = []) => {
      try {
        setLoading(true)

        const list = await ERAction.getAgentByCompany(id, `?page=${count}&_limit=50`)
        const newData = list?.data ?? []

        if (newData.length) {
          await fetchData(id, count + 1, [...tempData, ...newData])
        } else {
          setData(tempData)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    //fetch data when component mounted
    useEffect(() => {
      fetchData(form.kantor_marketing_pembeli.value.id)
    }, [])

    if (loading) return <ModalLoader open={true} />
    return <WrappedComponent {...props} data={data} />
  }

  return WithFetch
}

export default withFetch