import React from 'react'

// Components
import ModalV2 from 'components/organism/modal-v2';

// Assets
import { ReactComponent as HotListingLogo } from "assets/fire.svg";
import { ReactComponent as CrossIcon } from "assets/cross.svg";

// Componenst
import Button from "components/v1/Button"

const Divider = ({ className }) => <div className={`w-full h-[1px] bg-white-0 my-4 ${className}`} />

const ModalHotListing = ({
  onClose,
  setHotListingReason,
  hotListingReason,
  onContinue,
  ...props
}) => {

  const handleChangeText = (e) => {
    const value = e.target.value ?? "";
    const first96Char = value.substring(0, 96)
    setHotListingReason(first96Char);
  }

  return (
    <ModalV2 onClose={onClose} {...props}>
      <div className='bg-white rounded-md w-[488px] px-8 py-6 z-[42]'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <HotListingLogo className='w-6 h-6 object-fit mr-1.5' />
            <span className='font-semibold text-base'>Hot Listing</span>
          </div>
          <CrossIcon onClick={onClose} className='w-4 h-4 object-fit cursor-pointer' />
        </div>
        <Divider />
        <div className='rounded-md bg-gray-3 p-2'>
          <p className='text-xs'>Hot Listing adalah fitur berjangka waktu yang diharapkan dapat mendorong Marketing untuk melakukan share sebanyak-banyaknya terhadap listing yang terpilih. Oleh karena itu, harap mengisi apa alasan kamu menjadikan listing ini sebagai Hot Listing.</p>
        </div>
        <Divider />
        <div>
          <div className='flex justify-between items-center mb-1.5'>
            <p className='text-xs'>Mengapa Listing Ini Perlu Jadi Hot Listing? <span className='text-sm text-red-2'>*</span></p>
            <p className='text-xs text-gray-0'><span className='text-black-0'>{hotListingReason.length || 0}</span>/96</p>
          </div>

          <textarea
            value={hotListingReason}
            onChange={handleChangeText}
            placeholder='Tulis alasan...'
            className='mb-1.5 w-full h-[115px] py-2 px-3 bg-white rounded-md 
              border-solid border-[1px] border-black-3'
          />
          <p className='text-xs text-gray-0 mb-8'>Alasan ini akan dilihat oleh seluruh Marketing Associate CENTURY 21 Indonesia.</p>

          <div className='flex items-center justify-end'>
            <Button
              text="Batal"
              size="xl"
              type="secondary"
              className="mr-4"
              onClick={onClose}
            />
            <Button
              text="Lanjutkan"
              size="xl"
              disabled={!hotListingReason.length}
              onClick={onContinue}
            />
          </div>
        </div>
      </div>
    </ModalV2>
  )
}

export default ModalHotListing
