import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify';

// Assets
import { ReactComponent as IconX } from "assets/x-black.svg"

// Components
import CModal from 'components/v1/modal';
import CInput from './components/c-input';
import CSearch from './components/c-search';
import CDropdown from './components/c-dropdown';
import Button from 'components/v1/Button';

// Api
import { useGetListAreaOnly } from 'api/common/query';
import { useAddCalonMa } from 'api/training/mutation';
import { useGlobalCtx } from 'contexts';
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const ModalAddCalonMa = ({
  onSuccess,
  handleOpenModalRegisterCalonMa,
  setCalonMa,
  ...props
}) => {
  const { profile } = useGlobalCtx()
  const {
    ListEducation,
    isFreeBatchOpen,
    isPaidBatchOpen,
  } = useTrainingCtx()

  const [paramsListArea, setParamsListArea] = useState({
    q: ""
  })

  const { data: listAreaOnly } = useGetListAreaOnly({ params: paramsListArea })
  const filteredArea = useMemo(() => {
    if (!listAreaOnly?.data?.data?.length) return []

    return listAreaOnly.data.data.map((area) => ({ value: area?.id, label: area?.name }))
  }, [listAreaOnly])

  const [paramsAddCalonMa, setParamsAddCalonMa] = useState({
    "company_id": profile?.company?.id,
    "full_name": "",
    "card_number": "",
    "dob": "",
    "phone": "",
    "email": "",
    "area_id": "",
    "street": "",
    "last_education": ""
  })
  const { mutate: mutateAddCalonMa } = useAddCalonMa()

  const handleAddCalonMa = (register = false) => {
    mutateAddCalonMa({ user: paramsAddCalonMa }, {
      onSuccess: (response) => {
        props.onRequestClose()

        if (register) {
          handleOpenModalRegisterCalonMa()
          setCalonMa(response?.data?.data)
        } else {
          onSuccess()
        }
      },
      onError: (error) => {
        toast.error(error?.message ?? "Gagal menambahkan calon marketing")
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    const newParams = { ...paramsAddCalonMa }
    newParams[name] = value

    setParamsAddCalonMa(newParams)
  }

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded-md'>
        <div className='py-2 px-6 flex items-center justify-between border-b border-solid border-[#D8D8D9]'>
          <span className='text-[17px] font-semibold'>Tambah Calon Marketing Associate</span>
          <IconX onClick={props.onRequestClose} className='cursor-pointer w-[14px] h-[14px] object-contain' />
        </div>

        <div className='py-2 px-4'>
          <CInput
            label="Nama Lengkap"
            placeholder="contoh: John Doe"
            required
            onChange={handleChange}
            name="full_name"
            value={paramsAddCalonMa.full_name}
          />
          <CInput
            label="No. KTP"
            placeholder="minimal 16 digit kombinasi angka"
            required
            onChange={handleChange}
            name="card_number"
            value={paramsAddCalonMa.card_number}
          />
          <CInput
            label="Tanggal Lahir"
            placeholder="hh / bb / tttt"
            required
            onChange={handleChange}
            type="date"
            name="dob"
            value={paramsAddCalonMa.dob}
          />
          <CInput
            label="Telepon"
            placeholder="contoh: 085478560909"
            required
            onChange={handleChange}
            name="phone"
            value={paramsAddCalonMa.phone}
          />
          <CInput
            label="Alamat email"
            placeholder="contoh: johndoe@gmail.com"
            required
            onChange={handleChange}
            name="email"
            value={paramsAddCalonMa.email}
          />
          <CSearch
            label="Domisili"
            placeholder="Masukkan area"
            name="area_id"
            required
            selectedValue={paramsAddCalonMa.area_id}
            list={filteredArea}
            onSearch={(value) => setParamsListArea({ ...paramsListArea, q: value })}
            onSelect={(value) => setParamsAddCalonMa({ ...paramsAddCalonMa, area_id: value })}
          />
          <CInput
            label="Alamat detail"
            placeholder="contoh: Jl. Antasari No 15D"
            required
            onChange={handleChange}
            name="street"
            value={paramsAddCalonMa.street}
          />
          <CDropdown
            label="Pendidikan terakhir"
            placeholder="Pilih jenjang pendidikan"
            required
            selectedValue={paramsAddCalonMa.last_education}
            list={ListEducation}
            name="last_education"
            onSelect={(value) => setParamsAddCalonMa({ ...paramsAddCalonMa, last_education: value })}
          />
        </div>
        {isFreeBatchOpen || isPaidBatchOpen ?
          <div className='flex items-center justify-end px-5 py-2 border-t border-solid border-[#D8D8D9] rounded-b'>
            <Button onClick={() => handleAddCalonMa(false)} type="secondary" text="Simpan" className="mr-2" />
            <Button onClick={() => handleAddCalonMa(true)} btnPrimitiveType="submit" text="Daftarkan Training" />
          </div>
          :
          <div className='flex items-center justify-end px-5 py-2 border-t border-solid border-[#D8D8D9] rounded-b'>
            <Button onClick={props.onRequestClose} type="secondary" text="Batal" className="mr-2" />
            <Button onClick={() => handleAddCalonMa(false)} btnPrimitiveType="submit" text="Simpan" />
          </div>
        }
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalAddCalonMa isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}