import React, { useEffect, useState } from 'react'

function useRefresh() {
  const [showExitPrompt, setShowExitPrompt] = useState(false);

  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = () => {
      if (showExitPrompt) {
        return '';
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [
    showExitPrompt,
    setShowExitPrompt
  ]
}

export default useRefresh