import { createContext, useState, useContext, useMemo, useEffect } from "react";
import lodash from "lodash";

//Handlers
import { 
  handlePayloadSubmit, 
  handlePayloadCalculateCommision, 
  handleAgentRole 
} from "./handlePayload";

//API
import ERAction from "actions/eReportingAction";

//Context
import { useERState, useERMethod } from "pages/dashboard/e-reporting/ERCtx";

export const STEPS = {
  isCoBroking: 'isCoBroking',
  pilihKantorMarketingPenjual: 'pilihKantorMarketingPenjual',
  tulisMarketingNonCentury: 'tulisMarketingNonCentury',
  pilihMarketingPenjual: 'pilihMarketingPenjual',
  entryCoListingPenjual: 'entryCoListingPenjual',
  pilihAsistenPenjual: 'pilihAsistenPenjual',
  komisiPenjual: 'komisiPenjual',
  biayaReferralPenjual: 'biayaReferralPenjual',

  pilihKantorMarketingPembeli: 'pilihKantorMarketingPembeli',
  tulisMarketingPembeliNonCentury: 'tulisMarketingPembeliNonCentury',
  pilihMarketingPembeli: 'pilihMarketingPembeli',
  entryCoListingPembeli: 'entryCoListingPembeli',
  pilihAsistenPembeli: 'pilihAsistenPembeli',
  komisiPembeli: 'komisiPembeli',
  biayaReferralPembeli: 'biayaReferralPembeli',

  entryPilihListing: 'entryPilihListing',
  pilihListing: 'pilihListing',

  entryDataPropertiDanTransaksi: 'entryDataPropertiDanTransaksi',
  pilihTipeListing: 'pilihTipeListing',
  pilihProvinsiProperti: 'pilihProvinsiProperti',
  pilihKotaProperti: 'pilihKotaProperti',
  pilihAreaProperti: 'pilihAreaProperti',
  tulisAlamatProperti: 'tulisAlamatProperti',
  tanggalTransaksi: 'tanggalTransaksi',
  nilaiTransaksi: 'nilaiTransaksi',
  metodePembayaran: 'metodePembayaran',
  bankYangDigunakan: 'bankYangDigunakan',
  totalKomisiMarketing: 'totalKomisiMarketing',
  entryDataPemilik: 'entryDataPemilik',
  pilihDurasiSewa: 'pilihDurasiSewa',

  namaPemilikProperti: 'namaPemilikProperti',
  teleponPemilikProperti: 'teleponPemilikProperti',
  emailPemilikProperti: 'emailPemilikProperti',
  provinsiPemilikProperti: 'provinsiPemilikProperti',
  kotaPemilikProperti: 'kotaPemilikProperti',
  areaPemilikProperti: 'areaPemilikProperti',
  alamatPemilikProperti: 'alamatPemilikProperti',
  isPemilikBadanUsaha: 'isPemilikBadanUsaha',
  entryDataPembeli: 'entryDataPembeli',

  namaPembeliProperti: 'namaPembeliProperti',
  teleponPembeliProperti: 'teleponPembeliProperti',
  emailPembeliProperti: 'emailPembeliProperti',
  provinsiPembeliProperti: 'provinsiPembeliProperti',
  kotaPembeliProperti: 'kotaPembeliProperti',
  areaPembeliProperti: 'areaPembeliProperti',
  alamatPembeliProperti: 'alamatPembeliProperti',
  alamatPembeliProperti: 'alamatPembeliProperti',
  rekapKomisi: 'rekapKomisi',
  approvalPage: 'approvalPage',
  nilaiTransaksiSewa: 'nilaiTransaksiSewa',
  klasifikasiProperty: 'klasifikasiProperty',
  plafonKredit: 'plafonKredit',
  namaDebitur: 'namaDebitur',
  teleponDebitur: 'teleponDebitur',
};

//Context
const FormStateCtx = createContext(null);
const FormMethodCtx = createContext(null);

export const FormProvider = ({ children, updateListingData, initForm }) => {
  const { userLoginAuth, formType } = useERState()
  const { resetForm } = useERMethod()

  //State - Forms
  const [form, setForm] = useState(initForm)

  //State - Modal
  const [isOpenModalSubmit, setIsOpenModalSubmit] = useState(false)
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false)

  //State - commision
  const [fetchingCommision, setFetchingCommision] = useState(false);

  const disabledAll = useMemo(() => formType == 'approve', [formType])
  const isAprroval = useMemo(() => formType == 'approve', [formType])
  const isEditing = useMemo(() => formType == 'update', [formType])

  const [currentStep, setCurrentStep] = useState(isAprroval ? STEPS.approvalPage : STEPS.isCoBroking);
  const [prevStep, setPrevStep] = useState('');

  const [openLoader, setOpenLoader] = useState(false)

  const isRent = useMemo(() => form.selected_listing?.value?.property_type?.id === 2 ? true : false, [form.selected_listing])

  const [errorMessage, setErrorMessage] = useState("")

  //Memo - payload
  const submitPayload = async () => {
    try {
      setOpenLoader(true)

      const payload = handlePayloadSubmit(form, userLoginAuth, updateListingData)

      if (isAprroval) {
        await ERAction.approveTransaction(updateListingData?.id)
      } else if (isEditing) {
        await ERAction.editTransaction({ transaction_report: payload }, updateListingData?.id)
      } else {
        await ERAction.postTransactionReport({ transaction_report: payload })
      }
      setOpenLoader(false)
      setIsOpenModalSubmit(false)
      setIsOpenModalSuccess(true)
    } catch (err) {
      let pesan = isAprroval ? "menyetujui" : isEditing ? "mengubah" : "membuat"

      if (err?.message) {
        pesan = err?.message
      }

      console.log(err)
      setErrorMessage(`Gagal ${pesan} laporan transaksi`)
      setOpenLoader(false)
    }
  }

  //Detect agent role
  const detectAgentRole = () => handleAgentRole(form, userLoginAuth)

  //Handle prev state
  const handlePrevState = () => setPrevStep((val => [...val, currentStep]))

  const handleBackStep = () => {
    if (prevStep.length > 0) {
      setCurrentStep(prevStep[prevStep.length - 1])
      setPrevStep(prevStep.slice(0, prevStep.length - 1))
    }
  }

  //Handler - Forms
  const formHandler = (name, value) => {
    const newForm = { ...form };
    newForm[name].value = value;
    setForm(newForm);
  };

  //Handler - Modal
  const modalHandler = (name, val) => {
    const allModal = {
      modalSubmit: {
        setter: setIsOpenModalSubmit,
      }
    }

    for (let key in allModal) {
      if (key === name) {
        allModal[key].setter(val);
      } else {
        allModal[key].setter(false);
      }
    }
  }

  //Handler - Commision
  const calculateCommision = async () => {
    try {
      setOpenLoader(true)
      setFetchingCommision(true)
      const payload = handlePayloadCalculateCommision(form, userLoginAuth, updateListingData)
      const response = await ERAction.commisionCalculate({ transaction_report: payload })
      formHandler('calculated_commission', response.data)
      if (isAprroval) {
        setCurrentStep(STEPS.approvalPage)
      } else {
        setCurrentStep(STEPS.rekapKomisi)
      }
    } catch (error) {
      let pesan = "Gagal menghitung komisi"

      if (error?.message) {
        pesan = error?.message
      }

      setErrorMessage(pesan)
      console.log(error)
    } finally {
      setOpenLoader(false)
      setFetchingCommision(false)
    }
  }

  const handleCurrency = (value) => {
    if (!value || isNaN(value)) return 0;

    if (typeof value == 'string') {
      return lodash.toNumber(value.toLocaleString("id-ID"))
    } else {
      return value.toLocaleString("id-ID")
    }
  }

  useEffect(() => {
    if (isAprroval) {
      calculateCommision()
    }

    return () => {
      resetForm()
    }
  }, [])

  return (
    <FormStateCtx.Provider value={{
      currentStep,
      form,
      isOpenModalSubmit,
      fetchingCommision,
      isOpenModalSuccess,
      disabledAll,
      userLoginAuth,
      isAprroval,
      isEditing,
      formType,
      updateListingData,
      prevStep,
      openLoader,
      isRent,
      errorMessage
    }}>
      <FormMethodCtx.Provider value={{
        setCurrentStep,
        formHandler,
        modalHandler,
        calculateCommision,
        setForm,
        submitPayload,
        detectAgentRole,
        handleCurrency,
        handlePrevState,
        handleBackStep
      }}>
        {children}
      </FormMethodCtx.Provider>
    </FormStateCtx.Provider>
  )
}

//Custom hooks for the values
export const useFormState = () => {
  const ctx = useContext(FormStateCtx);

  if (!ctx) {
    throw new Error("useFormState must be used within the FormProvider");
  }

  return ctx;
};

//Custom hooks for the functions
export const useFormMethod = () => {
  const ctx = useContext(FormMethodCtx);

  if (!ctx) {
    throw new Error("useFormMethod must be used within the FormProvider");
  }

  return ctx;
};