import React from 'react'
import Modal from "components/organism/modal-v2"

import { ReactComponent as XIcon } from "assets/x-black.svg"
import Button from 'components/v1/Button'

const ModalKonfirmasi = (props) => {
  return (
    <Modal {...props}>
      <div className='z-[42]  bg-white-3 w-[488px] rounded-2xl py-6 px-8'>
        <div className='flex justify-end mb-4'>
          <XIcon onClick={props.onClose}  className='cursor-pointer w-4 h-4 object-contain' />
        </div>
        <p className='text-lg mb-2'>Yakin tetapkan marketing ini?</p>
        <p className='text-sm text-gray-2'>Setelah ini, leads yang marketingnya telah ditetapkan akan pindah di tab “Ditangani”. Kamu juga masih bisa mengubah dan menghapus marketing untuk leads ini pada tab “Ditangani”.</p>

        <div className='flex justify-end pt-2 mt-4 border-t border-solid border-white-0'>
          <Button onClick={props.onBack} type="secondary" className="mr-4" text="Kembali" />
          <Button onClick={props.onNext} text={props.nextText} />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalKonfirmasi isOpen={isOpen} {...props} />
}

export default Wrapper