import React from 'react'

const Toggle1 = ({ selected, disabled, ...props }) => {
  return (
    <div className='relative cursor-pointer' {...props}>
      <div className={`bg-gold-2 ${disabled && "bg-[#e6dfcd]"} w-[34px] rounded-[40px] h-[12px]`} />
      <div className={`absolute ${selected ? "left-[20px]" : "left-0"} top-[-2px] w-[16px] h-[16px] 
        rounded-[800px] ${selected ? "bg-black-1" : "bg-black-3"} ${disabled && "!bg-[#9f9f9f]"} transition-duration-[0.4s]`} />
    </div>
  )
}

export default Toggle1