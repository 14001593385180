import React from 'react'

//Assets
import { ReactComponent as BoyV1 } from "../../../assets/boy-v1.svg";

//Components
import { ModalSuccessV1 } from "../../../components";

function ModalSuccessUploadListing({ open, confirmAction, noImage, isMB }) {
  if(!open) return null
  return (
    <ModalSuccessV1
      Image={({ className }) => <BoyV1 className={className} />}
      title="Keren! Kamu adalah pahlawan marketing associate."
      Info={({ className }) =>
        <p className={className}>
          Terima kasih. Kamu sudah membantu marketing associate untuk melakukan upload listing. Untuk mengubah data listing, 
            kamu dapat melakukannya di menu marketing associate {"->"} listing.
          Tentunya marketing associate yang dapat kamu ubah listingnya masih satu kantor denganmu.
          <span style={{ display: "block", marginBottom: "8px" }} /> Selanjutnya sistem akan mengarahkan kamu kembali ke tampilan awal halaman upload listing.
          Seperti biasa, kamu harus memilih marketing associate terlebih dahulu sebelum bisa mengisi data listing.
        </p>}
      confirmText={isMB ? "Lihat laporan transaksi" : "Menuju listing dashboard"}
      confirmAction={confirmAction}
    />
  )
}

export default ModalSuccessUploadListing