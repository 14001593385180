import React from 'react'

//Styles
import Styles from './toggle-switch-rounded.module.scss'

const ToggleSwitchRounded = ({ toggle, active }) => {

  return (
    <label className={Styles['wrapper']}>
      <input type="checkbox" checked={active} onChange={toggle} />
      <span className={`${Styles['slider']} ${Styles['round']}`}></span>
    </label>
  )
}

export default ToggleSwitchRounded