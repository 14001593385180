import React from 'react'

//Styles
import Styles from "./text-large.module.scss";

//Utils
import { classModuleGrouping } from "utils/classModuleGrouping";

function TextLarge({children, required, ...props}) {
  return <p {...props} className={classModuleGrouping(Styles, 'text-large ' + props.className)}>{children} {required ? <span className={Styles['required']}>*</span> : null}</p>
}

export default TextLarge