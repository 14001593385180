import React from 'react'

// Components
import ModalWrapper from "./ModalWrapper";
import Divider from "./Divider";
import Button from 'components/v1/Button';

const ModalUmurTrx = ({ selectedTrx, onNextModal, onClose, ...props }) => {

  return (
    <ModalWrapper {...props} onClose={onClose} hideClose={true} className="!p-0">
      <div className='pt-6 px-8 pb-4'>
        <p className='text-[18px] font-semibold mb-2'>Apa itu Umur Transaksi?</p>
        <p className='text-sm text-gray-2'>Umur transaksi adalah jumlah hari sejak tanggal perlaporan transaksi. Sisa komisi yang belum lunas akan dikonversi otomatis menjadi Settled GCC bila umur transaksi melewati 180 hari dan tidak dibatalkan.</p>
        
        <Divider className="!my-4" />

        <div className='flex justify-center'>
          <Button
            text={"Mengerti"}
            onClick={onClose}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalUmurTrx