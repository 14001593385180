import React from 'react'
import dayjs from 'dayjs';

// Assets
import { ReactComponent as LampiranIcon } from 'assets/lampiran-1.svg'
import toRupiah from 'helpers/toRupiah';
import tranformDateToSlash from 'helpers/tranformDateToSlash'

const handleDate = (date) => dayjs(date).format('DD/MM/YYYY; HH:mm')

const columnWidth = {
  idTransaksi: "w-[11.58%]",
  tanggal: "w-[22.63%]",
  namaMA: "w-[18.42%]",
  nilaiTransaksi: "w-[18.42%]",
  GCC: "w-[18.42%]",
}

const headerClass = "flex items-center justify-between p-2 pb-1 bg-white-3";
const spanClass = "text-xs text-inter text-black-5";
const CHeader = () => {
  return (
    <div className={headerClass}>
      <span className={`${spanClass} ${columnWidth.idTransaksi}`}>ID Transaksi</span>
      <span className={`${spanClass} ${columnWidth.tanggal}`}>Tanggal</span>
      <span className={`${spanClass} ${columnWidth.namaMA}`}>Nama MA</span>
      <span className={`${spanClass} ${columnWidth.nilaiTransaksi} flex justify-end`}>Nilai Transaksi</span>
      <span className={`${spanClass} ${columnWidth.GCC} flex justify-end`}>GCC</span>
    </div>
  )
}

const bodyWrapperClass = "bg-white py-4 px-2 border-b-[0.5px] border-solid border-white-0 last:border-none"
const bodyUpperClass = "flex items-start justify-between pb-2";
const bodyBottomClass = "bg-white-4";

const dateClass = "text-xs text-gray-6 font-inter"
const agentsClass = "w-full text-xs font-inter mb-1 pb-1 border-b-[0.5px] border-solid border-white-0 truncate block";
const priceClass = "text-xs font-inter";
const typeClass = `text-xs font-inter text-blue-1 px-1 flex item-center justify-center 
border-[0.5px] border-solid border-blue-1 bg-blue-2 rounded-[80px]`

const listNameClass = "text-xs text-gray-6 font-inter";
const locationClass = "text-xs text-gray-6 font-inter";

const CBody = ({ 
  order = 1, 
  amount, 
  id,
  marketings,
  gcc,
  listing,
  kind,
  approved_at,
  created_at
 }) => {
  const isEven = order % 2 === 0;
  const isSale = kind === "Sale";
  
  return (
    <div className={`${bodyWrapperClass} ${isEven && "bg-white-4"}`}>
      <div className={`${bodyUpperClass}`}>
        <div className={`${columnWidth.idTransaksi}`}>
          <span className='text-xs font-semibold font-inter'>#{id}</span>
        </div>
        <div className={`${columnWidth.tanggal}`}>
          <p className={`${dateClass} truncate`}>Input: <span className='!text-black-5'>{handleDate(created_at)}</span></p>
          <p className={`${dateClass} truncate`}>Approved:: <span className='!text-black-5'>{handleDate(approved_at)}</span></p>
        </div>
        <div className={`${columnWidth.namaMA}`}>
          {!!marketings?.length && marketings.map((m, i) => {
            return <span key={`marketing-${i}`} className={agentsClass}>{m}</span>
          })}
        </div>
        <div className={`${columnWidth.nilaiTransaksi} flex flex-col items-end`}>
          <span className={priceClass}>Rp{toRupiah(amount)}</span>
          <span className={`${typeClass} ${!isSale && "!text-black-6 !bg-yellow-4 !border-yellow-5"}`}>{isSale ? "Jual" : "Sewa"}</span>
        </div>
        <div className={`${columnWidth.GCC} flex justify-end`}>
          <span className={priceClass}>Rp{toRupiah(gcc)}</span>
        </div>
      </div>

      <div className={`${bodyBottomClass} ${isEven && "!bg-white"}`}>
        <p className={listNameClass}>Nama Listing: <span className='text-black-5'>{listing?.title}</span></p>
        <p className={locationClass}>Lokasi Properti: <span className='text-black-5'>{listing?.location?.area}, {listing?.location?.city}, {listing?.location?.province}</span></p>
      </div>
    </div>
  )
}

const Lampiran = ({ listTrx }) => {

  if (!listTrx?.length) return null;
  return (
    <div className='flex justify-center'>
      <div className='w-[624px] mb-[40px] pb-[40px] border-b-[0.5px] border-solid border-white-0'>
        <div className='flex items-center mb-[40px] pb-[16px] 
        border-b border-solid border-white-3'>
          <LampiranIcon className="mr-2 w-[40px] h-[40px] object-contain" />
          <p className='text-[30px] font-inter font-semibold'>Lampiran</p>
        </div>

        <p className='text-[22px] font-inter font-medium mb-1'>Mutasi Transaksi</p>
        <p className='text-xs font-inter mb-2'>({listTrx.length} Transaksi)</p>

        <div className='border border-solid border-white-3'>
          <CHeader />
          {listTrx.map((list, i) => {
            return <CBody key={`list-transaction-${i}`} order={i+1} {...list} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Lampiran