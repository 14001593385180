import React from 'react'
import dayjs from 'dayjs';

// Components
import CardParticipant from './card-participant'

// Api
import { useGetDetailBatch } from 'api/training/query';

// Assets
import { ReactComponent as LockOpen } from "assets/lock-open-1.svg"
import { ReactComponent as LockLocked } from "assets/lock-locked.svg"

const CardBatch = ({ id, trainingId, tab, start_date, end_date }) => {
  const { data: detailBatch } = useGetDetailBatch({ batchId: id, params: {} })
  const isLocked = dayjs().isAfter(detailBatch?.data?.data?.end_date)

  const getParticipants = () => {
    const arrParticipant = detailBatch?.data?.data?.participants ?? [];

    if (!arrParticipant?.length) return null;

    if (tab === "peserta-lulus") {
      return arrParticipant?.filter(part => part.is_passed)
    }

    return arrParticipant?.filter(part => !part.is_passed)
  }

  return (
    <div className='w-full py-2 px-4'>
      <div className='rounded bg-[#f5f5f5] mb-2 last:mb-0'>
        <div className='rounded-t px-4 py-2'>
          <div className='flex items-center justify-between mb-1'>
            <p className='text-[11px] text-[#808285] mr-2'>Batch: {id}</p>
            {dayjs().isAfter(end_date) ? null : isLocked ? (
              <button className='flex items-center'>
                <div className='w-[12px] h-[12px] mr-0.5'>
                  <LockLocked />
                </div>
                <p className='text-[11px] text-[#FD4949]'>Pendaftaran ditutup</p>
              </button>
            ) : (
              <button className='flex items-center'>
                <div className='w-[12px] h-[12px] mr-0.5'>
                  <LockOpen />
                </div>
                <p className='text-[11px] text-[#808285]'>Pendaftaran dibuka</p>
              </button>
            )}
          </div>
          <p className='text-[13px] font-semibold'>{dayjs(start_date).format("DD/MM/YYYY")}-{dayjs(end_date).format("DD/MM/YYYY")}</p>
          {/* <p className='text-[13px] font-semibold'>{start_date?.replace("-","/")}-{end_date?.replace("-","/")}</p> */}
        </div>
        <div className='h-0.5 border-b-[1px] border-solid border-[#D8D8D9]' />
        {!!getParticipants()?.length ? (
          <div className='rounded-b px-4 py-2'>
            {getParticipants().map((participants, idx) => {
              return <CardParticipant key={`list-participant-${trainingId}-${participants?.id}-${idx}`} {...participants} />
            })}
          </div>
        ) : (
          <div className='p-4 rounded-b '>
            <p className='text-[13px] text-[#808285] mr-2'>Belum ada peserta</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardBatch