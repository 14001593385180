import React, { useState, useContext } from 'react'

//Styles
import "./style.scss"

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as MagnifierIcon } from "assets/magnifier.svg";

//Components
import { CModal, CSearchV1, Divider, Button } from "components";

import { DashboardCtx } from "../../context/LeadDashboard";

function ModalChooseAgent() {
  const { agentsList, onCloseModalAgents, onContinueModalAgents } = useContext(DashboardCtx);
  const [agents, setAgents] = useState([...agentsList])
  const [selectedAgent, setSelectedAgent] = useState(null)

  const searchAgent = (e) => {
    const value = e?.target?.value
    if (!agentsList?.length || !value || typeof value != "string") return setAgents(agentsList)

    const newAgent = agentsList.filter(agent => {
      if (agent?.full_name && typeof agent?.full_name == "string") {
        return agent.full_name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      }
    })
    setAgents(newAgent)
  }

  //Classnames
  const selectedAgentClass = (name) => selectedAgent?.full_name == name ? " selected" : ""

  return (
    <CModal toggle={onCloseModalAgents} id="leads-dashboard-select-agent">
      <div className='modal-content'>
        <div className='search-section'>
          <p className='search-suggestion'>Kamu yakin mengubah “<span>PIC”</span> dari leads ini?</p>
          <p className='search-header'>Pilih Marketing untuk leads yang dipilih</p>
          <CSearchV1
            onChange={searchAgent}
            className="search-agent"
            Icon={({ className }) => <MagnifierIcon className={className} />}
            placeholder="Siapa yang kamu cari?"
          />
          <p className='search-example'>Contoh. “robert fox”</p>
          <Divider />
          <p className='search-info'>Marketing associate yang dipilih</p>
          {
            selectedAgent?.full_name ? <p className='search-info-large selected-agent'>{selectedAgent.full_name}</p>
              :
              <p className='search-info-large'>Belum ada marketing associate yang dipilih...</p>
          }
        </div>
        <div className='list-section'>
          <div className='close-btn-wrapper'><CrossIcon onClick={onCloseModalAgents} className='close-icon' /></div>
          <p className='list-header'>Silahkan pilih marketing.</p>
          <div className='list-content'>
            {
              agents?.length ? agents.map((agent, key) => {
                return (
                  <button
                    className={`agent-name${selectedAgentClass(agent.full_name)}`}
                    key={`agent-name-${key}`}
                    onClick={() => setSelectedAgent(agent)}
                  >
                    <span>{agent.full_name}</span>
                  </button>
                )
              })
                : null
            }
          </div>
          <div className='btn-wrapper'>
            <Button
              className="choose-agent-btn"
              text="Batal"
              action={() => onCloseModalAgents()}
            />
            <Button
              disabled={!selectedAgent}
              className="choose-agent-btn"
              text="Pilih"
              action={() => onContinueModalAgents(selectedAgent)}
            />
          </div>
        </div>
      </div>
    </CModal>
  )
}

export default ModalChooseAgent