import { useState, useEffect, useMemo } from 'react'

// Components
import ModalV2 from 'components/organism/modal-v2';
import Button from 'components/v1/Button';

// Assets
import { ReactComponent as DownloadIcon } from "assets/download-v1.svg"
import { ReactComponent as XIcon } from "assets/x-v1.svg"
import { ReactComponent as CheckList } from "assets/checklist-v1.svg"
import { ReactComponent as ArrowSlider } from "assets/arrow-slider-1.svg"
import PDFIcon from "assets/pdf.png"

//Actions
import { getMarketingAgreement } from "actions";

// Context
import { useGlobalCtx } from 'contexts';

const CFile = ({ file, handleDownloadFile }) => {
  if (file.attachment.includes(".pdf")) {
    return (
      <div className='w-[715px] mx-[40px] h-full mb-6 flex items-center justify-center flex-col'>
        <img className='w-[100px] h-[100px] mb-6 object-contain' src={PDFIcon} alt={file?.name ?? "pdf"} />
        <div onClick={() => handleDownloadFile(file)} className='flex items-center cursor-pointer'>
          <p className='textbase text-white mr-4'>Download PDF</p>
          <DownloadIcon className='w-6 h-6 cursor-pointer object-contain mr-6' />
        </div>
      </div>
    )
  }
  return (
    <div className='w-[715px] mx-[40px] h-full'>
      <img className='w-full h-full object-contain' src={file.attachment} alt={file?.name ?? "file"} />
    </div>
  )
}

const ModalDetailPjp = ({
  onClose,
  propertyLocation,
  title,
  isOpen,
  allFiles,
  handleDownloadFile,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex === allFiles.length - 1) return;

    setCurrentIndex(currentIndex + 1)
  }

  const handlePrev = () => {
    if (currentIndex === 0) return;

    setCurrentIndex(currentIndex - 1)
  }

  return (
    <ModalV2 isOpen={isOpen} {...props}>
      <div className='w-full h-full z-[42] bg-black flex flex-col items-center justify-center'>
        <div className='pt-4 pb-4 px-[140px] flex justify-between w-full 
          border-b-[0.5px] border-solid border-black-7 border-opacity-10'>
          <div>
            <p className='text-white text-base mb-0.5'>{title}</p>
            <p className='text-gray-5 text-xs uppercase'>{propertyLocation?.area?.name}, {propertyLocation?.city?.name}</p>
          </div>
          <div className='flex items-center'>
            <DownloadIcon onClick={() => handleDownloadFile(allFiles[currentIndex])} className='w-6 h-6 cursor-pointer object-contain mr-6' />
            <XIcon onClick={onClose} className='cursor-pointer w-4 h-4 object-contain' />
          </div>
        </div>

        <div className='flex justify-center items-center h-[calc(100vh-75px-85px)] w-full'>
          <ArrowSlider onClick={handlePrev} className='rotate-180 cursor-pointer' />
          {!!allFiles[currentIndex] && (
            <CFile file={allFiles[currentIndex]} handleDownloadFile={handleDownloadFile} />
          )}
          <ArrowSlider onClick={handleNext} className='cursor-pointer' />
        </div>

        <div className='flex items-center justify-center w-full pt-2 pb-4 border-t-[0.5px] 
          border-solid border-black-7 border-opacity-10'>
          {!!allFiles?.length && allFiles.map((file, i) => {
            const handleCurrentIdx = () => {
              setCurrentIndex(i)
            }

            const isNotActive = currentIndex !== i;

            if (file.attachment.includes(".pdf")) {
              return (
                <div onClick={handleCurrentIdx} className={`${isNotActive && "opacity-[0.4]"} w-[60px] h-[60px] cursor-pointer rounded-[8px]`} key={`file-carousel-${i}`}>
                  <img className='w-full h-full object-cover rounded-[8px]' src={PDFIcon} alt={file?.name ?? "pdf"} />
                </div>
              )
            }
            return (
              <div onClick={handleCurrentIdx} className={`${isNotActive && "opacity-[0.4]"} w-[60px] h-[60px] cursor-pointer rounded-[8px]`} key={`file-carousel-bottom-${i}`}>
                <img className='w-full h-full object-cover rounded-[8px]' src={file.attachment} alt={file?.name ?? "file"} />
              </div>
            )
          })}
        </div>
      </div>
    </ModalV2>
  )
}

const ButtonDetailPjp = ({ propertyLocation, marketingAgreementUUID, idListing, title, ...props }) => {
  const { setShowLoading } = useGlobalCtx()
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState(null);

  const allFiles = useMemo(() => {
    let _files = [...images];

    if (!!attachments?.url) {
      _files.unshift({
        attachment: attachments.url,
        id: null,
        name: null
      })
    }

    return _files
  }, [images, attachments])

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleDownloadFile = async (file) => {
    const url = file.attachment;

    if (!url) return;
    try {
      setShowLoading(true);
      const isPDF = url.includes(".pdf");
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `PJP #${idListing}.${isPDF ? "pdf" : "png"}`; // Change the file name if needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    } finally {
      setShowLoading(false)
    }
  };

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleFetchMarketingAgreement = async () => {
    try {
      setIsLoading(true);
      const res = await getMarketingAgreement(marketingAgreementUUID);
      const data = res?.data ?? {}

      setImages(data?.data?.files ?? [])
      setAttachments(data?.data?.attachment ?? null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleFetchMarketingAgreement()
  }, [marketingAgreementUUID])

  if(!allFiles?.length) return null;
  return (
    <div>
      <div className='flex items-center justify-between px-[12px] py-2 bg-yellow-2 rounded-md border-[0.5px] border-solid border-yellow-0 
      shadow-[0px_4px_64px_0px_rgba(16,16,16,0.08)] mb-[40px]'>
        <div className='flex items-center'>
          <CheckList className='w-6 h-6 object-contain mr-2' />
          <p className='text-sm'>Listing ini sudah memiliki PJP</p>
        </div>
        <Button
          text="Lihat PJP"
          onClick={handleOpenModal}
          disabled={isLoading}
        />
      </div>
      {isOpenModal && !!allFiles?.length && <ModalDetailPjp
        isOpen={isOpenModal && !!allFiles?.length}
        onClose={handleCloseModal}
        propertyLocation={propertyLocation}
        title={title}
        handleDownloadFile={handleDownloadFile}
        allFiles={allFiles}
        {...props}
      />}
    </div>
  )
}

export default ButtonDetailPjp