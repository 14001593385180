import React from 'react'

//Styles
import Styles from "./style.module.scss";

//Assets - svg
import { ReactComponent as Check } from "assets/check-white.svg";

//Hooks
import useEnterKey from "hooks/useEnterKey"

function ButtonSelanjutnya(props) {
  useEnterKey(() => {
    props.onClick && props.onClick();
  })
  return (
    <button {...props} className={`${Styles['btn']} ${props.className || ""}`}>
      <span className={Styles['text']}>Selanjutnya</span>
      <Check className={Styles['icon']} />
    </button>
  )
}

export default ButtonSelanjutnya