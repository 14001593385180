import React from 'react'

//Components
import { ModalConfirmasiPindahHalaman } from "../components";
import { ListingHeader, ListingForms, ListingNavbar } from "./components";

function ComponentsRenderer() {
  return (
    <div id='upload-listing' className='nav-content-wrapper'>
      <ListingHeader />

      <div className='process-container'>
        <ListingNavbar />
        <ListingForms />
      </div>

      <ModalConfirmasiPindahHalaman />
    </div>
  )
}

export default ComponentsRenderer