import React, { useState } from 'react'
import Modal from "components/organism/modal-v2"
import { ReactComponent as InfoIcon } from "assets/info-1.svg"
import { ReactComponent as XIcon } from "assets/x-black.svg"
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import { toast } from 'react-toastify'

import Button from 'components/v1/Button'

import { confirmVisitScheduled } from 'actions'

import { useGlobalCtx } from 'contexts'

dayjs.locale("id");

const ModalChooseSchedule = ({ visitSchedules, id, onClose, ...props }) => {
  const { setShowLoading } = useGlobalCtx()
  const [approvedSchedule, setApprovedSchedule] = useState([]);

  const handleSubmit = async (schedule) => {
    try {
      setShowLoading(true)
      await confirmVisitScheduled(id, { "visit_schedule_id": schedule.id })

      let newArr = [...approvedSchedule, schedule]
      setApprovedSchedule(newArr)

      setShowLoading(false)
    } catch (error) {
      console.log(error)
      setShowLoading(false)
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    }
  }

  return (
    <Modal {...props}>
      <div className='z-[42] bg-white w-[488px] rounded-2xl py-6 px-8'>
        <div className='flex justify-end mb-4'>
          <XIcon onClick={onClose} className='cursor-pointer w-4 h-4 object-contain' />
        </div>
        <p className='text-lg flex items-center mb-2'><InfoIcon className='w-6 h-6 object-contain mr-1.5' /> Jadwal Kunjungan Pilihan Customer</p>
        <p className='text-sm text-gray-2 mb-2 pb-2'>Berikut ini adalah waktu kunjungan yang dipilih customer.</p>

        {!!visitSchedules?.length && visitSchedules.map((s, idx) => {

          if (!!approvedSchedule?.length && !!approvedSchedule.find(schedule => s?.id === schedule?.id)) return null;
          return (
            <button onClick={() => { handleSubmit(s) }} key={`schedule-${idx}`} className='cursor-pointer p-4 w-full border-[0.5px] border-solid border-white-0 bg-white-2 mb-2 rounded-[8px] last:mb-0'>
              <p className='text-start text-xs text-black-8'>Pilihan #${idx + 1}:</p>
              <div className='flex items-center justify-between'>
                <p className='text-base font-medium'>{dayjs(s?.schedule_at).format("dddd, DD MMMM YYYY")}</p>
                <p className='text-base'>{dayjs(s?.schedule_at).format("h:mm a")}</p>
              </div>
            </button>
          )
        })}


        <div className='flex justify-end mt-4'>
          <Button size="l" onClick={onClose} text="Mengerti" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalChooseSchedule isOpen={isOpen} {...props} />
}

export default Wrapper