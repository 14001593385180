import { useRef } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const useHtmlToPdf = () => {
  const htmlToPdfRef = useRef(null);

  const convertToPdf = async (setShowLoading, pdfName, imgWidth, imgHeight) => {
    try {
      setShowLoading && setShowLoading(true)
      const input = htmlToPdfRef.current;

      const width = imgWidth;
      const height = imgHeight;

      const canvasOptions = {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
      const canvas = await html2canvas(input, canvasOptions);
      const imgData = canvas.toDataURL('image/png');

      const pdfOptions = {
        orientation: 'p',
        unit: 'px',
        format: [width, height],
      };

      const pdf = new jsPDF(pdfOptions);
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, height);
      pdf.save(`${pdfName}.pdf`);
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading && setShowLoading(false)
    }
  };

  return {
    htmlToPdfRef,
    convertToPdf
  }
}

export default useHtmlToPdf