import React, { useContext, useMemo } from 'react'

//Styles
import Styles from './marketing.module.scss'

//Components
import Search from './components/search'
import Table from './components/table'
import ModalLoader from 'components/organism/ModalLogout';

//Hooks
import { useDetailListingState, MenuMarketingProvider } from './context'

//Context
import { GlobalContext } from 'contexts';

const Marketing = () => {
  const { profile } = useContext(GlobalContext);

  const {
    openModalLoader,
    loading,
    page,
    colleagues,
    totalData
  } = useDetailListingState();

  const companyName = useMemo(() => {
    if (profile && profile.company) {
      return profile.company.name
    }
    return ''
  }, [profile])

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['content']}>
        <p className={Styles['text-header']}>Office Dashboard</p>
        <p className={Styles['title']}>Century 21 <span>{companyName}</span></p>
        <div className={Styles['search-container']}>
          <Search />
          <p className={Styles['total']}><span>{totalData}</span> Marketing</p>
        </div>

        <Table />
      </div>
      {(page == 1) && <ModalLoader open={openModalLoader || loading} />}
    </div>
  )
}

const Wrapper = () => <MenuMarketingProvider><Marketing /></MenuMarketingProvider>

export default Wrapper;