import React, { useState, useEffect } from 'react'

import InputLoading from "./InputLoading";

function CSelectV1({
  options,
  label,
  placeholder,
  value,
  name,
  className,
  selectClassName,
  displayName,
  valueName,
  onSelect,
  disabled,
  useLoading = false,
  ComponentLabel,
  disablePlaceholder = true
}) {
  const [loading, setLoading] = useState(useLoading)

  const onSelectHandler = (e) => {
    onSelect(e, options, valueName)
  }

  useEffect(() => {
    if(loading){
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  return (
    <div className={`c-select-v1 ${className}`}>
      <p className='label'>{label} {ComponentLabel ? ComponentLabel : null}</p>
      <div className={`select-wrapper ${disabled ? "disabled" : ""}`}>
        {
          loading ? <InputLoading /> :
            <select
              name={name}
              className={`${selectClassName}${!value[valueName] ? " default" : ""}`}
              value={value[valueName] || ""}
              onChange={onSelectHandler}
              disabled={disabled}
            >
              <option className='defaultValue' disabled={disablePlaceholder} value={""}>{placeholder}</option>
              {options.length ? options.map((option, key) => <option key={key} value={option[valueName]}>{option[displayName]}</option>) : null}
            </select>
        }

      </div>
    </div>
  )
}

CSelectV1.defaultProps = {
  options: [],
  placeholder: "Select you option",
  displayName: "",
  valueName: "",
  value: {},
  onSelect: () => { },
}

export default CSelectV1