import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';

//Utils
import { getToken, setToken, removeToken } from "../utils";

//Actions
import { authAction } from "../actions";

function useAuth() {
  const tokenValidation = () => {
    const token = getToken();
    if (!token) return null;

    if (dayjs().isAfter(token.expiry)) {
      removeToken()
      return null
    }

    return token;
  }

  const [accessToken, setAccessToken] = useState(tokenValidation());

  const logoutAction = async (action) => {
    try {
      // const logout = await authAction.signOut();

      setAccessToken(null)
      removeToken()

      action && action()
    } catch (error) {
      console.log(error)
    }
  }

  const loginAction = async (data, callback) => {
    await authAction
      .requestLoginWeb(data)
      .then(res => res.data)
      .then(async (res) => {
        const { token, expired_at } = res;

        setToken(token, expired_at)
        setAccessToken(res)

        const auth = await authAction.getUserDetail()

        let path = "/"
        if (auth?.data?.role?.name == "Member Broker") {
          path = "/ereporting"
        }
        if (typeof callback == "function") callback(token, path)
      })
      .catch(err => {
        console.log(err)
        removeToken()
        setAccessToken(null)
      })
  }


  return {
    accessToken,
    tokenValidation,
    logoutAction,
    loginAction,
  }
}

export default useAuth