import React from 'react'

//Components
import { ModalLoader } from "components/organism"
import { LTHeader, LTBody, LTFooter } from "pages/dashboard/e-reporting/TableTransaksi/TTComponents";

//Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

const TTMain = () => {
  const { fetchingTransaction } = useTTCtx()
  return (
    <div>
      {/* MAIN Content */}
      <LTHeader />
      <LTBody />
      <LTFooter />

      {/* Modal */}
      <ModalLoader open={fetchingTransaction} />
    </div>
  )
}

export default TTMain