import React from 'react'

import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";

function BackButtonV1({
  onClick,
  className = "",
  text = "Kembali"
}) {
  return (
    <button onClick={onClick && onClick} className={`back-button-v1 ${className}`}>
      <LeftArrow className='back-icon' />
      <span>{text}</span>
    </button>
  )
}

export default BackButtonV1