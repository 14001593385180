import React from 'react'

// Components
import TableMenunggu from "./TableMenunggu"
import TableDisetujui from "./TableDisetujui"
import TableBelumLunas from "./TableBelumLunas"

//Context - TTCtx
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

function LTTable() {
  const { selectedTransactionStatus } = useTTCtx();

  if (selectedTransactionStatus === "bertahap") return <TableBelumLunas />
  if (selectedTransactionStatus === "selesai") return <TableDisetujui />
  return <TableMenunggu />
}

export default LTTable