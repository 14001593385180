import { createContext, useContext, useState, useEffect, useMemo } from "react";
import _ from "lodash"
import dayjs from "dayjs";

//Functions
const DataKeseluruhanCtx = createContext(null);

export const DataKeseluruhanProvider = ({ children }) => {
  const [numberOfRange, setNumberOfRange] = useState(1);
  const [periodRange, setPeriodRange] = useState({
    label: 'Minggu lalu',
    value: 'weeks'
  });

  const [uploads, setUploads] = useState(null);
  const [shares, setShares] = useState(null);

  return (
    <DataKeseluruhanCtx.Provider value={{
      uploads,
      shares,
      numberOfRange, 
      periodRange,
      setPeriodRange,
      setNumberOfRange
    }}>
      {children}
    </DataKeseluruhanCtx.Provider>
  )
}

//Custom hooks for the values
export const useDetailListingCtx = () => {
  const ctx = useContext(DataKeseluruhanCtx);

  if (!ctx) {
    throw new Error("useDetailListingCtx must be used within the DataKeseluruhanProvider");
  }

  return ctx;
};
