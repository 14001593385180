//Utils
import { api } from "../utils";

//Constant
import {
  //URL
  COMPANIES_LIST,
  USERS,
  PROVINCES,
  COUNTRIES,
  CITIES,
  GET_KPR,
  TRSANCTION_LIST
} from "../constant/URL";

const getCompanies = async (query = "") =>  await api().get(`${COMPANIES_LIST}${query}`)
const getAgentByCompany = async (id="", query = "") => await api().get(`${COMPANIES_LIST}/${id}/users${query}`)
const getListListing = async (id="", query = "") => await api({}, true).get(`${USERS}/${id}/properties${query}`) 
const getProvince = async (query = "") => await api().get(`${COUNTRIES}/1/provinces${query}`)
const getCities = async (provinceid = "", query = "") => await api().get(`${PROVINCES}/${provinceid}/cities${query}`)
const getAreas = async (cityId = "", query = "") => await api().get(`${CITIES}/${cityId}/areas${query}`)
const getKPR = async (query = "") => await api().get(`${GET_KPR}${query}`)

const postTransactionReport = async (payload) => await api().post(`${TRSANCTION_LIST}`, payload)
const editTransaction = async (payload, id) => await api().patch(`${TRSANCTION_LIST}/${id}`, payload)
const approveTransaction = async (id) => await api().patch(`${TRSANCTION_LIST}/${id}/approve`)
const approveBulkTransaction = async (payload) => await api().patch(`${TRSANCTION_LIST}/bulk-approve`, payload)
const commisionCalculate = async (payload) => await api().post(`${TRSANCTION_LIST}/commission-calculate`, payload)

const eReportingAction = {
  getCompanies,
  getAgentByCompany,
  getListListing,
  getProvince,
  getCities,
  getAreas,
  getKPR,
  postTransactionReport,
  approveTransaction,
  editTransaction,
  approveBulkTransaction,
  commisionCalculate
}

export default eReportingAction