import React, { useMemo } from 'react'

//Styles
import Styles from "./modal-listing.module.scss";

//Components
import Modal from 'pages/dashboard/e-reporting/forms/components/reuseables/modal'

//Assets
import { ReactComponent as CrossIcon } from "assets/cross.svg";

//Hooks
import useClassHandler from 'hooks/useClassHandler'
import { useGetValueByLodash, useDivideByLodash } from 'hooks/useLodash'

function ModalListing({ onClose, listing, onNext }) {
  const classHandler = useClassHandler(Styles);
  const getValue = useGetValueByLodash(listing);
  const divide = useDivideByLodash();

  const list = useMemo(() => ({
    image_url: getValue('summary.property_images.image.thumbnail.url') ?? "https://dummyimage.com/389x373/#fff/000000",
    price: getValue('price')?.toLocaleString("id-ID"),
    street: getValue(["summary", "property_locations", "0", "street"]),
    title: getValue('title') ?? "",
    id: getValue('id') ?? "",
    type: getValue('property_type.name') ?? "",
    agent: getValue('user.full_name') ?? "",
    kantor_agent: getValue('user.company.name') ?? "",
    isRent: getValue('property_type.name') == "Disewakan" ? true : false,
    period: (divide(getValue('min_rent'), 365) || 0) >= 1 ? "tahun" : "bulan"
  }), [listing])

  return (
    <Modal toggle={onClose}>
      <div className={classHandler('wrapper-content')}>
        <div className={classHandler('left')}>
          <div className={classHandler('image')}>
            <img src={list.image_url} alt='listing-image' />
          </div>
          <p className={classHandler('left-title')}>Kamu yakin lanjut membuat transaksi untuk listing ini?</p>
          <p className={classHandler('info')}>Setelah memilih untuk melanjutkan, kamu akan diarahkan untuk mengisi beberapa data yang diperlukan. Proses pengisian data terdiri atas 3 bagian, dengan waktu yang dibutuhkan kira-kira 10 menit. Pastikan kamu menyediakan waktu yang cukup.</p>
        </div>
        <div className={classHandler('right')}>
          <div>
            <div className={classHandler('wrapper-close')}>
              <CrossIcon onClick={onClose} />
            </div>
            <p className={classHandler('right-title')}>Detail listing</p>
            <p className={classHandler('price')}>Rp. {list.price} {list.isRent ? <span>{` / ${list.period}`}</span> : null}</p>
            <p className={classHandler('title')}>{list.title}</p>
            <p className={classHandler('street')}>{list.street}</p>

            <div className={classHandler('label-wrapper')}>
              <p className={classHandler(list.isRent ? "sewa" : "jual")}>{list.type}</p>
              <p className={classHandler('id')}><span className={classHandler('hash')}>ID</span> #{list.id}</p>
            </div>

            <div className={Styles['agent-info']}>
              <p className={classHandler('nama')}>Nama Agent</p>
              <p className={classHandler('value')}>{list.agent}</p>
            </div>
            <div className={Styles['agent-info']}>
              <p className={classHandler('nama')}>Kantor</p>
              <p className={classHandler('value')}>{list.kantor_agent}</p>
            </div>
          </div>

          <div className={classHandler('btn-wrapper')}>
            <button className={classHandler('batal')} onClick={onClose}>Batal</button>
            <button className={classHandler('lanjutkan')} onClick={onNext}>Ya, lanjutkan</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function withModal(Modal) {
  const HOCModal = (props) => {
    if (!props.open) return null

    return <Modal {...props} />
  }
  return HOCModal
}

export default withModal(ModalListing)