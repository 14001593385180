import React, { useEffect, useState, useRef } from 'react'

//Styles
import "./style.scss"
import { useDownloadExcel } from "react-export-table-to-excel";

//Assets
import { ReactComponent as UpDown } from "assets/updown.svg"

function Table({
  lowest,
  highest,
  total,
  rekap,
  filterSetter,
  numberOfRange,
  getRekapDataRefresh
}) {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Rekap data kantor",
    sheet: "Rekap data kantor"
  });


  const handleColor = (point, pointComparison, className) => {
    if (point == pointComparison) return className
    return ""
  }

  const activeAgentHandler = (upload, share) => {
    return upload + share >= (9 * numberOfRange) ? true : false
  }

  return (
    <div className='table-wrapper'>
      <div className='top'>
        <p>Rekap data per marketing</p>
        <div className='table-wrapper-right'>
          <button onClick={getRekapDataRefresh} className='export-refresh'>Refresh Data</button>
          <button onClick={onDownload} className='export-excel'>Ekspor ke Excel</button>
        </div>
      </div>
      <div className='tabel-rdk-wrapper'>
        <table ref={tableRef}>
          <thead>
            <tr>
              <td>No</td>
              <td>Nama Marketing</td>
              <td onClick={() => filterSetter('upload')}>Upload listing <UpDown className='updown' /></td>
              <td onClick={() => filterSetter('share')}>Share listing <UpDown className='updown' /></td>
              <td onClick={() => false && filterSetter('search')}>Search listing <UpDown className='updown' /></td>
              <td onClick={() => false && filterSetter('property')}>Property view <UpDown className='updown' /></td>
              <td onClick={() => filterSetter('active')}>Active Marketing <UpDown className='updown' /></td>
              {/* <td>Weekly active agent <UpDown className='updown' /></td> */}
            </tr>
          </thead>
          <tbody>
            {rekap?.data?.length ? (
              rekap.data.map((rek, key) => {
                return <tr key={`rekap-data-kantor-${key}`}>
                  <td>{key + 1}</td>
                  <td className={rek?.weekly_active_agent?.total >= 18 ? "highest" : null}>{rek?.name}</td>
                  <td className={handleColor(rek?.total_upload, lowest?.total_upload, "lowest") + " " + handleColor(rek?.total_upload, highest?.total_upload, "highest")}>{rek?.total_upload}</td>
                  <td className={handleColor(rek?.total_share, lowest?.total_share, "lowest") + " " + handleColor(rek?.total_share, highest?.total_share, "highest")}>{rek?.total_share}</td>
                  <td>N/A</td>
                  <td>N/A</td>

                  <td>
                    <span className={`${!activeAgentHandler(rek?.total_upload, rek?.total_share) ? 'lowest' : 'highest'}`}>{`${!activeAgentHandler(rek?.total_upload, rek?.total_share) ? 'No' : 'Yes'}`}</span>
                  </td>
                  {/* <td>
                    <span className={handleColor(rek?.daily_active_agent?.total, lowest?.daily_active_agent_total?.total, "lowest") + " " + handleColor(rek?.daily_active_agent?.total, highest?.daily_active_agent_total?.total, "highest")}>{rek?.daily_active_agent?.total}</span>/
                    <span className={handleColor(rek?.daily_active_agent?.percent, lowest?.daily_active_agent_percent?.percent, "lowest") + " " + handleColor(rek?.daily_active_agent?.percent, highest?.daily_active_agent_percent?.percent, "highest")}>{rek?.daily_active_agent?.percent}%</span>
                  </td> */}
                  {/* <td>
                    <span className={handleColor(rek?.weekly_active_agent?.total, lowest?.weekly_active_agent_total?.total, "lowest") + " " + handleColor(rek?.weekly_active_agent?.total, highest?.weekly_active_agent_total?.total, "highest")}>{rek?.weekly_active_agent?.total}</span>/
                    <span className={handleColor(rek?.weekly_active_agent?.percent, lowest?.weekly_active_agent_percent?.percent, "lowest") + " " + handleColor(rek?.weekly_active_agent?.percent, highest?.weekly_active_agent_percent?.percent, "highest")}>{rek?.weekly_active_agent?.percent}%</span>
                  </td> */}
                </tr>
              })
            ) : null}
          </tbody>
          <tfoot>
            <tr>
              <td>All </td>
              <td></td>
              <td>{total?.total_upload}</td>
              <td>{total?.total_share}</td>
              <td>N/A</td>
              <td>N/A</td>
              <td></td>
              {/* <td>{total?.daily_active_agent?.total}/{total?.daily_active_agent?.percent}%</td> */}
              {/* <td>{total?.weekly_active_agent?.total}/{total?.weekly_active_agent?.percent}%</td> */}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default Table