import { useState, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import "dayjs/locale/id";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

//Styles
import "./style.scss"

//Components
import Calendar from "pages/dashboard/key-metric-office-dashboard/reuseable-component/calendar"

//API
import { getRekapWeeklyActiveAgent } from "actions/quartery-reporting";

//Context
import { useDetailListingCtx } from "../../data-keseluruhan/ctxDataKeseluruhan";
import Loader from 'components/molecules/Loader'

const MyStockChart = ({ _selectedDate }) => {
  const {
    numberOfRange,
    periodRange
  } = useDetailListingCtx();
  const chartRef = useRef(null)

  //Upload
  const [summary, setSummary] = useState([])
  const [loading, setLoading] = useState(true)

  // const [selectedDate, setSelectedDate] = useState(dayjs())
  const selectedDate = useMemo(() => {
    return dayjs(_selectedDate)
  }, [_selectedDate])

  const [currentDate, setCurrentDate] = useState({
    from: "",
    to: ""
  })

  //Using
  const Chart = useMemo(() => {
    const data = summary.map(sum => sum.summary.waa);
    const categories = summary.map(sum => `${sum.period.date_from} - ${sum.period.date_to}`);

    const options = {
      chart: {
        type: 'column',
        backgroundColor: "#141414",
      },
      rangeSelector: {
        enabled: false
      },
      subtitle: {
        text: `${dayjs(currentDate.from).format('DD MMMM YYYY')} - ${dayjs(currentDate.to).format('DD MMMM YYYY')}`,
        align: 'right',
        style: {
          color: '#FFFFFF',
          fontFamily: 'SF Pro Display',
          fontSize: '17px',
          fontWeight: '600',
        }
      },
      title: {
        text: '',
        align: 'left',
        style: {
          color: '#A7A7A7',
          fontFamily: 'SF Pro Display',
          fontSize: '17px',
          fontWeight: '600'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            y: -120,
            style: {
              fontSize: 'calc(8px + 100%)',
              color: '#ffffff',
              fontFamily: 'SF Pro Display',
              fontWeight: '600'
            }
          },
        }
      },
      series: [{
        data
      }],
      yAxis: {
        gridLineColor: "#4F4F4F",
        gridLineDashStyle: "Dash",
        title: {
          text: "Total Marketing",
          style: {
            color: '#4F4F4F',
            fontFamily: 'SF Pro Display',
            fontSize: '13px',
            fontWeight: '400'
          }
        },
        opposite: false
      },
      xAxis: {
        tickWidth: 0,
        categories,
        labels: {
          style: {
            color: '#FFFFFF',
            fontFamily: 'SF Pro Display',
            fontSize: '13px',
            fontWeight: '600'
          }
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: { enabled: false },
      credits: {
        enabled: false
      },
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    )
  }, [summary])

  //Functions apis
  const handleGetWaaSummary = async (from, to) => {
    try {
      setLoading(true)
      const query = `?date[from]=${from}&date[to]=${to}`
      const getWAA = await getRekapWeeklyActiveAgent(query)
      setSummary(getWAA?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  /**USE EFFECTS */
  useEffect(() => {
    setCurrentDate({
      from: dayjs(selectedDate).subtract(numberOfRange || 1, periodRange.value).add(1, 'day').format("YYYY-MM-DD"),
      to: dayjs(selectedDate).format("YYYY-MM-DD")
    })
  }, [numberOfRange, periodRange, selectedDate])

  useEffect(() => {
    const { from, to } = currentDate;
    if (from && to) {
      handleGetWaaSummary(from, to)
    }
  }, [currentDate])

  return (
    <div className='rekap-data-chart'>
      <div className='calendar-wrapper'>
        {/* <Calendar
          selectedDate={selectedDate.toDate()}
          currentDate={currentDate}
          setDate={(date) => setSelectedDate(dayjs(date))}
        /> */}
      </div>
      <div className='chart-container'>
        <p className='title'>Rekap Weekly Active Marketing</p>
        <p className='total'>Marketing Terdaftar: {summary?.[0]?.summary?.all_user_active ?? 0}</p>
        {Chart}
      </div>
    </div>
  )
}
export default MyStockChart