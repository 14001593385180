import React from 'react'
import _ from "lodash"
import "./style.scss"

//Assets
import Megaphone from "assets/megaphone.png"
import LockKey from "assets/lock-key.png"

//Context
import { useDetailListingCtx } from "../../Context"

function Address() {
  const { getValue } = useDetailListingCtx()

  const locations = getValue("summary.property_locations") || []

  const Publish = locations.filter(l => l?.is_publish)?.[0]
  const UnPublish = locations.filter(l => !l?.is_publish)?.[0]

  return (
    <div className='listing-detail-address'>
      <div className='detail-address-card mb-[32px]'>
        <img className='address-card-icon' src={Megaphone} alt="megaphone" />
        <div className='address-content-wrapper'>
          <p className='address-header'>Alamat yang dipublikasikan</p>
          <p className='address-content'>{Publish?.street ?? ""}</p>
        </div>
      </div>

      <div className='detail-address-card'>
        <img className='address-card-icon' src={LockKey} alt="lockkey" />
        <div className='address-content-wrapper'>
          <p className='address-header'>Alamat yang <span>tidak</span> dipublikasikan</p>
          <p className='address-content'>{UnPublish?.street}</p>
        </div>
      </div>
    </div>
  )
}

export default Address