import React from 'react'

import BGBack from "assets/back-id-card-v2.jpg";

const Field = ({ label, text1, text2 }) => (
  <div className='flex items-start mb-[6px] last:mb-0'>
    <span className="text-gold-2 font-barlow-semi-condensed text-[11px] mr-2">{label}</span>
    <div className='flex flex-col'>
      <span className="text-gold-2 font-barlow-semi-condensed text-[11px]">{text1}</span>
      <span className="text-gold-2 font-barlow-semi-condensed text-[11px]">{text2}</span>
    </div>
  </div>
)

const BackCardV2 = ({
  refID,
  fullname,
  companyName,
  registrationNumber,
  role,
  phone,
  street,
  email,
  qrCodeUrl
}) => {
  return (
    <div ref={refID} className='relative w-[188px] h-[321px]'>
      <img src={BGBack} alt='Back Card' className='absolute z-10 w-full h-full' />

      <div className='absolute z-20 object-contain p-6'>
        <p className='text-gold-2 font-typold-condensed-medium text-[15px]'>{fullname}</p>
        <p className='text-gold-2 font-typold-extended-regular text-[10px] mb-[9px]'>{role}</p>
        <Field label="M" text1={phone} />
        <Field label="E" text1={email} text2={`${companyName.split(" ").join("")}@century21.co.id`} />
        <Field label="A" text1={street} />
      </div>

      <div className='bg-white absolute z-[21] left-[23.5px] bottom-[20px] w-[75px] h-[75px] rounded'>
        <img src={qrCodeUrl} alt='QR Code' className='w-full h-full object-contain' />
      </div>
    </div>
  )
}

export default BackCardV2