import React, { useState } from 'react'

//Styles
import Styles from "./input-commision.module.scss";

//Assets - svg
import { ReactComponent as XBlack } from "assets/x-black.svg";
import { ReactComponent as Percent } from "assets/percent-black.svg";

function InputCommision({ className, disabled, ...props }) {

  return (
    <div className={`${Styles['wrapper']} ${disabled ? Styles['disabled'] : ""}`}>
      <Percent className={Styles['percent']} />
      <input {...props} disabled={disabled} />
      <XBlack className={Styles['x']} />
    </div>
  )
}

export default InputCommision