import React from 'react'

//Styles
import Styles from './entry-data-pembeli.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import TextLarge from 'pages/dashboard/e-reporting/forms/components/reuseables/text-large'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonIconCustom from 'pages/dashboard/e-reporting/forms/components/reuseables/button-icon-custom/ButtonIconCustom'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Assets
import { ReactComponent as ArrowDown } from 'assets/arrow-down-with-tail.svg'

import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function EntryDataPembeli() {
  const { setCurrentStep, handlePrevState } = useFormMethod();
  const { isRent } = useFormState();

  const handleNext = () => {
    handlePrevState()
    setCurrentStep(STEPS.namaPembeliProperti);
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <Order withBg={true}>5. </Order>
        <TextMedium required>
          Isi data {isRent ? "penyewa" : "pembeli"} properti
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <TextLarge>
          Lengkapi data {isRent ? "penyewa" : "pembeli"} properti
        </TextLarge>
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            <ButtonIconCustom
              text={'Mulai  mengisi'}
              Icon={ArrowDown}
              onClick={handleNext}
            />
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default EntryDataPembeli