import React, { useMemo, useContext } from 'react'

//CSS
import "./style.scss"

//Components
import BodyNav from './body-nav/BodyNav';
import EmptyData from "./empty-data";
import Table from "./table";

//Context
import { DashboardCtx } from "../context/LeadDashboard";

const Body = () => {
  const { leadsLists, } = useContext(DashboardCtx);

  const isEmpty = useMemo(() => {
    if (!leadsLists?.length || !leadsLists) return true;

    return false
  }, [leadsLists])

  return (
    <div className='dashboard-leads-body'>
      <BodyNav />
      {isEmpty ? <EmptyData /> : <Table />}
    </div>
  )
}

export default Body