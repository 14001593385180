import React from 'react'

import Modal from 'react-modal';

const _customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0
  },
};
const CModal = ({customStyles, ...props}) => {
  return <Modal {...props} style={customStyles ?? _customStyles} />
}
Modal.setAppElement('#root');

export default CModal