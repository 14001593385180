import { useEffect } from "react";

//Styles
import "./is-colisting-penjual.module.scss";

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

//Components - reuseables
import BinaryQuestion from "pages/dashboard/e-reporting/forms/components/reuseables/binary-question";

function IsCoListingPenjual() {
  const { form, isAprroval, isEditing, userLoginAuth } = useFormState();

  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();

  //Functions
  const handleNextStep = (val) => {
    handlePrevState()
    formHandler('is_co_listing_penjual', val)
    if (val === 'tidak') {
      setCurrentStep(STEPS.biayaReferralPenjual);
    } else if (val === 'ya') {
      setCurrentStep(STEPS.pilihAsistenPenjual);
    }
  }

  useEffect(() => {
    const kantorPenjual = form.kantor_marketing_penjual.value.id;

    if(kantorPenjual !== userLoginAuth.company.id) {
      formHandler('is_co_listing_penjual', 'tidak')
      setCurrentStep(STEPS.biayaReferralPenjual);
    }
  }, [])

  return (
    <BinaryQuestion
      title="Marketing"
      question="Apakah ada co-listing?"
      onNext={handleNextStep}
      value={form.is_co_listing_penjual.value}
      disabledAll={isAprroval || isEditing}
    />
  )
}

export default IsCoListingPenjual