import React, { useState } from 'react'

import "./style.scss"

function CheckBoxes({ boxes = [], onChange, data }) {
  const handleCheck = (box) => {
    try {
      const findData = data.filter((f, k) => f?.facility_id == box?.facility_id)
      return findData.length ? true : false
    } catch (error) {
      return false
    }
  }
  return (
    <div className='checkboxes'>
      {boxes?.length ? (boxes?.map((box, key) => {
        return (<div key={`checkboxes-${key}`} className="checkbox-box"><input onChange={() => onChange(box)} type="checkbox" name={box?.name ?? ""} checked={handleCheck(box)} /> <span>{box?.name ?? ""}</span></div>)
      })) : null}
    </div>
  )
}

export default CheckBoxes