import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

//Assets
import CenturyLogo from "../../assets/century-21-logo.png"
import { ReactComponent as ReloadLogo } from "../../assets/reload.svg"
import { ReactComponent as DoubleCheckIcon } from "../../assets/double-green-check.svg"
import { ReactComponent as RedCrossIcon } from "../../assets/red-cross.svg"

//Component
import { CTextInputV1, Button, CInputPasswordV1, BackButtonV1 } from "../../components";
import { ModalLoader } from 'components/organism'

//Context
import { GlobalContext } from "../../contexts";

//Utils
import { debounce } from "../../utils";

//Action
import { authAction, confirmOtp, createNewPassword, requestOtp } from "../../actions";

const Login = () => {
  const navigate = useNavigate()
  const { loginAction, tokenValidation, accessToken } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState("email");
  const [errorcreatepass, seterrorcreatepass] = useState(false);
  const [form, setForm] = useState({
    email: {
      value: "",
      disabled: true,
      isValid: false,
      notFound: false,
      passwordExist: false
    },
    password: {
      value: "",
      disabled: false
    },
    password_confirmation: {
      value: "",
      disabled: false
    }
  })
  const [otp, setOtp] = useState("")

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    const newForm = { ...form };
    newForm[name].value = value;
    newForm[name].isValid = false;
    newForm[name].notFound = false;

    if (name === "email") {
      newForm[name].value = value.toLowerCase();
    }

    setForm({ ...newForm })

    if (name === "email") {
      fireCheckEmail()
    }
  }

  const checkEmailHandler = () => {
    setLoading(true)
    setTimeout(() => {
      authAction
        .checkEmail({ email: form.email.value })
        .then(res => {
          const newForm = { ...form };
          newForm.email.isValid = true;
          newForm.email.passwordExist = res?.data?.password_exist;
          setForm({ ...newForm })
        })
        .catch(err => {
          const newForm = { ...form };
          newForm.email.notFound = true;
          setForm({ ...newForm })

          // console.log(err)
        })
        .finally(() => setLoading(false))
    }, 1000)
  }

  const loginHandler = () => {
    if (form.password.value !== '' && form.password_confirmation.value !== '') {
      if (form.password.value !== form.password_confirmation.value) {
        seterrorcreatepass(true);
      } else {
        seterrorcreatepass(false);
        const password = {
          password: form.password.value,
          password_confirmation: form.password.value,
        }
        const data = { sign_in: { email: form.email.value, ...password } };

        loginAction(data, (token, path = "/") => {
          if (tokenValidation(token)) {
            navigate(path)
          }
        })
      }
    } else {
      const password = {
        password: form.password.value,
        password_confirmation: form.password.value,
      }
      const data = { sign_in: { email: form.email.value, ...password } };

      loginAction(data, (token, path = "/") => {
        if (tokenValidation(token)) {
          navigate(path)
        }
      })
    }
  }

  const handleOnKeyDown = (e) => {
    const name = e.target.name
    if (e.key != "Enter") return;

    switch (name) {
      case "email": {
        checkEmailHandler()
        break;
      }
      case "password": {
        loginHandler()
        break;
      }
      default: break;
    }
  }

  const fireCheckEmail = useCallback(debounce(checkEmailHandler, 2000), []);

  const checkPass = () => {
    if (form.email.passwordExist) {
      setProcess("password")
    } else {
      setProcess("sandi-baru")
    }
  }

  const handleRequestOtp = async () => {
    try {
      setLoading(true)
      const data = {
        email: form.email.value
      }
      const res = await requestOtp(data)
      setProcess("lupa-sandi")
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const confirmasiOtp = async () => {
    try {
      setLoading(true)
      const data = {
        email: form.email.value,
        otp: otp
      }
      const res = await confirmOtp(data)
      setProcess("sandi-baru")
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitPasswordBaru = async () => {
    try {
      setLoading(true)
      const data = {
        email: form.email.value,
        password: form.password.value,
        password_confirmation: form.password_confirmation.value
      }
      const res = await createNewPassword(data)
      toast.success("Berhasil membuat sandi baru")
      setForm({
        email: {
          value: "",
          disabled: true,
          isValid: false,
          notFound: false,
          passwordExist: false
        },
        password: {
          value: "",
          disabled: false
        },
        password_confirmation: {
          value: "",
          disabled: false
        }
      })
      setProcess("email")
    } catch (err) {
      toast.error("Gagal membuat sandi baru")
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (accessToken?.token) {
      navigate("/")
    }
  }, [])

  const RenderComponent = () => {
    switch (process) {
      case "email": {
        return (
          <div className='login-box'>
            <img src={CenturyLogo} alt="logo" className='login-logo' />
            <p className='header-text'>Masuk</p>

            <CTextInputV1
              onChange={onChangeHandler}
              name="email"
              value={form.email.value}
              disabled={loading}
              className="login-input"
              placeholder="example@century21.com"
              onKeyDown={handleOnKeyDown}
              CustomInfo={() => {
                if (form.email.isValid) {
                  return <div className='custom-info'><DoubleCheckIcon /> <span>Email kamu tersedia dalam sistem kami. Silahkan pilih selanjutnya untuk memasukkan kata sandi</span></div>
                }
                if (form.email.notFound) {
                  return <div className='custom-info'><RedCrossIcon /> <span>Tidak ditemukan email kamu di sistem kami. Silahkan menghubungi admin Century 21 Indonesia Pusat</span></div>
                }
                if (loading) return <div className='custom-info'><ReloadLogo className='rotate-infinite' /> <span>Kami sedang memeriksa ketersediaan email kamu...</span></div>
                return null
              }}
            />

            <Button
              action={() => checkPass()}
              disabled={!form.email.isValid}
              loading={loading}
              className="login-btn text-white"
              text="Selanjutnya"
            />
          </div>
        )
      }
      case "password": {
        return <div className='login-box'>
          <img src={CenturyLogo} alt="logo" className='login-logo' />
          <p className='header-text'>Masuk</p>

          <CInputPasswordV1
            onChange={onChangeHandler}
            name="password"
            value={form.password.value}
            className="login-input"
            placeholder="Kata sandi"
            onKeyDown={handleOnKeyDown}
          />

          <Button
            action={loginHandler}
            disabled={form.password.disabled}
            className="password-btn text-white"
            text="Masuk"
          />

          <p className='forgot-password' onClick={() => handleRequestOtp()}>Lupa kata sandi?</p>

          <div className='back-btn-wrapper'>
            <BackButtonV1
              onClick={() => { setProcess("email") }}
            />
          </div>
        </div>
      }
      case "lupa-sandi": {
        return <div className='login-box'>
          <img src={CenturyLogo} alt="logo" className='login-logo' />
          <p className='header-text'>Masukkan OTP</p>

          <CTextInputV1
            onChange={(e) => setOtp(e.target.value || "")}
            name="email"
            type={'number'}
            value={otp}
            className="login-input"
            placeholder="Masukkan OTP"
            onKeyDown={handleOnKeyDown}
            CustomInfo={() => {
              return <div className='custom-info'><span>Kami telah mengirimkan OTP ke nomor WhatsApp terdaftar Anda. Pastikan Anda memasukkan kode dengan benar.</span></div>
            }}
          />

          <Button
            action={confirmasiOtp}
            disabled={(otp.length === 6 && !loading) ? false : true}
            className="kirim-link-btn  text-white"
            text="Buat Kata Sandi Baru"
          />

          <p className='resend-otp' onClick={handleRequestOtp}>Belum terima OTP?<span>Kirim Ulang OTP</span></p>

          <div className='back-btn-wrapper'>
            <BackButtonV1
              text='Kembali masukkan kata sandi'
              onClick={() => { setProcess("password") }}
            />
          </div>

        </div>
      }
      case "sandi-baru": {
        return <div className='login-box'>
          <img src={CenturyLogo} alt="logo" className='login-logo' />
          <p className='header-text'>Buat Kata Sandi Baru</p>

          <CInputPasswordV1
            onChange={onChangeHandler}
            name="password"
            value={form.password.value}
            className="sandi-baru"
            placeholder="Kata sandi baru"
          />
          <p className='info-new-password'>Masukkan kata sandi baru</p>

          <CInputPasswordV1
            onChange={onChangeHandler}
            name="password_confirmation"
            value={form.password_confirmation.value}
            className="confirm-sandi"
            placeholder="Konfirmasi kata sandi"
          />
          <p className='info-new-password last'>Tulis kembali kata sandi baru. Pastikan kata sandi yang kamu masukkan sama dengan kata sandi baru pada isian sebelumnya.</p>
          {errorcreatepass && <div className='error-custom-info'> <span>Kata sandi baru dan konfirmasi kata sandi baru tidak sesuai</span></div>
          }
          <Button
            action={handleSubmitPasswordBaru}
            disabled={(form.password_confirmation.value === '' || form.password.value === '') || loading}
            className="password-btn  text-white"
            text="Buat kata sandi baru"
          />
        </div>
      }
      default: return null
    }
  }

  return (
    <div id='login-wrapper'>
      {RenderComponent()}
      {loading && <ModalLoader open={true} />}
      <ToastContainer />
    </div>
  )
}

export default Login