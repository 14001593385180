import React from 'react'

import InfoIcon from "../../../assets/info.png"

function CTextareaInputV1({ 
  label,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  error,
  info,
  className,
  textareaClassName
}) {
  return (
    <div className={`c-textarea-v1 ${className}`}>
      <p className='label'>{label}</p>
      <div className={`textarea-wrapper ${textareaClassName} ${disabled ? "disabled" : ""}`}>
        <textarea 
          name={name} 
          value={value}
          placeholder={placeholder} 
          disabled={disabled}
          onChange={onChange}
        />
      </div>
      {info ? <div className='print-info'><img className='info-icon' src={InfoIcon} /><span>{info}</span></div> : null}
      {error ? <p className='print-error'>{error}</p> : ""}
    </div>
  )
}

CTextareaInputV1.defaultProps = {
  label: "",
  value: "",
  error: "",
  name: "",
  placeholder: "",
  disabled: false,
  onChange: () => {}
}

export default CTextareaInputV1