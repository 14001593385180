import React from 'react'

//Styles
import Styles from "./input-biaya-referal-pembeli.module.scss";

//Components
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya'
import InfoIconGolden from 'pages/dashboard/e-reporting/forms/components/reuseables/info-icon-golden/InfoIconGolden'
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium/TextMedium';
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InputCurrency from 'pages/dashboard/e-reporting/forms/components/reuseables/input-currency'

//Utils
import { classModuleGrouping } from "utils/classModuleGrouping";
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function InputBiayaReferalPembeli() {
  const { formHandler, setCurrentStep, detectAgentRole, handlePrevState } = useFormMethod();
  const {
    form,
    isAprroval,
    isEditing
  } = useFormState();

  const classHandler = (strngs) => classModuleGrouping(Styles, strngs);

  const handleNext = () => {
    handlePrevState()
    if (isAprroval || isEditing) {
      if (detectAgentRole() == 'S1B1' || detectAgentRole() == 'S1XX' || detectAgentRole() == 'S1B0') {
        setCurrentStep(STEPS.entryDataPropertiDanTransaksi)
      } else {
        setCurrentStep(STEPS.pilihTipeListing)
      }
    }
    else setCurrentStep(STEPS.entryPilihListing)
  }

  return (
    <div className={classHandler('wrapper')}>
      <div className={classHandler('box')}>
        <div className={"!ml-[30px]"}>
          <div className={"!mb-[8px]"}>
            <TextMedium>
              Apakah ada biaya <span className={Styles['gold']}>refferal pembeli</span>? Silahkan masukkan berapa persen biaya refferal yang disisihkan dari komisi yang diterima pembeli. Jika ada
            </TextMedium>
          </div>
          <div className={"!mb-[8px]"}>
            <InputCurrency
              disabled={isAprroval}
              onValueChange={(e) => formHandler('biaya_refferal_pembeli', e)}
              value={form.biaya_refferal_pembeli.value || ""}
              placeholder={'Berapa rupiah?'}
            />
          </div>
          <InfoIconGolden>
            <span className={Styles['bold']}>Biaya refferal</span> adalah biaya yang dikeluarkan oleh marketing sebagai komisi kepada orang (non marketing) yang membantu marketing tersebut dalam melakukan transaksi.
          </InfoIconGolden>
          <BoxFlex>
            <>
              <ButtonSebelumnya />
              <ButtonSelanjutnya onClick={handleNext} />
            </>
          </BoxFlex>
        </div>
      </div>
    </div>
  )
}

const withInputBiayaReferalPembeli = (Component) => (props) => {
  const { form, userLoginAuth, isAprroval, isEditing } = useFormState();
  const { setCurrentStep, detectAgentRole } = useFormMethod();
  const [isRender, setIsRender] = React.useState(false)

  React.useEffect(() => {
    const kantorPembeli = form.kantor_marketing_pembeli.value.id;

    if (kantorPembeli !== userLoginAuth.company.id) {

      if (isAprroval || isEditing) {
        if (detectAgentRole() == 'S1B1' || detectAgentRole() == 'S1XX' || detectAgentRole() == 'S1B0') {
          setCurrentStep(STEPS.entryDataPropertiDanTransaksi)
        } else {
          setCurrentStep(STEPS.pilihTipeListing)
        }
      }
      else setCurrentStep(STEPS.entryPilihListing)

    } else {
      setIsRender(true)
    }
  }, [form.is_co_broking.value])

  if (!isRender) return null;
  return (
    <Component {...props} />
  )
}

export default withInputBiayaReferalPembeli(InputBiayaReferalPembeli)