import React from 'react'

//Styles
import Styles from './telepon-debitur.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'
import InfoIconGolden from 'pages/dashboard/e-reporting/forms/components/reuseables/info-icon-golden/InfoIconGolden'

//Assets
import { ReactComponent as Phone } from 'assets/phone.svg'

//Hooks
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function TeleponDebitur() {
  //Context
  const { setCurrentStep, formHandler, handlePrevState } = useFormMethod();
  const { disabledAll, form } = useFormState()

  //State
  const [value, setValue] = React.useState(form.debtor_phone.value ?? '')

  const onNext = () => {
    handlePrevState()
    formHandler('debtor_phone', value)
    setCurrentStep(STEPS.totalKomisiMarketing)
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Remove leading zeros
    const sanitizedValue = inputValue.replace(/^0+/, '');

    // Allow only numbers
    if (/^\d*$/.test(sanitizedValue)) {
      setValue(sanitizedValue);
    }
  };


  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium>
          Nomor Telepon Debitur
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <InputText
          value={value}
          disabled={disabledAll}
          type="tel"
          Icon={Phone}
          onChange={handleChange}
          placeholder="Tulis nomor telepon"
        />
        <InfoIconGolden className={Styles['telepon-debitur-info']}>
          Tidak wajib diisi (optional)
        </InfoIconGolden>
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            <ButtonSelanjutnya onClick={onNext} />
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default TeleponDebitur