import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

//Styles
import "./style.scss"

//Components
import Calendar from "pages/dashboard/key-metric-office-dashboard/reuseable-component/calendar"
import PriceCard from "pages/dashboard/key-metric-office-dashboard/reuseable-component/price-card"
import ActiveMarketingCard from "pages/dashboard/key-metric-office-dashboard/reuseable-component/active-marketing-card"
import InformasiYangDitampilkan from "pages/dashboard/key-metric-office-dashboard/reuseable-component/informasi-yang-ditampilkan"
import Chart from "pages/dashboard/key-metric-office-dashboard/reuseable-component/chart"

//API
import { getWeeklyActiveAgent, getSummary } from "actions/quartery-reporting";

//Context
import { useDetailListingCtx } from "../ctxDataKeseluruhan";

function BarisPertama() {
  const {
    numberOfRange,
    periodRange,
    setPeriodRange,
    setNumberOfRange
  } = useDetailListingCtx();

  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [currentDate, setCurrentDate] = useState({
    from: "",
    to: ""
  })

  //Weekly active agent
  const [waaData, setWaaData] = useState({
    "total": 0,
    "percent": 0,
    "summary": {
      "waa": 0,
      "all_user_active": 0
    }
  })
  const [waaLoading, setWaaLoading] = useState(true)

  //Summary
  const [summary, setSummary] = useState({
    "gross_clossed_commision": {
      "amount": 0,
      "unit": 0
    },
    "sale_commision": {
      "amount": 0,
      "unit": 0
    },
    "rent_commision": {
      "amount": 0,
      "unit": 0
    }
  })
  const [summaryLoading, setSummaryLoading] = useState(true)

  //Functions - API handler
  const handleGetWeeklyActiveAgents = async (from, to) => {
    try {
      setWaaLoading(true)
      const query = `?date[from]=${from}&date[to]=${to}`
      const getWAA = await getWeeklyActiveAgent(query)
      setWaaData(getWAA?.data ?? {})
    } catch (error) {
      console.log(error)
    } finally {
      setWaaLoading(false)
    }
  }

  const handleSummary = async (from, to) => {
    try {
      setSummaryLoading(true)
      const query = `?date[from]=${from}&date[to]=${to}`
      const getWAA = await getSummary(query)
      setSummary(getWAA?.data ?? {})
    } catch (error) {
      console.log(error)
    } finally {
      setSummaryLoading(false)
    }
  }

  /**USE EFFECTS */
  useEffect(() => {
    setCurrentDate({
      from: dayjs(selectedDate).subtract(numberOfRange || 1, periodRange.value).add(1, 'day').format("YYYY-MM-DD"),
      to: dayjs(selectedDate).format("YYYY-MM-DD")
    })
  }, [numberOfRange, periodRange, selectedDate])

  useEffect(() => {
    const { from, to } = currentDate;
    if (from && to) {
      handleGetWeeklyActiveAgents(from, to)
      handleSummary(from, to)
    }
  }, [currentDate])

  /** DISPLAY */
  return (
    <div>
      <div className='period-wrapper'>
        <div className='calendar-wrapper'>
          <Calendar
            selectedDate={selectedDate.toDate()}
            currentDate={currentDate}
            setDate={(date) => setSelectedDate(dayjs(date))}
          />
        </div>
        <InformasiYangDitampilkan
          hideInfoLabel={true}
          periodRange={periodRange}
          setPeriodRange={setPeriodRange}
          setNumberOfRange={setNumberOfRange}
          hideIcon
        />
      </div>
      <div className='baris-pertama'>
        {/* <div className='card-wrapper'>
          <div className='header-wrapper'>
            <div className='calendar-wrapper'>
              <Calendar
                selectedDate={selectedDate.toDate()}
                currentDate={currentDate}
                setDate={(date) => setSelectedDate(dayjs(date))}
              />
            </div>
          </div>
          <ActiveMarketingCard
            total={waaData?.summary?.waa}
            point={waaData?.total}
            loading={waaLoading}
          />
          <PriceCard
            amount={summary?.gross_clossed_commision?.amount}
            unit={summary?.gross_clossed_commision?.unit}
            loading={summaryLoading}
          />
        </div> */}
        <div className='card-wrapper'>
          <Chart _selectedDate={selectedDate} />
        </div>
      </div>
    </div>
  )
}

export default BarisPertama