import React from 'react'

function CModal({ toggle, children, className, id }) {
  return (
    <div id={id} className={`c-modal ${className}`}>
      <div className='backdrop' onClick={toggle} />
      {children}
    </div>
  )
}

CModal.defaultProps = {
  className: ""
}

export default CModal