import React from 'react'

//Components
import ComponentsRenderer from "./ComponentsRenderer";

//Context
import { UploadListingProvider } from "../../../contexts";

function Wrapper() {
  return (
    <UploadListingProvider>
      <ComponentsRenderer />  
    </UploadListingProvider>
  )
}

export default Wrapper