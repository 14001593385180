import React from 'react'

//Styles
import "./style.scss";
import Loader from 'components/molecules/Loader'

function InfoCard({ Icon, tag, point, loading = false }) {
  if (loading) {
    return (
      <div className='kmod-info-loader-wrapper'>
        <Loader />
      </div>
    )
  }
  return (
    <div className='kmod-info-card'>
      {Icon ? <Icon className='kmod-info-card-icon' /> : null}
      <p className='kmod-info-tag'>{tag}</p>
      <p className='kmod-info-point'>{point}</p>
    </div>
  )
}

export default InfoCard